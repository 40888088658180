import { FC, useState } from 'react';
import { useForm, UseFormRegister } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../config/yupSchema';
import { Button } from '../Button';
import SHOW_ICON from '../../assets/icons/showPassword.png';
import HIDE_ICON from '../../assets/icons/hidePassword.png';
import * as S from './style';
import * as AuthSelectors from '../../store/selectors/auth';
import { ErrorAlert } from '../ErrorAlert';
import { useSelector } from 'react-redux';

interface IAuthForm {
  formTitle: string;
  submit: ({ email, password }: SignInFormValues) => void;
  isLoading?: boolean;
  error?: string;
}

interface ICodeForm {
  formTitle: string;
  submit: ({ code }: CodeValue) => void;
  isLoading?: boolean;
  codeMessage: string | null;
}

export interface SignInFormValues {
  email: string;
  password: string;
}

export interface CodeValue {
  code: number;
}

const Input = ({
  title,
  placeholder,
  schema,
  type,
  errorFields,
  register,
  updateCurrentField,
  changeableType,
}: {
  title: string;
  placeholder: string;
  schema: 'email' | 'password' | 'code';
  type?: string;
  errorFields: string[] | [];
  register: UseFormRegister<any>;
  updateCurrentField: (currentField: string) => void;
  changeableType?: boolean;
}) => {
  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const isInvalid = errorFields.some((field) => field === schema);

  const onInputFocus = () => {
    updateCurrentField(schema);
  };

  const onChangeInputType = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else if (inputType === 'text') {
      setInputType('password');
    }
  };

  return (
    <S.InputLabel isInvalid={isInvalid}>
      <S.InputTitle>{title}</S.InputTitle>
      <S.Input
        type={changeableType ? inputType : type}
        placeholder={placeholder}
        id={schema}
        onFocus={onInputFocus}
        {...register(schema)}
      />
      {changeableType && (
        <S.ShowHidePassButton onClick={onChangeInputType}>
          <img alt="logo" src={inputType === 'password' ? SHOW_ICON : HIDE_ICON} />
        </S.ShowHidePassButton>
      )}
    </S.InputLabel>
  );
};

export const AuthForm: FC<IAuthForm> = ({
  formTitle,
  submit,
  isLoading,
  error,
}): JSX.Element | null => {
  const { register, handleSubmit, formState } = useForm<SignInFormValues>({
    resolver: yupResolver(schemas.loginSchema),
  });

  const [currentField, setCurrentField] = useState<string>('');
  const serverError = useSelector(AuthSelectors.errorSelector)
  const formSubmitHandler = (data: SignInFormValues) => {
    submit(data);
  };

  const updateCurrentField = (fieldName: string) => {
    setCurrentField(fieldName);
  };

  const errorMessage = formState.errors[currentField];

  return (
    <S.Form>
      <S.FormLabel>{formTitle}</S.FormLabel>
      <Input
        errorFields={Object.keys(formState.errors)}
        register={register}
        title="Email"
        placeholder=""
        schema="email"
        updateCurrentField={updateCurrentField}
      />
      <Input
        errorFields={Object.keys(formState.errors)}
        register={register}
        type="password"
        title="Password"
        placeholder=""
        schema="password"
        updateCurrentField={updateCurrentField}
        changeableType
      />
      {(errorMessage || error || serverError) && (
        <ErrorAlert
          text={(errorMessage && errorMessage.message) || error}
          top="210px"
          width="100%"
        />
      )}
      <Button
        isLoading={isLoading}
        marginTop="70px"
        title="Sign in"
        variant="primary"
        width="250px"
        onClick={handleSubmit(formSubmitHandler)}
      />
    </S.Form>
  );
};

export const CodeForm: FC<ICodeForm> = ({ formTitle, submit, codeMessage }): JSX.Element | null => {
  const { register, handleSubmit, formState } = useForm<CodeValue>({
    resolver: yupResolver(schemas.codeSchema),
  });

  const [currentField, setCurrentField] = useState<string>('');

  const formSubmitHandler = ({ code }: CodeValue) => {
    submit({ code });
  };

  const updateCurrentField = (fieldName: string) => {
    setCurrentField(fieldName);
  };

  const errorMessage = formState.errors[currentField];

  return (
    <S.Form>
      <S.FormLabel>{formTitle}</S.FormLabel>
      <Input
        errorFields={Object.keys(formState.errors)}
        register={register}
        title="Code"
        placeholder=""
        schema="code"
        updateCurrentField={updateCurrentField}
      />
      {errorMessage && <ErrorAlert text={errorMessage.message} top="150px" width="100%" />}
      {!!codeMessage && <ErrorAlert text={codeMessage} top="150px" width="100%" />}
      <Button
        marginTop="70px"
        title="Submit"
        variant="primary"
        width="250px"
        onClick={handleSubmit(formSubmitHandler)}
      />
    </S.Form>
  );
};
