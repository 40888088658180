import styled from 'styled-components';
import { Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ContentPageContainer = styled.div`
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 50px 30px 0;
`

export const AccordionMenuSummary = styled(AccordionSummary)<{
  current?: boolean 
}>`
  width: 150px;
  font-weight: 600;
    ${props => ({
    color: `${props.theme.palette.secondary.main} !important`
  })};
  & > .MuiAccordionSummary-content {
    margin: 0;
  }
`

export const AccordionMenu = styled(Accordion)`
  padding: 0 0 0 5px !important;
  border-radius: 25px !important;
  box-shadow: none !important;
  font-weight: 600;
  width: 100%;
  text-align: center !important;
    ${props => ({
    color: props.theme.palette.secondary.main,
    background: `${props.theme.palette.light.main} !important`,
  })};
  
`

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin: 0 !important;
  padding: 0 !important;
`


export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledButton = styled.button`
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  position: relative;
  ${props => ({
    background: props.theme.palette.secondary.main,
    color: props.theme.palette.light.main
  })}
`

export const AddIcon = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  width: 20px;
  height: 20px;
  ${props => ({
    color: props.theme.palette.secondary.main,
    background: props.theme.palette.light.main,
  })};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`