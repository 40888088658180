import { FC } from 'react';
import JoditEditor from "jodit-pro-react";
import { StyledTextEditorContainer } from './style';

type PropsType = {
  content: string
  setContent: (newContent: string) => void
}

export const TextEditor: FC<PropsType> = ({ 
  content,
  setContent
}) => {
  return (
    <StyledTextEditorContainer>
      <JoditEditor
        value={content}
        onChange={setContent}
      />
    </StyledTextEditorContainer>
  );
}