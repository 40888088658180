import { AddPlantResponse } from '../interfaces';
import { AddPlantResponseAPI } from '../interfaces/api/addPlantResponse';

export const addPlantResponseMapper = (
  response: AddPlantResponseAPI
): AddPlantResponse => {
  const { crop, plant_action, plaant_unit, pod } = response;
  return {
    uuid: response.uuid,
    active: response.active,
    photo: response.photo,
    video: response.video,
    plantActionDate: response?.plant_action_date,
    plantAction: {
      uuid: plant_action.uuid,
      name: plant_action.name,
      description: plant_action.description,
      plantAge: plant_action?.age,
    },
    plaantUnit: {
      uuid: plaant_unit.uuid,
      active: plaant_unit.active,
      name: plaant_unit.name,
      podLimit: plaant_unit.pod_limit,
    },
    pod: {
      number: pod.number,
    },
    crop: {
      name: crop.name,
      active: crop.active,
      alias: crop.alias,
      photo: crop.photo,
      video: crop.video,
      icon: crop.icon,
      shortDescription: crop.shortDescription,
      fullDescription: crop.fullDescription,
      uuid: crop.uuid,
      harvest: crop.harvest,
      replant: crop.replant,
      lifespan: crop.lifespan,
      nutrition: crop.nutrition,
      flavor: crop.flavor,
    },
  };
};
