import { FC, useLayoutEffect, useState } from 'react';
import { BrowserHistory } from 'history';
import { Router, RouterProps } from 'react-router-dom';

export const AppNavigator: FC<
  Omit<RouterProps, 'location' | 'navigationType'> & {
    navigator: BrowserHistory;
  }
> = ({ navigator: history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
