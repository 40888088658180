import { SuccessResponse } from '../interfaces';
import { CropAPI } from '../interfaces/api/crop';
import { Crop } from '../interfaces/crop';
import { cropMapper } from '../mappers/cropMapper';
import { ApiService } from './apiService';

export class CropService {
  static async searchCrops(name: string, limit = 25): Promise<Crop[]> {
    return ApiService.api
      .get<SuccessResponse<CropAPI[]>>(
        `/crop/search?name=${name}&limit=${limit}`
      )
      .then((response) => cropMapper(response.data.data));
  }
}
