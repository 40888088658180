import { FC } from "react";
import { Button } from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";
import { PopUpLayout } from "../../../../layouts/PopUp";
import * as S from './style';

interface DeletePopupProps {
  openDeletePopup: boolean;
  uploading: boolean;
  onDeletePopupSubmit: () => void;
  onCloseDeletePopup: () => void;
}

export const DeletePopup: FC<DeletePopupProps> = ({
  openDeletePopup,
  uploading,
  onDeletePopupSubmit,
  onCloseDeletePopup,
}) => {
  return(
    <PopUpLayout
      open={openDeletePopup}
      onClose={onCloseDeletePopup}
    >
      <S.PopUpContainer>
        <S.PopupTitle>
          Are you sure that you want to delete this firmware?
        </S.PopupTitle>
        <S.PopupButtonsContainer>
          <Button width='80px' title='Yes' variant='primary' onClick={onDeletePopupSubmit}/>
          <Button width='80px' title='No' variant='warning' onClick={onCloseDeletePopup}/>
        </S.PopupButtonsContainer>

        <S.PopupWarning>
          Warning: firmware will be deleted permanently!
        </S.PopupWarning>
        {uploading && <Loader position='absolute' bottom='0' bubbleSize='20px' width='100px'/>}
      </S.PopUpContainer>
    </PopUpLayout>
  )
}