import React from 'react';
import { Icon } from '@plant/data';

const SeedReplantIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.54543 5.05892C7.83372 3.07576 11.0421 2.43061 13.9061 2.21179C14.0487 2.20212 14.2188 2.3073 14.2188 2.50133V11.3602C14.2188 11.5138 14.0997 11.6401 13.9474 11.6498C11.2944 11.816 8.5822 12.0936 7.4978 13.7305C7.38296 13.904 7.12848 13.904 7.01364 13.7305C6.11759 12.378 4.1102 12.3056 1.94311 12.2275C1.48753 12.2111 1.02489 12.1945 0.564038 12.1656C0.411112 12.1559 0.292034 12.029 0.292034 11.876V3.01713C0.292034 2.84728 0.435897 2.71611 0.604533 2.7276C0.756757 2.73926 0.909955 2.75067 1.06364 2.76211C3.80117 2.96595 6.69215 3.18121 6.96516 5.05954V12.4719C6.96516 12.6321 7.09511 12.7621 7.25529 12.7621C7.41548 12.7621 7.54543 12.6321 7.54543 12.4719V5.05892ZM11.5776 7.09982C11.7637 6.19349 12.0406 5.74509 12.3719 5.58889C12.4323 5.56041 12.4139 5.46977 12.3472 5.46648C10.5238 5.37024 8.64911 6.0113 8.81266 7.58028L8.81349 7.58825C8.82725 7.72106 8.84565 7.89867 9.06068 7.77822C9.114 7.74836 9.15556 7.72396 9.19188 7.70263C9.3039 7.63686 9.36618 7.60029 9.57046 7.52261C9.54199 7.53467 9.41828 7.60109 9.32347 7.87374C9.20374 8.11571 9.32801 8.41871 9.5864 8.49195C10.3193 8.69966 11.3376 8.26939 11.5776 7.09982ZM9.57648 7.52032C9.57446 7.52109 9.57246 7.52185 9.57046 7.52261C9.57437 7.52095 9.57648 7.52032 9.57648 7.52032Z"
    />
  </svg>
);

export default React.memo(SeedReplantIcon);
