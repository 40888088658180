import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const pushNotificationsSelector = (state: AppState) => state.pushNotifications;

export const usersSelector = createSelector(pushNotificationsSelector, (state) => state.users);
export const subjectSelector = createSelector(pushNotificationsSelector, (state) => state.subject);
export const bodySelector = createSelector(pushNotificationsSelector, (state) => state.body);
export const loadingSelector = createSelector(pushNotificationsSelector, (state) => state.loading);
export const SelectedAllSelector = createSelector(
  pushNotificationsSelector,
  (state) => state.selectedAll,
);
export const searchUsersSelector = createSelector(pushNotificationsSelector, (state) => state.data);
