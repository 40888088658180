import { SoftwareService, UserService, UserAdminService, VersionApi } from '@plant/data';
import { toast } from 'react-toastify';
import { takeLatest, Effect, call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  addVersion,
  getVersionDetails,
  getVersionsList,
  deleteVersion,
  updateVersionDetails,
  getUnitSerials,
} from '../actions/software';

export class SoftwareSagaWorker {
  static *getVersions() {
    try {
      const data: SagaReturnType<typeof SoftwareService.getVersionsList> = yield call(
        SoftwareService.getVersionsList,
      );

      yield put(getVersionsList.success({ data }));
    } catch (error: any) {
      yield put(getVersionsList.failure(error?.response?.data));
      toast(`${error.message}`, { type: 'error' });
    }
  }

  static *addVersion(action: ActionType<typeof addVersion.request>) {
    try {
      const { data }: SagaReturnType<typeof SoftwareService.addVersion> = yield call(
        SoftwareService.addVersion,
        action.payload.formData,
      );
      yield put(addVersion.success(data));
      toast('Version uploaded');
      yield call(action.payload.successCallback);
    } catch (error: any) {
      yield put(addVersion.failure(error?.response?.data?.message || 'error'));
      toast(error?.response?.data?.message || 'error', { type: 'error' });
    }
  }

  static *getVersionDetails(action: ActionType<typeof getVersionDetails.request>) {
    try {
      const data: SagaReturnType<typeof SoftwareService.getVersionDetails> = yield call(
        SoftwareService.getVersionDetails,
        action.payload,
      );

      yield put(getVersionDetails.success(data));
    } catch (error: any) {
      yield put(getVersionDetails.failure(error?.response?.data?.message || 'error'));
      toast(error?.response?.data?.message || 'error', { type: 'error' });
    }
  }

  static *getUnitSerials() {
    try {
      const data: SagaReturnType<typeof SoftwareService.getUnitSerials> = yield call(SoftwareService.getUnitSerials);
      yield put(getUnitSerials.success(data));
    } catch (error: any) {
      yield put(getUnitSerials.failure(error?.response?.data?.message || 'error'));
      toast(error?.response?.data?.message || 'error', { type: 'error' });
    }
  }

  static *updateVersionDetails(action: ActionType<typeof updateVersionDetails.request>) {
    try {
      const { userInfo, ...other } = action.payload.updatedVersion;
      const version = {
        ...other,
        users: action.payload.updatedVersion.userInfo.map((user) => user.email),
      };
      yield call(SoftwareService.updateVersion, version);

      yield put(updateVersionDetails.success(action.payload.updatedVersion));
      yield call(action.payload.callback);
      toast('Version updated successfully', { type: 'success' });
    } catch (error: any) {
      yield put(updateVersionDetails.failure(error?.response?.data?.message || 'error'));
      toast(error?.response?.data?.message || 'error', { type: 'error' });
    }
  }
  static *deleteVersion(action: ActionType<typeof deleteVersion.request>) {
    try {
      yield call(SoftwareService.deleteVersion, action.payload.id);
      yield put(deleteVersion.success());
      yield call(action.payload.callback);
    } catch (error: any) {
      yield put(deleteVersion.failure(error?.response?.data?.message || 'error'));
      toast(error?.response?.data?.message || 'error', { type: 'error' });
    }
  }
}

export function* softwareSaga(): Generator<Effect, void> {
  yield takeLatest(getVersionsList.request, SoftwareSagaWorker.getVersions);
  yield takeLatest(addVersion.request, SoftwareSagaWorker.addVersion);
  yield takeLatest(deleteVersion.request, SoftwareSagaWorker.deleteVersion);
  yield takeLatest(getUnitSerials.request, SoftwareSagaWorker.getUnitSerials);
  yield takeLatest(getVersionDetails.request, SoftwareSagaWorker.getVersionDetails);
  yield takeLatest(updateVersionDetails.request, SoftwareSagaWorker.updateVersionDetails);
}
