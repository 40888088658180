import React from 'react';
import { Icon } from '@plant/data';

const HarvestIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.22238 9.94459C5.22238 9.4201 5.06294 8.90744 4.76493 8.47585L5.80771 7.59643C6.0601 8.24711 6.4353 8.9378 6.96148 9.33559C8.16256 10.237 11.0171 12.3022 11.8058 12.491C12.5946 12.6803 14.4609 12.2273 14.4609 12.2273L7.38116 6.27825L14.4609 0.329155C14.4609 0.329155 12.6018 -0.120972 11.8132 0.06663C11.0245 0.254232 8.1699 2.3195 6.96148 3.22087C6.43191 3.61748 6.05729 4.30985 5.80771 4.95887L4.76043 4.0789C5.24942 3.364 5.3514 2.45353 5.03253 1.64806C4.71423 0.843026 4.01678 0.248679 3.17122 0.0616396C2.32617 -0.1254 1.44288 0.119103 0.814657 0.715131C0.185944 1.3106 -0.105872 2.17926 0.0355357 3.03333C0.176939 3.88739 0.732971 4.61586 1.52 4.97699C2.30701 5.33866 3.22194 5.28571 3.96165 4.83615L5.3633 6.0147C5.36386 6.0147 5.37513 6.11273 5.41682 6.27835C5.38076 6.44397 5.3633 6.54539 5.3633 6.54539L3.96165 7.72056C3.30139 7.32451 2.49911 7.2417 1.77238 7.49521C1.04508 7.74872 0.467633 8.31153 0.196628 9.03202C-0.074347 9.75313 -0.0118127 10.557 0.367891 11.2269C0.747594 11.8968 1.40504 12.3632 2.16271 12.5007C2.92043 12.6381 3.69953 12.4325 4.29054 11.939C4.88151 11.4449 5.2229 10.7143 5.22234 9.94472L5.22238 9.94459ZM1.39044 2.61185C1.39101 2.11157 1.69241 1.66088 2.15493 1.46935C2.61688 1.27724 3.14925 1.38259 3.50362 1.73582C3.85797 2.08905 3.96501 2.62086 3.77516 3.08395C3.5853 3.54703 3.13518 3.85012 2.63492 3.85237C2.30535 3.8535 1.98874 3.72336 1.75495 3.4907C1.52171 3.25803 1.39046 2.94198 1.39046 2.61185L1.39044 2.61185ZM1.39044 9.94401L1.39044 9.94457C1.38988 9.44374 1.69071 8.99193 2.15268 8.79926C2.61463 8.6066 3.14757 8.71138 3.50249 9.0646C3.85741 9.41726 3.96557 9.94907 3.77572 10.4127C3.58587 10.8758 3.13574 11.1795 2.63491 11.1817C2.30591 11.1828 1.98929 11.0533 1.75606 10.8212C1.52282 10.5891 1.39101 10.2736 1.39043 9.94457L1.39044 9.94401Z" />
    <path d="M5.22238 9.94459C5.22238 9.4201 5.06294 8.90744 4.76493 8.47585L5.80771 7.59643C6.0601 8.24711 6.4353 8.9378 6.96148 9.33559C8.16256 10.237 11.0171 12.3022 11.8058 12.491C12.5946 12.6803 14.4609 12.2273 14.4609 12.2273L7.38116 6.27825L14.4609 0.329155C14.4609 0.329155 12.6018 -0.120972 11.8132 0.06663C11.0245 0.254232 8.1699 2.3195 6.96148 3.22087C6.43191 3.61748 6.05729 4.30985 5.80771 4.95887L4.76043 4.0789C5.24942 3.364 5.3514 2.45353 5.03253 1.64806C4.71423 0.843026 4.01678 0.248679 3.17122 0.0616396C2.32617 -0.1254 1.44288 0.119103 0.814657 0.715131C0.185944 1.3106 -0.105872 2.17926 0.0355357 3.03333C0.176939 3.88739 0.732971 4.61586 1.52 4.97699C2.30701 5.33866 3.22194 5.28571 3.96165 4.83615L5.3633 6.0147C5.36386 6.0147 5.37513 6.11273 5.41682 6.27835C5.38076 6.44397 5.3633 6.54539 5.3633 6.54539L3.96165 7.72056C3.30139 7.32451 2.49911 7.2417 1.77238 7.49521C1.04508 7.74872 0.467633 8.31153 0.196628 9.03202C-0.074347 9.75313 -0.0118127 10.557 0.367891 11.2269C0.747594 11.8968 1.40504 12.3632 2.16271 12.5007C2.92043 12.6381 3.69953 12.4325 4.29054 11.939C4.88151 11.4449 5.2229 10.7143 5.22234 9.94472L5.22238 9.94459ZM1.39044 2.61185C1.39101 2.11157 1.69241 1.66088 2.15493 1.46935C2.61688 1.27724 3.14925 1.38259 3.50362 1.73582C3.85797 2.08905 3.96501 2.62086 3.77516 3.08395C3.5853 3.54703 3.13518 3.85012 2.63492 3.85237C2.30535 3.8535 1.98874 3.72336 1.75495 3.4907C1.52171 3.25803 1.39046 2.94198 1.39046 2.61185L1.39044 2.61185ZM1.39044 9.94401L1.39044 9.94457C1.38988 9.44374 1.69071 8.99193 2.15268 8.79926C2.61463 8.6066 3.14757 8.71138 3.50249 9.0646C3.85741 9.41726 3.96557 9.94907 3.77572 10.4127C3.58587 10.8758 3.13574 11.1795 2.63491 11.1817C2.30591 11.1828 1.98929 11.0533 1.75606 10.8212C1.52282 10.5891 1.39101 10.2736 1.39043 9.94457L1.39044 9.94401Z" />
  </svg>
);
 
export default React.memo(HarvestIcon);
