import { SettingsAPI } from '../interfaces/api/settings';
import { Settings } from '../interfaces/settings';

export const settingsMapper = (settings: SettingsAPI): Settings => {
  const { environment } = settings;
  console.log('env', settings);

  return {
    timezone: settings.timezone,
    led: settings.led.map((item) => ({
      state: item.state,
      timestamp: item.timestamp,
      pwm: item.pwm,
      order: item.order,
      ledOff: item?.led_off,
    })),
    fan: settings.fan.map((item) => ({
      state: item.state,
      timestamp: item.timestamp,
    })),
    waterPump: settings.water_pump?.map((item) => ({
      state: item.state,
      timestamp: item.timestamp,
    })),
    airPump: settings.air_pump?.map((item) => ({
      state: item.state,
      timestamp: item.timestamp,
    })),
    ventile: settings.ventile?.map((item) => ({
      state: item.state,
      timestamp: item.timestamp,
    })),
    environment: {
      uuid: environment?.uuid,
      active: environment.active,
      name: environment.name,
      ledLevel: environment.led_level,
      nutritionLevel: environment.nutrition_level,
      vpaLevel: environment.vpa_level,
    },
  };
};
