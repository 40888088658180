import { FC } from "react";
import { StyledText } from "../../style";
import * as Styled from './style';

type PropType = {
  title: string,
  callback: () => void
  position?: 'absolute' | 'relative'
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export const NotificationButton: FC<PropType> = ({
  title,
  callback,
  position,
  top,
  right,
  bottom,
  left,
}) => {
  return (
    <Styled.StatusButton
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      onClick={callback}
    >
      <Styled.StatusButtonText>
        {title}
      </Styled.StatusButtonText>
    </Styled.StatusButton>
  )
}