import { useRef, FC } from "react";
import useVideoPlayer from "../../hooks/useVideoPlayer";
import * as Styled from './style';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { defaultTheme } from '@plant/ui';

type PropsType = {
  src: string
}

const VideoPlayer: FC<PropsType> = ({
  src
}) => {
  const videoElement = useRef(null);
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
  } = useVideoPlayer(videoElement);

  const onVideoProgressClick = (e) => {
    handleVideoProgress(e)
  }

  return (
    <Styled.StyledContainer>
      <Styled.StyledVideoWrapper>
        <Styled.StyledVideo
          src={src}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
        />
        <Styled.StyledVideoControls className="controls">
          <Styled.StyledVideoActions>
            <button onClick={togglePlay}>
              {!playerState.isPlaying ? (
                <PlayCircleIcon
                  sx={{
                    color: defaultTheme.palette.secondary.main
                  }}
                />
              ) : (
                <PauseCircleIcon
                  sx={{
                    color: defaultTheme.palette.secondary.main
                  }}
                />
              )}
            </button>
          </Styled.StyledVideoActions>
          <Styled.StyledInput
            type="range"
            min="0"
            max="100"
            className="input"
            value={playerState.progress}
            onChange={onVideoProgressClick}
          />
          <Styled.MuteBTN onClick={toggleMute}>
            {!playerState.isMuted ? (
              <VolumeUpIcon
                sx={{
                  color: defaultTheme.palette.secondary.main
                }}
              />
            ) : (
              <VolumeOffIcon
                sx={{
                  color: defaultTheme.palette.secondary.main
                }}
              />
            )}
          </Styled.MuteBTN>
        </Styled.StyledVideoControls>
      </Styled.StyledVideoWrapper>
    </Styled.StyledContainer>
  );
};

export default VideoPlayer;