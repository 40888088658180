import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import { IGetUsersDataRequest, IUsersDataSuccessFullResponse, ICurrentUserDataSuccessFullResponse, ICurrentUnitDataSuccessFullResponse } from '@plant/data';
import { CurrentSearchFieldType, OrderVariantsVariety, StatusButtonsType, TableHeadButtonsType } from '../types/users';

export enum UsersTypes {
  GetAllUsers = '[Users] GetAllUsers',
  GetAllUsersSuccess = '[Users] GetAllUsersSuccess',
  GetAllUsersFailed = '[Users] GetAllUsersFailed',

  UpdateAllUsers = '[Users] UpdateAllUsers',
  UpdateAllUsersSuccess = '[Users] UpdateAllUsersSuccess',
  UpdateAllUsersFailed = '[Users] UpdateAllUsersFailed',

  LoadMoreUsers = '[Users] LoadMoreUsers',
  LoadMoreUsersSuccess = '[Users] LoadMoreUsersSuccess',
  LoadMoreUsersFailed = '[Users] LoadMoreUsersFailed',

  FindUsers = '[Users] FindUsers',
  FindUsersSuccess = '[Users] FindUsersSuccess',
  FindUsersFailed = '[Users] FindUsersSuccess',

  GetCurrentUser = '[User] GetCurrentUser',
  GetCurrentUserSuccess = '[User] GetCurrentUserSuccess',
  GetCurrentUserFailed = '[User] GetCurrentUserFailed',

  GetCurrentUnit = '[User] GetCurrentUnit',
  GetCurrentUnitSuccess = '[User] GetCurrentUnitSuccess',
  GetCurrentUnitFailed = '[User] GetCurrentUnitFailed',

  DeleteCurrentUnit = '[User] DeleteCurrentUnit',
  DeleteCurrentUnitSuccess = '[User] DeleteCurrentUnitSuccess',
  DeleteCurrentUnitFailed = '[User] DeleteCurrentUnitFailed',

  SetTableHeadButtons = '[Users] SetTableHeadButtons',

  SetStatusButtons = '[Users] SetStatusButtons',

  SetCurrentField = '[Users] SetCurrentField',

  SetSortDirection = '[Users] SetSortDirection',

  ResetAllUsersData = '[Users] ResetAllUsersData',

  ResetAllUsers = '[Users] ResetAllUsers'
}

export const getAllUsersAction = createAsyncAction(
  UsersTypes.GetAllUsers,
  UsersTypes.GetAllUsersSuccess,
  UsersTypes.GetAllUsersFailed
)<IGetUsersDataRequest, IUsersDataSuccessFullResponse, any>();

export const updateAllUsersAction = createAsyncAction(
  UsersTypes.UpdateAllUsers,
  UsersTypes.UpdateAllUsersSuccess,
  UsersTypes.UpdateAllUsersFailed
)<IGetUsersDataRequest, IUsersDataSuccessFullResponse, any>();

export const loadMoreUsersAction = createAsyncAction(
  UsersTypes.LoadMoreUsers,
  UsersTypes.LoadMoreUsersSuccess,
  UsersTypes.LoadMoreUsersFailed
)<IGetUsersDataRequest, IUsersDataSuccessFullResponse, any>();

export const findUsersAction = createAsyncAction(
  UsersTypes.FindUsers,
  UsersTypes.FindUsersSuccess,
  UsersTypes.FindUsersFailed
)<IGetUsersDataRequest, IUsersDataSuccessFullResponse, any>();

export const getCurrentUserAction = createAsyncAction(
  UsersTypes.GetCurrentUser,
  UsersTypes.GetCurrentUserSuccess,
  UsersTypes.GetCurrentUserFailed
)<{ userId: string }, { data: ICurrentUserDataSuccessFullResponse }, any>();

export const getCurrentUnitAction = createAsyncAction(
  UsersTypes.GetCurrentUnit,
  UsersTypes.GetCurrentUnitSuccess,
  UsersTypes.GetCurrentUnitFailed
)<{ unitId: string, userId: string }, ICurrentUnitDataSuccessFullResponse, any>();

export const deleteCurrentUnitAction = createAsyncAction(
  UsersTypes.DeleteCurrentUnit,
  UsersTypes.DeleteCurrentUnitSuccess,
  UsersTypes.DeleteCurrentUnitFailed
)<{ unitId: string, onClose: () => void }, string, any>();

export const setTableHeadButtonsAction = createAction(UsersTypes.SetTableHeadButtons)<TableHeadButtonsType>();
export const setStatusButtonsAction = createAction(UsersTypes.SetStatusButtons)<StatusButtonsType>();
export const setCurrentFieldAction = createAction(UsersTypes.SetCurrentField)<CurrentSearchFieldType>();
export const setSortDirectionAction = createAction(UsersTypes.SetSortDirection)<OrderVariantsVariety>();
export const resetAllUsersDataAction = createAction(UsersTypes.ResetAllUsersData)();
export const resetAllUsersAction = createAction(UsersTypes.ResetAllUsers)();

export type UsersActionUnion = ActionType<
  typeof getAllUsersAction |
  typeof updateAllUsersAction |
  typeof loadMoreUsersAction |
  typeof findUsersAction |
  typeof getCurrentUserAction |
  typeof getCurrentUnitAction |
  typeof deleteCurrentUnitAction |
  typeof setTableHeadButtonsAction |
  typeof setStatusButtonsAction |
  typeof setCurrentFieldAction |
  typeof setSortDirectionAction |
  typeof resetAllUsersDataAction |
  typeof resetAllUsersAction 
>