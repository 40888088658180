import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import { defaultTheme } from '@plant/ui';

const StyledDeleteContentButton = styled.button<{
  position?: 'absolute' | 'relative'
  top?: string
  right?: string
  bottom?: string
  left?: string
  width?: string
}>`
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  ${props => ({
    position: props.position,
    top: props.top,
    right: props.right,
    bottom: props.bottom,
    left: props.left,
    width: props.width,
    height: props.width,
    background: props.theme.palette.error.main,
  })}
`;

export const DeleteContentButton = ({
  position,
  top,
  right,
  bottom,
  left,
  width,
  onClick
}: {
  position?: 'absolute' | 'relative'
  top?: string
  right?: string
  bottom?: string
  left?: string
  width?: string
  onClick: () => void
}) => {

  const onButtonClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onClick()
  }
  return(
    <StyledDeleteContentButton 
      position={position} 
      top={top} 
      right={right} 
      bottom={bottom}
      left={left}
      width={width}
      onClick={onButtonClick}
    >
      <ClearIcon sx={{color: defaultTheme.palette.error.light}}/>
    </StyledDeleteContentButton>
  )
}

export const StyledCropEditorWrapper = styled.div`
  padding: 0 40px;
  max-width: 1100px;
  margin: 50px auto;
  height: 85%;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 12px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
  ${props => ({
    background: props.theme.palette.primary.light
  })};
    border-radius: 20px;
  }

   &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${props => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`
    })}
  }
`

export const LogoImageInputWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const LogoImageInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`

export const LogoImageInputLabel = styled.label<{
  inValid?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  transition: all 0.3s;
  cursor: pointer;
  ${props => props.inValid && ({
    boxShadow: `inset 0 0 1px 2px ${props.theme.palette.error.main}`,
  })}
`

export const LogoImageInputLabelSpan = styled.span`
  font-weight: 500;
  ${props => ({
    color: props.theme.palette.primary.dark
  })};
`

export const LogoImageInputLabelImage = styled.img`
  width: 45px;
  object-fit: contain;
  object-position: center;
`

export const GridContainer = styled.div<{
  columns?: string
  rows?: string
  margin?: string
  gridGap?: string
  width?: string
  height?: string;
}>`
  display: grid;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${props => ({
    margin: props.margin,
    gridTemplateColumns: props.columns,
    gridTemplateRows: props.rows,
    gridGap: props.gridGap,
    width: props.width,
    height: props.height,
  })}
`

export const CropWrapper = styled.div`
  ${props => ({
   border: `1px solid ${props.theme.palette.primary.dark}`
  })}
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 30px;
  margin: 20px auto;
`

export const CropPlateBorder = styled.div<{
  inValid?: boolean
}>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 10px;
  transition: all 0.3s;
  ${props => ({
    background: `linear-gradient(${props.theme.palette.primary.dark}, ${props.theme.palette.light.main})`,
    boxShadow: `5px 5px 10px 13px ${props.theme.palette.light.light}`,
  })}
  ${props => props.inValid && ({
    background: `linear-gradient(${props.theme.palette.error.main}, ${props.theme.palette.light.main})`,
  })}
`
export const CropPlate = styled.div`
  width: 100%;
  height: 100%;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`

export const CropPlateInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`

export const CropPlateInputLabel = styled.label`
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const CropPlateInputLabelSpan = styled.span`
  font-weight: 600;
  font-size: 23px;
  ${props => ({
    color: props.theme.palette.light.dark
  })}  
`

export const CropPlateInputLabelImage = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
`
export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UploadVideoContainer = styled.div<{
  height?: string;
  inValid?: boolean
}>`
  width: 100%;
  height: 350px;
  border-radius: 25px;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: box-shadow 0.3s;
  ${props => ({
    height: props.height
  })}
  ${props => props.inValid && ({
    boxShadow: `inset 0 0 1px 2px ${props.theme.palette.error.main}`,
  })}
`

export const UploadVideoContainerTitle = styled.h3`
  font-weight: 500;
  margin-bottom: 20px;
  width: 40%;
  text-align: center;
  min-width: 250px;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-weight: 600;
`

export const UploadVideoContainerInput = styled.input`
  display: none;
`

export const UploadVideoContainerLabel = styled.label`
  ${props => ({
    color: props.theme.palette.secondary.main,
    border: `1px dotted ${defaultTheme.palette.secondary.main}`,
  })};
  font-weight: 600;
  font-size: 23px;
  width: 250px;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
`

export const TechniqueContainer = styled.div``

export const TechniqueButtonContainer = styled.div`
  display: flex;
  display: flex;
  justify-content: center;
`

export const TechniqueBlock = styled.div`    
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  padding: 20px 0;
  border-radius: 20px;
`

export const TechniqueButtonContainerTitle = styled.h3`
  margin: 0 0 10px 0;
  text-align: center;
  font-weight: 500;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
`

export const TechniqueButton = styled.button<{
  active?: boolean
  inValid?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 5px 35px;
  border-radius: 25px;
  border: none;
  margin: 0 20px;
  width: 250px;
  ${props => ({
    background: props.theme.palette.admin.main
  })}  
  ${props => props.active && ({
    background: props.theme.palette.secondary.light,
    boxShadow: `0px 5px 10px 3px ${props.theme.palette.primary.light}`
  })}
  ${props => props.inValid && ({
    transition: 'all 0.3s',
    boxShadow: `inset 0 0 1px 2px ${props.theme.palette.error.main}`,
  })}
`

export const TechniqueButtonIcon = styled.img`
  width: 30px;
`

export const TechniqueButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 10px;
  width: 100%;
`

export const TechniqueButtonText = styled.span<{
  color?: string
  active?: boolean
  fontSize?: string
}>`
  font-size: 17px;
  font-weight: 600;
  ${props => ({
    color: props.active ? props.theme.palette.secondary.dark : props.color,
    fontSize: props.fontSize,
  })}
`

export const TechniqueInfoContainer = styled.div`
  margin: 15px 0;
`

export const TechniqueInfoContainerImage = styled.img`
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
  object-fit: contain;
  object-position: center;
`

export const StyledAccordion = styled(Accordion)`
  margin: 12px 0 !important;
  padding: 0 !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none !important;
  overflow: hidden !important;
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  font-weight: 600;
  ${props => ({
    color: `${props.theme.palette.secondary.light} !important`
  })}
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 12px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
      ${props => ({
    background: props.theme.palette.primary.light
  })};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${props => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`
    })}
  }
`

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 !important;
    ${props => ({
    color: `${props.theme.palette.secondary.main} !important`
  })};
`

export const StyledName = styled.span`
  font-weight: 500;
  margin-left: 20px;
    ${props => ({
    color: `${props.theme.palette.secondary.main} !important`
  })};
`