import styled from "styled-components";

export const DashboardLayoutWrapper = styled.div`
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-template-columns: 120px calc(100% - 120px);
  grid-template-areas:  "asideMenu dashboardContainer"
                        "asideMenu dashboardContainer";   
  ${props => ({
    background: props.theme.palette.light.main
  })}
`
