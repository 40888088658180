import { UserAdminService, NotificationService, IUsersDataSuccessFullResponse } from '@plant/data';
import { toast } from 'react-toastify';
import { takeLatest, Effect, call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { snackToCamel } from '../../helpers/snakeToCamel';
import * as NotificationsActions from '../actions/notifications';

export class NotificationsSagaWorker {
  static *getUsersEmails({
    payload,
  }: ActionType<typeof NotificationsActions.getUsersEmailsAction.request>) {
    try {
      const response: SagaReturnType<typeof UserAdminService.getAllUsers> = yield call(
        UserAdminService.getAllUsers,
        payload,
      );
      const mappedResponse: IUsersDataSuccessFullResponse = snackToCamel(response.data.data);
      yield put(NotificationsActions.getUsersEmailsAction.success(mappedResponse));
    } catch (error: any) {
      yield put(NotificationsActions.getUsersEmailsAction.failure(error?.response?.data));
    }
  }

  static *sendEmailsToUsers({
    payload,
  }: ActionType<typeof NotificationsActions.sendEmailToUsersAction.request>) {
    try {
      yield call(NotificationService.sendEmailsToUsers, payload);
      yield put(NotificationsActions.sendEmailToUsersAction.success());
      toast('Success', { type: 'default' })
    } catch (error: any) {
      yield put(NotificationsActions.sendEmailToUsersAction.failure(error?.response?.data));
    }
  }
}

export function* notificationsSaga(): Generator<Effect, void> {
  yield takeLatest(
    NotificationsActions.getUsersEmailsAction.request,
    NotificationsSagaWorker.getUsersEmails,
  );
  yield takeLatest(
    NotificationsActions.sendEmailToUsersAction.request,
    NotificationsSagaWorker.sendEmailsToUsers,
  );
}
