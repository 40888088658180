import styled from "styled-components"

export const PopupTitle = styled.h3`
  width: 350px;
  text-align: center;
  font-weight: 500;
  ${props => ({
    color: props.theme.palette.secondary.dark,
  })}
  `

export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`

export const PopupButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  width: 230px;
`
export const StyledInputLabel = styled.span`
  font-weight: 500;
  ${props => ({
    color: props.theme.palette.secondary.dark,
  })}
`

export const StyledInputFieldWrapper = styled.div<{
  width?: string
}>`
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  border-radius: 35px;
  ${props => ({
    color: props.theme.palette.light.main,
  })}
  ${props => ({
    width: props.width,
  })}
`

export const PopupWarning = styled.h3`
  width: 350px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  ${props => ({
    color: props.theme.palette.warning.dark,
  })}
`