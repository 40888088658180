import React from 'react';
import { Icon } from '@plant/data';

const GoogleLogoIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.005071 8.09275C-0.156639 3.92569 3.57268 0.0748189 7.83462 0.0248575C10.0068 -0.156471 12.1201 0.669115 13.764 2.01563C13.0897 2.74129 12.4035 3.45857 11.6707 4.13008C10.2235 3.26955 8.4811 2.61377 6.78975 3.19618C4.06174 3.95643 2.40966 7.10889 3.41918 9.72086C4.25522 12.4488 7.64542 13.9459 10.3024 12.7999C11.6782 12.3178 12.5852 11.0754 12.9833 9.74043C11.4065 9.70968 9.82939 9.7289 8.25263 9.68627C8.24871 8.7681 8.24478 7.85342 8.24871 6.93525C10.8782 6.93141 13.5116 6.92372 16.145 6.94678C16.3067 9.19644 15.9676 11.604 14.4733 13.4096C12.4271 15.987 8.64673 16.7434 5.57139 15.7323C2.30756 14.6831 -0.0659671 11.465 0.005071 8.09275Z"
      fill="white"
    />
  </svg>
);

export default React.memo(GoogleLogoIcon);
