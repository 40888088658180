import styled from "styled-components";

export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`

export const PopupTitle = styled.h3`
  width: 350px;
  text-align: center;
  font-weight: 500;
${props => ({
  color: props.theme.palette.secondary.main,
})}
`
export const PopupWarning = styled.h3`
  width: 350px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  ${props => ({
    color: props.theme.palette.error.main
  })}
`

 export const PopupButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  width: 230px;
`