import styled from 'styled-components';

export const CropPageWrapper = styled.div`
  max-width: 900px;
  margin: 50px auto 0;
  height: 85%;
`

export const TitleNav = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 50px;
`

export const StyledArticleWrapper = styled.div`
  margin: 30px 0 0 0;
  overflow-y: auto;
  height: 90%;
  &::-webkit-scrollbar {
    width: 12px;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
      ${props => ({
    background: props.theme.palette.primary.light
  })};
    border-radius: 20px;
  }
   &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${props => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`
    })}
  }
`
export const StyledCropNameWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

export const StyledCropName = styled.h3`
  font-size: 42px;
  font-weight: 400;
  line-height: 42px;
${props => ({
  color: props.theme.palette.secondary.main,
})};
`

export const StyledViewsCount = styled.span`
  font-size: 20px;
  height: 30px;
  margin-left: 20px;
  font-weight: 400;
  line-height: 42px;
  ${props => ({
    color: props.theme.palette.primary.dark
  })}
`

export const Date = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 42px;
  ${props => ({
    color: props.theme.palette.primary.dark
  })}
`

export const StyledCropPhoto = styled.img`
  display: block;
  margin: 0 auto;
  width: 250px;
`

export const StyledCropDescription = styled.p`
  font-size: 17px;
  font-weight: 400;
${props => ({
  color: props.theme.palette.secondary.main,
})};
  margin: 30px 0;
`


export const TechniqueButtonsContainer = styled.div`

`
export const TechniqueButtonContainer = styled.div<{
  grid?: string
  columns?: string
}>`
  display: flex;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${props => props.grid && ({
    display: 'grid',
    gridTemplateColumns: props.columns
  })}
`


export const TechniqueBlock = styled.div`    
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  padding: 20px 0;
  border-radius: 20px;
  margin: 20px 0;
`

export const TechniqueButtonContainerTitle = styled.h3`
  margin: 0 0 10px 0;
  text-align: center;
  font-weight: 500;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
`

export const TechniqueButton = styled.button<{
  active?: boolean
  inValid?: boolean
  height?: string
  width?: string
  cursor?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 35px;
  border-radius: 15px;
  border: none;
  margin: 10px 20px;
  ${props => ({
    background: props.theme.palette.admin.main
  })}  
  ${props => props.active && ({
    background: props.theme.palette.primary.main,
    boxShadow: `0px 5px 10px 3px ${props.theme.palette.primary.light}`,
  })}
  ${props => props.inValid && ({
    transition: 'all 0.3s',
    boxShadow: `inset 0 0 1px 2px ${props.theme.palette.error.main}`,
  })}
  ${props => ({
    height: props.height,
    width: props.width,
    cursor: props.cursor
  })}
`

export const TechniqueButtonIcon = styled.img`
  width: 30px;
  margin-right: 20px;
`

export const TechniqueButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 10px;
  width: 100%;
`

export const TechniqueButtonText = styled.span<{
  color?: string
  active?: boolean
  fontSize?: string
}>`
  font-size: 17px;
  font-weight: 600;
  ${props => ({
    color: props.active ? props.theme.palette.secondary.main : props.color,
    fontSize: props.fontSize,
  })}
`

export const TechniqueDescription = styled.p`
  width: 80%;
  margin: 30px auto;
  text-align: center;
`

export const TechniqueImage = styled.img`
  width: 250px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
`

export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`

export const PopupTitle = styled.h3`
  width: 350px;
  text-align: center;
  font-weight: 500;
${props => ({
  color: props.theme.palette.secondary.main,
})}
`

export const PopupWarning = styled.h3`
  width: 350px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  ${props => ({
    color: props.theme.palette.error.main
  })}
`

export const PopupButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  width: 230px;
`