export interface Crop {
  uuid: string;
  active: boolean;
  name: string;
  alias: string;
  shortDescription: string;
  fullDescription: string;
  photo: string;
  video: string;
  icon: string;
  harvest: {
    name: string;
    description: string;
    time: string;
    image?: string;
  };
  replant: {
    name: string;
    description: string;
    time: string;
  };
  lifespan: {
    name: string;
    description: string;
    time: string;
  };
  nutrition: {
    uuid: string;
    active: boolean;
    name: string;
    description: string;
  }[];
  flavor: {
    uuid: string;
    active: boolean;
    name: string;
    description: string;
  }[];
}
