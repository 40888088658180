import { SuccessResponse, Unit } from '../interfaces';
import { EnvironmentAPI } from '../interfaces/api/environment';
import { Environment } from '../interfaces/environment';
import { environmentMapper } from '../mappers/environmentMapper';
import { ApiService } from './apiService';

export class EnvironmentService {
  static async getEnvironments(
    name?: string,
    uuid?: string,
    limit = 25,
    offset = 0,
  ): Promise<Environment[]> {
    const searchParams = new URLSearchParams([
      ['limit', limit.toString()],
      ['offset', offset.toString()],
    ]);

    if (name) searchParams.append('name', name);
    if (uuid) searchParams.append('uuid', uuid);

    return ApiService.api
      .get<SuccessResponse<Environment[]>>(`/environment/search?${searchParams}`)
      .then((response) => response.data.data);
  }

  static async changeEnvironment(unitUuid: string, environmentUuid: string): Promise<Environment> {
    return ApiService.api
      .put<
        SuccessResponse<{
          settings: {
            environment: EnvironmentAPI;
          };
        }>
      >(`/unit/settings/${unitUuid}`, {
        environmentUuid,
      })
      .then((response) => environmentMapper(response.data.data.settings.environment));
  }

  static async addEnvironment(
    name: string,
    ledLevel: number,
    ledTime: number,
    nutritionLevel: number,
    vpaLevel: number,
  ): Promise<Environment> {
    const postData: {
      active: boolean;
      name: string;
      nutrition_level: number;
      vpa_level: number;
      led_level?: number;
      led_time?: number;
    } = {
      name,
      active: true,
      nutrition_level: nutritionLevel,
      vpa_level: vpaLevel,
      led_level: ledLevel,
      led_time: ledTime,
    };

    return ApiService.api
      .post<SuccessResponse<EnvironmentAPI>>(`/environment`, postData)
      .then((response) => environmentMapper(response.data.data));
  }

  static async deleteEnvironment(environmentUuid: string): Promise<void> {
    return ApiService.api.delete(`/environment/${environmentUuid}`);
  }
}
