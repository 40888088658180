import React from 'react';
import { Icon } from '@plant/data';

const LeakIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 8.94827L3.35 12.3224C3.0237 12.6506 2.8015 13.0689 2.71148 13.5242C2.62147 13.9795 2.66769 14.4514 2.8443 14.8803C3.02092 15.3092 3.31999 15.6758 3.70369 15.9337C4.0874 16.1916 4.53852 16.3292 5 16.3292C5.46148 16.3292 5.9126 16.1916 6.29631 15.9337C6.68001 15.6758 6.97908 15.3092 7.1557 14.8803C7.33231 14.4514 7.37853 13.9795 7.28852 13.5242C7.1985 13.0689 6.9763 12.6506 6.65 12.3224L5 8.94827ZM5 8C5 8 6.47103 10.9822 7.12133 11.8482C7.77163 12.7143 7.8266 12.808 7.94236 13.3934C8.05811 13.9788 7.9987 14.5856 7.77163 15.137C7.54457 15.6885 7.16005 16.1598 6.66671 16.4914C6.17336 16.823 5.59334 17 5 17C4.40666 17 3.82664 16.823 3.33329 16.4914C2.83995 16.1598 2.45543 15.6885 2.22837 15.137C2.0013 14.5856 1.94189 13.9788 2.05764 13.3934C2.1734 12.808 2.05364 13.125 2.87867 11.8482C3.70369 10.5714 5 8 5 8Z" />
    <path d="M5 8.94827L3.35 12.3224C3.0237 12.6506 2.8015 13.0689 2.71148 13.5242C2.62147 13.9795 2.66769 14.4514 2.8443 14.8803C3.02092 15.3092 3.31999 15.6758 3.70369 15.9337C4.0874 16.1916 4.53852 16.3292 5 16.3292C5.46148 16.3292 5.9126 16.1916 6.29631 15.9337C6.68001 15.6758 6.97908 15.3092 7.1557 14.8803C7.33231 14.4514 7.37853 13.9795 7.28852 13.5242C7.1985 13.0689 6.9763 12.6506 6.65 12.3224L5 8.94827Z" />
    <path d="M9.5 1.73755L8.125 4.36184C7.85309 4.61717 7.66791 4.94246 7.5929 5.29659C7.51789 5.65072 7.55641 6.01778 7.70359 6.35135C7.85076 6.68493 8.09999 6.97004 8.41975 7.17063C8.7395 7.37122 9.11543 7.47829 9.5 7.47829C9.88457 7.47829 10.2605 7.37122 10.5803 7.17063C10.9 6.97004 11.1492 6.68493 11.2964 6.35135C11.4436 6.01778 11.4821 5.65072 11.4071 5.29659C11.3321 4.94246 11.1469 4.61717 10.875 4.36184L9.5 1.73755ZM9.5 1C9.5 1 10.7259 3.31948 11.2678 3.99307C11.8097 4.66667 11.8555 4.73957 11.952 5.19488C12.0484 5.6502 11.9989 6.12214 11.8097 6.55103C11.6205 6.97993 11.3 7.34651 10.8889 7.60443C10.4778 7.86234 9.99445 8 9.5 8C9.00555 8 8.5222 7.86234 8.11108 7.60443C7.69996 7.34651 7.37953 6.97993 7.19031 6.55103C7.00109 6.12214 6.95158 5.6502 7.04804 5.19488C7.1445 4.73957 7.0447 4.98614 7.73222 3.99307C8.41975 3 9.5 1 9.5 1Z" />
    <path d="M9.5 1.73755L8.125 4.36184C7.85309 4.61717 7.66791 4.94246 7.5929 5.29659C7.51789 5.65072 7.55641 6.01778 7.70359 6.35135C7.85076 6.68493 8.09999 6.97004 8.41975 7.17063C8.7395 7.37122 9.11543 7.47829 9.5 7.47829C9.88457 7.47829 10.2605 7.37122 10.5803 7.17063C10.9 6.97004 11.1492 6.68493 11.2964 6.35135C11.4436 6.01778 11.4821 5.65072 11.4071 5.29659C11.3321 4.94246 11.1469 4.61717 10.875 4.36184L9.5 1.73755Z" />
  </svg>
);

export default React.memo(LeakIcon);
