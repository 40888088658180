import { UserGroups, Esp } from '../constants';
import { Version } from '../interfaces';
import { VersionApi } from '../interfaces/api/version';
import { ApiService } from './apiService';

export class SoftwareService {
  static async getVersionsList(): Promise<Version[]> {
    return ApiService.api
      .get<Version[]>(`/software/version/list`)
      .then((response) => response.data);
  }

  static async addVersion(
    formData: FormData,
  ): Promise<{ data: Version; status: string; message: string }> {
    return ApiService.api
      .post<{ data: Version; status: string; message: string }>('/software/version', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => response.data);
  }

  static async updateVersion(version: Omit<VersionApi, 'file'>) {
    const data = { ...version, users: version.users.join(',') };
    ApiService.api.put(`/software/version?version=${version.version}`, data);
  }

  static async deleteVersion(version: string) {
    return ApiService.api.delete(`/software/version?version=${version}`);
  }

  static async getUnitSerials() {
    return ApiService.api.get('/unit/serials').then((res) => res.data.data);
  }

  static async getVersionDetails(version: string): Promise<{
    version: number;
    versionName: string;
    description: string;
    canDownload: UserGroups;
    type: Esp;
    userInfo: { email: string; uuid: string }[];
  }> {
    return ApiService.api
      .get(`/software/version/info?version=${version}`)
      .then((response) => response.data);
  }
}
