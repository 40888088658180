import { CropAPI } from '../interfaces/api/crop';
import { Crop } from '../interfaces/crop';

export const cropMapper = (crops: CropAPI[]): Crop[] => {
  return crops.map((crop) => {
    return {
      name: crop.name,
      active: crop.active,
      alias: crop.alias,
      photo: crop.photo,
      video: crop.video,
      icon: crop.icon,
      shortDescription: crop.shortDescription,
      fullDescription: crop.fullDescription,
      uuid: crop.uuid,
      harvest: crop.harvest,
      replant: crop.replant,
      lifespan: crop.lifespan,
      nutrition: crop.nutrition,
      flavor: crop.flavor,
    };
  });
};
