import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 30px auto 20px;
  max-width: 1400px;
  position: relative;
`;

export const UploadVideoContainer = styled.div`
  width: 100%;
  min-height: 150px;
  border-radius: 25px;
  ${(props) => ({
    background: props.theme.palette.light.main,
  })}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: box-shadow 0.3s;
`;

export const FileButton = styled.div`
  width: 200px;
  border-radius: 25px;
  border: 1px solid ${(props) => props.theme.palette.light.main};
`;

export const FileInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`;

export const FileLabel = styled.label`
  color: green;
  font-weight: 600;
  font-size: 21px;
`;

export const StyledCropEditorWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1100px;
  margin: 50px auto;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 12px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    ${(props) => ({
      background: props.theme.palette.primary.light,
    })};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${(props) => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`,
    })}
  }
`;

export const BottomControls = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto 150px 150px;
`