import React from 'react';
import { Icon } from '@plant/data';

const CropGuideIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0579 7.91234C13.5769 4.34217 19.3528 3.18074 24.5086 2.78681C24.7654 2.7694 25.0716 2.95874 25.0716 3.30804V19.2563C25.0716 19.5327 24.8572 19.7601 24.583 19.7775C19.8069 20.0767 14.9243 20.5765 12.9722 23.5233C12.7654 23.8356 12.3073 23.8356 12.1005 23.5233C10.4874 21.0884 6.87363 20.9582 2.97234 20.8176C2.15219 20.7881 1.31932 20.758 0.489673 20.7061C0.214369 20.6887 0 20.4601 0 20.1848V4.23662C0 3.93084 0.258988 3.6947 0.562575 3.71538C0.836615 3.73638 1.11241 3.75691 1.38907 3.77751C6.3173 4.14447 11.5218 4.532 12.0133 7.91345V21.2576C12.0133 21.5459 12.2472 21.7799 12.5356 21.7799C12.8239 21.7799 13.0579 21.546 13.0579 21.2576V7.91234ZM20.3171 11.5865C20.6521 9.95484 21.1505 9.14761 21.747 8.86642C21.8557 8.81514 21.8226 8.65196 21.7025 8.64605C18.4198 8.47279 15.045 9.62685 15.3394 12.4514C15.3399 12.4561 15.3404 12.4609 15.3409 12.4657C15.3657 12.7048 15.3988 13.0246 15.7859 12.8078C15.8819 12.754 15.9567 12.7101 16.0221 12.6717C16.2238 12.5533 16.3359 12.4874 16.7037 12.3476C16.6524 12.3693 16.4297 12.4889 16.259 12.9797C16.0435 13.4153 16.2672 13.9608 16.7324 14.0926C18.0518 14.4666 19.8849 13.692 20.3171 11.5865ZM16.7145 12.3435C16.7109 12.3448 16.7073 12.3462 16.7037 12.3476C16.7107 12.3446 16.7145 12.3435 16.7145 12.3435Z"
      />
    </svg>
  );
};

export default React.memo(CropGuideIcon);
