import React from 'react';
import { Icon } from '@plant/data';

const SearchIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9779 2.82524L10.9135 2.75726L10.9129 2.75782C8.55997 0.347101 4.78605 0.369587 2.45982 2.82529C0.114662 5.30098 0.114428 9.32718 2.45983 11.8026C4.62705 14.0903 8.04904 14.2652 10.4097 12.3305L13.7489 15.8551C14.0434 16.166 14.5225 16.166 14.817 15.8551C15.109 15.547 15.109 15.0496 14.817 14.7414L11.4722 11.2108C13.3089 8.72259 13.1453 5.11296 10.9779 2.82524ZM9.90966 10.689L9.90963 10.689C8.14991 12.5474 5.28773 12.5466 3.5279 10.689C1.76554 8.82882 1.7656 5.79918 3.52791 3.9389C5.28765 2.08132 8.14983 2.08139 9.90964 3.9389C11.672 5.79913 11.6728 8.82876 9.90966 10.689Z"
      stroke-width="0.176395"
    />
  </svg>
);

export default React.memo(SearchIcon);
