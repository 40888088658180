import React from 'react';
import { Icon } from '@plant/data';

const ArrowIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.46593 3.52046L3.96021 4.27905L4.46593 4.6162L4.97166 4.27905L4.46593 3.52046ZM0.313366 1.84782L3.96021 4.27905L4.97166 2.76187L1.32482 0.330645L0.313366 1.84782ZM4.97166 4.27905L8.6185 1.84782L7.60705 0.330645L3.96021 2.76187L4.97166 4.27905Z" />
  </svg>
);

export default React.memo(ArrowIcon);
