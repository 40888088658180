const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    width: 2,
  },

  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#888',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  scrollbarWidth: 'thin' as any,
};

export default scrollBarStyles;
