import React from 'react';
import { Icon } from '@plant/data';

const WaterIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.5 7.60632L1.575 11.5428C1.19432 11.9258 0.93508 12.4137 0.830065 12.9449C0.72505 13.4761 0.778976 14.0267 0.985022 14.527C1.19107 15.0274 1.53998 15.4551 1.98764 15.7559C2.4353 16.0568 2.96161 16.2174 3.5 16.2174C4.03839 16.2174 4.56469 16.0568 5.01236 15.7559C5.46002 15.4551 5.80893 15.0274 6.01498 14.527C6.22102 14.0267 6.27495 13.4761 6.16993 12.9449C6.06492 12.4137 5.80568 11.9258 5.425 11.5428L3.5 7.60632ZM3.5 6.5C3.5 6.5 5.2162 9.97922 5.97489 10.9896C6.73357 12 6.79771 12.1094 6.93275 12.7923C7.06779 13.4753 6.99848 14.1832 6.73357 14.8266C6.46867 15.4699 6.02006 16.0198 5.44449 16.4066C4.86892 16.7935 4.19223 17 3.5 17C2.80777 17 2.13108 16.7935 1.55551 16.4066C0.979939 16.0198 0.531335 15.4699 0.266427 14.8266C0.00151932 14.1832 -0.0677948 13.4753 0.0672499 12.7923C0.202295 12.1094 0.0625806 12.4792 1.02511 10.9896C1.98764 9.5 3.5 6.5 3.5 6.5Z" />
    <path d="M3.5 7.60632L1.575 11.5428C1.19432 11.9258 0.93508 12.4137 0.830065 12.9449C0.72505 13.4761 0.778976 14.0267 0.985022 14.527C1.19107 15.0274 1.53998 15.4551 1.98764 15.7559C2.4353 16.0568 2.96161 16.2174 3.5 16.2174C4.03839 16.2174 4.56469 16.0568 5.01236 15.7559C5.46002 15.4551 5.80893 15.0274 6.01498 14.527C6.22102 14.0267 6.27495 13.4761 6.16993 12.9449C6.06492 12.4137 5.80568 11.9258 5.425 11.5428L3.5 7.60632Z" />
  </svg>
);

export default React.memo(WaterIcon);
