export interface ISetCropInterface {
  active: boolean,
  name?: string,
  short_description: string,
  full_description: string,
  scientific_name: string,
  subspecies: string,
  type: string,
  photo?: string,
  video?: string,
  icon?: string,
  harvest_description: string,
  harvest_time: string,
  harvest_image?: string,
  replant_description: string,
  replant_time: string,
  lifespan_description: string,
  lifespan_time: string,
  flavorUuids: string[] | []
  nutritionUuids: string[] | []
}

export type IGetCropApiResponseInterface = {
  uuid: string,
  active: boolean,
  name: string,
  alias: string,
  short_description: string,
  full_description: string,
  scientific_name: string,
  subspecies: null | string,
  other_names: string,
  type: string,
  photo: string,
  icon: string,
  video: null | string,
  views?: number,
  harvest: {
      description: string,
      time: string,
      image?: string
  },
  replant: {
      description: string,
      time: null | string,
      image?: string
  },
  lifespan: {
      description: string,
      time: string,
      image?: string
  },
  nutrition: {
      uuid: string,
      active: boolean,
      name: string,
      description: string,
      current?: boolean
  }[],
  flavor: {
      uuid: string,
      active: boolean,
      name: string,
      description: string,
      current?: boolean
  }[]
}

export type IGetCropInterface = {
  uuid: string,
  active: boolean,
  name: string,
  alias: string,
  shortDescription: string,
  fullDescription: string,
  scientificName: string,
  subspecies: null | string,
  otherNames: string,
  type: string,
  photo: string,
  icon: string,
  video: null | string,
  views?: number,
  createdOn: string,
  harvest: {
      description: string,
      time: string,
      image?: string
  },
  replant: {
      description: string,
      time: null | string,
      image?: string
  },
  lifespan: {
      description: string,
      time: string,
      image?: string
  },
  nutrition: {
      uuid: string,
      active: boolean,
      name: string,
      description: string,
      current?: boolean
  }[],
  flavor: {
      uuid: string,
      active: boolean,
      name: string,
      description: string,
      current?: boolean
  }[]
}

export type IGetNutritionInterface = { 
  uuid: string, 
  name: string 
}[]

export type IGetFlavorInterface = { 
  uuid: string, 
  name: string 
}[]

export type IGetFaqApiResponseType = {
  id?: string,
  uuid?: string,
  active: boolean,
  subject: string,
  content: string,
  views?: number,
  created_on?: string,
  updated_on?: string
}

export type IGetFaqType = {
  id?: string,
  uuid?: string,
  active: boolean,
  subject: string,
  content: string,
  views?: number,
  createdOn?: string,
  updatedOn?: string
}

export type IGetAboutType = {
  active: boolean;
  content: {
    text: string, 
    title: string
  }[],
  createdOn: string,
  subject: string,
  updatedOn: string,
  uuid: string,
  views: number,
}

export type ErrorPayload = {
  message: string 
}
