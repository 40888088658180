import React from 'react';
import { Icon } from '@plant/data';

const SunIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.98811 4.30768C5.94385 4.30768 4.30859 5.94294 4.30859 7.98719C4.30859 10.0314 5.94385 11.6923 7.98811 11.6923C10.0324 11.6923 11.6933 10.0316 11.6933 7.98719C11.6933 5.94279 10.0325 4.30768 7.98811 4.30768Z" />
      <path d="M4.6338 5.02424C4.91443 4.68736 5.25131 4.37861 5.64443 4.15424C5.64443 2.72236 4.18505 2.86298 3.8481 0.841736C3.42685 2.55423 3.39872 3.70495 4.63436 5.02432L4.6338 5.02424Z" />
      <path d="M3.5088 8.30878C3.45255 7.8594 3.5088 7.41064 3.6213 6.96127C2.52629 5.83876 1.85313 7.21376 0 5.89438C0.730005 7.10127 1.93681 8.4488 3.50882 8.30815L3.5088 8.30878Z" />
      <path d="M5.02626 11.3964C4.68937 11.1158 4.40875 10.7508 4.15625 10.3858C2.6125 10.3295 3.11749 11.7895 0.84375 12.1821C2.2475 12.4909 4.04377 12.6315 5.02634 11.3958L5.02626 11.3964Z" />
      <path d="M11.395 10.9758C11.1144 11.3126 10.7494 11.6214 10.3844 11.8458C10.3281 13.4176 11.7881 12.9126 12.1807 15.1583C12.4895 13.7826 12.6301 11.9864 11.3945 10.9757L11.395 10.9758Z" />
      <path d="M12.5188 7.71935C12.5469 8.16873 12.4906 8.61748 12.4062 9.03874C13.4731 10.1612 14.1469 8.78624 15.9994 10.1056C15.2694 8.89873 14.0906 7.55121 12.5187 7.71937L12.5188 7.71935Z" />
      <path d="M10.9766 4.60365C11.3135 4.9124 11.6223 5.24928 11.8466 5.64241C13.4185 5.67053 12.9135 4.21052 15.1591 3.84607C13.7835 3.50919 11.9873 3.36857 10.9766 4.60421L10.9766 4.60365Z" />
      <path d="M9.03812 3.62124C10.0769 2.58248 8.92562 1.65627 10.105 -6.10352e-05C8.61749 0.926201 7.77507 1.68427 7.71875 3.50876C8.16813 3.48064 8.61688 3.50876 9.03813 3.62126L9.03812 3.62124Z" />
      <path d="M6.96142 12.4069C5.83891 13.4738 7.21392 14.1476 5.89453 16.0001C7.10142 15.27 8.44895 14.0912 8.30831 12.5194C7.85893 12.5475 7.41017 12.4913 6.96079 12.4069L6.96142 12.4069Z" />
    </svg>
  );
};

export default React.memo(SunIcon);
