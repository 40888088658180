import { createSelector } from 'reselect';
import { AppState } from '../reducers';

export const contentStoreSelector = (state: AppState) => state.content;

export const cropSelector = createSelector(contentStoreSelector, state => state.crop);
export const cropToSetSelector = createSelector(contentStoreSelector, state => state.cropToSet);
export const initialCropToSetSelector = createSelector(contentStoreSelector, state => state.initialCropToSet);
export const loadingSelector = createSelector(contentStoreSelector, state => state.loading);
export const loadingMoreCropsSelector = createSelector(contentStoreSelector, state => state.loadingMoreCrops);
export const loadingMoreCropsCompleteSelector = createSelector(contentStoreSelector, state => state.loadingMoreCropsComplete);
export const nutritionSelector = createSelector(contentStoreSelector, state => state.nutrition);
export const flavorSelector = createSelector(contentStoreSelector, state => state.flavor);
export const flavorUuidsSelector = createSelector(contentStoreSelector, state => state.cropToSet.flavorUuids);
export const nutritionUuidsSelector = createSelector(contentStoreSelector, state => state.cropToSet.nutritionUuids);
export const errorSelector = createSelector(contentStoreSelector, state => state.error);
export const uploadingSelector = createSelector(contentStoreSelector, state => state.uploading);
export const uploadIsFinishedSelector = createSelector(contentStoreSelector, state => state.uploadIsFinished);

export const cropToShowSelector = createSelector(contentStoreSelector, state => state.cropToShow);

export const faqsSelector = createSelector(contentStoreSelector, state => state.faqs);
export const loadingMoreFaqsSelector = createSelector(contentStoreSelector, state => state.loadingMoreFaqs);
export const loadingMoreFaqsCompleteSelector = createSelector(contentStoreSelector, state => state.loadingMoreFaqsComplete);

export const aboutSelector = createSelector(contentStoreSelector, state => state.about);
export const aboutFormSelector = createSelector(contentStoreSelector, state => state.aboutForm);
export const loadingMoreAboutSelector = createSelector(contentStoreSelector, state => state.loadingMoreAbout);
export const loadingMoreAboutCompleteSelector = createSelector(contentStoreSelector, state => state.loadingMoreAboutComplete);