import React from 'react';
import { Icon } from '@plant/data';

const NotificationsIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3177 3.67499C14.3177 2.56666 15.2177 1.66666 16.326 1.66666C17.4344 1.66666 18.3344 2.56666 18.3344 3.67499C18.3344 4.78332 17.4344 5.68332 16.326 5.68332C15.2177 5.68332 14.3177 4.78332 14.3177 3.67499ZM11.1102 12.2994L13.5186 9.1919L13.4852 9.20857C13.6186 9.02524 13.6436 8.7919 13.5519 8.58357C13.4611 8.37524 13.2602 8.23357 13.0444 8.2169C12.8186 8.1919 12.5944 8.2919 12.4602 8.47524L10.4444 11.0836L8.13524 9.2669C7.99358 9.15857 7.82691 9.11607 7.66024 9.13357C7.49441 9.15857 7.34441 9.2494 7.24358 9.38274L4.77774 12.5919L4.72691 12.6669C4.58524 12.9327 4.65191 13.2744 4.90191 13.4586C5.01858 13.5336 5.14358 13.5836 5.28524 13.5836C5.47774 13.5919 5.66024 13.4911 5.77691 13.3336L7.86858 10.6411L10.2436 12.4252L10.3186 12.4744C10.5852 12.6161 10.9186 12.5502 11.1102 12.2994ZM12.8763 3.15024C12.843 3.35857 12.8263 3.56691 12.8263 3.77524C12.8263 5.65024 14.343 7.16607 16.2096 7.16607C16.418 7.16607 16.618 7.14191 16.8263 7.10857V13.8327C16.8263 16.6586 15.1596 18.3336 12.3263 18.3336H6.1688C3.33464 18.3336 1.66797 16.6586 1.66797 13.8327V7.66691C1.66797 4.83357 3.33464 3.15024 6.1688 3.15024H12.8763Z"
      />
    </svg>
  );
};

export default React.memo(NotificationsIcon);
