import * as Styled from './style';
import { IUnitInfo } from "@plant/data";
import { useState, FC } from 'react';
import { defaultTheme } from '@plant/ui';

type PropsType = {
  currentUnit: IUnitInfo
}

export const Alerts: FC<PropsType> = ({
  currentUnit
}) => {
  
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
 
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  return (
    <Styled.StyledErrorIconWrapper>
      <Styled.StyledIconButton
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Styled.StyledErrorIcon/>
      </Styled.StyledIconButton>
      <Styled.StyledPopOver
        id={`pod-`}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Styled.StyledPopOverText bgColor={defaultTheme.palette.error.main} fColor={defaultTheme.palette.light.main} id={`crop-name`}>
          {currentUnit?.alerts && (
            currentUnit?.alerts.map((alert, index) => {
              return (
                <p key={alert.uuid}>
                  {`${index + 1}) ${alert.description}`}
                </p>
              )
            })
          )}
        </Styled.StyledPopOverText>
      </Styled.StyledPopOver>
    </Styled.StyledErrorIconWrapper>
  )
}