import { FC, useEffect } from 'react';
import { DashBoardLayout } from '../../layouts/Dashboard';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserAction } from '../../store/actions/users';
import { isLoadingCurrentUserSelector, currentUserSelector } from '../../store/selectors/users';
import { authAccessTokenSelector } from '../../store/selectors/auth';
import { LoadingScreen } from '../../components/LoadingScreen';
import * as Styled from './style';
import AVATAR from '../../assets/images/dashboard/avatar.png';
import { dateFormatter } from '../../helpers/dateFormatter';
import { Button } from '../../components/Button';
import { selectEmailTagAction } from '../../store/actions/notifications';
import { RouteNames } from '../../router';
import { useNavigate } from 'react-router-dom';
import { toggleUserAction } from '../../store/actions/pushNotifications';

export const UserPage: FC = (): JSX.Element => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCurrentUserLoading = useSelector(isLoadingCurrentUserSelector);
  const currentUser = useSelector(currentUserSelector);

  const token = useSelector(authAccessTokenSelector);

  useEffect(() => {
    dispatch(getCurrentUserAction.request({ userId: id }));
  }, []);

  const InfoLine = ({ title, value }: { title: string; value: string }) => {
    return (
      <Styled.InfoLineWrapper>
        <Styled.InfoLineWrapperTitle>{title}</Styled.InfoLineWrapperTitle>
        <Styled.InfoLineWrapperValue>{value}</Styled.InfoLineWrapperValue>
      </Styled.InfoLineWrapper>
    );
  };

  const onSendEmailButtonClick = (user: { email: string; uuid: string }) => () => {
    dispatch(selectEmailTagAction(user));
    navigate(RouteNames.DashboardNotifications);
  };

  const onSendNotification = () => {
    dispatch(toggleUserAction(currentUser));
    navigate(RouteNames.DashboardNotifications, { state: { pushNotification: true } });
  };

  const onUnitButtonClick = (userId: string, unitId: string) => () => {
    navigate(RouteNames.DashboardUnit.replace(':userId', userId).replace(':unitId', unitId));
  };

  return (
    <DashBoardLayout currentPage="users">
      {!isCurrentUserLoading ? (
        <Styled.UserInfoWrapper>
          {currentUser && (
            <>
              <Styled.GridContainer grid columns="20% 80%">
                <Styled.StyledImg
                  src={currentUser.photo ? `${currentUser.photo}?token=${token}` : AVATAR}
                  alt="Logo"
                />
                <Styled.GridContainer>
                  <InfoLine title="First Name" value={currentUser.firstName} />
                  <InfoLine title="Last Name" value={currentUser.lastName} />
                </Styled.GridContainer>
              </Styled.GridContainer>
              <Styled.GridContainer grid gap="10px" columns="auto 100px 150px">
                <InfoLine title="Email" value={currentUser.email} />
                <Button
                  marginTop="10px"
                  marginBottom="10px"
                  variant="primary"
                  title="Send Email"
                  onClick={onSendEmailButtonClick(currentUser)}
                />
                <Button
                  marginTop="10px"
                  marginBottom="10px"
                  variant="warning"
                  title="Send push notification"
                  onClick={onSendNotification}
                />
              </Styled.GridContainer>
              <Styled.GridContainer grid columns="50% 50%">
                <Styled.GridContainer>
                  <InfoLine title="User status" value={currentUser.status} />
                  <InfoLine title="User alias" value={currentUser.alias} />
                  <InfoLine title="Created on" value={dateFormatter(currentUser.createdOn)} />
                  <InfoLine title="Updated on" value={dateFormatter(currentUser.updatedOn)} />
                  <InfoLine title="User ID" value={currentUser.uuid} />
                </Styled.GridContainer>
                <Styled.GridContainer padding="10px">
                  <Styled.UnitsContainer>
                    <Styled.InfoLineWrapperTitle>Units</Styled.InfoLineWrapperTitle>
                    <Styled.UnitsList>
                      {currentUser?.units?.length
                        ? currentUser.units.map((unit) => (
                            <Styled.Unit
                              key={unit.uuid}
                              onClick={onUnitButtonClick(currentUser.uuid, unit.uuid)}
                            >
                              {unit.name}
                            </Styled.Unit>
                          ))
                        : 'No units'}
                    </Styled.UnitsList>
                  </Styled.UnitsContainer>
                </Styled.GridContainer>
              </Styled.GridContainer>
            </>
          )}
        </Styled.UserInfoWrapper>
      ) : (
        <LoadingScreen />
      )}
    </DashBoardLayout>
  );
};
