import React from 'react';
import { Icon } from '@plant/data';

const AboutIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.39102 1.66602H13.616C16.441 1.66602 18.3327 3.64935 18.3327 6.59935V13.4085C18.3327 16.3502 16.441 18.3327 13.616 18.3327H6.39102C3.56602 18.3327 1.66602 16.3502 1.66602 13.4085V6.59935C1.66602 3.64935 3.56602 1.66602 6.39102 1.66602ZM9.99238 7.55018C9.60072 7.55018 9.27572 7.22434 9.27572 6.82518C9.27572 6.41684 9.60072 6.09184 10.009 6.09184C10.409 6.09184 10.734 6.41684 10.734 6.82518C10.734 7.22434 10.409 7.55018 9.99238 7.55018ZM10.7243 13.1502C10.7243 13.5502 10.3993 13.8752 9.99095 13.8752C9.59095 13.8752 9.26595 13.5502 9.26595 13.1502V9.46683C9.26595 9.06599 9.59095 8.73349 9.99095 8.73349C10.3993 8.73349 10.7243 9.06599 10.7243 9.46683V13.1502Z"
      />
    </svg>
  );
};

export default React.memo(AboutIcon);
