import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px auto 20px;
  padding: 0 30px;
  max-width: 1400px;
  position: relative;
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadVideoContainer = styled.div`
  width: 100%;
  min-height: 150px;
  border-radius: 25px;
  ${(props) => ({
    background: props.theme.palette.light.main,
  })}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: box-shadow 0.3s;
`;

export const FileButton = styled.div`
  width: 200px;
  border-radius: 25px;
  border: 1px solid ${(props) => props.theme.palette.light.main};
`;

export const FileInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`;

export const FileLabel = styled.label`
  color: green;
  font-weight: 600;
  font-size: 21px;
`;

export const FormContainer = styled.div`
  display: grid;
  margin-bottom: 30px;
`;

export const Column = styled.div`
  display: grid;
  gap: 15px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 15px;
`

export const StyledAccordion = styled(Accordion)`
  padding: 0 !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none !important;
  overflow: hidden !important;
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  font-weight: 600;
  ${props => ({
    color: `${props.theme.palette.secondary.light} !important`
  })}
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 12px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
      ${props => ({
    background: props.theme.palette.primary.light
  })};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${props => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`
    })}
  }
`

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 !important;
    ${props => ({
    color: `${props.theme.palette.secondary.main} !important`
  })};
`

export const StyledName = styled.span`
  font-weight: 500;
  margin-left: 10px;
  width: 80%;
  overflow: hidden;
  text-overflow: clip;
  ${props => ({
    color: `${props.theme.palette.secondary.main} !important`
  })};
`

export const GridContainer = styled.div<{
  columns?: string
  rows?: string
  margin?: string
  gridGap?: string
  width?: string
  height?: string;
}>`
  display: grid;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${props => ({
    margin: props.margin,
    gridTemplateColumns: props.columns,
    gridTemplateRows: props.rows,
    gridGap: props.gridGap,
    width: props.width,
    height: props.height,
  })}
`