import React from 'react';
import { Icon } from '@plant/data';

const GridTemplateIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="5" height="5" rx="1.5" />
      <rect y="8" width="5" height="5" rx="1.5" />
      <rect x="8" width="5" height="5" rx="1.5" />
      <rect x="8" y="8" width="5" height="5" rx="1.5" />
    </svg>
  );
};

export default React.memo(GridTemplateIcon);
