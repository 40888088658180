import { SelectedVersion, Version, UnitSerials } from '@plant/data';
import { createReducer } from 'typesafe-actions';
import {
  addVersion,
  getVersionDetails,
  deleteVersion,
  getVersionsList,
  SoftwareActionUnion,
  updateVersionDetails,
  getUnitSerials,
} from '../actions/software';

export interface State {
  loading: boolean;
  error: string;
  selectedVersion: SelectedVersion;
  unitSerials: UnitSerials[];
  keys: string[];
  versions: {
    [key in string]: Version;
  };
}

export const initialState: State = {
  loading: false,
  error: null,
  selectedVersion: null,
  unitSerials: [],
  keys: [],
  versions: {},
};

export const reducer = createReducer<State, SoftwareActionUnion>(initialState)
  .handleAction(getVersionsList.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getVersionsList.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getVersionsList.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    keys: action.payload.data.map((version) => String(version.version)),
    versions: action.payload.data.reduce((acc, value) => {
      return {
        ...acc,
        [value.version]: value,
      };
    }, {}),
  }))
  .handleAction(addVersion.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addVersion.success, (state, action) => ({
    ...state,
    keys: state.keys.concat(String(action.payload.version)),
    versions: { ...state.versions, [action.payload.version]: action.payload },
  }))
  .handleAction(addVersion.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getVersionDetails.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getVersionDetails.success, (state, action) => ({
    ...state,
    selectedVersion: action.payload,
    loading: false,
    error: null,
  }))
  .handleAction(getVersionDetails.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getUnitSerials.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getUnitSerials.success, (state, action) => ({
    ...state,
    unitSerials: action.payload,
    loading: false,
    error: null,
  }))
  .handleAction(getUnitSerials.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(deleteVersion.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(deleteVersion.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(deleteVersion.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(updateVersionDetails.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(updateVersionDetails.success, (state, action) => ({
    ...state,
    loading: false,
    selectedVersion: action.payload,
    error: null,
  }))
  .handleAction(updateVersionDetails.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));
