import React from 'react';
import { SignInPage } from '../pages/SignIn';
import { DashboardUsersPage } from '../pages/DashboardUsersPage';
import { DashboardContentPage } from '../pages/DashboardContentPage';
import { DashboardNotificationsPage } from '../pages/DashboardNotificationsPage';
import { UserPage } from '../pages/UserPage';
import { UnitPage } from '../pages/UnitPage';
import { AddContentPage } from '../pages/AddContentPage';
import { CropPage } from '../pages/CropPage';
import DashboardFirmwarePage from '../pages/DashboardFirmwarePage';
import { AddVersionPage } from '../pages/AddVersionPage';
import VersionPage from '../pages/VersionPage';

export interface IRoute {
  path: string;
  element: React.ComponentType;
}

export enum RouteNames {
  Login = '/login',
  DashboardUsers = '/dashboard/users',
  DashboardUsersCurrentUser = '/dashboard/users/:id',
  DashboardUnit = '/dashboard/unit/:userId/:unitId',
  DashboardNotifications = '/dashboard/notifications',
  DashboardContent = '/dashboard/content',
  DashboardFirmware = '/dashboard/firmware',
  AddVersionPage = '/dashboard/firmware/add',
  VersionPage = '/dashboard/firmware/:id',

  DashboardContentManageAdd = '/dashboard/content/manage/:page/:mode/',
  DashboardContentManageEdit = '/dashboard/content/manage/:page/:mode/:id',

  DashboardContentCrop = '/dashboard/content/crop/:id',

  ANY = '*',
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.Login,
    element: SignInPage,
  },
];

export const privateRoutes: IRoute[] = [
  {
    path: RouteNames.DashboardUsers,
    element: DashboardUsersPage,
  },
  {
    path: RouteNames.DashboardUsersCurrentUser,
    element: UserPage,
  },
  {
    path: RouteNames.DashboardUnit,
    element: UnitPage,
  },
  {
    path: RouteNames.DashboardContent,
    element: DashboardContentPage,
  },
  {
    path: RouteNames.DashboardNotifications,
    element: DashboardNotificationsPage,
  },
  {
    path: RouteNames.DashboardContentManageAdd,
    element: AddContentPage,
  },
  {
    path: RouteNames.DashboardContentManageEdit,
    element: AddContentPage,
  },
  {
    path: RouteNames.DashboardContentCrop,
    element: CropPage,
  },
  {
    path: RouteNames.DashboardFirmware,
    element: DashboardFirmwarePage,
  },
  {
    path: RouteNames.AddVersionPage,
    element: AddVersionPage,
  },
  {
    path: RouteNames.VersionPage,
    element: VersionPage,
  },
];
