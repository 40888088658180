import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Button } from '@plant/ui';
import { UserGroups, Esp, Navigator } from '@plant/data';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DashBoardLayout } from '../../layouts/Dashboard';
import { Input } from '../../components/Input';
import { TextArea } from '../../components/TextArea';
import { AutocompleteInput } from '../../components/AutocompleteInput';
import { isEmailsLoadingSelector, usersSelector } from '../../store/selectors/notifications';
import { getUsersEmailsAction } from '../../store/actions/notifications';
import { addVersion, getUnitSerials } from '../../store/actions/software';
import { softwareErrorSelector, unitSerialsSelector } from '../../store/selectors/software';
import * as Styled from './styles';

export const AddVersionPage:FC = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [version, setVersion] = useState('');
  const [search, setSearch] = useState('');
  const [file, setFile] = useState<File>(null);
  const [group, setGroup] = useState<UserGroups>(UserGroups.Testing);
  const [espType, seEspType] = useState<Esp>(Esp.Cam);
  const [selectedUsers, setSelectedUsers] = useState<{ uuid: string; email: string }[]>([]);
  const [unitSerial, setUnitSerial] = useState<string>('')
  const isEmailsLoading = useSelector(isEmailsLoadingSelector);
  const users = useSelector(usersSelector);
  const error = useSelector(softwareErrorSelector);
  const serials = useSelector(unitSerialsSelector);

  useEffect(() => {
    if (search) {
      dispatch(
        getUsersEmailsAction.request({
          query: search,
          order: 'ASC',
          orderField: 'email',
          page: 1,
          count: 10,
        }),
      );
    }
  }, [dispatch, search]);

  useEffect(() => {
    dispatch(getUnitSerials.request())
    if (error) {
      toast(error, { type: 'error' });
    }
  }, [error]);

  const onUserClick = (selectedUser: { uuid: string; email: string }) => {
    setSelectedUsers((value) => {
      if (value.includes(selectedUser)) {
        return value.filter((user) => user.uuid !== selectedUser.uuid);
      } else {
        return value.concat(selectedUser);
      }
    });
  };

  const successCallback = () => Navigator.history.back();

  const handleUpload = async () => {
    if (name && version && description && file && unitSerial.length) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('version', version);
      formData.append('versionName', name);
      formData.append('description', description);
      formData.append('canDownload', group);
      formData.append('type', espType);
      formData.append('users', selectedUsers.map((user) => user.email).join(','));
      formData.append('unitSerial', unitSerial);
      dispatch(addVersion.request({ formData, successCallback }));
    } else {
      toast('Fill all fields', { type: 'error' });
    }
  };

  const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (field, value) => {
    setter(value);
  };

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];

    if (file) {
      setFile(file);
    }
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup(e.currentTarget.value as UserGroups);
  };

  const handleGroupChangeEsp = (e: React.ChangeEvent<HTMLInputElement>) => {
    seEspType(e.currentTarget.value as Esp);
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];

    if (file) {
      setFile(file);
    }
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const onUnitSerialCheckBockCluck = (serial: string) => () => {
    setUnitSerial(serial)
  }

  return (
    <DashBoardLayout currentPage="firmware">
      <Styled.Container>
        <Styled.FormContainer>
          <Styled.Row>
            <Styled.Column>
              <Input
                title="Name" 
                value={name} 
                fieldTitle="name" 
                onChange={handleChange(setName)}
              />
              <Input
                title="Version (Number)"
                value={version}
                fieldTitle="version"
                onChange={handleChange(setVersion)}
              />
            </Styled.Column>
            <TextArea
              title="Description"
              value={description}
              fieldTitle="Description"
              onChange={handleChange(setDescription)}
            />
          </Styled.Row>
          <Styled.Row>
            <Styled.UploadVideoContainer
              onDrop={handleDropFile}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
            >
              <Styled.FileInput onChange={handleChangeFile} accept=".py" type="file" id="file" />
              <Styled.FileLabel htmlFor="file">{file?.name || 'Drop file or click'}</Styled.FileLabel>
            </Styled.UploadVideoContainer>
            <Styled.Column>
            <Styled.Row>
              <FormControl>
                <Styled.FileLabel>User group</Styled.FileLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  row
                  value={group}
                  onChange={handleGroupChange}
                >
                  <FormControlLabel value={UserGroups.Testing} control={<Radio />} label="Testing" />
                  <FormControlLabel value={UserGroups.Close} control={<Radio />} label="Close" />
                  <FormControlLabel value={UserGroups.All} control={<Radio />} label="All" />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <Styled.FileLabel>Esp</Styled.FileLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label-2"
                  defaultValue="female"
                  name="radio-buttons-group-2"
                  row
                  value={espType}
                  onChange={handleGroupChangeEsp}
                >
                  <FormControlLabel value={Esp.Main} control={<Radio />} label="ESP-32" />
                  <FormControlLabel value={Esp.Cam} control={<Radio />} label="ESP-32 CAM" />
                </RadioGroup>
              </FormControl>
            </Styled.Row>
            {group === UserGroups.Testing && (
              <AutocompleteInput
                title="Emails addresses"
                onInputChangeHandler={handleChangeSearch}
                inputValue={search}
                onEmailTagClickHandler={onUserClick}
                placeholder="Email"
                loading={isEmailsLoading}
                preselected={selectedUsers}
                data={users}
                disabled={false}
              />
            )}
            <Styled.StyledAccordion>
              <Styled.StyledAccordionSummary
                expandIcon={<ExpandMoreIcon/>}
              >
                Select unit serial {!!serials.length && `(${serials.length})`}
              </Styled.StyledAccordionSummary>
              <Styled.StyledAccordionDetails>
                <Styled.GridContainer columns="33% 33% 33%">
                  {!!serials && serials.map(n => {
                    return (
                      <Styled.GridContainer key={n.uuid} margin="5px 0" columns="10% 90%">
                        <Styled.StyledCheckbox checked={n.unitSerial === unitSerial} onChange={onUnitSerialCheckBockCluck(n.unitSerial)}/>
                        <Styled.StyledName>{n.unitSerial}</Styled.StyledName>
                      </Styled.GridContainer>
                    )
                  })}
                </Styled.GridContainer>
              </Styled.StyledAccordionDetails>
            </Styled.StyledAccordion>
            </Styled.Column>
          </Styled.Row>
        </Styled.FormContainer>
        <Styled.CenteredContainer>
          <Button maxWidth={200} title="Upload" variant="contained" onClick={handleUpload} />
        </Styled.CenteredContainer>
      </Styled.Container>
    </DashBoardLayout>
  );
};
