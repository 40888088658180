const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const dateFormatter = (date: string) => {
  const data = new Date(date);
  return `${data.getDate()} ${month[data.getMonth()]} ${data.getFullYear()} / ${data.getHours()}:${data.getMinutes()}:${data.getSeconds()}`
}