import { UserAdminService, IUsersDataSuccessFullResponse, NotificationService } from '@plant/data';
import { takeLatest, Effect, call, put, SagaReturnType, debounce } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { snackToCamel } from '../../helpers/snakeToCamel';
import { requestUsersAction, sendPushNotification } from '../actions/pushNotifications';

export class PushNotificationsSagaWorker {
  static *getUsers({ payload }: ActionType<typeof requestUsersAction.request>) {
    try {
      const response: SagaReturnType<typeof UserAdminService.getAllUsers> = yield call(
        UserAdminService.getAllUsers,
        payload,
      );
      const mappedResponse: IUsersDataSuccessFullResponse = snackToCamel(response.data.data);
      yield put(requestUsersAction.success(mappedResponse));
    } catch (error) {
      yield put(requestUsersAction.failure(error?.response?.data));
    }
  }

  static *sendPushNotification({ payload }: ActionType<typeof sendPushNotification.request>) {
    try {
      yield call(NotificationService.sendPushToUsers, payload);

      yield put(sendPushNotification.success());
      toast('Notification sent successfully', { type: 'success' });
    } catch (error) {
      yield put(sendPushNotification.failure(error?.response?.data || 'error'));
      toast(error?.response?.data?.message, { type: 'error' });
    }
  }
}

export function* pushNotificationsSaga(): Generator<Effect, void> {
  yield debounce(1000, requestUsersAction.request, PushNotificationsSagaWorker.getUsers);
  yield takeLatest(sendPushNotification.request, PushNotificationsSagaWorker.sendPushNotification);
}
