import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const UnitInfoWrapper = styled.div`
  width: 90%;
  margin: 30px auto;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const UnitTitle = styled.h3`
  font-weight: 600;
  ${(props) => ({
    color: props.theme.palette.secondary.main,
  })};
  margin: 10px 0;
`;

export const AccordionMenu = styled(Accordion)`
  padding: 0 0 0 5px !important;
  border-radius: 25px !important;
  box-shadow: none !important;
  font-weight: 600;
  width: 80%;
  margin: 0 auto 20px !important;
  text-align: center !important;
  ${(props) => ({
    color: props.theme.palette.secondary.main,
    background: `${props.theme.palette.light.main} !important`,
  })};
`;

export const AccordionMenuSummary = styled(AccordionSummary)<{
  current?: string;
}>`
  width: 100%;
  font-weight: 400;
  position: relative;
  ${(props) =>
    props.current && {
      fontWeight: 600,
      color: `${props.theme.palette.secondary.main} !important`,
    }}
  & > .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const UnitNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const UnitNameInSummary = styled.p`
  text-align: left;
  width: 100%;
`;

export const UnitDeleteButton = styled.button`
  background: none;
  border: none;
  & > svg {
    ${props => ({
      color: props.theme.palette.error.main
    })}
  }
`;

export const AccordionMenuSummaryContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    ${(props) => ({
      background: props.theme.palette.primary.light,
    })};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${(props) => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`,
    })}
  }
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin: 0 !important;
  padding: 0 !important;
`;

export const UnitContainer = styled.div`
  border-radius: 25px;
  padding: 15px;
  max-width: 600px;
  margin: 0 auto;
  ${(props) => ({
    backgroundColor: props.theme.palette.light.main,
  })}
`;

export const PodsContainer = styled.div`
  border-radius: 25px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => ({
    background: props.theme.palette.green.light,
  })}
`;

export const EnvContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  &::before {
    background: none !important;
  }
  pointer-events: none;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  & > .MuiAccordionSummary-content {
    margin: 0 !important;
    padding: 0 !important;
  }
  & > .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    ${(props) => ({
      background: props.theme.palette.green.light,
    })};
    border-radius: 50%;
    pointer-events: auto;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PodColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
`;

export const BottomContainer = styled.div`
  max-width: 600px;
  margin: 25px auto 0;
  display: flex;
  justify-content: space-between;
`;

export const EnvironmentContainer = styled.div`
  padding: 20px;
  ${(props) => ({
    background: props.theme.palette.light.main,
  })}
  width: 48%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
`;

export const EnvironmentTitle = styled.div`
  ${(props) => ({
    background: props.theme.palette.green.light,
  })};
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  border-radius: 25px;
`;

export const EnvironmentTitleName = styled.span`
  font-size: 14px;
  font-weight: 500;
  ${(props) => ({
    color: props.theme.palette.secondary.light,
  })};
`;

export const EnvironmentTitleValue = styled.span`
  font-size: 15px;
  font-weight: 600;
  ${(props) => ({
    color: props.theme.palette.secondary.main,
  })};
`;

export const EnvironmentDescription = styled.p`
  font-weight: 500;
  font-size: 14px;
  ${(props) => ({
    color: props.theme.palette.secondary.main,
  })};
  margin: 10px 0;
`;

export const TimelapseContainer = styled.div`
  padding: 20px;
  ${(props) => ({
    background: props.theme.palette.light.main,
  })}
  width: 48%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
`;
