import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteInput } from '../../../../components/AutocompleteInput';
import {
  requestUsersAction,
  sendPushNotification,
  setBodyAction,
  setSubjectAction,
  toggleUserAction,
} from '../../../../store/actions/pushNotifications';
import {
  bodySelector,
  loadingSelector,
  searchUsersSelector,
  SelectedAllSelector,
  subjectSelector,
  usersSelector,
} from '../../../../store/selectors/pushNotifications';
import * as Styled from './styles';

const PushNotificationsForm = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const loading = useSelector(loadingSelector);
  const selectedUsers = useSelector(usersSelector);
  const subject = useSelector(subjectSelector);
  const body = useSelector(bodySelector);
  const selectedAll = useSelector(SelectedAllSelector);
  const data = useSelector(searchUsersSelector);

  useEffect(() => {
    if (search) {
      dispatch(
        requestUsersAction.request({
          query: search,
          order: 'ASC',
          orderField: 'email',
          page: 1,
          count: 10,
        }),
      );
    }
  }, [dispatch, search]);

  const handleToggleUser = (user: { email: string; uuid: string }) => {
    dispatch(toggleUserAction(user));
    setSearch('');
  };

  const handleChangeSubject = (e: SyntheticEvent<HTMLInputElement>) => {
    dispatch(setSubjectAction(e.currentTarget.value));
  };

  const handleChangeBody = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    dispatch(setBodyAction(e.currentTarget.value));
  };

  return (
    <Styled.Container>
      <AutocompleteInput
        title="Users"
        onInputChangeHandler={setSearch}
        onEmailTagClickHandler={handleToggleUser}
        inputValue={search}
        placeholder="Email"
        loading={loading}
        preselected={selectedUsers}
        data={data}
        disabled={selectedAll}
      />
      <Styled.StyledInputWrapper>
        <Styled.StyledInputLabel>Subject</Styled.StyledInputLabel>
        <Styled.StyledInput
          placeholder="Write your subject here"
          onChange={handleChangeSubject}
          value={subject}
        />
      </Styled.StyledInputWrapper>
      <Styled.StyledInputWrapper>
        <Styled.StyledInputLabel>Body</Styled.StyledInputLabel>
        <Styled.StyledTextArea
          placeholder="Write your body here"
          onChange={handleChangeBody}
          value={body}
        />
      </Styled.StyledInputWrapper>
    </Styled.Container>
  );
};

export default React.memo(PushNotificationsForm);
