import React from 'react';
import { Icon } from '@plant/data';

const StarIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.06248 0.495197L10.9008 4.10602C11.0362 4.36762 11.2947 4.54929 11.5928 4.58966L15.7219 5.17747C15.963 5.21058 16.1819 5.33492 16.3297 5.52467C16.4759 5.71199 16.5386 5.94937 16.5031 6.18272C16.4742 6.3765 16.3809 6.55575 16.238 6.69302L13.2461 9.52789C13.0272 9.72571 12.9281 10.0188 12.981 10.3054L13.7176 14.2909C13.7961 14.7721 13.4699 15.2259 12.981 15.3172C12.7795 15.3486 12.573 15.3155 12.3914 15.2251L8.70821 13.3495C8.43486 13.2146 8.11197 13.2146 7.83862 13.3495L4.15547 15.2251C3.70292 15.4601 3.14219 15.3002 2.89032 14.8642C2.797 14.6906 2.76397 14.4928 2.79452 14.2998L3.53115 10.3135C3.58401 10.0277 3.48408 9.73298 3.26607 9.53516L0.274127 6.7019C-0.081801 6.36601 -0.0925367 5.81292 0.250178 5.46492C0.25761 5.45765 0.265868 5.44958 0.274127 5.4415C0.416167 5.3002 0.602802 5.21058 0.804302 5.18716L4.93339 4.59855C5.23069 4.55737 5.48917 4.37731 5.62543 4.11409L7.39763 0.495197C7.55537 0.185145 7.88239 -0.0078304 8.23749 0.000243885H8.34815C8.65618 0.0365781 8.92457 0.223094 9.06248 0.495197Z" />
    </svg>
  );
};

export default React.memo(StarIcon);
