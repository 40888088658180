import { FC, useState, useEffect } from 'react';
import { DashBoardLayout } from '../../layouts/Dashboard';
import * as S from './style';
import { CropList } from '../../components/CropList';
import { FaqList } from '../../components/FaqList';
import { AboutList } from '../../components/AboutList';
import GrassIcon from '@mui/icons-material/Grass';
import QuizIcon from '@mui/icons-material/Quiz';
import InfoIcon from '@mui/icons-material/Info';
import { IGetFaqType } from '@plant/data';
import { resetUsersEmailsAction } from '../../store/actions/notifications';
import { useDispatch } from 'react-redux';
import { RouteNames } from '../../router';
import { useNavigate } from "react-router-dom";

export const DashboardContentPage: FC = (): JSX.Element => {

  const CONTENT_TABS: Array<{id: 'cropGuide' | 'faq' | 'about', title: string}> = [
    {id: 'cropGuide', title: 'Crop Guide'},
    {id: 'faq', title: 'FAQ'},
    {id: 'about', title: 'About'},
  ]

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetUsersEmailsAction())
  }, [])

  const [ currentTab, setCurrentTab ] = useState<'cropGuide' | 'faq' | 'about'>('cropGuide');
  const [ expanded, setExpanded ] = useState(false);

  const [ currentModel, setCurrentModel ] = useState<'add' | 'delete' | 'edit' | 'deploy' | null>(null)
  const [ currentFaq, setCurrentFaq ] = useState<IGetFaqType>(null)

  const onAccordionMenuClickHandle = () => {
    setExpanded(!expanded)
  }

  const onMenuItemClick = (key: 'cropGuide' | 'faq' | 'about') => () => {
    if (key !== currentTab) {
      setCurrentTab(key);
    }
    setExpanded(false);
  }

  const onAddCropGuideButtonClick = () => {
    navigate(RouteNames.DashboardContentManageAdd.replace(':page', 'crop').replace(':mode', 'add'));
  }

  const onAddFaqButtonClick = () => {
    setCurrentFaq({
      active: false,
      subject: '',
      content: '',
    })
    setCurrentModel('add')
  }

  const onAddAboutButtonClick = () => {
    setCurrentModel('add')
  }

  const currentContentPage = {
    cropGuide: <CropList/>,
    faq: <FaqList currentModel={currentModel} currentFaq={currentFaq} setCurrentModel={setCurrentModel} setCurrentFaq={setCurrentFaq}/>,
    about: <AboutList currentModel={currentModel} currentFaq={currentFaq} setCurrentModel={setCurrentModel} setCurrentFaq={setCurrentFaq}/>,

  }

  const Icons = {
    cropGuide: <GrassIcon/>,
    faq: <QuizIcon />,
    about: <InfoIcon/>
  }

  const addItemCallback = {
    cropGuide: onAddCropGuideButtonClick,
    faq: onAddFaqButtonClick,
    about: onAddAboutButtonClick
  }

  return (
    <DashBoardLayout rootPage currentPage="content">
      <S.ContentPageContainer>
        <S.TopContainer>
          <S.AccordionMenuSummary>
            <S.AccordionMenu 
              expanded={expanded}
              onClick={onAccordionMenuClickHandle}
            >
              <S.AccordionMenuSummary current
                expandIcon={
                  <S.StyledExpandMoreIcon />
                }
              >
                {CONTENT_TABS.find(tab => tab.id === currentTab).title}
              </S.AccordionMenuSummary>
              {CONTENT_TABS.map(tab => 
                <S.AccordionMenuSummary
                key={tab.id}
                onClick={onMenuItemClick(tab.id)}
                current={currentTab === tab.id}
              >
                {tab.title}
              </S.AccordionMenuSummary>
              )}
            </S.AccordionMenu>
          </S.AccordionMenuSummary>
          <S.StyledButton onClick={addItemCallback[currentTab]}>
            <S.AddIcon>
              +
            </S.AddIcon>
            {Icons[currentTab]}
          </S.StyledButton>
        </S.TopContainer>
        {currentContentPage[currentTab]}
      </S.ContentPageContainer>
    </DashBoardLayout>
  )
}