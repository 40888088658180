import React from 'react';
import * as Styled from './styles';

export interface TitledFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  isInvalid?: boolean;
  rightIcon?: React.ReactNode;
  withBorder?: boolean;
  disabled?: boolean;
  withoutShadow?: boolean;
}

const KitTitledField = React.forwardRef<HTMLDivElement, TitledFieldProps>(
  (
    {
      title,
      rightIcon,
      isInvalid = false,
      withBorder = false,
      disabled = false,
      withoutShadow = false,
      children,
      ...otherProps
    },
    ref
  ) => {
    return (
      <Styled.Container
        ref={ref}
        isInvalid={isInvalid}
        withBorder={withBorder}
        disabled={disabled}
        withoutShadow={withoutShadow}
        {...otherProps}
      >
        <Styled.InnerContainer>
          <Styled.Title isInvalid={isInvalid}>{title}</Styled.Title>
          {children}
        </Styled.InnerContainer>
        <Styled.IconContainer>{rightIcon}</Styled.IconContainer>
      </Styled.Container>
    );
  }
);

export default React.memo(KitTitledField);
