import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import {
  ISignInRequest,
  ISignInSuccessFullResponse,
  ISignInFailedResponse,
  IUserData,
  IEnterCodeRequest,
} from '@plant/data';

export enum AuthTypes {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] LoginSuccess',
  LoginFailed = '[Auth] LoginFailed',

  EnterCode = '[Auth] EnterCode',
  EnterCodeSuccess = '[Auth] EnterCodeSuccess',
  EnterCodeFailed = '[Auth] EnterCodeFailed',

  SetAuthData = '[Auth] SetAuthData',

  SetIsAuthenticated = '[Auth] SetIsAuthenticated',

  ResetAuth = '[Auth] ResetAuth',
}

export const loginAction = createAsyncAction(
  AuthTypes.Login,
  AuthTypes.LoginSuccess,
  AuthTypes.LoginFailed,
)<ISignInRequest, { status: string, email: string }, ISignInFailedResponse>();

export const enterCodeAction = createAsyncAction(
  AuthTypes.EnterCode,
  AuthTypes.EnterCodeSuccess,
  AuthTypes.EnterCodeFailed,
)<IEnterCodeRequest, ISignInSuccessFullResponse, string>();

export const setAuthDataAction = createAction(AuthTypes.SetAuthData)<{
  data: IUserData;
  token: string;
}>();

export const setIsAuthenticatedAction = createAction(AuthTypes.SetIsAuthenticated)<boolean>();

export const resetAuthAction = createAction(AuthTypes.ResetAuth)();

export type AuthActionUnion = ActionType<
  | typeof loginAction
  | typeof enterCodeAction
  | typeof setIsAuthenticatedAction
  | typeof setAuthDataAction
  | typeof resetAuthAction
>;
