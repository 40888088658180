import styled from "styled-components";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

export const SmartPictureWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StyledPicture = styled.img`
  max-height: 150px;
  width: 100%;
  object-fit: cover;
  margin: 10px 0 0 0;
  border-radius: 20px;
  object-position: center;
`

export const StyledPictureDate = styled.div`
  ${props => ({
    background: props.theme.palette.primary.light
  })}
  padding: 10px 20px;
  border-radius: 25px;
  margin-top: 20px;
  font-weight: 500;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
`

export const StyledPictureDateTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  ${props => ({
    color: props.theme.palette.primary.dark
  })};
`

export const DownloadButton = styled.button`
  position: absolute;
  transition: all 0.3s;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => ({
    background: props.theme.palette.primary.main,
  })}
`

export const StyledDownloadForOfflineIcon = styled(DownloadForOfflineIcon)`
${props => ({
  color: props.theme.palette.secondary.main,
})};
`