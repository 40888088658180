import styled from "styled-components"

export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`

export const PopupButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  width: 230px;
`
export const StyledInputLabel = styled.span`
  font-weight: 500;
  ${props => ({
    color: props.theme.palette.secondary.light,
  })}
`

export const StyledInputFieldWrapper = styled.div<{
  width?: string
}>`
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  border-radius: 35px;
  ${props => ({
    width: props.width,
    background: props.theme.palette.light.main,
  })}
`

export const InputWrapper = styled.div`
  width: 100%;
  padding: 5px 10px;
  border-radius: 20px;
  ${props => ({
    boxShadow: `0 0 5px 1px ${props.theme.palette.gray.dark}`,
  })}
`

export const StyledInputField = styled.input`
  border: none;
  outline: none;
  font-weight: 600;
  margin: 10px 0 5px 0;
  font-size: 16px;
  ${props => ({
    color: props.theme.palette.secondary.main,
  })}
`

export const StyledTextAreaWrapper = styled.div<{
  width?: string
  height?: string
  margin?: string
}>`
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  border-radius: 35px;
  transition: all 0.3s;
  margin-top: 15px;
  ${props => ({
    width: 650,
    height: props.height,
    margin: props.margin,
    background: props.theme.palette.light.main,
  })}
`

export const ContentList = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin: 10px 0;
  border-radius: 20px;
  padding-bottom: 10px;
  ${props => ({
    boxShadow: `0 0 5px 1px ${props.theme.palette.gray.dark}`,
  })}
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledContentFieldsWrapper = styled.div`
  margin: 3px;
  padding: 10px;
  border-radius: 20px;
  transition: all 0.3s;
  position: relative;
  &:hover {
    & > button {
      transform: scale(1);
      transition: transform 0.3s;
    }
    ${props => ({
      boxShadow: `inset 0 0 3px 1px ${props.theme.palette.gray.contrastText}`,
    })}
  }
`

export const DeleteButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 70px;
  transform: scale(0);
  ${props => ({
    background: props.theme.palette.error.main,
    color: props.theme.palette.light.main,
  })}
`

export const StyledTextAreaLabel = styled.span`
  font-weight: 500;
  ${props => ({
    color: props.theme.palette.primary.dark,
  })}
`

export const StyledTextAreaField = styled.textarea`
  resize: none;
  border: none;
  outline: none;
  font-weight: 600;
  margin: 10px 0;
  font-size: 16px;
  height: 100%;
  ${props => ({
    color: props.theme.palette.secondary.main,
  })}

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    ${props => ({
      background: props.theme.palette.primary.light
    })}
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${props => ({
      background: props.theme.palette.primary.dark,
      border: `3px solid ${props.theme.palette.light.main}`,
    })}
  }
`

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 700;
  position: absolute;
  bottom: -10px;
`