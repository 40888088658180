import { FC, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { DashBoardLayout } from '../../layouts/Dashboard';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/LoadingScreen';
import * as Styled from './style';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUnitAction, deleteCurrentUnitAction } from '../../store/actions/users';
import {
  isLoadingCurrentUnitSelector,
  currentUnitSelector,
  currentUserSelector,
  isDeletingUnitSelector,
} from '../../store/selectors/users';
import { authAccessTokenSelector } from '../../store/selectors/auth';
import TEMP_LOGO from '../../assets/images/dashboard/temp.png';
import DROP_LOGO from '../../assets/images/dashboard/dropGreen.png';
import HUMIDITY_LOGO from '../../assets/images/dashboard/humidity.png';
import FAN_LOGO from '../../assets/images/dashboard/fan.png';
import { dateFormatter } from '../../helpers/dateFormatter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ErrorAlert } from '../../components/ErrorAlert';
import { EnvInfoContainer } from './components/EnvInfoContainer';
import { InfoLine } from './components/InfoLine';
import { Pod } from './components/Pod';
import { SmartPicture } from './components/SmartPicture';
import { Alerts } from './components/Alerts';
import { RouteNames } from '../../router';
import { useNavigate } from 'react-router-dom';
import { DeletePopup } from './components/DeletePopup';

export const UnitPage: FC = (): JSX.Element => {
  const [date, setDate] = useState(null);
  const [currentImg, setCurrentImg] = useState(null);
  const [currentImgDate, setCurrentImgDate] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState('');

  const { userId, unitId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoadingCurrentUnit = useSelector(isLoadingCurrentUnitSelector);
  const currentUnit = useSelector(currentUnitSelector);
  const token = useSelector(authAccessTokenSelector);
  const isDeletingUnit = useSelector(isDeletingUnitSelector);

  const currentUser = useSelector(currentUserSelector);

  const ledTime = currentUnit?.settings?.led?.filter((item) => !item.led_off);
  const onValues = ledTime && [ledTime[0], ledTime[1]];
  const startDateVal =
    onValues && onValues[0]?.timestamp !== 'Invalid date' ? onValues[0]?.timestamp : '';
  const endDataVal =
    onValues && onValues[1]?.timestamp !== 'Invalid date' ? onValues[1]?.timestamp : '';

  const isLedOff = ledTime
    ?.filter((item) => item.led_off ?? false)
    ?.reduce((acc, val) => (val.led_off ? val.led_off : acc), false);

  useEffect(() => {
    dispatch(getCurrentUnitAction.request({ userId, unitId }));
  }, [unitId]);

  useEffect(() => {
    if (currentUnit && !!currentUnit.images.length) {
      const imgUrl = `${currentUnit?.images[currentUnit.images.length - 1]?.url}?token=${token}`;
      setCurrentImg(imgUrl);
      setCurrentImgDate(currentUnit?.images[currentUnit.images.length - 1]?.createdOn);
    }
  }, [currentUnit]);

  useEffect(() => {
    if (date) {
      const selectedImg = currentUnit.images.reduce((acc, val) => {
        const photoDate = new Date(val.createdOn);
        const photoDateDay = `${photoDate.getFullYear()}-${photoDate.getMonth()}-${photoDate.getDate()}`;

        const selectedDate = new Date(date);
        const selectedDateDay = `${selectedDate.getFullYear()}-${selectedDate.getMonth()}-${selectedDate.getDate()}`;

        return photoDateDay === selectedDateDay ? val?.url : acc;
      }, '');
      const imgUrl = `${selectedImg}?token=${token}`;
      if (selectedImg) {
        setCurrentImg(imgUrl);
      } else {
        setCurrentImg('');
      }
    }
  }, [date]);

  const openCloseAccordionClick = () => setExpanded((prev) => !prev);

  const onSelectUnitButtonClick = (unitId: string) => () => {
    setExpanded(false);
    navigate(
      RouteNames.DashboardUnit.replace(':userId', currentUser.uuid).replace(':unitId', unitId),
    );
  };

  const onDeleteUnitButtonClick = (uuid: string) => () => {
    setUnitToDelete(uuid);
    setOpenDeletePopup(true);
  };

  const onCloseDeletePopup = () => {
    setUnitToDelete('');
    setExpanded(false);
    setOpenDeletePopup(false);
  };

  const onSubmitDeleteUnitButtonClick = () => {
    dispatch(
      deleteCurrentUnitAction.request({
        unitId: unitToDelete,
        onClose: onCloseDeletePopup,
      }),
    );
  };

  return (
    <DashBoardLayout currentPage="users">
      {!isLoadingCurrentUnit && currentUnit ? (
        <Styled.UnitInfoWrapper>
          <Styled.AccordionMenuSummary>
            <Styled.AccordionMenu expanded={expanded}>
              <Styled.AccordionMenuSummary
                current="true"
                expandIcon={<Styled.StyledExpandMoreIcon />}
                onClick={openCloseAccordionClick}
              >
                {currentUnit?.name}
              </Styled.AccordionMenuSummary>
              <Styled.AccordionMenuSummaryContainer>
                {currentUser &&
                  Boolean(currentUser.units.length) &&
                  currentUser.units.map((unit) => (
                    <Styled.AccordionMenuSummary key={unit.uuid}>
                      <Styled.UnitNameContainer>
                        <Styled.UnitNameInSummary onClick={onSelectUnitButtonClick(unit.uuid)}>
                          {unit?.name}
                        </Styled.UnitNameInSummary>
                      </Styled.UnitNameContainer>
                    </Styled.AccordionMenuSummary>
                  ))}
              </Styled.AccordionMenuSummaryContainer>
            </Styled.AccordionMenu>
          </Styled.AccordionMenuSummary>
          <Styled.UnitContainer>
            <Styled.PodsContainer>
              <Styled.PodColumn>
                <Pod currentUnit={currentUnit} number={1} />
                <Pod currentUnit={currentUnit} number={2} />
              </Styled.PodColumn>
              <Pod currentUnit={currentUnit} number={3} />
              <Styled.PodColumn>
                <Pod currentUnit={currentUnit} number={4} />
                <Pod currentUnit={currentUnit} number={5} />
              </Styled.PodColumn>
              <Pod currentUnit={currentUnit} number={6} />
              <Styled.PodColumn>
                <Pod currentUnit={currentUnit} number={7} />
                <Pod currentUnit={currentUnit} number={8} />
              </Styled.PodColumn>
            </Styled.PodsContainer>
            {!!currentUnit?.alerts?.length && <Alerts currentUnit={currentUnit} />}
            <Styled.EnvContainer>
              <EnvInfoContainer
                iconSrc={DROP_LOGO}
                title="Water"
                value={`${currentUnit?.status?.water_level || 0}%`}
              />
              <EnvInfoContainer
                iconSrc={TEMP_LOGO}
                title="Temp."
                value={`${currentUnit?.status?.temperature || 0}°C`}
              />
              <EnvInfoContainer
                iconSrc={HUMIDITY_LOGO}
                title="Humidity"
                value={`${currentUnit?.status?.humidity || 0}%`}
              />
              <EnvInfoContainer
                iconSrc={FAN_LOGO}
                title="Fan"
                value={currentUnit?.status?.fan ? 'On' : 'Off'}
              />
            </Styled.EnvContainer>
            <Styled.StyledAccordion>
              <Styled.StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              />
              <AccordionDetails>
                <Styled.InfoContainer>
                  <InfoLine
                    title="Unit status:"
                    value={currentUnit?.status?.connected ? 'Connected' : 'Disconnected'}
                  />
                  <InfoLine title="Pod limit:" value={currentUnit?.pod_limit} />
                  <InfoLine title="Led status:" value={isLedOff ? 'Off' : 'On'} />
                  <InfoLine title="Led light time:" value={`${startDateVal} - ${endDataVal}`} />
                  <InfoLine
                    title="Unit created on:"
                    value={dateFormatter(currentUnit?.created_on)}
                  />
                  <InfoLine
                    title="Unit updated on:"
                    value={dateFormatter(currentUnit?.updated_on)}
                  />
                  <InfoLine title="Unit serial:" value={currentUnit?.unit_serial} />
                </Styled.InfoContainer>
              </AccordionDetails>
            </Styled.StyledAccordion>
          </Styled.UnitContainer>
          <Styled.BottomContainer>
            <Styled.EnvironmentContainer>
              {currentUnit?.settings?.environment?.standard ? (
                <>
                  <Styled.EnvironmentTitle>
                    <Styled.EnvironmentTitleName>Selected environment</Styled.EnvironmentTitleName>
                    <Styled.EnvironmentTitleValue>
                      {currentUnit?.settings?.environment?.name}
                    </Styled.EnvironmentTitleValue>
                  </Styled.EnvironmentTitle>
                  <Styled.EnvironmentDescription>
                    {currentUnit?.settings?.environment?.description}
                  </Styled.EnvironmentDescription>
                </>
              ) : (
                <>
                  <Styled.EnvironmentTitle>
                    <Styled.EnvironmentTitleName>Custom environment</Styled.EnvironmentTitleName>
                    <Styled.EnvironmentTitleValue>
                      {currentUnit?.settings?.environment?.name}
                    </Styled.EnvironmentTitleValue>
                  </Styled.EnvironmentTitle>
                  <InfoLine
                    title="Led level:"
                    value={currentUnit?.settings?.environment?.led_level}
                  />
                  <InfoLine
                    title="Led time:"
                    value={`${currentUnit?.settings?.environment?.led_start_time} - ${currentUnit?.settings?.environment?.led_stop_time}`}
                  />
                  <InfoLine
                    title="Nutrition level:"
                    value={currentUnit?.settings?.environment?.nutrition_level}
                  />
                  <InfoLine
                    title="Nutrition ml:"
                    value={currentUnit?.settings?.environment?.nutrition_level}
                  />
                </>
              )}
            </Styled.EnvironmentContainer>
            <Styled.TimelapseContainer>
              {currentImg ? (
                <SmartPicture url={currentImg} currentImgDate={currentImgDate} />
              ) : currentUnit?.images.length ? (
                <ErrorAlert
                  position="relative"
                  top="30px"
                  text="Probably photo doesn't exist this day"
                />
              ) : (
                <ErrorAlert position="relative" top="30px" text="The user don't have any photos" />
              )}
            </Styled.TimelapseContainer>
          </Styled.BottomContainer>
          <DeletePopup
            open={openDeletePopup}
            onClosePopup={onCloseDeletePopup}
            onSubmit={onSubmitDeleteUnitButtonClick}
            uploading={isDeletingUnit}
          />
        </Styled.UnitInfoWrapper>
      ) : (
        <LoadingScreen />
      )}
    </DashBoardLayout>
  );
};
