import { Checkbox } from '@mui/material';
import styled from 'styled-components';

export const SelectAllEmailsButton = styled.button<{
  position?: 'absolute' | 'relative';
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}>`
  padding: 3px 20px 3px 35px;
  border-radius: 25px;
  transition: all 0.3s;
  border: none;
  font-size: 14px;
  ${(props) => ({
    background: props.theme.palette.light.main,
  })}
  ${(props) => ({
    position: props.position || 'relative',
    top: props.top,
    right: props.right,
    bottom: props.bottom,
    left: props.left,
  })}
`;
export const SelectAllEmailsTitle = styled.span`
  ${(props) => ({
    color: props.theme.palette.secondary.main,
  })};
  font-weight: 600;
`;

export const SelectAllEmailsCheckbox = styled(Checkbox)`
  & > .MuiSvgIcon-root {
    ${(props) => ({
      color: props.theme.palette.secondary.main,
    })};
  }
`;
