import React from 'react';
import { Icon } from '@plant/data';

const CropGuideIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.9193 2.50169C0.924175 4.45876 -0.180196 6.56846 2.07919 8.87267C4.03019 10.8621 6.46946 10.1137 8.46491 8.15702C11.2254 5.44958 11.0839 0.772541 10.7187 0.39972C10.308 -0.0192214 5.77442 -0.29852 2.9193 2.5015L2.9193 2.50169Z" />
  </svg>
);

export default React.memo(CropGuideIcon);
