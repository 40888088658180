import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGetAboutType, IGetFaqType } from '@plant/data';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Loader } from '../Loader';
import { Button } from '../Button';
import { getListOfAboutAction, loadMoreAboutAction } from '../../store/actions/content';
import { LoadingScreen } from '../LoadingScreen';
import { DeployPopup } from './components/DeployPopup';
import { EditPopup } from './components/EditPopup';
import { DeletePopup } from './components/DeletePopup';
import * as ContentActions from '../../store/actions/content';
import * as ContentSelectors from '../../store/selectors/content';
import * as Styled from './style';


type PropsType = {
  currentModel: 'add' | 'delete' | 'edit' | 'deploy' | null;
  currentFaq: IGetFaqType;
  setCurrentModel: (model: 'add' | 'delete' | 'edit' | 'deploy' | null) => void;
  setCurrentFaq: (faq: IGetFaqType) => void;
};

export const AboutList: FC<PropsType> = ({
  currentModel,
  currentFaq,
  setCurrentModel,
  setCurrentFaq,
}) => {
  const count = 15;

  const dispatch = useDispatch();

  const about = useSelector(ContentSelectors.aboutSelector);
  const aboutForm = useSelector(ContentSelectors.aboutFormSelector);
  const loading = useSelector(ContentSelectors.loadingSelector);
  const loadingMoreAbout = useSelector(ContentSelectors.loadingMoreAboutSelector);
  const loadingMoreAboutComplete = useSelector(ContentSelectors.loadingMoreAboutCompleteSelector);
  const uploading = useSelector(ContentSelectors.uploadingSelector);
  const uploadIsFinished = useSelector(ContentSelectors.uploadIsFinishedSelector);
  const error = useSelector(ContentSelectors.errorSelector);

  const [page, setPage] = useState(1);
  const [formError, setFormError] = useState('');
  const [expandedRow, setExpandedRow] = useState<string>('');

  useEffect(() => {
    dispatch(
      getListOfAboutAction.request({
        count,
        page: 1,
      }),
    );
  }, []);

  useEffect(() => {
    if (uploadIsFinished && !error) {
      setCurrentModel(null);
      setCurrentFaq(null);
      dispatch(
        getListOfAboutAction.request({
          count,
          page: 1,
        }),
      );
      setPage(1);
    }
  }, [uploadIsFinished, dispatch]);

  const loadMore = () => {
    dispatch(
      loadMoreAboutAction.request({
        count,
        page: page + 1,
      }),
    );
    setPage(page + 1);
  };

  const onContainerScroll = (e: any) => {
    const nearTheListBottom =
      e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop - 10 < 0;
    if (nearTheListBottom && !loadingMoreAbout) {
      loadMore();
    }
  };

  const onShowHideButtonClick = (id: string) => () => {
    if (expandedRow === id) {
      setExpandedRow('');
    } else {
      setExpandedRow(id);
    }
  };

  const onClosePopup = () => {
    setCurrentModel(null);
    setCurrentFaq(null);
    setFormError('')
    dispatch(ContentActions.clearAboutFormAction());
  };

  const onDeleteButtonClick = (faq: IGetFaqType) => () => {
    setCurrentFaq(faq);
    setCurrentModel('delete');
  };

  const deleteFaq = ({ id }: { id: string }) => {
    dispatch(ContentActions.deleteAboutByIdAction.request({ id }));
  };

  const onEditButtonClick = (faq: IGetAboutType) => () => {
    dispatch(ContentActions.setAboutFormAction(faq));
    setCurrentModel('edit');
  };

  const addObjectToAboutForm = () => {
    dispatch(ContentActions.addObjectToAboutFormAction());
  };

  const deleteObjectToAboutForm = (index: number) => {
    dispatch(ContentActions.deleteObjectFromAboutFormAction(index));
  };

  const setAboutSubject = (text: string) => {
    dispatch(ContentActions.setAboutFormSubjectAction(text));
  };

  const setAboutContent = (data: { value: string; id: string; index: number }) => {
    dispatch(ContentActions.setAboutFormContentAction(data));
  };

  const onSaveButtonClick = () => {
    setFormError('');
    const isValidForm =
      !!aboutForm.subject.length &&
      aboutForm.content.every(({ text, title }) => text.length && title.length);
    if (!isValidForm) return setFormError('Fill all fields');
    switch (currentModel) {
      case 'add':
        dispatch(ContentActions.addAboutByIdAction.request({
          faq: aboutForm,
          successCallback: onClosePopup
        }));
        break;
      case 'edit':
      case 'deploy':
        dispatch(ContentActions.setAboutByIdAction.request({
          faq: aboutForm,
          successCallback: onClosePopup
        }));
        break;

      default:
        break;
    }
  };

  const onDeployButtonClick = (faq: IGetAboutType) => () => {
    dispatch(ContentActions.prepareToDeployAboutFormContentAction(faq));
    setCurrentModel('deploy');
  };

  const onUploadButtonClick = () => {
    onSaveButtonClick();
    onClosePopup();
  };

  return (
    <Styled.StyledFaqListContainer onScroll={!loadingMoreAboutComplete ? onContainerScroll : null}>
      {about &&
        !loading &&
        about.map((faq, index) => {
          return (
            <Styled.StyledAccordion expanded={expandedRow === faq.uuid} key={faq.uuid}>
              <Styled.StyledAccordionSummary
                expandIcon={
                  <Styled.OpenCloseButton onClick={onShowHideButtonClick(faq.uuid)}>
                    <Styled.StyledExpandMoreIcon />
                  </Styled.OpenCloseButton>
                }
              >
                <Styled.SummaryTitle>{faq.subject}</Styled.SummaryTitle>
                <Button
                  height="20px"
                  fullWidth
                  title="Edit"
                  variant="primary"
                  onClick={onEditButtonClick(faq)}
                />
                <Button
                  height="20px"
                  fullWidth
                  title="Delete"
                  variant="warning"
                  onClick={onDeleteButtonClick(faq)}
                />
                <Styled.DeployButton onClick={onDeployButtonClick(faq)} active={faq.active}>
                  {faq.active ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Styled.DeployButton>
              </Styled.StyledAccordionSummary>
              <Styled.StyledAccordionDetails>
                {faq.content.map((el, index) => (
                  <Styled.ContentWrapper key={index}>
                    <Styled.ContentTittle>{el.title}</Styled.ContentTittle>
                    <Styled.ContentText>{el.text}</Styled.ContentText>
                  </Styled.ContentWrapper>
                ))}
              </Styled.StyledAccordionDetails>
            </Styled.StyledAccordion>
          );
        })}
      {about && !about.length && loading && <LoadingScreen />}
      {loadingMoreAbout && (
        <Loader
          position="relative"
          bottom="0"
          left="100%"
          margin="0 auto"
          bubbleSize="11px"
          height="30px"
          width="70px"
        />
      )}
      <DeletePopup
        uploading={uploading}
        currentFaq={currentFaq}
        onSubmit={deleteFaq}
        currentModel={currentModel}
        onClosePopup={onClosePopup}
      />
      <EditPopup
        currentModel={currentModel}
        onClosePopup={onClosePopup}
        setAboutSubject={setAboutSubject}
        setAboutContent={setAboutContent}
        addObjectToAboutForm={addObjectToAboutForm}
        deleteObjectToAboutForm={deleteObjectToAboutForm}
        onSaveButtonClick={onSaveButtonClick}
        formError={formError}
        aboutForm={aboutForm}
        loading={uploading}
      />
      <DeployPopup
        uploading={uploading}
        currentFaq={aboutForm}
        onSubmit={onUploadButtonClick}
        currentModel={currentModel}
        onClosePopup={onClosePopup}
      />
    </Styled.StyledFaqListContainer>
  );
};
