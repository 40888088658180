import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { dateFormatter } from "../../../../helpers/dateFormatter";
import { IUnitInfo } from "@plant/data";
import { authAccessTokenSelector } from "../../../../store/selectors/auth";
import * as Styled from './style';

type PropsType = {
  number: number
  currentUnit: IUnitInfo
}

export const Pod: FC<PropsType> = ({
  number,
  currentUnit
}) => {

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const currentPod = currentUnit?.pods.find(pod => pod.number === number)?.plant;
  const token = useSelector(authAccessTokenSelector);
  
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Styled.PodWrapper
      id={`crop-${number}-id`}
      onMouseEnter={currentPod ? handlePopoverOpen : null}
      onMouseLeave={currentPod ? handlePopoverClose : null}
    >
      <Styled.PodPlateBorder>
        <Styled.PodPlate>
          {currentPod ? (
            <Styled.PodImage 
              alt="logo" 
              src={`${currentPod?.photo ? currentPod?.photo : currentPod?.crop?.photo}?token=${token}`}
              />
          ) : (
            <Styled.PodText>
              Empty
            </Styled.PodText>
          )}
        </Styled.PodPlate>
      </Styled.PodPlateBorder>
      <Styled.StyledPopOver
        id={`pod-${number}`}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Styled.StyledPopOverText align="center" id={`crop-${number}-name`}>
          <Styled.PlantAgeTitle>
            {currentPod?.crop?.name}
          </Styled.PlantAgeTitle>
          {currentPod?.plant_action?.plant_age && (
            <>
              <Styled.PlantInfoContainer>
                <Styled.PlantAgeTitle>
                  Plant age:
                </Styled.PlantAgeTitle>
                <Styled.PlantAgeValue>
                  {`${currentPod?.plant_action?.plant_age} days`}
                </Styled.PlantAgeValue>
              </Styled.PlantInfoContainer>
              <Styled.PlantInfoContainer>
                <Styled.PlantAgeTitle>
                  Plant date:
                </Styled.PlantAgeTitle>
                <Styled.PlantAgeValue>
                  {dateFormatter(currentPod?.plant_action_date)}
                </Styled.PlantAgeValue>
              </Styled.PlantInfoContainer>
            </>
          )}
        </Styled.StyledPopOverText>
      </Styled.StyledPopOver>
    </Styled.PodWrapper>
  )
}