import { ApiService, LOCAL_STORAGE_KEYS } from '@plant/data';
import { takeLatest, Effect, put } from 'redux-saga/effects';
import * as AppActions from '../actions/app';
import { APP_CONFIG } from '../../config/appConfig';
import { setAuthDataAction } from '../actions/auth';
import { ActionType } from 'typesafe-actions';

export class StartAppSagaWorker {
  static *startApp({ payload }: ActionType<typeof AppActions.startAppAction.request>) {
    try {
      const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.authData))?.data;
      const token = localStorage.getItem(LOCAL_STORAGE_KEYS.token);

      if (userData) {
        userData.tokens = { token };

        yield put(setAuthDataAction({ data: userData, token }));
      }
      yield ApiService.init(APP_CONFIG.BASE_URL, payload);
      yield ApiService.getToken(token);
      yield put(AppActions.startAppAction.success());
    } catch (error) {
      yield put(AppActions.startAppAction.failure());
    }
  }
}

export function* startAppSaga(): Generator<Effect, void> {
  yield takeLatest(AppActions.startAppAction.request, StartAppSagaWorker.startApp);
}
