import { UserAdminService, IUsersDataSuccessFullResponse } from '@plant/data';
import { takeLatest, Effect, call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { snackToCamel } from '../../helpers/snakeToCamel';
import * as UsersActions from '../actions/users';

export class UsersSagaWorker {
  static *getAllUsers({ payload }: ActionType<typeof UsersActions.getAllUsersAction.request>) {
    try {
      const response: SagaReturnType<typeof UserAdminService.getAllUsers> = yield call(
        UserAdminService.getAllUsers,
        payload,
      );
      const mappedResponse: IUsersDataSuccessFullResponse = snackToCamel(response.data);
      yield put(UsersActions.getAllUsersAction.success(mappedResponse));
    } catch (error: any) {
      yield put(UsersActions.getAllUsersAction.failure(error?.response?.data));
    }
  }

  static *updatingAllUsers({
    payload,
  }: ActionType<typeof UsersActions.updateAllUsersAction.request>) {
    try {
      const response: SagaReturnType<typeof UserAdminService.getAllUsers> = yield call(
        UserAdminService.getAllUsers,
        payload,
      );
      const mappedResponse: IUsersDataSuccessFullResponse = snackToCamel(response.data.data);
      yield put(UsersActions.updateAllUsersAction.success(mappedResponse));
    } catch (error: any) {
      yield put(UsersActions.updateAllUsersAction.failure(error?.response?.data));
    }
  }

  static *loadMoreUsers({ payload }: ActionType<typeof UsersActions.loadMoreUsersAction.request>) {
    try {
      const response: SagaReturnType<typeof UserAdminService.getAllUsers> = yield call(
        UserAdminService.getAllUsers,
        payload,
      );
      const mappedResponse: IUsersDataSuccessFullResponse = snackToCamel(response.data.data);
      yield put(UsersActions.loadMoreUsersAction.success(mappedResponse));
    } catch (error: any) {
      yield put(UsersActions.loadMoreUsersAction.failure(error?.response?.data));
    }
  }

  static *findUsersUsers({ payload }: ActionType<typeof UsersActions.findUsersAction.request>) {
    try {
      const response: SagaReturnType<typeof UserAdminService.getAllUsers> = yield call(
        UserAdminService.getAllUsers,
        payload,
      );
      yield put(UsersActions.resetAllUsersAction());
      const mappedResponse: IUsersDataSuccessFullResponse = snackToCamel(response.data.data);
      yield put(UsersActions.findUsersAction.success(mappedResponse));
    } catch (error: any) {
      yield put(UsersActions.findUsersAction.failure(error?.response?.data));
    }
  }

  static *getCurrentUser({
    payload,
  }: ActionType<typeof UsersActions.getCurrentUserAction.request>) {
    try {
      const response: SagaReturnType<typeof UserAdminService.getCurrentUser> = yield call(
        UserAdminService.getCurrentUser,
        payload,
      );
      yield put(UsersActions.getCurrentUserAction.success(response.data));
    } catch (error: any) {
      yield put(UsersActions.getCurrentUserAction.failure(error?.response?.data));
    }
  }

  static *getCurrentUnit({
    payload,
  }: ActionType<typeof UsersActions.getCurrentUnitAction.request>) {
    try {
      const response: SagaReturnType<typeof UserAdminService.getCurrentUnit> = yield call(
        UserAdminService.getCurrentUnit,
        payload,
      );
      yield put(UsersActions.getCurrentUnitAction.success(response.data));
      yield put(UsersActions.getCurrentUserAction.request(payload));
    } catch (error: any) {
      yield put(UsersActions.getCurrentUnitAction.failure(error?.response?.data));
    }
  }

  static *deleteCurrentUnit({
    payload,
  }: ActionType<typeof UsersActions.deleteCurrentUnitAction.request>) {
    try {
      yield call(UserAdminService.deleteUnit, payload.unitId);
      yield put(UsersActions.deleteCurrentUnitAction.success(payload.unitId));
      toast('Success', { type: "success" })
    } catch (error: any) {
      toast('Failed to delete unit', { type: "error" })
      yield put(UsersActions.deleteCurrentUnitAction.success(payload.unitId));
      // yield put(UsersActions.deleteCurrentUnitAction.failure(error?.response?.data));
    } finally {
      yield call(payload.onClose)
    }
  }
}

export function* usersSaga(): Generator<Effect, void> {
  yield takeLatest(UsersActions.getAllUsersAction.request, UsersSagaWorker.getAllUsers);
  yield takeLatest(UsersActions.updateAllUsersAction.request, UsersSagaWorker.updatingAllUsers);
  yield takeLatest(UsersActions.loadMoreUsersAction.request, UsersSagaWorker.loadMoreUsers);
  yield takeLatest(UsersActions.findUsersAction.request, UsersSagaWorker.findUsersUsers);
  yield takeLatest(UsersActions.getCurrentUserAction.request, UsersSagaWorker.getCurrentUser);
  yield takeLatest(UsersActions.getCurrentUnitAction.request, UsersSagaWorker.getCurrentUnit);
  yield takeLatest(UsersActions.deleteCurrentUnitAction.request, UsersSagaWorker.deleteCurrentUnit);
}
