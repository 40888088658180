import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const usersStoreSelector = (state: AppState) => state.users;

export const usersSelector = createSelector(usersStoreSelector, (state) => state.data);
export const usersLoadingSelector = createSelector(usersStoreSelector, (state) => state.loading);
export const updatingUsersSelector = createSelector(
  usersStoreSelector,
  (state) => state.updatingUsers,
);
export const searchingUsersSelector = createSelector(
  usersStoreSelector,
  (state) => state.isSearching,
);
export const loadingMoreUsersSelector = createSelector(
  usersStoreSelector,
  (state) => state.loadingMoreUsers,
);
export const completeScrollSelector = createSelector(
  usersStoreSelector,
  (store) => store.completeScroll,
);
export const isLoadingCurrentUserSelector = createSelector(
  usersStoreSelector,
  (store) => store.isLoadingCurrentUser,
);
export const currentUserSelector = createSelector(usersStoreSelector, (store) => store.currentUser);
export const isLoadingCurrentUnitSelector = createSelector(
  usersStoreSelector,
  (store) => store.isLoadingCurrentUnit,
);
export const currentUnitSelector = createSelector(usersStoreSelector, (store) => store.currentUnit);
export const tableHeadButtonsSelector = createSelector(
  usersStoreSelector,
  (store) => store.tableHeadButtons,
);
export const statusButtonsSelector = createSelector(
  usersStoreSelector,
  (store) => store.statusButtons,
);
export const currentFieldSelector = createSelector(
  usersStoreSelector,
  (store) => store.currentField,
);
export const sortDirectionSelector = createSelector(
  usersStoreSelector,
  (store) => store.sortDirection,
);
export const isDeletingUnitSelector = createSelector(
  usersStoreSelector,
  (store) => store.isDeletingUnit,
);
