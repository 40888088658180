const toCamel = (str: string): string =>
  str.replace(/([-_][a-z])/gi, $1 =>
    $1.toUpperCase().replace('-', '').replace('_', ''),
  );

const isObject = (obj: Record<string, unknown>) =>
  obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';

export const snackToCamel = (obj: any): any => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach(k => {
      n[toCamel(k)] = snackToCamel(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map(i => {
      return snackToCamel(i);
    });
  }
  return obj;
};
