import React from 'react';
import { Icon } from '@plant/data';

const ShowPasswordIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.58206 0.471161C3.6308 0.471161 0.164062 5.91821 0.164062 5.91821C0.164062 5.91821 2.68937 11.3653 8.58206 11.3653C14.524 11.3653 17.0001 5.91821 17.0001 5.91821C17.0001 5.91821 13.5835 0.471161 8.58206 0.471161ZM8.58206 9.384C6.65095 9.384 5.11627 7.84933 5.11627 5.91821C5.11627 3.9871 6.65095 2.45243 8.58206 2.45243C10.5132 2.45243 12.0478 3.9871 12.0478 5.91821C12.0478 7.84933 10.5132 9.384 8.58206 9.384Z"
      fill="#A7B3AE"
    />
    <path
      d="M10.0679 5.91881C10.0679 6.73953 9.40312 7.40428 8.5824 7.40428C7.76168 7.40428 7.09692 6.73953 7.09692 5.91881C7.09692 5.09809 7.76168 4.43333 8.5824 4.43333C9.40312 4.43333 10.0679 5.09809 10.0679 5.91881Z"
      fill="#A7B3AE"
    />
  </svg>
);

export default React.memo(ShowPasswordIcon);
