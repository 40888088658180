export interface UserData {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  alias: string;
  isOwner: string;
  socialAuthToken: string;
  photo: string;
  type: number;
  updatedOn: string;
  token: string;
  refreshToken: string;
  country: string;
  streetAddress: string;
  city: string;
  emailNotifications: boolean;
  pushNotifications: boolean;
}
