import { FC } from 'react';
import LOGO from '../../assets/images/auth/Logo.png';
import * as Styled from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { AuthForm, CodeForm } from '../../components/AuthForm';
import { loginAction, resetAuthAction, enterCodeAction } from '../../store/actions/auth';
import { ISignInRequest } from '@plant/data';
import { useEffect, useState } from 'react';
import * as authSelectors from '../../store/selectors/auth';

export const SignInPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const isLoading = useSelector(authSelectors.authLoadingSelector);
  const email = useSelector(authSelectors.emailSelector);
  const error = useSelector(authSelectors.errorSelector);
  const authStatus = useSelector(authSelectors.authStatusSelector);
  const authEmail = useSelector(authSelectors.authEmailSelector);


  useEffect(() => {
    dispatch(resetAuthAction());
  }, [dispatch]);

  const onSubmitAuthFormHandler = ({ email, password }: ISignInRequest) => {
    dispatch(loginAction.request({ email, password }));
  };

  const onSubmitCodeFormHandler = ({ code }: { code: number }) => {
    dispatch(enterCodeAction.request({
      email: authEmail,
      code: code.toString()
    }))
  };

  return (
    <Styled.AuthLayoutWrapper>
      <Styled.LogoWrapper>
        <Styled.LogoImg src={LOGO} />
        <Styled.LogoText>plaant admin</Styled.LogoText>
      </Styled.LogoWrapper>
      <Styled.FormContainer>
        <Styled.FrontSide current={!!authStatus}>
          <AuthForm
            error={error}
            isLoading={isLoading}
            formTitle="Sign in"
            submit={onSubmitAuthFormHandler}
          />
        </Styled.FrontSide>
        <Styled.BackSide current={!!authStatus}>
          <CodeForm formTitle="Code" submit={onSubmitCodeFormHandler} codeMessage={error} />
          <Styled.HelperText>
            Please enter the secret code from email which we were sent to{' '}
            <Styled.EmailText>{email}</Styled.EmailText>
          </Styled.HelperText>
        </Styled.BackSide>
      </Styled.FormContainer>
    </Styled.AuthLayoutWrapper>
  );
};
