import { AxiosResponse } from 'axios';
import { ApiService } from './apiService';
import { ISendEmailsToUsersRequest, PushNotification } from '../interfaces';

export class NotificationService {
  static async sendEmailsToUsers(
    payload: ISendEmailsToUsersRequest,
  ): Promise<AxiosResponse<ISendEmailsToUsersRequest>> {
    return ApiService.api.post<ISendEmailsToUsersRequest>('/admin/sendMail', payload);
  }

  static async sendPushToUsers(
    notification: PushNotification,
  ): Promise<AxiosResponse<{ message: string }>> {
    return ApiService.api.post<{ message: string }>('/admin/sendNotifications', notification);
  }
}
