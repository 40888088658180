import { AddPlantResponse, SuccessResponse } from '../interfaces';
import { Action } from '../interfaces/action';
import { AddPlantResponseAPI } from '../interfaces/api/addPlantResponse';
import { addPlantResponseMapper } from '../mappers/addPlantResponseMapper';
import { ApiService } from './apiService';

export class PlantService {
  static async getActions(): Promise<Action[]> {
    return ApiService.api
      .get<SuccessResponse<Action[]>>(`/plant/action`)
      .then((response) => response.data.data);
  }

  static async addPlant(
    unitUuid: string,
    cropUuid: string,
    plantActionUuid: string,
    pod: number,
    active = true
  ): Promise<AddPlantResponse> {
    return ApiService.api
      .post<AddPlantResponseAPI>(`/plant`, {
        unitUuid,
        cropUuid,
        plantActionUuid,
        pod,
        active,
      })
      .then((response) => addPlantResponseMapper(response.data));
  }

  static async removePlant(
    plantUuid: string
  ): Promise<SuccessResponse<Record<string, never>>> {
    return ApiService.api
      .delete<SuccessResponse<Record<string, never>>>(`/plant/${plantUuid}`)
      .then((response) => response.data);
  }
}
