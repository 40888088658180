import { defaultTheme } from '@plant/ui';
import { StyledButton } from './styles';
import { Spinner } from '../Spiner/index';

export const Button = ({
  title,
  variant,
  onClick,
  width,
  height,
  marginTop,
  marginBottom,
  fullWidth,
  align,
  isLoading,
  position,
  top,
  right,
  bottom,
  left,
  disabled
}: {
  title: string,
  variant: 'primary' | 'secondary' | 'warning',
  onClick: () => void,
  width?: string,
  height?: string,
  marginTop?: string | 0,
  marginBottom?: string | 0,
  fullWidth?: boolean,
  align?: 'left' | 'right',
  isLoading?: boolean
  position?: 'relative' | 'absolute';
  top?: string
  right?: string
  bottom?: string
  left?: string 
  disabled?: boolean
}) => {
  return (
    <StyledButton
      disabled={isLoading || disabled}
      type='button'
      variant={variant}
      width={width}
      height={height}
      marginTop={marginTop}
      marginBottom={marginBottom}
      fullWidth={fullWidth}
      align={align}
      onClick={onClick}
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
    >
      {!isLoading ? title : <Spinner owncolor={defaultTheme.palette.secondary.main} width='25px' height='25px'/>}
    </StyledButton>
  )
}