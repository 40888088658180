import styled from "styled-components";
import ErrorIcon from '@mui/icons-material/Error';
import Popover from '@mui/material/Popover';

export const StyledErrorIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: -10px;
`

export const StyledIconButton = styled.button`
  cursor: auto;
  border: none;
  background: none;
`

export const StyledErrorIcon = styled(ErrorIcon)`
  display: block;
  margin: 0 auto;
  ${props => ({
    color: props.theme.palette.error.main
  })}
`

export const StyledPopOverText = styled.div<{
  fColor?: string
  bgColor?: string
  align?: 'center' | 'left' | 'right'
}>`
  padding: 5px 10px;
  overflow: hidden;
  font-size: 14px;
  overflow: hidden;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-weight: 500;
  display: flex;
  flex-direction: column;
  ${props => ({
    color: props.fColor,
    background: props.bgColor,
    alignItems: 
      props.align === 'center' ? 'center' :
      props.align === 'left' ? 'flex-start' :
      props.align === 'right' && 'flex-end',
  })}
  & > p {
    margin: 5px;
  }
`

export const StyledPopOver = styled(Popover)`
  pointer-events: none;
  & > .MuiPaper-root {
    border-radius: 25px;
    overflow: hidden;
  }
`