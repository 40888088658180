import styled from 'styled-components'
import { AutocompleteGetTagProps } from '@mui/base/AutocompleteUnstyled';



interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  disabled: boolean;
}

const StyledCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  position: relative;
  margin: 5px;
  cursor: pointer;
  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    ${props => ({
      background: props.theme.palette.secondary.main,
    })}
    transform: rotate(45deg);
  }
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    ${props => ({
      background: props.theme.palette.secondary.main,
    })}
    transform: rotate(135deg);
  }
`

export const StyledTagSpan = styled.span<{
  disabled: boolean
}>`
  ${props => props.disabled && ({
    textDecoration: 'line-through'
  })}
`

export const Tag = (props: TagProps) => {
  const { label, onDelete, disabled, ...other } = props;
  return (
    <div {...other}>
      <StyledTagSpan disabled={disabled}>{label}</StyledTagSpan>
      <StyledCloseIcon onClick={onDelete}/>
    </div>
  );
}

export const Root = styled.div`
  width: 100%;
  font-size: 14px;
  border-radius: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AutocompleteInputWrapper = styled.div`
  padding: 0 25px;
  border-radius: 50px;
  position: relative;
  z-index: 1;
  width: 100%;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
`

export const Label = styled.label`
  line-height: 1.5;
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0 0 10px;
  ${props => ({
    color: props.theme.palette.secondary.main,
  })}
`;

export const InputWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  width: 100%;
`

export const StyledInput = styled.input`
  background: none;
  height: 30px;
  box-sizing: border-box;
  padding: 4px 6px;
  min-width: 50px;
  border: none;
  margin: 0;
  outline: 0;
  font-size: 17px;
  font-weight: 600;
  ${props => ({
    color: props.theme.palette.secondary.main,
  })}
  &::placeholder {
    ${props => ({
      color: props.theme.palette.primary.dark,
    })}
    font-weight: 400;
  }
`

export const StyledTag = styled(Tag)<TagProps>`
  display: flex;
  max-width: 300px;
  align-items: center;
  height: 20px;
  margin: 4px;
  line-height: 22px;
  border-radius: 15px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  ${props => ({
    border: `1px solid ${props.theme.palette.primary.dark}`
  })}

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 15px;
    ${props => ({
      color: props.theme.palette.secondary.main,
    })}
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }

  svg.disabled {
    cursor: auto !important;
  }
`;

export const Listbox = styled.ul`
  width: 95%;
  max-height: 250px;
  margin: 2px auto 0;
  padding: 25px 20px;
  position: absolute;
  top: 110%;
  border-radius: 25px;
  overflow: auto;
  z-index: 0;
  display: flex;
  flex-direction: column;
  z-index: 3;
  ${props => ({
    background: props.theme.palette.light.main,
    boxShadow: `0px 0px 1px 1px ${props.theme.palette.secondary.light}`,
  })}
  &::-webkit-scrollbar {
    display: none;
  }

  & > li {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
 

  & svg {
    color: transparent;
  }
`

export const ListboxEmail = styled.span`
  font-weight: 600;
  font-size: 15px;
  display: block;
  padding: 5px 0;
  cursor: pointer;
  ${props => ({
    color: props.theme.palette.secondary.main,
  })}
`
export const ListboxName = styled.span`
  font-weight: 400;
  font-size: 15px;
  display: block;
  padding: 5px 0;
  cursor: pointer;
  ${props => ({
    color: props.theme.palette.secondary.main,
  })}
`