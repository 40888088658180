import styled from "styled-components"

export const StyledInputFieldWrapper = styled.div<{
  width?: string
  margin?: string
  inValid?: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  border-radius: 35px;
  opacity: ${({ disabled }) => disabled ? 0.7 : 1};
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  transition: all 0.3s;
  ${props => ({
    width: props.width,
    margin: props.margin,
  })}
  ${props => props.inValid && ({
    boxShadow: `inset 0 0 1px 2px ${props.theme.palette.error.main}`
  })}
`

export const StyledInputLabel = styled.span`
  font-weight: 500;
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
`

export const StyledInputField = styled.input`
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  border: none;
  outline: none;
  font-weight: 600;
  margin: 10px 0 5px 0;
  background: none;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-size: 16px;
`

