export interface IGetUsersDataRequest {
  query: string | null;
  order: 'DESC' | 'ASC' | null;
  orderField: 'first_name' | 'email' | string | null;
  page: number | null;
  count: number;
  userStatus?: boolean;
  unitStatus?: boolean;
  userStatusValue?: 'online' | 'offline' | null;
  unitStatusValue?: boolean;
}

export type IUsersDataSuccessFullApiResponse = {
  data: {
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
    isOnline?: boolean;
    usersPlaants: {
      uuid: string;
      name: string;
      status?: boolean;
    }[];
  }[];
};

export type IUsersDataSuccessFullResponse = {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  isOnline?: boolean;
  usersPlaants: {
    uuid: string;
    name: string;
    status?: boolean;
  }[];
}[];

export type IUserInfo = {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  alias: string;
  createdOn: string;
  updatedOn: string;
  photo: string | null;
  status: string;
  units: {
    connected: boolean;
    name: string;
    uuid: string;
  }[];
};

export interface ICurrentUserDataSuccessFullResponse extends IUserInfo {
  data: IUserInfo;
}

export type IUnitInfo = {
  uuid: string;
  active: boolean;
  name: string;
  unit_serial: null | string;
  pod_limit: number;
  created_on: string;
  updated_on: string;
  status: {
    led_intensity: number;
    fan: boolean;
    leak_sensor: boolean;
    water_level: number;
    water_level_snapshot: number;
    water_level_snapshot_date: string;
    temperature: number;
    humidity: number;
    connected: boolean;
    reset_factory: boolean;
    softwareUpdateAvailable: boolean;
    camera_image_last_date: string;
    camera_video_last_date: null | string;
    updated_on: string;
  };
  settings: {
    fan: {
      state: number;
      timestamp: string;
    }[];
    water_pump: [];
    air_pump: {
      state: number;
      timestamp: string;
    }[];
    ventile: [];
    timezone: string;
    schedule_updated_on: string;
    updated_on: string;
    environment: {
      uuid: string;
      active: boolean;
      standard: boolean;
      name: string;
      description: string;
      led_level: number;
      led_time: number;
      led_pwm: number;
      led_pwm_stage1: number;
      led_pwm_stage2: number;
      led_start_time: string;
      led_stop_time: string;
      fan_time: number;
      nutrition_level: number;
      nutrition_ml: number;
      vpa_level: number;
    };
    led: {
      state: number;
      timestamp: string;
      led_off: boolean;
      order: number;
      pwm: number;
    }[];
    led_unit: {
      state: number;
      timestamp: string;
      led_off: boolean;
      order: number;
      pwm: number;
    }[];
  };
  pods: {
    number: number;
    plant: {
      uuid: string;
      active: boolean;
      photo: null | string;
      video: null | string;
      plant_action: {
        uuid: string;
        name: string;
        description: string;
        plant_age: null | string | number;
      };
      crop: {
        uuid: string;
        active: boolean;
        name: string;
        alias: string;
        short_description: string;
        full_description: string;
        scientific_name: string;
        subspecies: null | string;
        other_names: null | string;
        type: string;
        photo: string;
        icon: string;
        video: string;
        harvest: {
          description: string;
          time: string;
          image: string;
        };
        replant: {
          description: string;
          time: null | string;
        };
        lifespan: {
          description: string;
          time: null | string;
        };
        nutrition: {
          uuid: string;
          active: boolean;
          name: string;
          description: string;
        }[];
        flavor: {
          uuid: string;
          active: boolean;
          name: string;
          description: string;
        }[];
      };
      created_on: string;
      plant_action_date: null | string;
    };
  }[];
  alerts: {
    uuid: string;
    type: number;
    description: string;
  }[];
  images: {
    file_name: string;
    createdOn: string;
    time_diff: string;
    time_diff_days: number;
    url: string;
  }[];
};

export interface ICurrentUnitDataSuccessFullResponse extends IUnitInfo {
  data?: IUnitInfo;
}
