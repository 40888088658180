import { FC } from "react"
import { PopUpLayout } from "../../../../layouts/PopUp"
import { IGetCropInterface } from "@plant/data"
import { Button } from "../../../Button";
import { Loader } from "../../../Loader";
import * as Styled from './style';

type PropsType = {
  open: boolean
  loading: boolean
  crops: IGetCropInterface[] | null
  currentCropId: string
  onClose: () => void
  onSubmit: (crop: IGetCropInterface) => void
}

export const ShowHideCropPopup: FC<PropsType> = ({
  open,
  loading,
  crops,
  currentCropId,
  onClose,
  onSubmit
}) => {
  const currentCrop = crops && crops.find(crop => crop.uuid === currentCropId);
  const active = currentCrop && currentCrop.active;

  const onSubmitHandler = (crop: IGetCropInterface) => () => {
    onSubmit(crop)
  }

  return(
    <PopUpLayout
      open={open}
      onClose={onClose}
    >
      <Styled.PopUpContainer>
        <Styled.PopupTitle>
          {`Are you sure that you want to ${active ? 'hide' : 'show'} this crop guide?`}
        </Styled.PopupTitle>
        <Styled.PopupButtonsContainer>
          <Button disabled={loading} width='80px' title='Yes' variant='primary' onClick={onSubmitHandler(currentCrop)}/>
          <Button disabled={loading} width='80px' title='No' variant='warning' onClick={onClose}/>
        </Styled.PopupButtonsContainer>
        {loading && <Loader position='absolute' bottom='-5px' bubbleSize='12px' width='100px'/>}
      </Styled.PopUpContainer>
    </PopUpLayout>
  )
}