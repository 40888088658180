import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import {
  ISendEmailsToUsersRequest,
  IGetUsersDataRequest,
  IUsersDataSuccessFullResponse,
} from '@plant/data';

export enum UsersTypes {
  GetUsersEmails = '[Notifications] GetAllUsers',
  GetUsersEmailsSuccess = '[Notifications] GetUsersEmailsSuccess',
  GetUsersEmailsFailed = '[Notifications] GetUsersEmailsFailed',

  SendEmailToUsers = '[Notifications] SendEmailToUsers',
  SendEmailToUsersSuccess = '[Notifications] SendEmailToUsersSuccess',
  SendEmailToUsersFailed = '[Notifications] SendEmailToUsersFailed',

  SetInputValue = '[Notifications] SetInputValue',
  SelectEmailTag = '[Notifications] SelectEmailTag',
  SetSubject = '[Notifications] SetSubject',
  SetEmailContent = '[Notifications] SetEmailContent',
  SelectAllEmails = '[Notifications] SelectAllEmails',

  ResetUsersEmails = '[Users] ResetUsersEmails',
}

export const getUsersEmailsAction = createAsyncAction(
  UsersTypes.GetUsersEmails,
  UsersTypes.GetUsersEmailsSuccess,
  UsersTypes.GetUsersEmailsFailed,
)<IGetUsersDataRequest, IUsersDataSuccessFullResponse, any>();

export const sendEmailToUsersAction = createAsyncAction(
  UsersTypes.SendEmailToUsers,
  UsersTypes.SendEmailToUsersSuccess,
  UsersTypes.SendEmailToUsersFailed,
)<ISendEmailsToUsersRequest, void, any>();

export const setInputValueAction = createAction(UsersTypes.SelectEmailTag)<string>();
export const selectEmailTagAction = createAction(UsersTypes.SetInputValue)<{
  email: string;
  uuid: string;
}>();
export const setSubjectAction = createAction(UsersTypes.SetSubject)<string>();
export const setEmailContentAction = createAction(UsersTypes.SetEmailContent)<string>();
export const selectAllEmailsAction = createAction(UsersTypes.SelectAllEmails)();

export const resetUsersEmailsAction = createAction(UsersTypes.ResetUsersEmails)();

export type UsersActionUnion = ActionType<
  | typeof getUsersEmailsAction
  | typeof sendEmailToUsersAction
  | typeof setInputValueAction
  | typeof selectEmailTagAction
  | typeof setSubjectAction
  | typeof setEmailContentAction
  | typeof selectAllEmailsAction
  | typeof resetUsersEmailsAction
>;
