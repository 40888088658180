import styled, { keyframes, css } from "styled-components";

const expand = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.7);
    opacity: 1;
  }
  100% {
    transform: scale(0.6);
    opacity: 0.5;
  }
`

export const LoaderWrapper = styled.div<{
  position?: 'absolute' | 'relative' | 'fixed';
}>`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff80;
  ${props => ({
    position: props.position
  })}
`