import { FC, PropsWithChildren } from 'react';
import { StyledModal, DeletePopupContentWrapper } from './style';

interface IProps extends PropsWithChildren {
  open: boolean
  onClose: () => void
}

export const PopUpLayout: FC<IProps> = ({
  children,
  open,
  onClose
}) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
    >
      <DeletePopupContentWrapper>
        {children}
      </DeletePopupContentWrapper>
    </StyledModal>
  )
}