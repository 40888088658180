import React from 'react';
import { Icon } from '@plant/data';

const PlantIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5042 11.3685L13.6367 3.85314C13.8685 3.03608 13.4285 2.17896 12.6294 1.89115L12.4801 1.8374C8.87131 0.537539 4.91805 0.561994 1.32557 1.9064C0.572024 2.18839 0.152353 2.99319 0.352471 3.77249L2.29566 11.3397C2.45758 11.9702 2.95901 12.4569 3.59409 12.6C5.7643 13.0888 8.03306 13.085 10.2033 12.5961C10.8273 12.4556 11.3296 11.9838 11.5042 11.3685ZM7 5.00011C9.76142 5.00011 12 4.32853 12 3.50011C12 2.67168 9.76142 2.00011 7 2.00011C4.23858 2.00011 2 2.67168 2 3.50011C2 4.32853 4.23858 5.00011 7 5.00011Z"
    />
  </svg>
);

export default React.memo(PlantIcon);
