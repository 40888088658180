import { createReducer } from 'typesafe-actions';
import { IUserData } from '@plant/data';
import * as AuthActions from '../actions/auth';

export interface State {
  data: IUserData | null;
  loading: boolean;
  error: string | null;
  authStatus: string | null;
  authEmail: string | null;
  isAuthenticated: boolean;
  tokens: { token: string; refreshToken?: string } | null;
}

export const initialState: State = {
  data: null,
  loading: false,
  error: null,
  authStatus: null,
  authEmail: null,
  isAuthenticated: false,
  tokens: null,
};

export const reducer = createReducer<State, AuthActions.AuthActionUnion>(initialState)
  .handleAction(AuthActions.loginAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(AuthActions.loginAction.success, (state, action) => ({
    ...state,
    error: null,
    loading: false,
    authStatus: action.payload.status,
    authEmail: action.payload.email
  }))
  .handleAction(AuthActions.loginAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.message,
    loading: false,
  }))

  .handleAction(AuthActions.enterCodeAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(AuthActions.enterCodeAction.success, (state, action) => ({
    ...state,
    error: null,
    loading: false,
    data: action.payload.data.data,
    isAuthenticated: true,
  }))
  .handleAction(AuthActions.enterCodeAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))

  .handleAction(AuthActions.setIsAuthenticatedAction, (state, action) => ({
    ...state,
    isAuthenticated: action.payload,
  }))

  .handleAction(AuthActions.setAuthDataAction, (state, action) => ({
    ...state,
    isAuthenticated: true,
    data: action.payload.data,
    tokens: { token: action.payload.token },
  }))

  .handleAction(AuthActions.resetAuthAction, () => initialState);
