import { styled } from '@mui/material';

export const Container = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isInvalid' &&
    prop !== 'withBorder' &&
    prop !== 'disabled' &&
    prop !== 'withoutShadow',
})<{
  withBorder: boolean;
  isInvalid: boolean;
  disabled: boolean;
  withoutShadow: boolean;
}>(({ withBorder, isInvalid, disabled, withoutShadow, theme }) => ({
  backgroundColor: theme.palette.light.main,
  borderRadius: 15,
  border: isInvalid
    ? '1px solid rgba(238, 118, 116, 0.3)'
    : withBorder
    ? '2px solid #D8D8D8'
    : '1px solid transparent',

  padding: '13px 27px 17px 20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 10,
  gap: 2,
  opacity: disabled ? 0.6 : 1,

  boxShadow:
    !withoutShadow && isInvalid
      ? `0px 5px 10px ${theme.palette.error.light}`
      : withoutShadow
      ? 'none'
      : '0px 9px 24px #E1EBE9',
}));

export const InnerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 2,
});

export const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Title = styled('p', {
  shouldForwardProp: (prop) => prop !== 'isInvalid',
})<{ isInvalid: boolean }>(({ theme, isInvalid }) => ({
  fontSize: 14,
  fontWeight: 500,
  margin: 0,
  color: isInvalid ? theme.palette.error.main : '#A7B3AE',
}));
