import { FC } from 'react';
import { DashBoardLayout } from '../../layouts/Dashboard';
import { useParams } from "react-router-dom";
import { CropEditor } from '../../components/CropEditor';

export const AddContentPage: FC = (): JSX.Element => {
  
  const { page, mode, id } = useParams();
  
  const editorProps = { mode, page, id };

  const editors = {
    crop: <CropEditor {...editorProps}/>,
  }

  return (
    <DashBoardLayout currentPage="content">
      {editors[page]}
    </DashBoardLayout>
  )
}