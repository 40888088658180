import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const softwareSelector = (state: AppState) => state.software;

export const softwareLoadingSelector = createSelector(softwareSelector, (state) => state.loading);
export const softwareErrorSelector = createSelector(softwareSelector, (state) => state.error);
export const softwareVersionsSelector = createSelector(softwareSelector, (state) => state.versions);
export const softwareKeysSelector = createSelector(softwareSelector, (state) => state.keys);
export const unitSerialsSelector = createSelector(softwareSelector, (state) => state.unitSerials);
export const softwareSelectedVersionSelector = createSelector(
  softwareSelector,
  (state) => state.selectedVersion,
);
