import React, { useState } from 'react';
import * as Styled from './styles';
import showIcon from '../../../assets/icons/showPassword.png';
import hideIcon from '../../../assets/icons/hidePassword.png';
import { TitledField } from '../TitledField';

type InputVariants = 'text' | 'password';
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  handleFocus?: () => void;
  isInvalid?: boolean;
  disabled?: boolean;
  disabledInput?: boolean;
  type?: InputVariants;
  RightElement?: React.ReactNode;
  withoutShadow?: boolean;
  withBorder?: boolean;
}

const KitInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      title,
      handleFocus,
      isInvalid = false,
      disabled = false,
      disabledInput = false,
      withBorder = false,
      withoutShadow = false,
      type = 'text',
      RightElement,
      ...inputProps
    },
    ref
  ) => {
    const [inputType, setInputType] = useState<InputVariants>(type);

    const changeInputType = () => {
      if (inputType === 'password') {
        setInputType('text');
      } else {
        setInputType('password');
      }
    };

    const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      handleFocus && handleFocus();
    };

    const rightIcon = () => {
      if (RightElement) {
        return RightElement;
      }

      if (type !== 'password') {
        return <></>;
      }

      return (
        <Styled.ShowHidePassButton onClick={changeInputType}>
          <img
            src={inputType === 'password' ? showIcon : hideIcon}
            alt="show"
          />
        </Styled.ShowHidePassButton>
      );
    };

    return (
      <TitledField
        disabled={disabled}
        title={title}
        isInvalid={isInvalid}
        rightIcon={rightIcon()}
        withBorder={withBorder}
        withoutShadow={withoutShadow}
      >
        <Styled.InputContainer>
          <Styled.Input
            ref={ref}
            type={inputType}
            onFocus={handleInputFocus}
            disabled={disabled || disabledInput}
            {...inputProps}
          />
        </Styled.InputContainer>
      </TitledField>
    );
  }
);

export default React.memo(KitInput);
