import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, FC } from "react";
import * as Styled from './style';

type PropsType = {
  setSearchText: (val: string) => void,
  value: string,
}

export const SearchInput: FC<PropsType> = ({
  setSearchText,
  value,
}) => {

  const [ isOpened, setIsOpened ] = useState<boolean>(false);
  const [ clicked, setClicked ] = useState<boolean>(false);

  const onButtonClickHandler = () => {
    if (!clicked) {
      setClicked(true);
    }
    setSearchText('');
    setIsOpened(!isOpened);
  }

  const onInputChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value)
  }

  return (
    <Styled.SearchInputContainer
      isInputOpen={isOpened}
      clicked={clicked}
    >
      <Styled.SearchInputButton
        onClick={onButtonClickHandler}
      >
        <FontAwesomeIcon icon={faSearch}/>
      </Styled.SearchInputButton>
      <Styled.SearchInput
        onChange={onInputChangeHandler}
        placeholder='search'
        value={value}
      />
    </Styled.SearchInputContainer>
  )
}