import { Alert } from './alert';
import { Pod } from './pod';
import { Image } from './image';
import { Settings } from './settings';

export interface Unit {
  uuid: string;
  active: boolean;
  name: string;
  unitSerial: string;
  podLimit: string;
  createdOn: string;
  updatedOn: string;
  status: {
    ledIntensity: number;
    fan: boolean;
    leakSensor: boolean;
    waterLevel: number;
    temperature: number;
    humidity: number;
    connected: boolean;
    resetFactory: boolean;
    cameraImageLastDate: string;
    cameraVideoLastDate: string;
  };
  settings: Settings;
  alerts: Alert[];
  images: Image[];
  pods: Pod[];
}
