import styled from "styled-components";

export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`

export const PopupButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  width: 230px;
`

export const StyledInputFieldWrapper = styled.div<{
  width?: string
}>`
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  border-radius: 35px;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  ${props => ({
    width: props.width,
  })}
`

export const StyledInputLabel = styled.span`
  font-weight: 500;
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
`

export const ShadowWrapper = styled.div`
  width: 100%;
  padding: 5px 15px;
  border-radius: 15px;
  margin-top: 15px;
  ${props => ({
    boxShadow: `0 0 5px 1px ${props.theme.palette.gray.dark}`,
  })}
`

export const StyledInputField = styled.input`
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  border: none;
  outline: none;
  font-weight: 600;
  margin: 10px 0 5px 0;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-size: 16px;
`

export const StyledTextAreaWrapper = styled.div<{
  width?: string
  height?: string
  margin?: string
  inValid?: boolean
}>`
  display: flex; 
  flex-direction: column;
  padding: 5px 25px;
  border-radius: 35px;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  transition: all 0.3s;
  ${props => ({
    width: props.width,
    height: props.height,
    margin: props.margin,
  })}
  ${props => props.inValid && ({
    boxShadow: `inset 0 0 1px 2px ${props.theme.palette.error.main}`,
  })}
`

export const StyledTextAreaLabel = styled.span`
  font-weight: 500;
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
`

export const StyledTextAreaField = styled.textarea`
  resize: none;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  border: none;
  outline: none;
  font-weight: 600;
  margin: 10px 0 5px 0;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-size: 16px;
  height: 100%;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
      ${props => ({
    background: props.theme.palette.primary.light
  })};
    border-radius: 20px;
  }

   &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${props => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`
    })}
  }
`

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 700;
  position: absolute;
  bottom: -10px;
`