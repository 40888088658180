import { ActionType, createAsyncAction } from 'typesafe-actions';
import { SelectedVersion, Version, UnitSerials } from '@plant/data';

export enum SoftwareTypes {
  GetVersionsList = '[SOFTWARE] GetVersionsList',
  GetVersionsListSuccess = '[SOFTWARE] GetVersionsListSuccess',
  GetVersionsListFailed = '[SOFTWARE] GetVersionsListFailed',

  AddVersion = '[SOFTWARE] AddVersion',
  AddVersionSuccess = '[SOFTWARE] AddVersionSuccess',
  AddVersionFailed = '[SOFTWARE] AddVersionFailed',

  GetVersionDetails = '[SOFTWARE] GetVersionDetails',
  GetVersionDetailsSuccess = '[SOFTWARE] GetVersionDetailsSuccess',
  GetVersionDetailsFailed = '[SOFTWARE] GetVersionDetailsFailed',

  GetUnitSerials = '[SOFTWARE] GetUnitSerials',
  GetUnitSerialsSuccess = '[SOFTWARE] GetUnitSerialsSuccess',
  GetUnitSerialsFailed = '[SOFTWARE] GetUnitSerialsFailed',

  DeleteVersion = '[SOFTWARE] DeleteVersion',
  DeleteVersionSuccess = '[SOFTWARE] DeleteVersionSuccess',
  DeleteVersionFailed = '[SOFTWARE] DeleteVersionFailed',

  UpdateVersionDetails = '[SOFTWARE] UpdateVersionDetails',
  UpdateVersionDetailsSuccess = '[SOFTWARE] UpdateVersionDetailsSuccess',
  UpdateVersionDetailsFailed = '[SOFTWARE] UpdateVersionDetailsFailed',
}

export const getVersionsList = createAsyncAction(
  SoftwareTypes.GetVersionsList,
  SoftwareTypes.GetVersionsListSuccess,
  SoftwareTypes.GetVersionsListFailed,
)<void, { data: Version[] }, string>();

export const addVersion = createAsyncAction(
  SoftwareTypes.AddVersion,
  SoftwareTypes.AddVersionSuccess,
  SoftwareTypes.AddVersionFailed,
)<{formData: FormData, successCallback: () => void}, Version, string>();

export const getVersionDetails = createAsyncAction(
  SoftwareTypes.GetVersionDetails,
  SoftwareTypes.GetVersionDetailsSuccess,
  SoftwareTypes.GetVersionDetailsFailed,
)<string, SelectedVersion, string>();

export const getUnitSerials = createAsyncAction(
  SoftwareTypes.GetUnitSerials,
  SoftwareTypes.GetUnitSerialsSuccess,
  SoftwareTypes.GetUnitSerialsFailed,
)<void, UnitSerials[], string>();

export const deleteVersion = createAsyncAction(
  SoftwareTypes.DeleteVersion,
  SoftwareTypes.DeleteVersionSuccess,
  SoftwareTypes.DeleteVersionFailed,
)<{id: string, callback: () => void}, void, string>();

export const updateVersionDetails = createAsyncAction(
  SoftwareTypes.UpdateVersionDetails,
  SoftwareTypes.UpdateVersionDetailsSuccess,
  SoftwareTypes.UpdateVersionDetailsFailed,
)<{ updatedVersion: SelectedVersion, callback: () => void }, SelectedVersion, string>();

export type SoftwareActionUnion = ActionType<
  | typeof getVersionsList
  | typeof addVersion
  | typeof getUnitSerials
  | typeof getVersionDetails
  | typeof deleteVersion
  | typeof updateVersionDetails
>;
