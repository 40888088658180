import { Crop } from './crop';

export interface Pod {
  number: number;
  plant: {
    uuid: string;
    active: boolean;
    photo: string;
    video: string;
    plantActionDate: string;
    plantAction: {
      uuid: string;
      name: string;
      description: string;
      plantAge: number;
    };
    crop: Crop;
  };
}
