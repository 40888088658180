import React from 'react';
import { Icon } from '@plant/data';

const FullyGrowReplantIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0159 0.0342711C11.2623 2.82768 9.62151 4.3792 7.09473 4.64511C7.67102 2.91622 8.64625 1.67448 10.0654 0.965126C8.64681 1.27543 7.40506 2.20637 6.42979 3.80281C5.8535 1.05381 7.75969 -0.231788 12.0165 0.0342711H12.0159Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.80845 6.72925H6.38474C6.60639 5.00036 7.13835 3.3596 8.20288 2.11841C7.27196 2.82769 6.69564 3.71434 6.1631 4.73444C5.49815 4.33547 4.87753 3.9365 4.12331 3.71486C4.87692 4.15816 5.40889 4.77878 5.89704 5.48858C5.85271 5.88756 5.80838 6.28652 5.80838 6.72983L5.80845 6.72925Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.000560445 3.49267C1.59649 5.13288 3.28153 5.57673 5.09911 4.8669C4.21251 3.84732 3.14801 3.35966 1.9511 3.35966C2.97068 3.09368 4.16811 3.31532 5.5424 4.06894C4.87745 1.71896 3.01505 1.54159 0 3.49264L0.000560445 3.49267Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.15373 6.64062H11.3512C11.6615 6.64062 11.9275 6.9066 11.9275 7.17259V7.79321C11.9275 8.10351 11.6616 8.32517 11.3512 8.32517H10.9966L10.376 13.2463C10.2873 13.6453 10.0214 14 9.62238 14H2.97175C2.52845 14 2.26247 13.6453 2.21814 13.2463L1.50886 8.32517H1.15423C0.843927 8.32517 0.577938 8.10353 0.577938 7.79321V7.17259C0.577938 6.90661 0.843913 6.64062 1.15423 6.64062H1.15373Z"
    />
  </svg>
);

export default React.memo(FullyGrowReplantIcon);
