import { FC, MouseEvent, ChangeEvent } from 'react';
import * as S from './style';
import { Loader } from '../../../Loader';
import { Button } from '../../../Button';
import { PopUpLayout } from '../../../../layouts/PopUp';
import { IGetAboutType } from '@plant/data';

interface EditPopupProps {
  currentModel: 'add' | 'delete' | 'edit' | 'deploy' | null;
  aboutForm: IGetAboutType;
  formError: string;
  loading: boolean;
  onSaveButtonClick: () => void;
  addObjectToAboutForm: () => void;
  setAboutSubject: (text: string) => void;
  setAboutContent: ({ value, id, index }: { value: string, id: string, index: number }) => void
  deleteObjectToAboutForm: (index: number) => void;
  onClosePopup: () => void;
}

export const EditPopup: FC<EditPopupProps> = ({ 
  currentModel, 
  aboutForm, 
  loading,
  formError,
  onSaveButtonClick,
  addObjectToAboutForm, 
  onClosePopup, 
  deleteObjectToAboutForm,
  setAboutSubject,
  setAboutContent,
}) => {
  
  const deleteObjectToAboutFormHandle = (e: MouseEvent<HTMLButtonElement>) => {
    deleteObjectToAboutForm(Number(e.currentTarget.id));
  };

  const setAboutSubjectHandle = (e: ChangeEvent<HTMLInputElement>) => {
    setAboutSubject(e.currentTarget.value);
  }

  const setAboutContentHandle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, id } = e.currentTarget;
    const index = Number(e.currentTarget.getAttribute('data-index'));
    setAboutContent({ value, id, index })
  }

  return (
    <PopUpLayout open={currentModel === 'edit' || currentModel === 'add'} onClose={onClosePopup}>
      <S.PopUpContainer>
        <S.InputWrapper>
          <S.StyledInputFieldWrapper width="100%">
            <S.StyledInputLabel>About subject</S.StyledInputLabel>
            <S.StyledInputField id="subject" onChange={setAboutSubjectHandle} value={aboutForm.subject} />
          </S.StyledInputFieldWrapper>
        </S.InputWrapper>
        <S.ContentList>
          {aboutForm.content.map((el, index) => (
            <S.StyledContentFieldsWrapper key={index}>
              <S.StyledInputFieldWrapper width="100%">
                <S.StyledInputLabel>About subject</S.StyledInputLabel>
                <S.StyledInputField data-index={index} id="title" onChange={setAboutContentHandle} value={el.title} />
              </S.StyledInputFieldWrapper>
              <S.StyledTextAreaWrapper width="450px" height="200px">
                <S.StyledTextAreaLabel>About description</S.StyledTextAreaLabel>
                <S.StyledTextAreaField data-index={index} id="text" onChange={setAboutContentHandle} value={el.text} />
              </S.StyledTextAreaWrapper>
              {aboutForm.content.length > 1 &&
              <S.DeleteButton data-index={index} id={`${index}`} onClick={deleteObjectToAboutFormHandle}>
                -
              </S.DeleteButton>}
            </S.StyledContentFieldsWrapper>
          ))}
          <Button title="+" width="45px" variant="primary" onClick={addObjectToAboutForm} />
        </S.ContentList>
        <S.PopupButtonsContainer>
          <Button title="Send" width="80px" variant="primary" onClick={onSaveButtonClick} />
          <Button title="Cancel" width="80px" variant="warning" onClick={onClosePopup} />
        </S.PopupButtonsContainer>
        {formError && <S.ErrorMessage>{formError}</S.ErrorMessage>}
        {loading && <Loader position="absolute" bottom="70px" bubbleSize="20px" width="100px" />}
      </S.PopUpContainer>
    </PopUpLayout>
  );
};
