import React from 'react';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { defaultTheme } from '@plant/ui';
import {
  Root,
  InputWrapper,
  StyledTag,
  StyledInput,
  Listbox,
  AutocompleteInputWrapper,
  ListboxEmail,
  ListboxName,
  Label,
} from './style';
import { Spinner } from '../Spiner';

interface AutocompleteMultipleInputProps {
  preselected: { email: string; uuid: string }[];
  data?: Array<{ email: string; firstName: string; lastName: string; uuid: string }>;
  placeholder: string;
  loading: boolean;
  onAutocompleteInputBlur?: () => void;
  selectedAllEmails?: boolean;
  disabled: boolean;
  onInputChangeHandler: (val: string) => void;
  inputValue: string;
  onEmailTagClickHandler: (value: { email: string; uuid: string }) => void;
  title?: string;
}

export const AutocompleteInput: React.FC<AutocompleteMultipleInputProps> = ({
  preselected,
  data,
  placeholder,
  loading,
  selectedAllEmails,
  disabled,
  onInputChangeHandler,
  inputValue,
  onEmailTagClickHandler,
  title,
}) => {

  const { getRootProps, getInputProps, getTagProps, getListboxProps, setAnchorEl } =
    useAutocomplete({
      id: 'customized-hook-demo',
      defaultValue: [],
      multiple: true,
      options: data,
      getOptionLabel: (option: any) => option.email,
    });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    onInputChangeHandler(value);
  };

  const onClickEmailTag = (value: { email: string; uuid: string }) => () => {
    onEmailTagClickHandler(value);
  };

  return (
    <Root>
      <AutocompleteInputWrapper {...getRootProps()}>
        <Label>{title}</Label>
        <InputWrapper ref={setAnchorEl}>
          {!!preselected &&
            preselected.map((option, index) => {
              return (
                <StyledTag
                  key={option}
                  label={option.email}
                  disabled={disabled}
                  {...getTagProps({ index })}
                  onDelete={!disabled ? onClickEmailTag(option) : null}
                />
              );
            })}
          <StyledInput
            placeholder={placeholder}
            {...getInputProps()}
            value={inputValue}
            disabled={disabled}
            onChange={onInputChange}
          />
          {loading && (
            <Spinner
              owncolor={defaultTheme.palette.secondary.dark}
              width="25px"
              height="25px"
              bottom="5px"
              right="35px"
            />
          )}
        </InputWrapper>
      </AutocompleteInputWrapper>
      {!selectedAllEmails && data.length > 0 && inputValue.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(data as typeof data).map((option, index) => {
            return (
              <li key={index} onClick={onClickEmailTag(option)}>
                <ListboxEmail>{option.email}</ListboxEmail>
                <ListboxName>
                  {option.firstName} {option.lastName}
                </ListboxName>
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
};
