import * as Styled from './style';
import { Loader } from '../../../Loader';
import { Button } from '../../../Button';
import { PopUpLayout } from '../../../../layouts/PopUp';
import { IGetAboutType } from '@plant/data';
import { FC } from 'react';

type PropsType = {
  currentModel: 'add' | 'delete' | 'edit' | 'deploy' | null
  currentFaq: IGetAboutType,
  onSubmit: (payload: {id: string, faq: IGetAboutType}) => void
  onClosePopup: () => void
  uploading: boolean
}

export const DeployPopup: FC<PropsType> = ({
  currentModel,
  currentFaq,
  onSubmit,
  onClosePopup,
  uploading
}) => {
  
  const onSubmitButtonClickHandle = () => {
    onSubmit({id: currentFaq.uuid, faq: currentFaq })
  }

  return (
    <PopUpLayout
      open={currentModel === 'deploy'}
      onClose={onClosePopup}
    >
      {currentFaq &&
      <>
        <Styled.PopUpContainer>
          <Styled.PopupTitle>
            {currentFaq.active ? 'Are you sure to show this "about" article' : 'Are you sure to hide this "about" article'}
          </Styled.PopupTitle>
          <Styled.PopupButtonsContainer>
            <Button title='Send' width='80px' variant='primary' onClick={onSubmitButtonClickHandle}/>
            <Button title='Cancel' width='80px' variant='warning' onClick={onClosePopup}/>
          </Styled.PopupButtonsContainer>
          {uploading && <Loader position='absolute' bottom='70px' bubbleSize='20px' width='100px'/>}
        </Styled.PopUpContainer>
      </>}
    </PopUpLayout>
  )
}