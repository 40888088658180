import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled, { css, keyframes  } from "styled-components";
import Checkbox from '@mui/material/Checkbox';

const openAnimation = keyframes`
 0% { width: 35px; }
 100% {width: 250px; }
`

const closeAnimation = keyframes`
 0% { width: 250px; }
 100% {width: 35px; }
`

export const StyledHeader = styled.h1`
  font-size: 24px;
  font-weight: 700;
${props => ({
  color: props.theme.palette.secondary.dark,
})};
`
export const TopContainer = styled.div`
  display: flex;
  width: 95%;
  margin: 30px auto 20px;
  max-width: 1400px;
  position: relative;
`
export const SearchInputContainer = styled.div<{
  isInputOpen: boolean;
  clicked: boolean;
}>`
  display: flex;
  height: 35px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 0 0 20px;
  animation-duration: 0.3s;
  ${props => css`
    width: ${props.isInputOpen ? '250px' : '35px'};
    animation-name: ${props.clicked ? props.isInputOpen ? openAnimation : closeAnimation : 'none'};
    animation-duration: 0.3s;
  `}
`

export const SearchInputButton = styled.button`
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 50%;
  position: absolute;
    ${props => ({
    background: props.theme.palette.primary.light
  })};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const SearchInput = styled.input`
  display: block;
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-weight: 700;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-size: 16px;
  position: relative;
  left: 35px;
  &::placeholder {
    font-weight: 500;
  }
`

export const Table = styled.div`
  padding: 20px;
  ${props => ({
    background: props.theme.palette.admin.main
  })}  
  height: calc(100% - 130px);
  width: 95%;
  margin: 0 auto;
  border-radius: 20px;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
`

export const TableHead = styled.div`
  display: flex;
  padding: 0 16px;
  width: calc(100% - 30px);
`

export const TableHeadTitle = styled.p<{
  width: string;
  fontWeight?: number
}>`
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
  ${props => ({
    width: props.width,
  })}
`

export const SortFieldButton = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15px;
  margin-left: 15px;
  width: 10px;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  & > svg {
    position: absolute;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 !important;
    ${props => ({
    color: `${props.theme.palette.secondary.main} !important`
  })};
`

export const TableRowColumn = styled.div<{
  width?: string;
  padding?: string;
  fontWeight?: number;
  flexDirection?: 'column' | 'row'
}>`
  position: relative;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
  font-weight: 700;
  ${props => ({
    color: props.theme.palette.secondary.main
  })};
  overflow: hidden;
  margin-right: 5px;
  display: flex;
  ${props => ({
    padding: props.padding,
    fontWeight: props.fontWeight,
    flexDirection: props.flexDirection
  })}
`

export const StyledText = styled.span<{
  color?: string
  margin?: string
}>`
  ${props => ({
    color: props.color,
    margin: props.margin,
  })}
`

export const TableBody = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  margin-top: 10px;
  border-radius: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const TableRow = styled.div`
  display: flex;
`

export const AccordionTR = styled(Accordion)`
  margin: 12px 0 !important;
  padding: 0 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  overflow: hidden !important;
`

export const AccordionSummaryTR = styled(AccordionSummary)<{
  grid?: string
  col?: string
}>`
  width: 100%;
  & > .MuiAccordionSummary-content {
    ${props => props.grid && ({
      display: 'grid !important',
      gridTemplateColumns: `${props.col} !important`,
    })}
  }
`

export const AccordionDetailsTR = styled(AccordionDetails)<{
  grid?: string
  col?: string
}>`
  width: calc(100% - 30px);
  ${props => props.grid && ({
    display: 'grid !important',
    gridTemplateColumns: `${props.col} !important`,
  })}
`

export const UnitContainer = styled.div`
  position: relative;
  margin: 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const UnitInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`

export const UnitInfoText = styled.p<{
  fontWeight?: number
}>`
  ${props => ({
    fontWeight: props.fontWeight,
  })}
`

export const UnitInfoTextName = styled.span`
  position: relative;
`

export const Indicator = styled.span<{
  online?: boolean,
  top?: string,
  left?: string,
  position?: 'absolute' | 'relative',
  margin?: string,
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  ${props => ({
    top: props.top,
    left: props.left,
    position: props.position,
    background: props.online ? props.theme.palette.secondary.main : props.theme.palette.error.main,
    margin: props.margin,
  })}
`

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin: 0 !important;
  padding: 0 !important;
`

export const OpenCloseButton = styled.button<{
  disabled: boolean
}>`
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => ({
    background: props.disabled ? props.theme.palette.admin.main : props.theme.palette.primary.main,
  })}
`