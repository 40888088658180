import { OrderVariantsType, StatusButtonsType, TableHeadButtonsType } from "../store/types/users"

export enum OrderVariants {
  Asc = 'ASC',
  Desc = 'DESC'
}

export const ORDER_VARIANTS: OrderVariantsType = {
  none: null,
  asc: OrderVariants.Asc,
  desc: OrderVariants.Desc,
}


export const INITIAL_TABLE_HEAD_BUTTONS: TableHeadButtonsType = [
  {
    id: 0,
    title: '',
    width: "5%",
    currentOrderVariant: 0,
  },
  {
    id: 1,
    title: 'User name',
    width: "35%",
    orderVariants: [ORDER_VARIANTS.none, ORDER_VARIANTS.asc, ORDER_VARIANTS.desc],
    currentOrderVariant: 0,
    searchField: 'first_name'
  },
  {
    id: 2,
    title: 'Email',
    width: "30%",
    orderVariants: [ORDER_VARIANTS.none, ORDER_VARIANTS.asc, ORDER_VARIANTS.desc],
    currentOrderVariant: 0,
    searchField: 'email'
  },
  {
    id: 3,
    title: 'Unit',
    width: "30%",
    currentOrderVariant: 0,
  },
]

export enum StatusButtonsStatuses {
  Offline = 'offline',
  Online = 'online'
}

export const STATUS_BUTTONS: StatusButtonsType = {
  userStatus: {
    active: false,
    status: null,
    value: null
  },
  unitStatus: {
    active: false,
    status: null,
    value: null
  }
}
