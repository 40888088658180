import { About, IGetFaqApiResponseType, IGetFaqType, SuccessResponse, Unit } from '../interfaces';
import { faqMapper } from '../mappers/faqMapper';
import { ApiService } from './apiService';

export class ContentService {
  static async getFAQ(page: number, count: number): Promise<IGetFaqType[]> {
    return ApiService.api
      .get<SuccessResponse<IGetFaqApiResponseType[]>>(`/content/faq?page=${page}&count=${count}`)
      .then((response) => faqMapper(response.data.data));
  }

  static async getAbout(page: number, count: number): Promise<About[]> {
    return ApiService.api
      .get<SuccessResponse<About[]>>(`/content/about?page=${page}&count=${count}`)
      .then((response) => response.data.data);
  }
}
