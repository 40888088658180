import React from 'react';
import { Icon } from '@plant/data';

const ReplantIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.2424 10.9752C21.2567 10.9644 24.75 8.56143 24.75 5.47739L24.75 5.46235C24.7371 2.37191 21.2179 -0.0117813 18.1995 4.35177e-05L11.8113 0.0247502C11.7748 0.0247502 11.7404 0.0397889 11.7157 0.0644957C11.6899 0.0902768 11.6759 0.124652 11.6759 0.160102L11.6759 0.161176L11.6931 4.39781L10.5577 4.40211L10.5566 4.40211L10.5555 4.40211L4.18875 4.49557L4.17908 2.12048C4.17478 1.1161 3.24774 0.3029 2.11122 0.30719L0.134665 0.315784C0.060545 0.317932 0.000387175 0.378088 0.000387172 0.453284L0.000387172 0.454358L0.0390582 10.3747C0.0390582 10.4112 0.0540958 10.4456 0.0788036 10.4703C0.104585 10.4961 0.138959 10.51 0.175483 10.51L2.15204 10.5014C2.69882 10.4993 3.21337 10.3102 3.60008 9.96863C3.9911 9.62381 4.20485 9.16618 4.20485 8.67957L4.20485 8.67312L4.19519 6.32273L10.7124 6.4409C10.7242 6.4409 10.735 6.43768 10.7457 6.43445L11.705 6.43016C11.7028 6.43982 11.6996 6.44842 11.6996 6.45808L11.6996 6.45916L11.7168 10.8646C11.7168 10.9012 11.7318 10.9355 11.7565 10.9603C11.7823 10.986 11.8167 11 11.8532 11L18.2424 10.9752ZM1.39239 1.97335L1.69426 1.97228C2.03157 1.9712 2.34738 2.084 2.58479 2.29023C2.81789 2.49219 2.94572 2.76073 2.9468 3.04433L2.96506 7.76002L2.96506 7.76432C2.96506 8.02106 2.85871 8.26919 2.66536 8.46364C2.42796 8.70212 2.08421 8.83962 1.72005 8.84069L1.41819 8.84284L1.39239 1.97335Z" />
  </svg>
);

export default React.memo(ReplantIcon);
