import styled from "styled-components";

export const StatusButton = styled.button<{
  position?: 'absolute' | 'relative';
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  active?: boolean;
}>`
  padding: 15px 25px;
  border: none;
  font-weight: 500;
  border-radius: 25px;
  font-size: 14px;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  ${props => ({
    background: props.theme.palette.light.main
  })}  width: 185px;
  ${props => props.active && ({
    color: props.theme.palette.light.main,
    background: props.theme.palette.secondary.main,
  })}
  ${props => props.position && ({
    position: props.position,
    left: props.left,
    right: props.right,
    bottom: props.bottom,
    top: props.top,
  })}
`

export const StatusButtonText = styled.span<{
  active?: boolean
  isOnline?: boolean
  isOffline?: boolean
  margin?: string
}>`
  font-weight: 500;
  ${props => props.active && ({
    fontWeight: props.active && 600,

  })}
  ${props => props.isOffline && ({
    color:  props.theme.palette.error.main,
  })}
  ${props => props.isOnline && ({
    color: props.theme.palette.primary.main,
  })}
  ${props => props.margin && ({
    margin: props.margin,
  })}
`