import styled, { css, keyframes  } from "styled-components";

const openAnimation = keyframes`
 0% { width: 35px; }
 100% {width: 250px; }
`

const closeAnimation = keyframes`
 0% { width: 250px; }
 100% {width: 35px; }
`

export const SearchInputContainer = styled.div<{
  isInputOpen: boolean;
  clicked: boolean;
}>`
  display: flex;
  height: 35px;
  border-radius: 20px;
  overflow: hidden;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  margin: 0 0 0 20px;
  ${props => css`
    width: ${props.isInputOpen ? '250px' : '35px'};
    animation-name: ${props.clicked ? props.isInputOpen ? openAnimation : closeAnimation : 'none'};
    animation-duration: 0.3s;
  `}
`

export const SearchInputButton = styled.button`
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const SearchInput = styled.input`
  display: block;
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-weight: 700;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-size: 16px;
  position: relative;
  left: 35px;
  &::placeholder {
    font-weight: 500;
  }
`