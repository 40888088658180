import { fork } from 'redux-saga/effects';
import { authSaga } from './auth';
import { startAppSaga } from './app';
import { usersSaga } from './users';
import { notificationsSaga } from './notifications';
import { contentSaga } from './content';
import { pushNotificationsSaga } from './pushNotifications';
import { softwareSaga } from './software';

export function* sagas(): Generator {
  yield fork(authSaga);
  yield fork(startAppSaga);
  yield fork(usersSaga);
  yield fork(notificationsSaga);
  yield fork(contentSaga);
  yield fork(pushNotificationsSaga);
  yield fork(softwareSaga);
}
