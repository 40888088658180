import { FC, useEffect, useState } from "react";
import { saveAs } from 'file-saver';
import { dateFormatter } from "../../../../helpers/dateFormatter";
import * as Styled from './style';
import { ErrorAlert } from "../../../../components/ErrorAlert";

type PropsType = {
  url: string
  currentImgDate: string
}

export const SmartPicture: FC<PropsType> = ({
  url,
  currentImgDate
}) => {
  const [ error, setError ] = useState(false);
  const [ showDownloadButton, setShowDownloadButton ] = useState(false);

  useEffect(() => {
    fetch(url).then(({ status }: { status: number }) => setError(!(status === 200)))
  }, [])

  const onMouseEnterWrapper = () => {
    setShowDownloadButton(true);
  }

  const onMouseLeaveWrapper = () => {
    setShowDownloadButton(false);
  }

  const onDownloadButtonClick = () => {
    saveAs(url, 'img.jpg')
  }

  return (
    <Styled.SmartPictureWrapper
      onMouseEnter={onMouseEnterWrapper}
      onMouseLeave={onMouseLeaveWrapper}
    >
      {
        !error ?
        <>
          <Styled.StyledPicture src={url}/>
          <Styled.StyledPictureDate>
            <Styled.StyledPictureDateTitle>
              The last photo in:
            </Styled.StyledPictureDateTitle>
            {dateFormatter(currentImgDate)}
          </Styled.StyledPictureDate>
        </> :
        <ErrorAlert top="30px" text="Problems with loading photo" />
      }
      {showDownloadButton && !error && <Styled.DownloadButton onClick={onDownloadButtonClick}><Styled.StyledDownloadForOfflineIcon /></Styled.DownloadButton> }
    </Styled.SmartPictureWrapper>
  )
}