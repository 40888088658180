import { Alert } from './alert';
import { Image } from './image';

export interface UnitStore {
  uuid: string;
  active: boolean;
  name: string;
  unitSerial: string;
  podLimit: string;
  createdOn: string;
  updatedOn: string;
  status: {
    ledIntensity: number;
    fan: boolean;
    leakSensor: boolean;
    waterLevel: string;
    temperature: string;
    humidity: string;
    connected: boolean;
    resetFactory: boolean;
    cameraImageLastDate: string;
    cameraVideoLastDate: string;
  };
  settings: {
    timezone: string;
    led: {
      state: number;
      timestamp: string;
      pwm: number;
      ledOff: boolean;
    }[];
    fan: {
      state: number;
      timestamp: string;
    }[];
    waterPump: {
      state: number;
      timestamp: string;
    }[];
    airPump: {
      state: number;
      timestamp: string;
    }[];
    ventile: {
      state: number;
      timestamp: string;
    }[];
    environment: {
      uuid: string;
      active: boolean;
      name: string;
      ledLevel: number;
      nutritionLevel: number;
      vpaLevel: number;
    };
  };
  alerts: Alert[];
  images: Image[];
  pods: string[];
}
