import { LOCAL_STORAGE_KEYS } from '../constants';
import { SuccessResponse } from '../interfaces/serverResponse';
import { UserData } from '../interfaces/userData';
import { ApiService } from './apiService';

export class AuthService {
  static async login(email: string, password: string): Promise<UserData> {
    return ApiService.api
      .post<SuccessResponse<UserData>>('/auth/signin', {
        email,
        password,
      })
      .then((response) => response.data.data);
  }

  static async signUp(
    email: string,
    password: string,
    first_name: string,
    last_name: string,
  ): Promise<UserData> {
    return ApiService.api
      .post<SuccessResponse<UserData>>('/auth/signup', {
        email,
        password,
        first_name,
        last_name,
      })
      .then((response) => response.data.data);
  }

  static async recoverPassword(email: string): Promise<void> {
    return ApiService.api.post('/password/restore', {
      email,
    });
  }

  static async setNewPassword(newPassword: string, token: string): Promise<void> {
    return ApiService.api.put(
      '/password/newPassword',
      { new_password: newPassword },
      {
        headers: {
          token: token,
        },
      },
    );
  }

  static async socialAuth(type: number, socialAuthToken: string): Promise<UserData> {
    return ApiService.api
      .post<SuccessResponse<UserData>>('/auth/social', {
        type,
        social_auth_token: socialAuthToken,
      })
      .then((response) => response.data.data);
  }

  static async facebookLogin(): Promise<string> {
    return ApiService.api
      .get<string>('/auth/facebook/redirect-url')
      .then((response) => response.data);
  }

  static async googleLogin(): Promise<string> {
    return ApiService.api.get<string>('/auth/google').then((response) => response.data);
  }

  static async refreshToken(): Promise<{ token: string; refreshToken: string } | null> {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.refreshToken);

    if (refreshToken)
      return ApiService.api
        .get('/auth/refresh', {
          headers: {
            refreshtoken: `${refreshToken}`,
          },
          transformRequest: (data, headers) => {
            if (headers) delete headers['token'];
            return data;
          },
        })
        .then((response) => response.data.data);

    return null;
  }
}
