import styled from "styled-components";

export const ButtonWrapper = styled.button<{
  position?: 'relative' | 'absolute' | 'fixed'
  top?: string
  right?: string
  bottom?: string
  left?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: none;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  padding: 5px;
  ${props => ({
    position: props.position || 'relative',
    top: props.top,
    right: props.right,
    bottom: props.bottom,
    left: props.left,
    color: props.theme.palette.secondary.main,
    boxShadow: `1px 1px 3px 1px ${props.theme.palette.light.light}`,
  })}
`
