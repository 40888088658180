import { DashboardLayoutWrapper } from './style';
import { AsideMenu } from '../../components/AsideMenu';
import { DashboardContainer } from '../../components/DashboardContainer';
import { useDispatch } from 'react-redux';
import { resetAuthAction } from '../../store/actions/auth';
import { resetUsersEmailsAction } from '../../store/actions/notifications';
import { resetAllUsersAction } from '../../store/actions/users';
import { LOCAL_STORAGE_KEYS } from '@plant/data';
import { useNavigate } from 'react-router-dom';

interface IProps {
  currentPage: 'users' | 'notifications' | 'content' | 'firmware';
  children: JSX.Element;
  rootPage?: boolean;
}

export const DashBoardLayout = ({ currentPage, children, rootPage }: IProps) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onLogOutButtonHandler = () => {
    dispatch(resetUsersEmailsAction());
    dispatch(resetAllUsersAction());
    dispatch(resetAuthAction());
    localStorage.removeItem(LOCAL_STORAGE_KEYS.authData);
  };

  const onChangeButtonPageHandler = (url: string) => {
    navigate(url);
  };

  return (
    <DashboardLayoutWrapper>
      <AsideMenu
        onChangeButtonPageHandler={onChangeButtonPageHandler}
        onLogOutButtonHandler={onLogOutButtonHandler}
        currentPage={currentPage}
      />
      <DashboardContainer rootPage={rootPage} children={children} />
    </DashboardLayoutWrapper>
  );
};
