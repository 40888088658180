import React from 'react';
import { Icon } from '@plant/data';

const ShareIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.38429 8.8812C2.56555 7.53938 4.18388 6.58236 6.00808 6.23575C6.07243 6.21664 6.13678 6.21027 6.2005 6.21027C6.58597 6.21027 6.89434 6.51864 6.89434 6.90411V8.21845L13.046 4.31609L6.89434 0V1.58025C6.89434 1.895 6.68217 2.17726 6.36806 2.25434C5.91186 2.36329 5.47542 2.51111 5.06447 2.69079C3.19574 3.48721 1.75076 4.91884 0.884172 6.63979C0.222818 7.96886 -0.091932 9.4782 0.0234017 10.9997C0.363629 10.2224 0.826203 9.51004 1.38498 8.88057L1.38429 8.8812Z"
        fillOpacity="0.82"
      />
    </svg>
  );
};

export default React.memo(ShareIcon);
