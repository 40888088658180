import React, { useEffect, useState } from 'react';
import { TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons'
import * as Styled from './styles';
import { dateFormatter } from '../../helpers/dateFormatter'
import { DashBoardLayout } from '../../layouts/Dashboard';
import { COLUMNS } from './data';
import { softwareLoadingSelector, softwareVersionsSelector, softwareKeysSelector } from '../../store/selectors/software';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router';
import { getVersionsList } from '../../store/actions/software';
import { LoadingScreen } from '../../components/LoadingScreen';

const FirmwarePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const versions = useSelector(softwareVersionsSelector);
  const versionsKeys = useSelector(softwareKeysSelector);
  const loading = useSelector(softwareLoadingSelector);

  const count = versionsKeys?.length || 0;

  useEffect(() => {
    dispatch(getVersionsList.request());
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAdd = () => {
    navigate(RouteNames.AddVersionPage);
  };

  const handleClick = (version: string) => () => {
    navigate(RouteNames.VersionPage.replace(':id', version));
  };

  return (
    <DashBoardLayout rootPage currentPage="firmware">
      <>
        {loading && <LoadingScreen/>}
        {!loading && <Styled.Container>
          <Styled.StyledButton onClick={handleAdd}>
            <Styled.AddIcon>
              +
            </Styled.AddIcon>
            <Styled.StyledIcon icon={faMicrochip} />
          </Styled.StyledButton>
          <Styled.Table>
            <Styled.TableHead>
              {COLUMNS.map((column) => (
                <Styled.TableHeadTitle width={column.width} key={column.field}>{column.headerName}</Styled.TableHeadTitle>
              ))}
            </Styled.TableHead>
            <Styled.TableBody>
              {(rowsPerPage > 0
                ? versionsKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : versionsKeys
              ).map((key) => (
                <Styled.AccordionTR onClick={handleClick(String(versions[key].version))}>
                  <Styled.TableRowColumn fontWeight={600} width='30%'>{versions[key].versionName}</Styled.TableRowColumn>
                  <Styled.TableRowColumn fontWeight={400} width='30%'>{versions[key].description}</Styled.TableRowColumn>
                  <Styled.TableRowColumn fontWeight={400} width='30%'>{versions[key].version}</Styled.TableRowColumn>
                  <Styled.TableRowColumn fontWeight={400} width='40%'>{dateFormatter(versions[key].uploadedTime)}</Styled.TableRowColumn>
                </Styled.AccordionTR>
              ))}
            </Styled.TableBody>
            <TablePagination
              page={page}
              rowsPerPageOptions={[5, 10]}
              rowsPerPage={rowsPerPage}
              count={count}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Styled.Table>
        </Styled.Container>}
      </>
    </DashBoardLayout>
  );
};

export default React.memo(FirmwarePage);
