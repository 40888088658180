import React, { FC, useEffect, useState } from 'react';
import { DashBoardLayout } from '../../layouts/Dashboard';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { defaultTheme } from '@plant/ui';
import { getCropByIdAction } from '../../store/actions/content';
import * as S from './style';
import { Button } from '../../components/Button';
import { useSelector } from 'react-redux';
import { cropToShowSelector, uploadingSelector } from '../../store/selectors/content';
import { authAccessTokenSelector } from '../../store/selectors/auth';
import VideoPlayer from '../../components/VideoPlayer';
import { TECHNIQUE_BUTTONS } from '../../constants/content';
import { switchContentTabAction, deleteCropByIdAction } from '../../store/actions/content';
import { PopUpLayout } from '../../layouts/PopUp';
import { Loader } from '../../components/Loader';
import { RouteNames } from '../../router';
import { useNavigate } from "react-router-dom";
import { dateConverter } from '@plant/data';

export const CropPage: FC = (): JSX.Element => {

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCropByIdAction.request({ id }));
  }, [dispatch, id])

  const [ currentTab, setCurrentTab ] = useState<'harvest' | 'replant' | 'lifespan'>('harvest');
  const [ openDeletePopup, setOpenDeletePopup ] = useState<boolean>(false);

  const cropToShow = useSelector(cropToShowSelector);
  const token = useSelector(authAccessTokenSelector);
  const uploading = useSelector(uploadingSelector);

  const onTabButtonsClick = (tabName: 'harvest' | 'replant' | 'lifespan') => () => {
    setCurrentTab(tabName)
  }

  const onSwitchCurrentTab = (tab: 'flavor' | 'nutrition', id: string) => () => {
    dispatch(switchContentTabAction({tab, id}))
  }

  const onCloseDeletePopup = () => {
    setOpenDeletePopup(false)
  }

  const onOpenDeletePopup = () => {
    setOpenDeletePopup(true)
  }

  const onRedirectToEditPage = () => {
    navigate(RouteNames.DashboardContentManageEdit.replace(':page', 'crop').replace(':mode', 'edit').replace(':id', cropToShow.uuid))
  }

  const onDeletePopupSubmit = () => {
    dispatch(deleteCropByIdAction.request({ id }))
  }

  const DeletePopup = () => {
    return(
      <PopUpLayout
        open={openDeletePopup}
        onClose={onCloseDeletePopup}
      >
        <S.PopUpContainer>
          <S.PopupTitle>
            Are you sure that you want to delete this crop guide?
          </S.PopupTitle>
          <S.PopupButtonsContainer>
            <Button width='80px' title='Yes' variant='primary' onClick={onDeletePopupSubmit}/>
            <Button width='80px' title='No' variant='warning' onClick={onCloseDeletePopup}/>
          </S.PopupButtonsContainer>

          <S.PopupWarning>
            Warning: crop guide will be deleted permanently!
          </S.PopupWarning>
          {uploading && <Loader position='absolute' bottom='0' bubbleSize='20px' width='100px'/>}
        </S.PopUpContainer>
      </PopUpLayout>
    )
  }

  return (
    <DashBoardLayout currentPage='content'>
      {cropToShow && <S.CropPageWrapper>
        <S.TitleNav>
          <Button
            variant='primary'
            title='Edit'
            onClick={onRedirectToEditPage}
            width="100px"
            position='absolute'
            top='0'
            right="120px"
          />
          <Button
            variant='warning'
            title='Delete'
            onClick={onOpenDeletePopup}
            width="100px"
            position='absolute'
            top='0'
            right='0'
          />
        </S.TitleNav>
        <S.StyledArticleWrapper>
          <S.StyledCropNameWrapper>
            <S.StyledCropName>{cropToShow.name}</S.StyledCropName>
            <S.StyledViewsCount>{`${cropToShow.views} views`}</S.StyledViewsCount>
          </S.StyledCropNameWrapper>
          <S.Date>{dateConverter(cropToShow.createdOn)}</S.Date>
          <S.StyledCropPhoto src={`${cropToShow.photo}?token=${token}`} />
          <S.StyledCropDescription>{cropToShow.fullDescription}</S.StyledCropDescription>
          {cropToShow.video && <VideoPlayer src={`${cropToShow.video}?token=${token}`} />}
          <S.TechniqueBlock>
            <S.TechniqueButtonContainerTitle>
              {TECHNIQUE_BUTTONS.find(button => button.tabName === currentTab).headTitle}
            </S.TechniqueButtonContainerTitle>
            <S.TechniqueButtonContainer>
              {
                TECHNIQUE_BUTTONS.map(button => {
                  return (
                    <S.TechniqueButton
                      onClick={onTabButtonsClick(button.tabName)}
                      active={button.tabName === currentTab} key={button.id}>
                      <S.TechniqueButtonIcon src={button.iconSrc} />
                      <S.TechniqueButtonTextContainer>
                        <S.TechniqueButtonText color={defaultTheme.palette.secondary.main}>
                          {button.title}
                        </S.TechniqueButtonText>
                        <S.TechniqueButtonText fontSize="13px" active={button.tabName === currentTab} color={defaultTheme.palette.light.dark}>
                          {cropToShow[button.tabName].time}
                        </S.TechniqueButtonText>
                      </S.TechniqueButtonTextContainer>
                    </S.TechniqueButton>
                  )
                })
              }
            </S.TechniqueButtonContainer>
            <S.TechniqueDescription>
              {cropToShow[currentTab].description}
            </S.TechniqueDescription>
            {cropToShow[currentTab]?.image && <S.TechniqueImage src={`${cropToShow[currentTab]?.image}?token=${token}`}/>}
          </S.TechniqueBlock>

          {cropToShow?.nutrition &&
            <S.TechniqueBlock>
              <S.TechniqueButtonContainerTitle>Nutrition</S.TechniqueButtonContainerTitle>
              <S.TechniqueButtonContainer>
                {
                  cropToShow.nutrition.map(button => {
                    return (
                      <S.TechniqueButton
                        width='auto'
                        height='65px'
                        onClick={onSwitchCurrentTab('nutrition', button.uuid)}
                        active={button.current} key={button.uuid}>
                        <S.TechniqueButtonTextContainer>
                          <S.TechniqueButtonText color={defaultTheme.palette.secondary.main}>
                            {button.name}
                          </S.TechniqueButtonText>
                        </S.TechniqueButtonTextContainer>
                      </S.TechniqueButton>
                    )
                  })
                }
              </S.TechniqueButtonContainer>
              <S.TechniqueDescription>
                {cropToShow?.nutrition.find(nutr => nutr?.current === true)?.description}
              </S.TechniqueDescription>
            </S.TechniqueBlock>
          }

          {cropToShow?.flavor &&
            <S.TechniqueBlock>
              <S.TechniqueButtonContainerTitle>Flavors</S.TechniqueButtonContainerTitle>
              <S.TechniqueButtonContainer>
                {
                  cropToShow.flavor.map(button => {
                    return (
                      <S.TechniqueButton
                        cursor='auto'
                        height='65px'
                        key={button.uuid}>
                        <S.TechniqueButtonTextContainer>
                          <S.TechniqueButtonText color={defaultTheme.palette.secondary.main}>
                            {button.name}
                          </S.TechniqueButtonText>
                        </S.TechniqueButtonTextContainer>
                      </S.TechniqueButton>
                    )
                  })
                }
              </S.TechniqueButtonContainer>
            </S.TechniqueBlock>
          }
        </S.StyledArticleWrapper>
        <DeletePopup/>
      </S.CropPageWrapper>}
    </DashBoardLayout>
  )
}
