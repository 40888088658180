import { configureStore, Store, compose, applyMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { AppState, rootReducer } from './reducers';
import { sagas } from './sagas';

const sagaMiddleware = createSagaMiddleware();

const createStore = (): { store: Store<AppState> } => {
  const middlewares = [sagaMiddleware];

  const composeEnhancers =
    window.ENABLE_DEVTOOLS && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = configureStore({
    reducer: rootReducer,
    enhancers: [composeEnhancers(applyMiddleware(...middlewares))],
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: false,
    }),
  });

  sagaMiddleware.run(sagas);

  return { store };
};

export default createStore;