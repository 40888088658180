import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const notificationsStoreSelector = (state: AppState) => state.notifications;

export const usersSelector = createSelector(notificationsStoreSelector, state => state.data);
export const inputValueSelector = createSelector(notificationsStoreSelector, state => state.inputValue);
export const selectedEmailTagsSelector = createSelector(notificationsStoreSelector, state => state.selectedEmailTags);
export const isEmailsLoadingSelector = createSelector(notificationsStoreSelector, state => state.emailLoading);
export const subjectSelector = createSelector(notificationsStoreSelector, state => state.subject);
export const emailContentSelector = createSelector(notificationsStoreSelector, state => state.emailContent);
export const selectedAllEmailSelector = createSelector(notificationsStoreSelector, state => state.selectedAllEmail);
export const isSendingEmailsSelector = createSelector(notificationsStoreSelector, state => state.isSendingEmails);
export const sendIsFinishedSelector = createSelector(notificationsStoreSelector, state => state.sendIsFinished);