import React from 'react';
import { Icon } from '@plant/data';

const ListTemplateIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 1H16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 6H16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 11H16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 1H1.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 6H1.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 11H1.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default React.memo(ListTemplateIcon);
