import React from 'react';
import { Icon } from '@plant/data';

const PersonalIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4096 6.07587C14.4096 8.52339 12.4472 10.4859 9.99792 10.4859C7.5495 10.4859 5.58626 8.52339 5.58626 6.07587C5.58626 3.62834 7.5495 1.66666 9.99792 1.66666C12.4472 1.66666 14.4096 3.62834 14.4096 6.07587ZM9.9987 18.3333C6.38401 18.3333 3.33203 17.7458 3.33203 15.4791C3.33203 13.2116 6.40318 12.6449 9.9987 12.6449C13.6142 12.6449 16.6654 13.2324 16.6654 15.4991C16.6654 17.7667 13.5942 18.3333 9.9987 18.3333Z"
      />
    </svg>
  );
}; 

export default React.memo(PersonalIcon);
