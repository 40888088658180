import { AxiosResponse } from 'axios';
import { ISignInSuccessFullResponse } from '../interfaces';
import { ApiService } from './apiService';

export class AdminAuthService {
  static async authCode(email: string, password: string): Promise<AxiosResponse<string>> {
    return ApiService.api.post<string>('/admin/code', { email, password })
  }

  static async login(email: string, code: string): Promise<AxiosResponse<ISignInSuccessFullResponse>> {
    return ApiService.api.post<ISignInSuccessFullResponse>('/admin/signin', { email, code })
  }
}