import { SuccessResponse } from '../interfaces/serverResponse';
import { UserData } from '../interfaces/userData';
import { ApiService } from './apiService';

export class UserService {
  static async getUser() {
    return ApiService.api.get<{ data: UserData }>('/users').then((response) => response.data.data);
  }

  static async changeUserData(
    firstName: string,
    lastName: string,
    country?: string,
    city?: string,
    streetAddress?: string,
  ): Promise<UserData> {
    return ApiService.api
      .put<SuccessResponse<UserData>>('/users', {
        firstName,
        lastName,
        country,
        city,
        streetAddress,
      })
      .then((response) => response.data.data);
  }

  static async uploadAvatar(body: FormData): Promise<string> {
    return ApiService.api
      .put<SuccessResponse<{ photo: string }>>('/users/photo', body)
      .then((response) => response.data.data.photo);
  }

  static async removeAvatar(uuid: string): Promise<void> {
    ApiService.api.delete<SuccessResponse<{ photo: string }>>(`/users/photo/${uuid}`);
  }

  static async deleteAccount(uuid: string): Promise<void> {
    ApiService.api.delete(`/users/${uuid}`);
  }
}
