import styled from "styled-components";

export const StyledErrorWrapper = styled.div<{
  width?: string,
  top?: string,
  left?: string,
  right?: string,
  bottom?: string,
  position?: 'relative' | 'absolute',
  margin: string
}>`
  position: absolute;
  padding: 10px 15px;
  border-radius: 25px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  ${props => ({
    color: props.theme.palette.light.main,
  })}
  ${props => ({
    width: props.width,
    top: props.top,
    left: props.left,
    right: props.right,
    bottom: props.bottom,
    position: props.position || 'absolute',
    margin: props.margin,
    background: props.theme.palette.error.main,
  })}
`