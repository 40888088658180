import { IGetFaqApiResponseType, IGetFaqType } from '../interfaces';

export function faqMapper(faq: IGetFaqApiResponseType[]) {
  return faq.map<IGetFaqType>(
    ({
      active,
      content,
      created_on,
      id,
      subject,
      updated_on,
      uuid,
      views,
    }) => ({
      active,
      content,
      createdOn: created_on,
      id,
      subject,
      updatedOn: updated_on,
      uuid,
      views,
    })
  );
}
