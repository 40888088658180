import { UserGroups } from '../../constants';

export interface VersionApi {
  versionName: string;
  version: number;
  description: string;
  canDownload: UserGroups;
  file: FormData;
  users: string[];
}
