import React from 'react';
import { Icon } from '@plant/data';

const FaqIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66602 10.0125C1.66602 5.62262 5.17435 1.66669 10.016 1.66669C14.7493 1.66669 18.3327 5.54751 18.3327 9.9875C18.3327 15.1369 14.1327 18.3334 9.99935 18.3334C8.63268 18.3334 7.11602 17.9661 5.89935 17.2484C5.47435 16.9897 5.11602 16.7977 4.65768 16.9479L2.97435 17.4487C2.54935 17.5822 2.16602 17.2484 2.29102 16.7977L2.84935 14.9282C2.94102 14.6695 2.92435 14.3941 2.79102 14.1771C2.07435 12.8585 1.66602 11.4146 1.66602 10.0125ZM10.516 11.7949H9.241C9.241 11.5649 9.26267 11.3433 9.306 11.1299C9.35267 10.9133 9.42767 10.7133 9.531 10.5299C9.621 10.3533 9.721 10.2133 9.831 10.1099C9.941 10.0066 10.0643 9.9016 10.201 9.79493C10.3377 9.68826 10.4893 9.5466 10.656 9.36993C10.7893 9.21993 10.8777 9.0716 10.921 8.92493C10.9677 8.77826 10.991 8.61826 10.991 8.44493C10.991 8.33493 10.9793 8.23326 10.956 8.13993C10.9327 8.04326 10.891 7.95826 10.831 7.88493C10.7377 7.7616 10.6177 7.67493 10.471 7.62493C10.3243 7.5716 10.1677 7.54493 10.001 7.54493C9.86434 7.54493 9.73267 7.5616 9.606 7.59493C9.48267 7.62826 9.37767 7.68326 9.291 7.75993C9.091 7.91993 8.996 8.1466 9.006 8.43993H7.736C7.76934 8.1066 7.85934 7.79826 8.006 7.51493C8.156 7.22826 8.35267 6.99993 8.596 6.82993C8.78934 6.69326 9.00434 6.58993 9.241 6.51993C9.47767 6.4466 9.716 6.40993 9.956 6.40993C10.3227 6.40993 10.6693 6.4666 10.996 6.57993C11.3227 6.69326 11.5927 6.87993 11.806 7.13993C11.9527 7.30993 12.066 7.5066 12.146 7.72993C12.226 7.95326 12.266 8.17993 12.266 8.40993C12.266 8.75326 12.191 9.0666 12.041 9.34993C11.8943 9.63326 11.7077 9.89493 11.481 10.1349C11.3243 10.3049 11.176 10.4483 11.036 10.5649C10.8993 10.6816 10.7893 10.8083 10.706 10.9449C10.6127 11.1016 10.5577 11.2349 10.541 11.3449C10.5243 11.4516 10.516 11.6016 10.516 11.7949ZM10.516 13.7499H9.251V12.4249H10.516V13.7499Z"
      />
    </svg>
  );
};

export default React.memo(FaqIcon);
