import styled from 'styled-components';
import JoditEditor from 'jodit-react';

export const StyledTextEditorContainer = styled.div`
  margin-top: 30px;
  padding: 25px;
  border-radius: 50px;
  ${(props) => ({
    background: props.theme.palette.light.main,
  })}
  height: 70%;
  & > .jodit-react-container {
    height: 100%;
    & > .jodit-container {
      border: none;
      & > .jodit-workplace {
        max-height: 40vh;
        padding: 5px 0;
        & > .jodit-wysiwyg {
          &::-webkit-scrollbar {
            width: 12px;
          }
          &::-webkit-scrollbar-track {
            ${(props) => ({
              background: props.theme.palette.primary.light,
            })};
            border-radius: 12px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            ${props => ({
              background: props.theme.palette.primary.main,
            })}
          }
        }
      }
      & > .jodit-toolbar__box {
        background: none;
      }
      & > .jodit-status-bar {
        display: none;
      }
      & > .jodit-add-new-line {
        display: none;
      }
    }
  }
`;
