import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApiService, EventsTypes, LOCAL_STORAGE_KEYS } from '@plant/data';
import './App.css';
import AppRouter from './components/AppRouter';
import { startAppAction } from './store/actions/app';
import { appIsReadySelector } from './store/selectors/app';
import { isAuthenticatedSelector } from './store/selectors/auth';
import { setIsAuthenticatedAction } from './store/actions/auth';
import * as AuthActions from './store/actions/auth';

function App() {
  const dispatch = useDispatch();

  const appIsReady = useSelector(appIsReadySelector);
  const auth = useSelector(isAuthenticatedSelector);

  const resetAuth = async () => {
    await localStorage.removeItem(LOCAL_STORAGE_KEYS.token)
    await localStorage.removeItem(LOCAL_STORAGE_KEYS.authData)
    await localStorage.removeItem(LOCAL_STORAGE_KEYS.refreshToken)
    dispatch(AuthActions.resetAuthAction())
  };

  useEffect(() => {
    dispatch(startAppAction.request(resetAuth));
  }, [dispatch]);

  useEffect(() => {
    if (auth) {
      ApiService.emitter.addEventListener(EventsTypes.logout, () => {
        dispatch(setIsAuthenticatedAction(false));
      });
    }
  }, [auth]);

  return <>{appIsReady && <AppRouter />}</>;
}

export default App;
