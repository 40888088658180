import { Crop } from './crop';

export interface AddPlantResponse {
  uuid: string;
  active: boolean;
  photo: string;
  video: string;
  plantActionDate: string;
  plantAction: {
    uuid: string;
    name: string;
    description: string;
    plantAge: number;
  };
  plaantUnit: {
    uuid: string;
    active: boolean;
    name: string;
    podLimit: string;
  };
  pod: {
    number: 0;
  };
  crop: Crop;
}
