import React from 'react';
import { Icon } from '@plant/data';

const PlantsIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6875 12.3353C8.24803 11.1342 5.35715 7.4945 5.35715 2.4086C11.7857 1.3372 15.2663 3.4405 15.5357 9.90867C16.0714 10.5545 16.3839 12.3353 16.3839 12.3353V16.8282H24.442C25.0337 16.8282 25.5175 17.3087 25.4689 17.8984C25.0377 23.1269 21.4888 28.6586 15.5357 28.6586C9.9794 28.6586 6.08461 23.1267 5.60743 17.8982C5.55365 17.3089 6.03774 16.8282 6.62947 16.8282H14.6875V12.3353ZM17.5061 12.0926C23.1604 10.6917 24.442 8.79617 24.442 4.01579C20.1563 2.94429 16.0714 5.08722 16.0714 9.08468C17.0248 10.3232 17.2518 10.9667 17.5061 12.0926Z"
      />
    </svg>
  );
};

export default React.memo(PlantsIcon);
