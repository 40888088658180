import React from 'react';
import { Icon } from '@plant/data';

const LightingIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.4292 17.9045C6.4292 20.0327 7.21268 22.087 8.62899 23.6738C10.0466 25.2622 11.9988 26.2716 14.1132 26.5127C16.2276 26.7538 18.357 26.2076 20.0947 24.9784C21.8324 23.7504 23.0566 21.9248 23.5349 19.8506C24.012 17.7777 23.7107 15.6004 22.6862 13.7348C21.6616 11.869 19.9867 10.4466 17.9801 9.7369C17.8294 9.68416 17.729 9.54103 17.729 9.38156V7.232H15.469V14.7354L20.3807 19.6472C20.5276 19.7941 20.5276 20.0326 20.3807 20.1795C20.2338 20.3264 19.9953 20.3264 19.8484 20.1795L15.0922 15.4234L10.336 20.1795C10.1891 20.3264 9.95056 20.3264 9.80367 20.1795C9.65677 20.0326 9.65676 19.7941 9.80367 19.6472L14.7154 14.7354V7.232H12.4554V9.38156C12.4554 9.54102 12.3549 9.68415 12.2043 9.7369C10.5168 10.3358 9.05527 11.442 8.02056 12.9047C6.98594 14.3662 6.42974 16.1128 6.42849 17.9046L6.4292 17.9045Z" />
      <path d="M12.4561 6.47874H17.7295V3.21431H12.4561V6.47874Z" />
    </svg>
  );
};

export default React.memo(LightingIcon);
