import React from 'react';
import { Icon } from '@plant/data';

const TempIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.88097 1.63098C4.88097 0.729868 4.1511 0 3.24999 0C2.34888 0 1.61902 0.729868 1.61902 1.63098V7.15894C1.12157 7.60695 0.803528 8.24864 0.803528 8.97037C0.803528 10.3215 1.89883 11.4168 3.24999 11.4168C4.60116 11.4168 5.69645 10.3215 5.69645 8.97037C5.69645 8.24867 5.37841 7.607 4.88097 7.15894V1.63098ZM4.06548 2.44646H3.24999V3.26195H4.06548V4.07744H3.24999V4.89293H4.06548V5.70842H2.4345V1.63098C2.4345 1.18093 2.79995 0.815488 3.24999 0.815488C3.70004 0.815488 4.06548 1.18093 4.06548 1.63098V2.44646Z" />
  </svg>
);

export default React.memo(TempIcon);
