import React from 'react';
import { Icon } from '@plant/data';

const LogoIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 124 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8273 6.71624C12.1678 6.00764 12.6167 5.33287 13.1633 4.70474C13.5611 4.24758 14.0101 3.81602 14.5047 3.41555C14.9993 3.81602 15.4491 4.24758 15.8461 4.70474C16.3918 5.33196 16.8407 6.00764 17.1812 6.71532C17.5217 6.50777 17.8712 6.31485 18.2287 6.13565C18.4939 6.00307 18.7636 5.87781 19.0387 5.76169C18.6113 4.87754 18.0576 4.05192 17.4007 3.29669C16.7332 2.52958 15.959 1.8347 15.0979 1.22485L14.5038 0.803345L13.9106 1.22393C13.0495 1.83469 12.2753 2.52866 11.6077 3.29577C10.9509 4.051 10.3971 4.87754 9.96973 5.76169C10.2439 5.87781 10.5145 6.00307 10.7807 6.13565C11.1382 6.31577 11.4877 6.50869 11.8273 6.71624Z"
      fill="#99C6B0"
    />
    <path
      d="M11.8273 6.71624C12.1678 6.00764 12.6167 5.33287 13.1633 4.70474C13.5611 4.24758 14.0101 3.81602 14.5047 3.41555C14.9993 3.81602 15.4491 4.24758 15.8461 4.70474C16.3918 5.33196 16.8407 6.00764 17.1812 6.71532C17.5217 6.50777 17.8712 6.31485 18.2287 6.13565C18.4939 6.00307 18.7636 5.87781 19.0387 5.76169C18.6113 4.87754 18.0576 4.05192 17.4007 3.29669C16.7332 2.52958 15.959 1.8347 15.0979 1.22485L14.5038 0.803345L13.9106 1.22393C13.0495 1.83469 12.2753 2.52866 11.6077 3.29577C10.9509 4.051 10.3971 4.87754 9.96973 5.76169C10.2439 5.87781 10.5145 6.00307 10.7807 6.13565C11.1382 6.31577 11.4877 6.50869 11.8273 6.71624Z"
      fill="#0F896C"
    />
    <path
      d="M5.66843 8.63457C7.16306 8.78818 8.57614 9.21151 9.86557 9.8561C11.0717 10.4596 12.1693 11.2578 13.1183 12.2096C12.8557 12.5561 12.6084 12.9154 12.3754 13.2857C12.9883 13.6213 13.5753 14.0007 14.1317 14.4204C14.4767 13.8709 14.862 13.3506 15.2831 12.8642C16.3584 11.6207 17.6684 10.594 19.1425 9.85702C20.4319 9.21151 21.8459 8.78818 23.3396 8.63549C23.7975 9.55255 24.1487 10.5336 24.3772 11.5631C25.1192 11.6098 25.8468 11.7131 26.5556 11.8694C26.2625 10.1222 25.6694 8.47822 24.8324 6.99703L24.5152 6.43564L23.8808 6.46764C21.8611 6.56821 19.9471 7.09212 18.2267 7.95249C16.8477 8.64189 15.5923 9.54798 14.5027 10.6269C13.4131 9.54798 12.1586 8.6428 10.7796 7.95249C9.05912 7.09212 7.14424 6.56821 5.12542 6.46764L4.49101 6.43564L4.1738 6.99703C3.33689 8.47731 2.74369 10.1213 2.45068 11.8694C3.15947 11.7131 3.88617 11.6098 4.629 11.5631C4.85929 10.5336 5.21054 9.55255 5.66843 8.63457Z"
      fill="#99C6B0"
    />
    <path
      d="M5.66843 8.63457C7.16306 8.78818 8.57614 9.21151 9.86557 9.8561C11.0717 10.4596 12.1693 11.2578 13.1183 12.2096C12.8557 12.5561 12.6084 12.9154 12.3754 13.2857C12.9883 13.6213 13.5753 14.0007 14.1317 14.4204C14.4767 13.8709 14.862 13.3506 15.2831 12.8642C16.3584 11.6207 17.6684 10.594 19.1425 9.85702C20.4319 9.21151 21.8459 8.78818 23.3396 8.63549C23.7975 9.55255 24.1487 10.5336 24.3772 11.5631C25.1192 11.6098 25.8468 11.7131 26.5556 11.8694C26.2625 10.1222 25.6694 8.47822 24.8324 6.99703L24.5152 6.43564L23.8808 6.46764C21.8611 6.56821 19.9471 7.09212 18.2267 7.95249C16.8477 8.64189 15.5923 9.54798 14.5027 10.6269C13.4131 9.54798 12.1586 8.6428 10.7796 7.95249C9.05912 7.09212 7.14424 6.56821 5.12542 6.46764L4.49101 6.43564L4.1738 6.99703C3.33689 8.47731 2.74369 10.1213 2.45068 11.8694C3.15947 11.7131 3.88617 11.6098 4.629 11.5631C4.85929 10.5336 5.21054 9.55255 5.66843 8.63457Z"
      fill="#0F896C"
    />
    <path
      d="M28.8441 14.3781L28.2232 14.1587C26.7366 13.6338 25.1389 13.3477 23.4794 13.3477C21.6246 13.3477 19.8468 13.7042 18.2133 14.3543C16.8665 14.8901 15.6183 15.6252 14.5027 16.5231C13.3871 15.6252 12.1389 14.8901 10.793 14.3543C9.16041 13.7042 7.38173 13.3477 5.52779 13.3477C3.86829 13.3477 2.27061 13.6338 0.784051 14.1587L0.163979 14.3772L0.091398 15.0428C0.031362 15.5868 0 16.1391 0 16.6995C0 18.317 0.254481 19.8768 0.726704 21.3361C1.43369 23.5258 2.62724 25.4871 4.17653 27.0889C5.72492 28.6917 7.62994 29.9352 9.76077 30.6877H9.76166C11.2482 31.2125 12.845 31.4978 14.5045 31.4978C16.164 31.4978 17.7617 31.2116 19.2482 30.6868C22.0905 29.6829 24.5287 27.8067 26.2581 25.37C27.1228 24.1521 27.811 22.7935 28.2823 21.3342C28.7536 19.875 29.009 18.3151 29.009 16.6977C29.009 16.1372 28.9776 15.585 28.9176 15.0428L28.8441 14.3781ZM8.97672 28.0563C7.16847 27.1383 5.60933 25.7833 4.43459 24.1275C3.69266 23.0833 3.10395 21.9203 2.70072 20.6713C2.29749 19.4233 2.07886 18.0893 2.07886 16.6995C2.07886 16.4509 2.08602 16.2049 2.10036 15.9589C3.18818 15.6408 4.33692 15.4698 5.52779 15.4698C7.12187 15.4698 8.64069 15.7752 10.0385 16.3311C11.0833 16.7462 12.0601 17.303 12.9463 17.9768C11.8558 19.1527 10.9516 20.5122 10.2823 22.0053C9.45521 23.8504 8.98926 25.9012 8.97672 28.0563ZM26.3065 20.6713C25.7017 22.5439 24.6792 24.2253 23.3513 25.5995C22.0233 26.9737 20.3907 28.0398 18.5672 28.6835V28.6826C17.2939 29.1315 15.9292 29.3766 14.5036 29.3766C13.3127 29.3766 12.164 29.2056 11.0762 28.8874C11.0618 28.6424 11.0547 28.3955 11.0547 28.1477C11.0547 26.267 11.4552 24.4886 12.1721 22.8867C12.8889 21.2848 13.9256 19.8603 15.1972 18.6992L15.3127 18.594C16.3853 17.6385 17.621 16.8669 18.9696 16.3311C20.3665 15.7752 21.8862 15.4698 23.4803 15.4698C24.6712 15.4698 25.819 15.6408 26.9077 15.9589C26.9221 16.204 26.9293 16.4509 26.9293 16.6986C26.9293 18.0884 26.7106 19.4224 26.3065 20.6713Z"
      fill="#99C6B0"
    />
    <path
      d="M28.8441 14.3781L28.2232 14.1587C26.7366 13.6338 25.1389 13.3477 23.4794 13.3477C21.6246 13.3477 19.8468 13.7042 18.2133 14.3543C16.8665 14.8901 15.6183 15.6252 14.5027 16.5231C13.3871 15.6252 12.1389 14.8901 10.793 14.3543C9.16041 13.7042 7.38173 13.3477 5.52779 13.3477C3.86829 13.3477 2.27061 13.6338 0.784051 14.1587L0.163979 14.3772L0.091398 15.0428C0.031362 15.5868 0 16.1391 0 16.6995C0 18.317 0.254481 19.8768 0.726704 21.3361C1.43369 23.5258 2.62724 25.4871 4.17653 27.0889C5.72492 28.6917 7.62994 29.9352 9.76077 30.6877H9.76166C11.2482 31.2125 12.845 31.4978 14.5045 31.4978C16.164 31.4978 17.7617 31.2116 19.2482 30.6868C22.0905 29.6829 24.5287 27.8067 26.2581 25.37C27.1228 24.1521 27.811 22.7935 28.2823 21.3342C28.7536 19.875 29.009 18.3151 29.009 16.6977C29.009 16.1372 28.9776 15.585 28.9176 15.0428L28.8441 14.3781ZM8.97672 28.0563C7.16847 27.1383 5.60933 25.7833 4.43459 24.1275C3.69266 23.0833 3.10395 21.9203 2.70072 20.6713C2.29749 19.4233 2.07886 18.0893 2.07886 16.6995C2.07886 16.4509 2.08602 16.2049 2.10036 15.9589C3.18818 15.6408 4.33692 15.4698 5.52779 15.4698C7.12187 15.4698 8.64069 15.7752 10.0385 16.3311C11.0833 16.7462 12.0601 17.303 12.9463 17.9768C11.8558 19.1527 10.9516 20.5122 10.2823 22.0053C9.45521 23.8504 8.98926 25.9012 8.97672 28.0563ZM26.3065 20.6713C25.7017 22.5439 24.6792 24.2253 23.3513 25.5995C22.0233 26.9737 20.3907 28.0398 18.5672 28.6835V28.6826C17.2939 29.1315 15.9292 29.3766 14.5036 29.3766C13.3127 29.3766 12.164 29.2056 11.0762 28.8874C11.0618 28.6424 11.0547 28.3955 11.0547 28.1477C11.0547 26.267 11.4552 24.4886 12.1721 22.8867C12.8889 21.2848 13.9256 19.8603 15.1972 18.6992L15.3127 18.594C16.3853 17.6385 17.621 16.8669 18.9696 16.3311C20.3665 15.7752 21.8862 15.4698 23.4803 15.4698C24.6712 15.4698 25.819 15.6408 26.9077 15.9589C26.9221 16.204 26.9293 16.4509 26.9293 16.6986C26.9293 18.0884 26.7106 19.4224 26.3065 20.6713Z"
      fill="#0F896C"
    />
    <path
      d="M61.1623 4.39473H59.0117V22.8722H61.1623V4.39473Z"
      fill="#99C6B0"
    />
    <path
      d="M61.1623 4.39473H59.0117V22.8722H61.1623V4.39473Z"
      fill="#0F896C"
    />
    <path
      d="M76.9936 10.4932C76.697 10.2006 76.3753 9.93359 76.0321 9.69678C74.9066 8.92053 73.5428 8.4652 72.0841 8.46611C71.1127 8.46611 70.1808 8.66726 69.3358 9.03299C68.0679 9.58067 66.9918 10.4922 66.231 11.6415C65.4702 12.7899 65.024 14.1815 65.0249 15.67C65.0249 16.6611 65.222 17.612 65.5805 18.4742C66.1172 19.768 67.0106 20.8661 68.1369 21.6423C69.2624 22.4186 70.6262 22.8739 72.0849 22.873C73.0572 22.873 73.9882 22.6719 74.8332 22.3061C75.6423 21.9569 76.3735 21.4586 76.9945 20.8451V22.873H79.145V9.08876H76.9945V10.4932H76.9936ZM76.6083 17.6184C76.2364 18.5163 75.6127 19.2834 74.8287 19.8238C74.0437 20.3641 73.1047 20.6777 72.0849 20.6787C71.4039 20.6787 70.7615 20.5388 70.1746 20.2855C69.2946 19.9061 68.5428 19.2697 68.0142 18.4697C67.4846 17.6687 67.1772 16.7105 67.1763 15.67C67.1763 14.9751 67.3134 14.3196 67.5616 13.7207C67.9335 12.8228 68.5572 12.0557 69.3412 11.5163C70.1262 10.9759 71.0643 10.6623 72.0849 10.6614C72.7659 10.6614 73.4084 10.8013 73.9953 11.0545C74.8753 11.434 75.6271 12.0704 76.1566 12.8704C76.6862 13.6713 76.9936 14.6286 76.9945 15.67C76.9936 16.364 76.8565 17.0205 76.6083 17.6184Z"
      fill="#99C6B0"
    />
    <path
      d="M76.9936 10.4932C76.697 10.2006 76.3753 9.93359 76.0321 9.69678C74.9066 8.92053 73.5428 8.4652 72.0841 8.46611C71.1127 8.46611 70.1808 8.66726 69.3358 9.03299C68.0679 9.58067 66.9918 10.4922 66.231 11.6415C65.4702 12.7899 65.024 14.1815 65.0249 15.67C65.0249 16.6611 65.222 17.612 65.5805 18.4742C66.1172 19.768 67.0106 20.8661 68.1369 21.6423C69.2624 22.4186 70.6262 22.8739 72.0849 22.873C73.0572 22.873 73.9882 22.6719 74.8332 22.3061C75.6423 21.9569 76.3735 21.4586 76.9945 20.8451V22.873H79.145V9.08876H76.9945V10.4932H76.9936ZM76.6083 17.6184C76.2364 18.5163 75.6127 19.2834 74.8287 19.8238C74.0437 20.3641 73.1047 20.6777 72.0849 20.6787C71.4039 20.6787 70.7615 20.5388 70.1746 20.2855C69.2946 19.9061 68.5428 19.2697 68.0142 18.4697C67.4846 17.6687 67.1772 16.7105 67.1763 15.67C67.1763 14.9751 67.3134 14.3196 67.5616 13.7207C67.9335 12.8228 68.5572 12.0557 69.3412 11.5163C70.1262 10.9759 71.0643 10.6623 72.0849 10.6614C72.7659 10.6614 73.4084 10.8013 73.9953 11.0545C74.8753 11.434 75.6271 12.0704 76.1566 12.8704C76.6862 13.6713 76.9936 14.6286 76.9945 15.67C76.9936 16.364 76.8565 17.0205 76.6083 17.6184Z"
      fill="#0F896C"
    />
    <path
      d="M94.4774 10.4932C94.1808 10.2006 93.8592 9.93359 93.516 9.69678C92.3905 8.92053 91.0267 8.4652 89.5679 8.46611C88.5957 8.46611 87.6647 8.66726 86.8197 9.03299C85.5518 9.58067 84.4756 10.4922 83.7149 11.6415C82.9541 12.7899 82.5079 14.1815 82.5088 15.67C82.5088 16.6611 82.7059 17.612 83.0643 18.4742C83.6011 19.768 84.4945 20.8661 85.6208 21.6423C86.7463 22.4186 88.1101 22.8739 89.5688 22.873C90.5402 22.873 91.4721 22.6719 92.317 22.3061C93.1262 21.9569 93.8574 21.4586 94.4774 20.8451V22.873H96.628V9.08876H94.4774V10.4932ZM94.0921 17.6193C93.7203 18.5172 93.0966 19.2843 92.3126 19.8247C91.5276 20.365 90.5894 20.6787 89.5688 20.6796C88.8878 20.6796 88.2454 20.5397 87.6584 20.2864C86.7785 19.907 86.0267 19.2706 85.498 18.4706C84.9685 17.6696 84.6611 16.7114 84.6602 15.6709C84.6602 14.9761 84.7973 14.3205 85.0455 13.7216C85.4174 12.8237 86.0411 12.0566 86.8251 11.5172C87.6101 10.9768 88.5482 10.6632 89.5688 10.6623C90.2498 10.6623 90.8923 10.8022 91.4792 11.0555C92.3592 11.4349 93.111 12.0713 93.6396 12.8713C94.1692 13.6722 94.4765 14.6295 94.4774 15.6709C94.4774 16.364 94.3403 17.0205 94.0921 17.6193Z"
      fill="#99C6B0"
    />
    <path
      d="M94.4774 10.4932C94.1808 10.2006 93.8592 9.93359 93.516 9.69678C92.3905 8.92053 91.0267 8.4652 89.5679 8.46611C88.5957 8.46611 87.6647 8.66726 86.8197 9.03299C85.5518 9.58067 84.4756 10.4922 83.7149 11.6415C82.9541 12.7899 82.5079 14.1815 82.5088 15.67C82.5088 16.6611 82.7059 17.612 83.0643 18.4742C83.6011 19.768 84.4945 20.8661 85.6208 21.6423C86.7463 22.4186 88.1101 22.8739 89.5688 22.873C90.5402 22.873 91.4721 22.6719 92.317 22.3061C93.1262 21.9569 93.8574 21.4586 94.4774 20.8451V22.873H96.628V9.08876H94.4774V10.4932ZM94.0921 17.6193C93.7203 18.5172 93.0966 19.2843 92.3126 19.8247C91.5276 20.365 90.5894 20.6787 89.5688 20.6796C88.8878 20.6796 88.2454 20.5397 87.6584 20.2864C86.7785 19.907 86.0267 19.2706 85.498 18.4706C84.9685 17.6696 84.6611 16.7114 84.6602 15.6709C84.6602 14.9761 84.7973 14.3205 85.0455 13.7216C85.4174 12.8237 86.0411 12.0566 86.8251 11.5172C87.6101 10.9768 88.5482 10.6632 89.5688 10.6623C90.2498 10.6623 90.8923 10.8022 91.4792 11.0555C92.3592 11.4349 93.111 12.0713 93.6396 12.8713C94.1692 13.6722 94.4765 14.6295 94.4774 15.6709C94.4774 16.364 94.3403 17.0205 94.0921 17.6193Z"
      fill="#0F896C"
    />
    <path
      d="M123.655 7.95691H120.164V4.39473H118.014V7.95691H115.52V10.1513H118.014V22.8722H120.164V10.1513H123.655V7.95691Z"
      fill="#99C6B0"
    />
    <path
      d="M123.655 7.95691H120.164V4.39473H118.014V7.95691H115.52V10.1513H118.014V22.8722H120.164V10.1513H123.655V7.95691Z"
      fill="#0F896C"
    />
    <path
      d="M52.0375 9.69678C50.912 8.92053 49.5482 8.4652 48.0894 8.46611C47.1172 8.46611 46.1862 8.66726 45.3412 9.03299C44.5348 9.38134 43.8063 9.8769 43.1871 10.4877V9.12533H41.0365V15.3646C41.0321 15.4661 41.0303 15.5676 41.0303 15.6691C41.0303 15.7587 41.0339 15.8474 41.0365 15.9361V26.8777H43.1871V20.8515C43.4819 21.1422 43.8009 21.4065 44.1414 21.6414C45.2668 22.4177 46.6306 22.873 48.0894 22.8721C49.0608 22.8721 49.9927 22.671 50.8376 22.3052C52.1056 21.7575 53.1817 20.846 53.9425 19.6967C54.7032 18.5483 55.1495 17.1567 55.1486 15.6682C55.1486 14.6771 54.9514 13.7262 54.593 12.864C54.0572 11.5711 53.1638 10.473 52.0375 9.69678ZM52.6136 17.6193C52.2418 18.5172 51.6181 19.2843 50.8341 19.8247C50.0491 20.365 49.1109 20.6787 48.0903 20.6796C47.4093 20.6796 46.7668 20.5397 46.1799 20.2864C45.3 19.907 44.5482 19.2706 44.0186 18.4706C43.5312 17.7336 43.2328 16.8632 43.1871 15.9178V15.4094C43.2167 14.8115 43.3493 14.2446 43.5661 13.7207C43.938 12.8228 44.5616 12.0557 45.3457 11.5163C46.1306 10.9759 47.0697 10.6623 48.0894 10.6614C48.7704 10.6614 49.4129 10.8013 49.9998 11.0545C50.8798 11.434 51.6315 12.0704 52.1602 12.8704C52.6898 13.6713 52.9971 14.6286 52.998 15.67C52.9989 16.364 52.8609 17.0205 52.6136 17.6193Z"
      fill="#99C6B0"
    />
    <path
      d="M52.0375 9.69678C50.912 8.92053 49.5482 8.4652 48.0894 8.46611C47.1172 8.46611 46.1862 8.66726 45.3412 9.03299C44.5348 9.38134 43.8063 9.8769 43.1871 10.4877V9.12533H41.0365V15.3646C41.0321 15.4661 41.0303 15.5676 41.0303 15.6691C41.0303 15.7587 41.0339 15.8474 41.0365 15.9361V26.8777H43.1871V20.8515C43.4819 21.1422 43.8009 21.4065 44.1414 21.6414C45.2668 22.4177 46.6306 22.873 48.0894 22.8721C49.0608 22.8721 49.9927 22.671 50.8376 22.3052C52.1056 21.7575 53.1817 20.846 53.9425 19.6967C54.7032 18.5483 55.1495 17.1567 55.1486 15.6682C55.1486 14.6771 54.9514 13.7262 54.593 12.864C54.0572 11.5711 53.1638 10.473 52.0375 9.69678ZM52.6136 17.6193C52.2418 18.5172 51.6181 19.2843 50.8341 19.8247C50.0491 20.365 49.1109 20.6787 48.0903 20.6796C47.4093 20.6796 46.7668 20.5397 46.1799 20.2864C45.3 19.907 44.5482 19.2706 44.0186 18.4706C43.5312 17.7336 43.2328 16.8632 43.1871 15.9178V15.4094C43.2167 14.8115 43.3493 14.2446 43.5661 13.7207C43.938 12.8228 44.5616 12.0557 45.3457 11.5163C46.1306 10.9759 47.0697 10.6623 48.0894 10.6614C48.7704 10.6614 49.4129 10.8013 49.9998 11.0545C50.8798 11.434 51.6315 12.0704 52.1602 12.8704C52.6898 13.6713 52.9971 14.6286 52.998 15.67C52.9989 16.364 52.8609 17.0205 52.6136 17.6193Z"
      fill="#0F896C"
    />
    <path
      d="M112.636 12.2433C112.175 11.1315 111.407 10.1898 110.44 9.5223C109.474 8.85576 108.302 8.46443 107.05 8.46535C106.215 8.46535 105.415 8.63815 104.689 8.95177C103.6 9.42173 102.677 10.2053 102.023 11.1918C101.369 12.1775 100.986 13.3734 100.987 14.6516V22.8732H103.137V21.776V14.6516C103.137 14.0975 103.247 13.5755 103.445 13.0982C103.74 12.3832 104.239 11.7715 104.863 11.3409C105.489 10.9102 106.236 10.6606 107.05 10.6597C107.593 10.6597 108.104 10.7713 108.572 10.9733C109.273 11.275 109.872 11.7834 110.294 12.4207C110.716 13.0589 110.961 13.8214 110.962 14.6516V22.8732H113.112V14.6516C113.113 13.8004 112.943 12.9848 112.636 12.2433Z"
      fill="#99C6B0"
    />
    <path
      d="M112.636 12.2433C112.175 11.1315 111.407 10.1898 110.44 9.5223C109.474 8.85576 108.302 8.46443 107.05 8.46535C106.215 8.46535 105.415 8.63815 104.689 8.95177C103.6 9.42173 102.677 10.2053 102.023 11.1918C101.369 12.1775 100.986 13.3734 100.987 14.6516V22.8732H103.137V21.776V14.6516C103.137 14.0975 103.247 13.5755 103.445 13.0982C103.74 12.3832 104.239 11.7715 104.863 11.3409C105.489 10.9102 106.236 10.6606 107.05 10.6597C107.593 10.6597 108.104 10.7713 108.572 10.9733C109.273 11.275 109.872 11.7834 110.294 12.4207C110.716 13.0589 110.961 13.8214 110.962 14.6516V22.8732H113.112V14.6516C113.113 13.8004 112.943 12.9848 112.636 12.2433Z"
      fill="#0F896C"
    />
  </svg>
);

export default React.memo(LogoIcon);
