import styled from "styled-components";

export const UserInfoWrapper = styled.div`
  width: 90%;
  margin: 30px auto;
  max-width: 900px;
`
export const GridContainer = styled.div<{
  grid?: boolean
  columns?: string
  gap?: string
  height?: string
  padding?: string
}>`
  ${props => ({
    display: props.grid ? 'grid' : 'block',
    gridTemplateColumns: props.columns || null,
    gridGgap: props.gap,
    height: props.height,
    padding: props.padding,
  })}
`

export const StyledImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  align-self: center;
  ${props => ({
    border: `3px solid  ${props.theme.palette.light.main}`,
  })}
`

export const InfoContainer = styled.div`
  /* display: grid; */
`

export const InfoLineWrapper = styled.div`
  margin: 10px 0;
  padding: 10px 20px;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  border-radius: 25px;
`

export const InfoLineWrapperTitle = styled.div`
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
  font-size: 14px;
  font-weight: 500;
`

export const InfoLineWrapperValue = styled.div`
  font-weight: 600;
    ${props => ({
    color: `${props.theme.palette.secondary.main}`
  })};
  font-size: 16px;
`

export const UnitsContainer = styled.div`
  padding: 10px 20px;
  border-radius: 20px;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  height: 100%;
  max-height: 345px;
  font-size: 16px;
  font-weight: 600;
    ${props => ({
    color: `${props.theme.palette.secondary.main}`
  })};
`

export const UnitsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 12px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
      ${props => ({
    background: props.theme.palette.primary.light
  })};
    border-radius: 20px;
  }

   &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${props => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`
    })}
  }
`

export const Unit = styled.button`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  margin: 5px 0;
  text-align: left;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  background: none;
  border: none;
  &:hover {
    ${props => ({
    color: props.theme.palette.primary.dark
  })};
  }
`