import React from 'react';
import { Icon } from '@plant/data';

const AddPlantIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.01176 0.680298C6.34032 0.680298 5.79613 1.22449 5.79613 1.89593V5.54283H2.14923C1.47778 5.54283 0.933594 6.08702 0.933594 6.75846C0.933594 7.42991 1.47778 7.9741 2.14923 7.9741H5.79613V11.621C5.79613 12.2924 6.34032 12.8366 7.01176 12.8366C7.6832 12.8366 8.22739 12.2924 8.22739 11.621V7.9741H11.8743C12.5457 7.9741 13.0899 7.42991 13.0899 6.75846C13.0899 6.08702 12.5457 5.54283 11.8743 5.54283H8.22739V1.89593C8.22739 1.22449 7.6832 0.680298 7.01176 0.680298Z" />
  </svg>
);

export default React.memo(AddPlantIcon);
