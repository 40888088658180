import React, { FC, useEffect, useState } from 'react';
import * as S from './style';
import { useDispatch, useSelector } from 'react-redux';
import {
  faqsSelector,
  loadingSelector,
  loadingMoreFaqsSelector,
  loadingMoreFaqsCompleteSelector,
  uploadingSelector,
  uploadIsFinishedSelector,
  errorSelector,
} from '../../store/selectors/content';
import { LoadingScreen } from '../LoadingScreen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Loader } from '../Loader';
import { Button } from '../Button';
import * as ContentActions from '../../store/actions/content';
import { IGetFaqType } from '@plant/data';
import { DeployPopup } from './components/DeployPopup';
import { AddPopup } from './components/AddPopup';
import { EditPopup } from './components/EditPopup';
import { DeletePopup } from './components/DeletePopup'

export const FaqList = ({
  currentModel,
  currentFaq,
  setCurrentModel,
  setCurrentFaq,
}: {
  currentModel: 'add' | 'delete' | 'edit' | 'deploy' | null;
  currentFaq: IGetFaqType;
  setCurrentModel: (model: 'add' | 'delete' | 'edit' | 'deploy' | null) => void;
  setCurrentFaq: (faq: IGetFaqType) => void;
}) => {
  const count = 15;

  const dispatch = useDispatch();

  const faqs = useSelector(faqsSelector);
  const loading = useSelector(loadingSelector);
  const loadingMoreFaqs = useSelector(loadingMoreFaqsSelector);
  const loadingMoreFaqsComplete = useSelector(loadingMoreFaqsCompleteSelector);
  const uploading = useSelector(uploadingSelector);
  const uploadIsFinished = useSelector(uploadIsFinishedSelector);
  const error = useSelector(errorSelector);

  const [page, setPage] = useState(1);
  const [formError, setFormError] = useState('');
  const [expandedRow, setExpandedRow] = useState<string>('');

  useEffect(() => {
    dispatch(
      ContentActions.getListOfFaqsAction.request({
        count,
        page: 1,
      })
    );
  }, []);

  useEffect(() => {
    if (uploadIsFinished && !error) {
      setCurrentModel(null);
      setCurrentFaq(null);
      dispatch(
        ContentActions.getListOfFaqsAction.request({
          count,
          page: 1,
        })
      );
      setPage(1);
    }
  }, [uploadIsFinished]);

  const loadMore = () => {
    dispatch(
      ContentActions.loadMoreFaqsAction.request({
        count,
        page: page + 1,
      })
    );
    setPage(page + 1);
  };

  const onContainerScroll = (e: any) => {
    const nearTheListBottom =
      e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop - 10 <
      0;
    if (nearTheListBottom && !loadingMoreFaqs) {
      loadMore();
    }
  };

  const onShowHideButtonClick = (id: string) => () => {
    if (expandedRow === id) {
      setExpandedRow('');
    } else {
      setExpandedRow(id);
    }
  };

  const onClosePopup = () => {
    setCurrentModel(null);
    setCurrentFaq(null);
    setFormError('')
  };

  const onDeleteButtonClick = (faq: IGetFaqType) => () => {
    setCurrentFaq(faq);
    setCurrentModel('delete');
  };

  const deleteFaq = ({ id }: { id: string }) => {
    dispatch(ContentActions.deleteFaqByIdAction.request({ id }));
  };

  const onEditButtonClick = (faq: IGetFaqType) => () => {
    setCurrentFaq(faq);
    setCurrentModel('edit');
  };

  const onUploadButtonClick = ({
    id,
    faq,
  }: {
    id: string;
    faq: IGetFaqType;
  }) => {
    setFormError('')
    const isValidForm = faq.subject.length && faq.content.length;
    if (!isValidForm) return setFormError('Fill all fields')
    dispatch(ContentActions.setFaqByIdAction.request({ id, faq }));
  };

  const onAddButtonClick = ({ faq }: { faq: IGetFaqType }) => {
    setFormError('')
    const isValidForm = faq.subject.length && faq.content.length;
    if (!isValidForm) return setFormError('Fill all fields')
    dispatch(ContentActions.addFaqByIdAction.request({ faq }));
  };

  const onDeployButtonClick = (faq: IGetFaqType) => () => {
    setCurrentFaq({
      ...faq,
      active: !faq.active,
    });
    setCurrentModel('deploy');
  };

  return (
    <S.StyledFaqListContainer
      onScroll={!loadingMoreFaqsComplete ? onContainerScroll : null}
    >
      {faqs &&
        !loading &&
        faqs.map((faq) => {
          return (
            <S.StyledAccordion expanded={expandedRow === faq.uuid} key={faq.uuid}>
              <S.StyledAccordionSummary
                expandIcon={
                  <S.OpenCloseButton onClick={onShowHideButtonClick(faq.uuid)}>
                    <S.StyledExpandMoreIcon />
                  </S.OpenCloseButton>
                }
              >
                <S.SummaryTitle>{faq.subject}</S.SummaryTitle>
                <Button
                  height="20px"
                  fullWidth
                  title="Edit"
                  variant="primary"
                  onClick={onEditButtonClick(faq)}
                />
                <Button
                  height="20px"
                  fullWidth
                  title="Delete"
                  variant="warning"
                  onClick={onDeleteButtonClick(faq)}
                />
                <S.DeployButton
                  onClick={onDeployButtonClick(faq)}
                  active={faq.active}
                >
                  {faq.active ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </S.DeployButton>
              </S.StyledAccordionSummary>
              <S.StyledAccordionDetails>{faq.content}</S.StyledAccordionDetails>
            </S.StyledAccordion>
          );
        })}
      {faqs && !faqs.length && loading && <LoadingScreen />}
      {loadingMoreFaqs && (
        <Loader
          position="relative"
          bottom="0"
          left="100%"
          margin="0 auto"
          bubbleSize="11px"
          height="30px"
          width="70px"
        />
      )}
      <DeletePopup
        uploading={uploading}
        currentFaq={currentFaq}
        onSubmit={deleteFaq}
        currentModel={currentModel}
        onClosePopup={onClosePopup}
      />
      <EditPopup
        uploading={uploading}
        currentFaq={currentFaq}
        formError={formError}
        onSubmit={onUploadButtonClick}
        setCurrentFaq={setCurrentFaq}
        currentModel={currentModel}
        onClosePopup={onClosePopup}
      />
      <AddPopup
        uploading={uploading}
        currentFaq={currentFaq}
        formError={formError}
        onSubmit={onAddButtonClick}
        setCurrentFaq={setCurrentFaq}
        currentModel={currentModel}
        onClosePopup={onClosePopup}
      />
      <DeployPopup
        uploading={uploading}
        currentFaq={currentFaq}
        onSubmit={onUploadButtonClick}
        currentModel={currentModel}
        onClosePopup={onClosePopup}
      />
    </S.StyledFaqListContainer>
  );
};
