import { createReducer } from 'typesafe-actions';
import { IGetCropInterface, ISetCropInterface, IGetNutritionInterface, IGetFlavorInterface, IGetFaqType, IGetAboutType } from '@plant/data';

import * as ContentActions from '../actions/content';

export interface State {
  crop: IGetCropInterface[] | []
  cropToSet: ISetCropInterface
  initialCropToSet: ISetCropInterface
  loading: boolean
  loadingMoreCrops: boolean
  error: string | null
  nutrition: IGetNutritionInterface | []
  flavor: IGetFlavorInterface | []
  uploading: boolean,
  uploadIsFinished: boolean
  loadingMoreCropsComplete: boolean
  cropToShow: IGetCropInterface | null

  faqs: IGetFaqType[] | []
  faqToSet: IGetFaqType,
  loadingMoreFaqs: boolean,
  loadingMoreFaqsComplete: boolean,

  about: IGetAboutType[] | []
  aboutForm: IGetAboutType,
  aboutToSet: IGetFaqType,
  loadingMoreAbout: boolean,
  loadingMoreAboutComplete: boolean,

}

const initialCropToAdd = {
  active: false,
  name: '',
  short_description: "",
  full_description: "",
  scientific_name: '',
  subspecies: '',
  type: '',
  photo: '',
  video: '',
  icon: '',
  harvest_description: '',
  harvest_time: '',
  harvest_image: '',
  replant_description: '',
  replant_time: '',
  lifespan_description: '',
  lifespan_time: '',
  flavorUuids: [],
  nutritionUuids: [],
}

export const initialState: State = {
  crop: [],
  cropToSet: initialCropToAdd,
  initialCropToSet: initialCropToAdd,
  nutrition: [],
  flavor: [],
  loading: false,
  loadingMoreCrops: false,
  error: null,
  uploading: false,
  uploadIsFinished: false,
  loadingMoreCropsComplete: false,
  cropToShow: null,

  faqs: [],
  faqToSet: null,
  loadingMoreFaqs: false,
  loadingMoreFaqsComplete: false,

  about: [],
  aboutForm: {
    active: false,
    content: [
      {
        text: '', 
        title: ''
      },
    ],
    createdOn: '',
    subject: '',
    updatedOn: '',
    uuid: '',
    views: 0,
  },
  aboutToSet: null,
  loadingMoreAbout: false,
  loadingMoreAboutComplete: false,
}

export const reducer = createReducer<State, ContentActions.ContentActionUnion>(initialState)
  
  .handleAction(ContentActions.changeCropToSetFieldActionAction, (state, action) => ({
    ...state,
    cropToSet: {
      ...state.cropToSet,
      [action.payload.fieldName]: action.payload.value
    }
  }))

  .handleAction(ContentActions.getNutritionAction.request, state => ({
    ...state,
    error: null,
    loading: true,
    uploading: false
  }))
  .handleAction(ContentActions.getNutritionAction.success, (state, action) => ({
    ...state,
    nutrition: action.payload.data,
    loading: false,
    error: null,
  }))
  .handleAction(ContentActions.getNutritionAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loading: false
  }))

  .handleAction(ContentActions.getFlavorAction.request, state => ({
    ...state,
    error: null,
    loading: true,
    uploading: false
  }))
  .handleAction(ContentActions.getFlavorAction.success, (state, action) => ({
    ...state,
    flavor: action.payload.data,
    loading: false,
    error: null,
  }))
  .handleAction(ContentActions.getFlavorAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loading: false
  }))

  .handleAction(ContentActions.uploadCropArticleAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.uploadCropArticleAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.uploadCropArticleAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    uploading: false,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.updateCropArticleAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.updateCropArticleAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.updateCropArticleAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    uploading: false,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.changeCropArticleStatusAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.changeCropArticleStatusAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
    crop: state.crop.map(cr => cr.uuid === action.payload.id ? {
      ...cr,
      active: !cr.active
    } : cr)
  }))
  .handleAction(ContentActions.changeCropArticleStatusAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.message,
    uploading: false,
    uploadIsFinished: true
  }))

  .handleAction(ContentActions.deleteCropByIdAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.deleteCropByIdAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.deleteCropByIdAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    uploading: false,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.getListOfCropsAction.request, state => ({
    ...state,
    error: null,
    loading: true,
    loadingMoreCropsComplete: false
  }))
  .handleAction(ContentActions.getListOfCropsAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    crop: action.payload.data,
    loadingMoreCropsComplete: false
  }))
  .handleAction(ContentActions.getListOfCropsAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    uploading: false,
    uploadIsFinished: false,
    loadingMoreCropsComplete: false
  }))

  .handleAction(ContentActions.loadMoreCropsCropsAction.request, state => ({
    ...state,
    error: null,
    loadingMoreCrops: true,
  }))
  .handleAction(ContentActions.loadMoreCropsCropsAction.success, (state, action) => ({
    ...state,
    loadingMoreCrops: false,
    error: null,
    crop: [...state.crop, ...action.payload.data],
  }))
  .handleAction(ContentActions.loadMoreCropsCropsAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loadingMoreCrops: false,
    loadingMoreCropsComplete: true,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.getCropByIdAction.request, state => ({
    ...state,
    error: null,
    loading: true,
  }))
  .handleAction(ContentActions.getCropByIdAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    cropToShow: {
      ...action.payload,
      nutrition: action.payload.nutrition.map((nut, idx) => idx === 0 ? {...nut, current: true} : {...nut, current: false}),
      flavor: action.payload.flavor.map((flavor, idx) => idx === 0 ? {...flavor, current: true} : {...flavor, current: false})
    }
  }))
  .handleAction(ContentActions.getCropByIdAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loading: false,

  }))

  .handleAction(ContentActions.getCropToChangeByIdAction.request, state => ({
    ...state,
    error: null,
    loading: true,
  }))
  .handleAction(ContentActions.getCropToChangeByIdAction.success, (state, action) => {
    const cropInfo = {
      ...state.cropToSet,
      uuid: action.payload.uuid,
      active: action.payload.active,
      name: action.payload.name || '',
      subspecies: action.payload.subspecies || '',
      type: action.payload.type || '',
      flavorUuids: action.payload.flavor.map(fl => fl.uuid),
      short_description: action.payload.shortDescription || '',
      full_description: action.payload.fullDescription || '',
      harvest_description: action.payload.harvest.description || '',
      harvest_time: action.payload.harvest.time || '',
      harvest_image: action.payload.harvest.image,
      lifespan_description: action.payload.lifespan.description || '',
      lifespan_time: action.payload.lifespan.time || '',
      replant_description: action.payload.replant.description || '',
      replant_time: action.payload.replant.time || '',
      icon: action.payload.icon,
      nutritionUuids: action.payload.nutrition.map(n => n.uuid),
      photo: action.payload.photo,
      scientific_name: action.payload.scientificName || '',
      video: action.payload.video
    }
    return {
      ...state,
      loading: false,
      error: null,
      cropToSet: cropInfo,
      initialCropToSet: cropInfo
    }
  })
  .handleAction(ContentActions.getCropToChangeByIdAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loading: false,

  }))

  .handleAction(ContentActions.getListOfFaqsAction.request, state => ({
    ...state,
    error: null,
    loading: true,
    loadingMoreFaqsComplete: false,
  }))
  .handleAction(ContentActions.getListOfFaqsAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    faqs: action.payload.data,
    loadingMoreFaqsComplete: false,
  }))
  .handleAction(ContentActions.getListOfFaqsAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loading: false,
  }))

  .handleAction(ContentActions.loadMoreFaqsAction.request, state => ({
    ...state,
    error: null,
    loadingMoreFaqs: true,
  }))
  .handleAction(ContentActions.loadMoreFaqsAction.success, (state, action) => ({
    ...state,
    loadingMoreFaqs: false,
    error: null,
    faqs: [...state.faqs, ...action.payload.data],
  }))
  .handleAction(ContentActions.loadMoreFaqsAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loadingMoreFaqs: false,
    loadingMoreFaqsComplete: true,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.deleteFaqByIdAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.deleteFaqByIdAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.deleteFaqByIdAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    uploading: false,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.setFaqByIdAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.setFaqByIdAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.setFaqByIdAction.failure, (state, action) => ({
    ...state,
    error: action?.payload?.message,
    uploading: false,
    uploadIsFinished: true
  }))

  .handleAction(ContentActions.addFaqByIdAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.addFaqByIdAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.addFaqByIdAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.message,
    uploading: false,
    uploadIsFinished: true
  }))

  .handleAction(ContentActions.getListOfAboutAction.request, state => ({
    ...state,
    error: null,
    loading: true,
    loadingMoreAboutComplete: false,
  }))
  .handleAction(ContentActions.getListOfAboutAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    about: action.payload.data,
    loadingMoreAboutComplete: false,
  }))
  .handleAction(ContentActions.getListOfAboutAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loading: false,
  }))

  .handleAction(ContentActions.loadMoreAboutAction.request, state => ({
    ...state,
    error: null,
    loadingMoreAbout: true,
  }))
  .handleAction(ContentActions.loadMoreAboutAction.success, (state, action) => ({
    ...state,
    loadingMoreAbout: false,
    error: null,
    about: [...state.about, ...action.payload.data],
  }))
  .handleAction(ContentActions.loadMoreAboutAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    loadingMoreAbout: false,
    loadingMoreAboutComplete: true,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.deleteAboutByIdAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.deleteAboutByIdAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.deleteAboutByIdAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    uploading: false,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.setAboutByIdAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.setAboutByIdAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.setAboutByIdAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.message,
    uploading: false,
    uploadIsFinished: true
  }))

  .handleAction(ContentActions.addAboutByIdAction.request, state => ({
    ...state,
    error: null,
    uploading: true,
    uploadIsFinished: false
  }))
  .handleAction(ContentActions.addAboutByIdAction.success, (state, action) => ({
    ...state,
    uploading: false,
    error: null,
    uploadIsFinished: true,
  }))
  .handleAction(ContentActions.addAboutByIdAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.message,
    uploading: false,
    uploadIsFinished: true
  }))

  .handleAction(ContentActions.switchContentTabAction, (state, action) => ({
    ...state,
    cropToShow: {
      ...state.cropToShow,
      [action.payload.tab]: state.cropToShow[action.payload.tab]
        .map(tab => tab.uuid === action.payload.id ? 
        {...tab, current: true} : 
        {...tab, current: false})
    }
  }))

  .handleAction(ContentActions.selectOptionsAction, (state, action) => ({
    ...state,
    cropToSet: {
      ...state.cropToSet,
      [action.payload.fieldName]: state.cropToSet[action.payload.fieldName]?.some(el => el === action.payload.value) ?
      state.cropToSet[action.payload.fieldName].filter(el => el !== action.payload.value) :
      [...state.cropToSet[action.payload.fieldName], action.payload.value]
    }
  }))

  .handleAction(ContentActions.setAboutFormAction, (state, action) => ({
    ...state,
    aboutForm: action.payload
  }))

  .handleAction(ContentActions.prepareToDeployAboutFormContentAction, (state, action) => ({
    ...state,
    aboutForm: {
      ...action.payload,
      active: !action.payload.active
    }
  }))

  .handleAction(ContentActions.addObjectToAboutFormAction, state => ({
    ...state,
    aboutForm: {
      ...state.aboutForm,
      content: [
        ...state.aboutForm.content,
        {
          text: '', 
          title: ''
        }
      ]
    }
  }))

  .handleAction(ContentActions.clearAboutFormAction, state => ({
    ...state,
    aboutForm: initialState.aboutForm
  }))

  .handleAction(ContentActions.deleteObjectFromAboutFormAction, (state, action) => ({
    ...state,
    aboutForm: {
      ...state.aboutForm,
      content: state.aboutForm.content.filter((_, idx) => idx !== action.payload),
    }
  }))

  .handleAction(ContentActions.setAboutFormSubjectAction, (state, action) => ({
    ...state,
    aboutForm: {
      ...state.aboutForm,
      subject: action.payload
    }
  }))

  .handleAction(ContentActions.setAboutFormContentAction, (state, action) => ({
    ...state,
    aboutForm: {
      ...state.aboutForm,
      content: state.aboutForm.content.map((el, index) => {
        return index === action.payload.index ? {
          ...el,
          [action.payload.id]: action.payload.value
        } : el
      })
    }
  }))

  .handleAction(ContentActions.resetUploadIsFinishedAction, state => ({
    ...state,
    uploadIsFinished: false
  }))

  .handleAction(ContentActions.clearNewCropAction, state => ({
    ...state,
    cropToSet: initialCropToAdd
  }))

  .handleAction(ContentActions.resetContentActionAction, () => initialState)