import { AxiosResponse } from 'axios';
import { ApiService } from './apiService';
import {
  IGetUsersDataRequest,
  IUsersDataSuccessFullApiResponse,
  ICurrentUserDataSuccessFullResponse,
  ICurrentUnitDataSuccessFullResponse,
} from '../interfaces';

export class UserAdminService {
  static async getAllUsers({
    query,
    order,
    orderField,
    page,
    count,
    userStatus,
    unitStatus,
    userStatusValue,
    unitStatusValue,
  }: IGetUsersDataRequest): Promise<AxiosResponse<{ data: IUsersDataSuccessFullApiResponse}>> {
    let endpoint = `/admin/users`;
    if (count) {
      endpoint = `${endpoint}?count=${count}`;
    } else {
      endpoint = `${endpoint}?count=${20}`;
    }
    if (page) {
      endpoint = `${endpoint}&page=${page}`;
    } else {
      endpoint = `${endpoint}&page=${1}`;
    }
    if (query) {
      endpoint = `${endpoint}&query=${query}`;
    }
    if (order) {
      endpoint = `${endpoint}&order=${order}`;
    }
    if (orderField) {
      endpoint = `${endpoint}&orderField=${orderField}`;
    }
    if (userStatus) {
      endpoint = `${endpoint}&userStatus=${userStatusValue}`;
    }
    if (unitStatus) {
      endpoint = `${endpoint}&unitStatus=${unitStatusValue}`;
    }
    return ApiService.api.get<{ data: IUsersDataSuccessFullApiResponse }>(endpoint);
  }

  static async getCurrentUser({
    userId,
  }: {
    userId: string;
  }): Promise<AxiosResponse<{ data: ICurrentUserDataSuccessFullResponse }>> {
    return ApiService.api.get<{ data: ICurrentUserDataSuccessFullResponse }>(
      `/admin/users/${userId}`,
    );
  }

  static async getCurrentUnit({
    unitId,
  }: {
    unitId: string;
  }): Promise<AxiosResponse<ICurrentUnitDataSuccessFullResponse>> {
    return ApiService.api.get<ICurrentUnitDataSuccessFullResponse>(`/unit/${unitId}`);
  }

  static async deleteUnit(unitId: string): Promise<AxiosResponse<{ data: { status: string } }>> {
    return ApiService.api.delete<{ data: { status: string } }>(`/unit/${unitId}`);
  }
}
