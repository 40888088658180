import { styled } from '@mui/material';

export const InputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
}));

export const Input = styled('input')(({ theme }) => ({
  border: 'none',
  margin: 0,
  background: 'none',
  width: '100%',
  outline: 'none',
  fontWeight: 600,
  color: theme.palette.secondary.main,
  fontSize: 16,
}));

export const ShowHidePassButton = styled('div')`
  border: none;
  background: none;
  cursor: pointer;
`;
