import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const AsideMenuWrapper = styled.div`
  grid-area: asideMenu;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
`

export const Logo = styled.img`
  position: absolute;
  width: 60px;
  top: 30px;
`

export const ButtonsContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledButton = styled.button<{
  active?: boolean
}>`
  text-transform: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100px;
  font-size: 14px;
  font-weight: 600 !important;
  border-radius: 25px !important;
  margin: 15px 0 !important;
  overflow: hidden;
  ${props => ({
    background: `${props.active ? props.theme.palette.admin.light : props.theme.palette.light.main} !important`,
    color: `${props.active ? props.theme.palette.secondary.main : props.theme.palette.admin.contrastText} !important`
  })}
`

export const StyledIcon = styled(FontAwesomeIcon)<{
  color?: string
}>`
  margin-bottom: 10px;
  font-size: 20px;
`


export const StyledMenuButton = styled.button`
  background: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  margin-bottom: 30px;
  position: absolute !important;
  bottom: 30px;
`

export const StyledPopoverInternalContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 250px;
`

export const StyledSpan = styled.span<{
  color?: string,
  fontWeight?: number,
  fontSize?: string
}>`
  ${props => ({
    color: props.color,
    fontWeight: props.fontWeight,
    fontSize: props.fontSize,
  })}
`

export const MenuItemLogOutButton = styled.button`

`