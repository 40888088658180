import { UserGroups, Esp } from '../constants';

export interface SelectedVersion {
  version: number;
  description: string;
  versionName: string;
  canDownload: UserGroups;
  type: Esp;
  userInfo: { email: string; uuid: string }[];
}
