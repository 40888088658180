import { AxiosResponse } from 'axios';
import { ApiService } from './apiService';
import { IGetNutritionInterface, IGetFaqApiResponseType, IGetFlavorInterface, ISetCropInterface, IGetCropInterface, IGetCropApiResponseInterface, IGetAboutType } from '../interfaces';

export class ContentAdminService {
  static async getCrop({ page, count }: { page: number, count: number }): Promise<AxiosResponse<{data: IGetCropInterface[] | []}>> {
    return ApiService.api.get(`/content/crop?page=${page}&count=${count}`);
  }

  static async getCropById({ id }: { id: string }): Promise<AxiosResponse<{data: IGetCropApiResponseInterface }>> {
    return ApiService.api.get(`/crop/${id}`);
  }

  static async getNutrition(): Promise<AxiosResponse<{data: IGetNutritionInterface | []}>> {
    return ApiService.api.get('/content/nutrition');
  }

  static async getFlavor(): Promise<AxiosResponse<{data: IGetFlavorInterface | []}>> {
    return ApiService.api.get('/content/flavor');
  }

  static async uploadCropArticle(payload: FormData): Promise<AxiosResponse<ISetCropInterface>> {
    return ApiService.api.post<ISetCropInterface>('/crop', payload);
  }

  static async updateCropArticle(payload: FormData): Promise<AxiosResponse<ISetCropInterface>> {
    return ApiService.api.put<ISetCropInterface>(`/crop/${payload.get('uuid')}`, payload);
  }

  static async deleteCropArticle(id: string): Promise<AxiosResponse<ISetCropInterface>> {
    return ApiService.api.delete<ISetCropInterface>(`/crop/${id}`);
  }


  static async getFaq({ page, count }: { page: number, count: number }): Promise<AxiosResponse<{data: IGetFaqApiResponseType[] | []}>> {
    return ApiService.api.get(`/content/faq?page=${page}&count=${count}`);
  }

  static async deleteFaq(id: string): Promise<AxiosResponse<string>> {
    return ApiService.api.delete<string>(`/content/faq/${id}`);
  }

  static async setFaq({id, faq}: {id: string, faq: IGetFaqApiResponseType}): Promise<AxiosResponse<string>> {
    return ApiService.api.put<string>(`/content/faq/${id}`, faq);
  }

  static async addFaq({faq}: {faq: IGetFaqApiResponseType}): Promise<AxiosResponse<string>> {
    return ApiService.api.post<string>(`/content/faq`, faq);
  }


  static async getAbout({ page, count }: { page: number, count: number }): Promise<AxiosResponse<{data: IGetAboutType[] | []}>> {
    return ApiService.api.get(`/content/about?page=${page}&count=${count}`);
  }

  static async deleteAbout(id: string): Promise<AxiosResponse<string>> {
    return ApiService.api.delete<string>(`/content/about/${id}`);
  }

  static async setAbout(faq: IGetAboutType): Promise<AxiosResponse<string>> {
    return ApiService.api.put<string>(`/content/about/${faq.uuid}`, faq);
  }

  static async addAbout({faq}: {faq: IGetAboutType}): Promise<AxiosResponse<string>> {
    return ApiService.api.post<string>(`/content/about`, faq);
  }
}