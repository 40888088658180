import React from 'react';
import { StyledSwitch, CustomInput } from './styles';

export interface SwitchProps {
  title: string;
  text: string;
  value: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => void;
}

const KitSwitch = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ title, value, text, onChange }, ref) => {
    return (
      <CustomInput
        title={title}
        disabledInput
        value={text}
        ref={ref}
        RightElement={<StyledSwitch onChange={onChange} checked={value} />}
      />
    );
  }
);

export default React.memo(KitSwitch);
