import React from 'react';
import { Icon } from '@plant/data';

const TempIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.83333 2.42857C8.83333 1.0868 7.78916 0 6.5 0C5.21084 0 4.16667 1.0868 4.16667 2.42857V10.6599C3.455 11.327 3 12.2825 3 13.3571C3 15.369 4.56697 17 6.5 17C8.43303 17 10 15.3691 10 13.3571C10 12.2825 9.545 11.3271 8.83333 10.6599V2.42857ZM7.66667 3.64286H6.5V4.85714H7.66667V6.07143H6.5V7.28571H7.66667V8.5H5.33333V2.42857C5.33333 1.75844 5.85615 1.21429 6.5 1.21429C7.14385 1.21429 7.66667 1.75844 7.66667 2.42857V3.64286Z" />
  </svg>
);

export default React.memo(TempIcon);
