import styled, { css } from 'styled-components';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const StyledFaqListContainer = styled.div`
  margin-top: 20px;
  border-radius: 15px;
  height: 90%;
  max-height: 730px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const StyledAccordion = styled(Accordion)`
  margin: 12px 0 !important;
  padding: 0 !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none !important;
  overflow: hidden !important;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  & > .MuiAccordionSummary-content {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto 70px 70px 20px;
    padding-right: 20px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  font-weight: 600;
  white-space: pre-wrap;
  ${props => ({
    color: props.theme.palette.secondary.dark,
  })}
`

export const SummaryTitle = styled.h3`
  font-size: 17px;
`


export const OpenCloseButton = styled.button`
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => ({
    background: props.theme.palette.primary.main,
  })}
`

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin: 0 !important;
  padding: 0 !important;
`;

export const DeployButton = styled.button<{
  active?: boolean;
}>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  ${props => ({
    background: props.active ? props.theme.palette.secondary.dark : props.theme.palette.primary.light,
    color: props.active ? props.theme.palette.light.main : props.theme.palette.error.main,
  })}
`

export const EditPoPupWrapper = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
`;


export const ContentWrapper = styled.div`
`;

export const ContentTittle = styled.h3`
  font-weight: 800;
  font-size: 15px;
`

export const ContentText = styled.p`
  margin: 15px 0 25px;
  font-weight: 300;
`
