import * as yup from 'yup';

export const passRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
const passMinLength = 8;

export const getPasswordSchema = ({ length = passMinLength, passReg = passRegExp } = {}) =>
  yup
    .string()
    .min(length, `Password must be at least ${length} characters`)
    .required('Password is required!')
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .matches(
      passReg,
      `Must Contain at least ${length} Characters, One Uppercase, One Lowercase, One Number`,
    );

export const loginSchema = yup.object().shape({
  email: yup.string().email('Please enter correct email address').required('Email is required!'),
  password: getPasswordSchema(),
  
});

export const codeSchema = yup.object().shape({
  code: yup
    .string()
    // .required('Code is required!')
    // .matches(/^(0|[1-9]\d*)$/, 'Code must contain only numbers')
})
