import React from 'react';
import { Icon } from '@plant/data';

const RemoveIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.71087 0H13.2891C13.6719 0 14 0.433693 14 0.86741V1.87938C14 2.38536 13.6719 2.74679 13.2891 2.74679H12.8517L12.0861 10.7712C11.9768 11.4217 11.6487 12 11.1565 12H2.95283C2.406 12 2.07791 11.4217 2.02323 10.7712L1.14832 2.74679H0.71087C0.328103 2.74679 0 2.38538 0 1.87938V0.86741C0 0.433716 0.328086 0 0.71087 0ZM3.64645 2.64645C3.84171 2.45118 4.15829 2.45118 4.35355 2.64645L7 5.29289L9.64645 2.64645C9.84171 2.45118 10.1583 2.45118 10.3536 2.64645C10.5488 2.84171 10.5488 3.15829 10.3536 3.35355L7.70711 6L10.3536 8.64645C10.5488 8.84171 10.5488 9.15829 10.3536 9.35355C10.1583 9.54882 9.84171 9.54882 9.64645 9.35355L7 6.70711L4.35355 9.35355C4.15829 9.54882 3.84171 9.54882 3.64645 9.35355C3.45118 9.15829 3.45118 8.84171 3.64645 8.64645L6.29289 6L3.64645 3.35355C3.45118 3.15829 3.45118 2.84171 3.64645 2.64645Z"
    />
  </svg>
);

export default React.memo(RemoveIcon);
