export enum OrderVariants {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderVariantsType = {none: null, asc: OrderVariants.Asc, desc: OrderVariants.Desc}


export const ORDER_VARIANTS: OrderVariantsType = {
  none: null,
  asc: OrderVariants.Asc,
  desc: OrderVariants.Desc,
}

export enum StatusButtonsStatuses {
  Offline = 'offline',
  Online = 'online'
}

export enum CurrentSearchField {
  FirstName = 'first_name',
  Email = 'email'
}

