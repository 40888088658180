import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Events, LOCAL_STORAGE_KEYS } from '../constants';
import { AuthService } from './auth';

export class ApiService {
  static api: AxiosInstance;
  static interceptor: number;
  static emitter: EventTarget = new EventTarget();

  static getToken(token: string): void {
    ApiService.api.interceptors.request.eject(ApiService.interceptor);

    ApiService.interceptor = ApiService.api.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (token) {
          (config.headers ??= {})['Authorization'] = `Bearer ${token}`;
          (config.headers ??= {})['token'] = token;
        }
        return config;
      },
    );
  }
  static init(baseUrl: string, failedCallback?: () => void): void {
    ApiService.api = axios.create({
      baseURL: baseUrl,
    });

    ApiService.api.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;

        if (error.response.status === 401 && originalRequest.headers.refreshtoken) {
          ApiService.emitter.dispatchEvent(Events.logout);
        }

        if (
          error.response.status === 401 &&
          !originalRequest._retry &&
          !originalRequest.headers.refreshtoken
        ) {
          originalRequest._retry = true;
          try {
            const tokens = await AuthService.refreshToken();
            if (tokens) {
              ApiService.getToken(tokens.token);
              localStorage.setItem(LOCAL_STORAGE_KEYS.token, tokens.token);
              localStorage.setItem(LOCAL_STORAGE_KEYS.refreshToken, tokens.refreshToken);
              return ApiService.api(originalRequest);
            }
          } catch (error) {
            if (failedCallback) failedCallback();
          }
        }
        return Promise.reject(error);
      },
    );
  }
}
