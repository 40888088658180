import { combineReducers } from '@reduxjs/toolkit';
import 'react-redux';
import * as fromAuth from './auth';
import * as fromStartApp from './app';
import * as fromUsers from './users';
import * as fromNotifications from './notifications';
import * as fromContent from './content';
import * as fromPushNotifications from './pushNotifications';
import * as fromSoftware from './software';

export interface AppState {
  auth: fromAuth.State;
  app: fromStartApp.State;
  users: fromUsers.State;
  notifications: fromNotifications.State;
  content: fromContent.State;
  pushNotifications: fromPushNotifications.State;
  software: fromSoftware.State;
}

export const rootReducer = combineReducers<AppState>({
  auth: fromAuth.reducer,
  app: fromStartApp.reducer,
  users: fromUsers.reducer,
  notifications: fromNotifications.reducer,
  content: fromContent.reducer,
  pushNotifications: fromPushNotifications.reducer,
  software: fromSoftware.reducer,
});
