import { FC } from 'react';
import { StyledSpinner } from './style';

type PropsType = {
  top?: string
  left?: string
  right?: string
  bottom?: string
  width?: string
  height?: string
  owncolor?: string
}

export const Spinner: FC<PropsType> = ({
  top,
  left,
  right,
  bottom,
  width,
  height,
  owncolor
}) => (
  <StyledSpinner
    top={top}
    left={left}
    right={right}
    bottom={bottom}
    width={width}
    height={height}
    owncolor={owncolor}
  />
);