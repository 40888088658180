import styled from "styled-components"
import Popover from '@mui/material/Popover';

export const PodWrapper = styled.div`
  ${props => ({
   border: `1px solid ${props.theme.palette.primary.dark}`
  })}  
  width: 90px;
  height: 90px;
  border-radius: 50%;
  padding: 10px;
`

export const PodPlateBorder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 3px;
  
  ${props => ({
    background: `linear-gradient(${props.theme.palette.light.light}, ${props.theme.palette.light.main})`,
    boxShadow: `0px 4px 10px 3px ${props.theme.palette.light.light}`
  })}
`
export const PodPlate = styled.div`
  width: 100%;
  height: 100%;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PodText = styled.span`
  font-weight: 600;
  ${props => ({
    color: props.theme.palette.secondary.light
  })}
`

export const PodImage = styled.img`
  width: 100%;
  height: 100%;
`

export const PlantInfoContainer = styled.div`
  margin: 0 !important;
  display: flex;
`
export const PlantAgeTitle = styled.p`
  font-weight: 600;
`

export const PlantAgeValue = styled.span`
  font-weight: 400;
`

export const StyledPopOver = styled(Popover)`
  pointer-events: none;
  & > .MuiPaper-root {
    border-radius: 25px;
    overflow: hidden;
  }
`

export const StyledPopOverText = styled.div<{
  fColor?: string
  bgColor?: string
  align?: 'center' | 'left' | 'right'
}>`
  padding: 5px 10px;
  overflow: hidden;
  font-size: 14px;
  overflow: hidden;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-weight: 500;
  display: flex;
  flex-direction: column;
  ${props => ({
    color: props.fColor,
    background: props.bgColor,
    alignItems: 
      props.align === 'center' ? 'center' :
      props.align === 'left' ? 'flex-start' :
      props.align === 'right' && 'flex-end'
  })}
  & > p {
    margin: 5px;
  }
`