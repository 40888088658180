import React from 'react';
import { Icon } from '@plant/data';

const LightIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3185 5.62949C13.1702 5.62949 15.4802 7.99939 15.4802 10.9245C15.4802 12.9529 14.3695 14.7118 12.7392 15.6018V17.651L7.90098 17.6501V15.6009C6.26992 14.7119 5.15674 12.9528 5.15674 10.9245C5.15674 7.99919 7.46937 5.62951 10.3185 5.62951V5.62949Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.90088 18.9072H12.7391V20.1887H11.1647V21.0726H9.47535V20.1887H7.9009L7.90088 18.9072Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3184 0C10.6411 0 10.9029 0.271216 10.9029 0.599594V1.79878C10.9029 2.12718 10.6411 2.39838 10.3209 2.39838C9.99826 2.39838 9.73389 2.12716 9.73389 1.79878V0.599594C9.73389 0.271194 9.99828 0 10.3184 0Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.5148 11.59C20.5148 11.9184 20.2504 12.1896 19.9303 12.1896H18.7613C18.4411 12.1896 18.1768 11.9184 18.1768 11.59C18.1768 11.2616 18.4386 10.9904 18.7613 10.9904H19.9303C20.2504 10.9904 20.5148 11.2616 20.5148 11.59Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.46486 11.59C2.46486 11.9184 2.20047 12.1896 1.88035 12.1896H0.710481C0.390345 12.1896 0.125977 11.9184 0.125977 11.59C0.125977 11.2616 0.387815 10.9904 0.710481 10.9904H1.87949C2.20047 10.9904 2.46484 11.2616 2.46484 11.59H2.46486Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.1214 4.74819C3.86462 4.94835 3.4955 4.9007 3.30037 4.63815L2.58494 3.68849C2.38982 3.42768 2.43628 3.04903 2.68968 2.84975C2.94646 2.64699 3.31557 2.69725 3.5107 2.95719L4.22614 3.90685C4.42126 4.1668 4.37565 4.54545 4.1214 4.74821V4.74819Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.519 4.74819C16.7758 4.94835 17.1424 4.9007 17.3401 4.63815L18.053 3.68849C18.2506 3.42768 18.2042 3.04903 17.9482 2.84975C17.694 2.64699 17.3249 2.69725 17.1272 2.95719L16.4143 3.90685C16.2192 4.1668 16.2648 4.54545 16.519 4.74821V4.74819Z"
    />
  </svg>
);

export default React.memo(LightIcon);
