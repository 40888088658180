import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import { 
  IGetNutritionInterface, 
  IGetFlavorInterface, 
  IGetFaqType,
  ErrorPayload,
  IGetCropInterface,
  IGetAboutType
} from '@plant/data';

export enum ContentTypes {
  GetListOfFaqs = '[Content] GetListOfFaqs',
  GetListOfFaqsSuccess = '[Content] GetListOfFaqsSuccess',
  GetListOfFaqsFailed = '[Content] GetListOfFaqsFailed',

  LoadMoreFaqs = '[Content] LoadMoreFaqs',
  LoadMoreFaqsSuccess = '[Content] LoadMoreFaqsSuccess',
  LoadMoreFaqsFailed = '[Content] LoadMoreFaqsFailed',

  DeleteFaqById = '[Content] DeleteFaqById',
  DeleteFaqByIdSuccess = '[Content] DeleteFaqByIdSuccess',
  DeleteFaqByIdFailed = '[Content] DeleteFaqByIdFailed',

  SetFaqById = '[Content] SetFaqById',
  SetFaqByIdSuccess = '[Content] SetFaqByIdSuccess',
  SetFaqByIdFailed = '[Content] SetFaqByIdFailed',

  AddFaqById = '[Content] AddFaqById',
  AddFaqByIdSuccess = '[Content] AddFaqByIdSuccess',
  AddFaqByIdFailed = '[Content] AddFaqByIdFailed',

  GetListOfAbout = '[Content] GetListOfAbout',
  GetListOfAboutSuccess = '[Content] GetListOfAboutSuccess',
  GetListOfAboutFailed = '[Content] GetListOfAboutFailed',

  LoadMoreAbout = '[Content] LoadMoreAbout',
  LoadMoreAboutSuccess = '[Content] LoadMoreAboutSuccess',
  LoadMoreAboutFailed = '[Content] LoadMoreAboutFailed',

  DeleteAboutById = '[Content] DeleteAboutById',
  DeleteAboutByIdSuccess = '[Content] DeleteAboutByIdSuccess',
  DeleteAboutByIdFailed = '[Content] DeleteAboutByIdFailed',

  SetAboutById = '[Content] SetAboutById',
  SetAboutByIdSuccess = '[Content] SetAboutByIdSuccess',
  SetAboutByIdFailed = '[Content] SetAboutByIdFailed',

  AddAboutById = '[Content] AddAboutById',
  AddAboutByIdSuccess = '[Content] AddAboutByIdSuccess',
  AddAboutByIdFailed = '[Content] AddAboutByIdFailed',

  GetListOfCrops = '[Content] GetListOfCrops',
  GetListOfCropsSuccess = '[Content] GetListOfCropsSuccess',
  GetListOfCropsFailed = '[Content] GetListOfCropsFailed',

  LoadMoreCropsCrops = '[Content] LoadMoreCropsCrops',
  LoadMoreCropsSuccess = '[Content] LoadMoreCropsSuccess',
  LoadMoreCropsFailed = '[Content] LoadMoreCropsFailed',

  GetCropById = '[Content] GetCropById',
  GetCropByIdSuccess = '[Content] GetCropByIdSuccess',
  GetCropByIdFailed = '[Content] GetCropByIdFailed',

  GetCropToChangeById = '[Content] GetCropToChangeById',
  GetCropToChangeByIdSuccess = '[Content] GetCropToChangeByIdSuccess',
  GetCropToChangeByIdFailed = '[Content] GetCropToChangeByIdFailed',

  DeleteCropBuId = '[Content] DeleteCropBuId',
  DeleteCropBuIdSuccess = '[Content] DeleteCropBuIdSuccess',
  DeleteCropBuIdFailed = '[Content] DeleteCropBuIdFailed',

  GetNutrition = '[Content] GetNutrition',
  GetNutritionSuccess = '[Content] GetNutritionSuccess',
  GetNutritionFailed = '[Content] GetNutritionFailed',

  GetFlavor = '[Content] GetFlavor',
  GetFlavorSuccess = '[Content] GetFlavorSuccess',
  GetFlavorFailed = '[Content] GetFlavorFailed',

  UploadCropArticle = '[Content] UploadCropArticle',
  UploadCropArticleSuccess = '[Content] UploadCropArticleSuccess',
  UploadCropArticleFailed = '[Content] UploadCropArticleFailed',

  UpdateCropArticle = '[Content] UpdateCropArticle',
  UpdateCropArticleSuccess = '[Content] UpdateCropArticleSuccess',
  UpdateCropArticleFailed = '[Content] UpdateCropArticleFailed',

  ChangeCropArticleStatus = '[Content] ChangeCropArticleStatus',
  ChangeCropArticleStatusSuccess = '[Content] ChangeCropArticleStatusSuccess',
  ChangeCropArticleStatusFailed = '[Content] ChangeCropArticleStatusFailed',

  SetAboutForm = '[Content] SetAboutForm',

  AddObjectToAboutForm = '[Content] AddObjectToAboutForm',

  DeleteObjectFromAboutForm = '[Content] DeleteObjectFromAboutForm',

  ClearAboutForm = '[Content] ClearAboutForm',

  SetAboutFormSubject = '[Content] SetAboutFormSubject',

  SetAboutFormContent = '[Content] SetAboutFormContent',

  PrepareToDeployAboutFormContent = '[Content] PrepareToDeployAboutFormContent',

  SwitchContentTab = '[Content] SwitchContentTab',
   
  ResetUploadIsFinished = '[Content] ResetUploadIsFinished',

  ChangeCropToSetField = '[Content] ChangeCropToSetField',

  SelectOptions = '[Content] SelectOptions',

  ClearNewCrop = '[Content] ClearNewCrop',

  ResetContent = '[Content] ResetContent'
}

export const getListOfFaqsAction = createAsyncAction(
  ContentTypes.GetListOfFaqs,
  ContentTypes.GetListOfFaqsSuccess,
  ContentTypes.GetListOfFaqsFailed
)<{ page?: number | string, count?: number | string, uuid?: string }, { data: IGetFaqType[] | [] }, { error: string}>();

export const loadMoreFaqsAction = createAsyncAction(
  ContentTypes.LoadMoreFaqs,
  ContentTypes.LoadMoreFaqsSuccess,
  ContentTypes.LoadMoreFaqsFailed
)<{ page?: number | string, count?: number | string, uuid?: string }, { data: IGetFaqType[] | [] }, { error: string}>();

export const deleteFaqByIdAction = createAsyncAction(
  ContentTypes.DeleteFaqById,
  ContentTypes.DeleteFaqByIdSuccess,
  ContentTypes.DeleteFaqByIdFailed
)<{id: string}, string, { error: string}>();

export const setFaqByIdAction = createAsyncAction(
  ContentTypes.SetFaqById,
  ContentTypes.SetFaqByIdSuccess,
  ContentTypes.SetFaqByIdFailed
)<{id: string, faq: IGetFaqType}, string, ErrorPayload>();

export const addFaqByIdAction = createAsyncAction(
  ContentTypes.AddFaqById,
  ContentTypes.AddFaqByIdSuccess,
  ContentTypes.AddFaqByIdFailed
)<{faq: IGetFaqType}, void, ErrorPayload>();


export const getListOfAboutAction = createAsyncAction(
  ContentTypes.GetListOfAbout,
  ContentTypes.GetListOfAboutSuccess,
  ContentTypes.GetListOfAboutFailed
)<{ page?: number | string, count?: number | string, uuid?: string }, { data: IGetAboutType[] | [] }, { error: string}>();

export const loadMoreAboutAction = createAsyncAction(
  ContentTypes.LoadMoreAbout,
  ContentTypes.LoadMoreAboutSuccess,
  ContentTypes.LoadMoreAboutFailed
)<{ page?: number | string, count?: number | string, uuid?: string }, { data: IGetAboutType[] | [] }, { error: string}>();

export const deleteAboutByIdAction = createAsyncAction(
  ContentTypes.DeleteAboutById,
  ContentTypes.DeleteAboutByIdSuccess,
  ContentTypes.DeleteAboutByIdFailed
)<{id: string}, string, { error: string}>();

export const setAboutByIdAction = createAsyncAction(
  ContentTypes.SetAboutById,
  ContentTypes.SetAboutByIdSuccess,
  ContentTypes.SetAboutByIdFailed
)<{faq: IGetAboutType, successCallback: () => void}, string, ErrorPayload>();

export const addAboutByIdAction = createAsyncAction(
  ContentTypes.AddAboutById,
  ContentTypes.AddAboutByIdSuccess,
  ContentTypes.AddAboutByIdFailed
)<{faq: IGetAboutType, successCallback: () => void}, void, ErrorPayload>();


export const getListOfCropsAction = createAsyncAction(
  ContentTypes.GetListOfCrops,
  ContentTypes.GetListOfCropsSuccess,
  ContentTypes.GetListOfCropsFailed
)<{ page?: number | string, count?: number | string, uuid?: string }, { data: IGetCropInterface[] | [] }, { error: string}>();

export const loadMoreCropsCropsAction = createAsyncAction(
  ContentTypes.LoadMoreCropsCrops,
  ContentTypes.LoadMoreCropsSuccess,
  ContentTypes.LoadMoreCropsFailed
)<{ page?: number | string, count?: number | string, uuid?: string }, { data: IGetCropInterface[] | [] }, { error: string}>();

export const getCropByIdAction = createAsyncAction(
  ContentTypes.GetCropById,
  ContentTypes.GetCropByIdSuccess,
  ContentTypes.GetCropByIdFailed
)<{id: string}, IGetCropInterface, { error: string}>();

export const getCropToChangeByIdAction = createAsyncAction(
  ContentTypes.GetCropToChangeById,
  ContentTypes.GetCropToChangeByIdSuccess,
  ContentTypes.GetCropToChangeByIdFailed
)<{id: string}, IGetCropInterface, { error: string}>();

export const deleteCropByIdAction = createAsyncAction(
  ContentTypes.DeleteCropBuId,
  ContentTypes.DeleteCropBuIdSuccess,
  ContentTypes.DeleteCropBuIdFailed
)<{id: string}, void, { error: string}>();

export const getNutritionAction = createAsyncAction(
  ContentTypes.GetNutrition,
  ContentTypes.GetNutritionSuccess,
  ContentTypes.GetNutritionFailed
)<void, { data: IGetNutritionInterface | [] }, { error: string}>();

export const getFlavorAction = createAsyncAction(
  ContentTypes.GetFlavor,
  ContentTypes.GetFlavorSuccess,
  ContentTypes.GetFlavorFailed
)<void, { data: IGetFlavorInterface | [] }, { error: string}>();

export const uploadCropArticleAction = createAsyncAction(
  ContentTypes.UploadCropArticle,
  ContentTypes.UploadCropArticleSuccess,
  ContentTypes.UploadCropArticleFailed
)<FormData, void, { error: string}>();

export const updateCropArticleAction = createAsyncAction(
  ContentTypes.UpdateCropArticle,
  ContentTypes.UpdateCropArticleSuccess,
  ContentTypes.UpdateCropArticleFailed
)<FormData, void, { error: string}>();

export const changeCropArticleStatusAction = createAsyncAction(
  ContentTypes.ChangeCropArticleStatus,
  ContentTypes.ChangeCropArticleStatusSuccess,
  ContentTypes.ChangeCropArticleStatusFailed
)<FormData, { id: string }, {
  message: string; error: string
}>();

export const switchContentTabAction = createAction(ContentTypes.SwitchContentTab)<{
  tab: 'flavor' | 'nutrition',
  id: string
}>();

export const setAboutFormAction = createAction(ContentTypes.SetAboutForm)<IGetAboutType>();

export const addObjectToAboutFormAction = createAction(ContentTypes.AddObjectToAboutForm)();

export const deleteObjectFromAboutFormAction = createAction(ContentTypes.DeleteObjectFromAboutForm)<number>();

export const clearAboutFormAction = createAction(ContentTypes.ClearAboutForm)();

export const setAboutFormSubjectAction = createAction(ContentTypes.SetAboutFormSubject)<string>();

export const setAboutFormContentAction = createAction(ContentTypes.SetAboutFormContent)<{ value: string, id: string, index: number }>();

export const prepareToDeployAboutFormContentAction = createAction(ContentTypes.PrepareToDeployAboutFormContent)<IGetAboutType>();

export const resetUploadIsFinishedAction = createAction(ContentTypes.ResetUploadIsFinished)();

export const changeCropToSetFieldActionAction = createAction(ContentTypes.ChangeCropToSetField)<{fieldName: string, value: string}>();

export const selectOptionsAction = createAction(ContentTypes.SelectOptions)<{fieldName: 'flavorUuids' | 'nutritionUuids', value: string}>();

export const clearNewCropAction = createAction(ContentTypes.ClearNewCrop)();

export const resetContentActionAction = createAction(ContentTypes.ResetContent)();

export type ContentActionUnion = ActionType<
  | typeof getListOfCropsAction
  | typeof loadMoreFaqsAction
  | typeof deleteFaqByIdAction
  | typeof setFaqByIdAction
  | typeof addFaqByIdAction
  | typeof loadMoreCropsCropsAction

  | typeof getListOfAboutAction
  | typeof loadMoreAboutAction
  | typeof deleteAboutByIdAction
  | typeof addAboutByIdAction
  | typeof setAboutByIdAction 

  | typeof getCropByIdAction
  | typeof getCropToChangeByIdAction 
  | typeof deleteCropByIdAction
  | typeof getListOfFaqsAction
  | typeof getNutritionAction
  | typeof getFlavorAction
  | typeof uploadCropArticleAction
  | typeof updateCropArticleAction
  | typeof changeCropArticleStatusAction
  | typeof setAboutFormAction
  | typeof addObjectToAboutFormAction
  | typeof clearAboutFormAction
  | typeof deleteObjectFromAboutFormAction
  | typeof setAboutFormSubjectAction
  | typeof setAboutFormContentAction
  | typeof prepareToDeployAboutFormContentAction
  | typeof switchContentTabAction
  | typeof resetUploadIsFinishedAction
  | typeof changeCropToSetFieldActionAction
  | typeof clearNewCropAction
  | typeof resetContentActionAction
  | typeof selectOptionsAction
>