import React from 'react';
import { Icon } from '@plant/data';

const AddToCartIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6947 8.66377L13.1973 6.50941C13.2995 6.07165 13.6897 5.76196 14.1393 5.76196H15.8319C16.3661 5.76196 16.7991 6.19501 16.7991 6.72919C16.7991 7.26337 16.3661 7.69641 15.8319 7.69641H14.9068L13.6467 13.0834L13.0522 16.6508C12.9864 17.0456 12.6102 17.3102 12.2164 17.2386L2.73187 15.5141C2.46318 15.4653 2.24554 15.2681 2.17052 15.0055L0.619831 9.57807C0.488917 9.11988 0.832961 8.66377 1.30949 8.66377H12.6947Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6995 19.3931C12.6995 20.0722 12.1489 20.6227 11.4698 20.6227C10.7908 20.6227 10.2402 20.0722 10.2402 19.3931C10.2402 18.714 10.7908 18.1635 11.4698 18.1635C12.1489 18.1635 12.6995 18.714 12.6995 19.3931ZM5.3225 19.3933C5.3225 20.0724 4.77198 20.6229 4.09289 20.6229C3.4138 20.6229 2.86328 20.0724 2.86328 19.3933C2.86328 18.7142 3.4138 18.1637 4.09289 18.1637C4.77198 18.1637 5.3225 18.7142 5.3225 19.3933Z"
      />
      <path
        opacity="0.5"
        d="M6.9971 6.12547C7.03378 6.15682 7.09321 6.15682 7.12989 6.12547L9.90819 3.7511C9.96762 3.70094 9.9258 3.61442 9.84216 3.61442H8.19073V0.48455C8.19073 0.440035 8.14891 0.404297 8.09683 0.404297H6.03089C5.97881 0.404297 5.93699 0.440035 5.93699 0.48455V3.61442H4.28556C4.20192 3.61442 4.16011 3.70094 4.2188 3.75173L6.9971 6.12547Z"
      />
    </svg>
  );
};

export default React.memo(AddToCartIcon);
