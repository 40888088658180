import React, { useEffect, useRef, useState } from 'react';
import * as Styled from './style';
import { ArrowIcon } from '../assets';
import { Input } from '../Input';

export interface SelectProps {
  title: string;
  data: Item[];
  value: string;
  onChange: (name: string) => void;
  withBorder?: boolean;
  withoutShadow?: boolean;
  disabled?: boolean;
  clearItem?: string;
  withSearch?: boolean;
}

interface Item {
  name: string;
  uuid?: string;
}

const KitSelect = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      title,
      value,
      data,
      onChange,
      clearItem,
      disabled = false,
      withBorder = false,
      withoutShadow = false,
      withSearch = false,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (withSearch && isOpen === true) {
        searchRef?.current?.focus();
      }

      if (withSearch && isOpen === false) {
        setSearchValue('');
      }
    }, [isOpen]);

    const handleToggle = () => !disabled && setIsOpen((value) => !value);

    const handleClear = (e: React.SyntheticEvent<HTMLDivElement>) => {
      e.stopPropagation();
      handleToggle();
      onChange('');
    };

    const handleSelect = (item: Item) => (e: React.SyntheticEvent<HTMLDivElement>) => {
      e.stopPropagation();
      handleToggle();
      onChange(item.uuid ? item.uuid : item.name);
    };

    const handleSearchValueChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
      if (withSearch) setSearchValue(e.currentTarget.value);
    };

    return (
      <>
        <Styled.Wrapper onClick={handleToggle} disabled={disabled}>
          <Input
            title={title}
            disabledInput={!isOpen}
            value={value}
            ref={ref}
            withBorder={withBorder}
            withoutShadow={withoutShadow}
            RightElement={
              <Styled.ArrowWrapper isOpen={isOpen}>
                <ArrowIcon width={9} height={5} />
              </Styled.ArrowWrapper>
            }
          />
          {withSearch && (
            <Styled.HiddenInput
              ref={searchRef}
              value={searchValue}
              onChange={handleSearchValueChange}
            />
          )}
          {isOpen && (
            <Styled.ListContainer>
              {clearItem && <Styled.ListItem onClick={handleClear}>{clearItem}</Styled.ListItem>}
              {data
                .filter((item) => item.name.includes(searchValue))
                .map((item) => {
                  return (
                    <Styled.ListItem key={item.uuid || item.name} onClick={handleSelect(item)}>
                      {item.name}
                    </Styled.ListItem>
                  );
                })}
            </Styled.ListContainer>
          )}
        </Styled.Wrapper>
        {isOpen && <Styled.Backdrop onClick={handleToggle} />}
      </>
    );
  },
);

export default React.memo(KitSelect);
