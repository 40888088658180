import { Led } from './led';

export interface Settings {
  timezone: string;
  led: Led[];
  fan: {
    state: number;
    timestamp: string;
  }[];
  waterPump: {
    state: number;
    timestamp: string;
  }[];
  airPump: {
    state: number;
    timestamp: string;
  }[];
  ventile: {
    state: number;
    timestamp: string;
  }[];
  environment: {
    uuid: string;
    active: boolean;
    name: string;
    ledLevel: number;
    nutritionLevel: number;
    vpaLevel: number;
  };
}
