import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledVideo = styled.video`
  width: 100%;
`

export const StyledVideoWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  &:hover {
    & > .controls {
      transform: translateY(0%);
    }
  }
`

export const StyledVideoControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
`

export const StyledVideoActions = styled.div`
  & > button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    & > i {
      background-color: none;
      color: white;
      font-size: 30px;
    }
  }
`

export const StyledInput = styled.input`
    -webkit-appearance: none !important;
    border-radius: 20px;
    height: 3px;
    width: 350px;
    ${props => ({
      background: props.theme.palette.primary.main
    })}
    &::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      cursor: pointer;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      ${props => ({
        background: `${props.theme.palette.secondary.main} !important`
      })}
    }
    &::-moz-range-progress {
      background: white;
    }
`

export const MuteBTN = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  & > i {
    background-color: none;
    color: white;
    font-size: 20px;
  }
`