import { FC } from 'react'
import * as Styled from './style'

type PropsType = {
  iconSrc: string
  title: string
  value: string
}

export const EnvInfoContainer: FC<PropsType> = ({
  iconSrc,
  title,
  value
}) => {
  return (
    <Styled.EnvInfoContainerWrapper>
      <Styled.EnvInfoContainerWrapperImg src={iconSrc} alt="logo"/>
      <Styled.EnvInfoContainerWrapperValue>
        {value}
      </Styled.EnvInfoContainerWrapperValue>
      <Styled.EnvInfoContainerWrapperTitle>
        {title}
      </Styled.EnvInfoContainerWrapperTitle>
    </Styled.EnvInfoContainerWrapper>
  )
}