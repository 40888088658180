import { FC, useEffect, useState } from 'react';
import { DashBoardLayout } from '../../layouts/Dashboard';
import * as Styled from './style';
import { AutocompleteInput } from '../../components/AutocompleteInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUsersEmailsAction,
  setInputValueAction,
  selectEmailTagAction,
  setSubjectAction,
  setEmailContentAction,
  resetUsersEmailsAction,
  selectAllEmailsAction,
  sendEmailToUsersAction,
} from '../../store/actions/notifications';
import {
  usersSelector,
  selectedEmailTagsSelector,
  isEmailsLoadingSelector,
  subjectSelector,
  emailContentSelector,
  inputValueSelector,
  selectedAllEmailSelector,
  isSendingEmailsSelector,
} from '../../store/selectors/notifications';
import { TextEditor } from '../../components/TextEditor';
import { Button } from '../../components/Button';
import { ISendEmailsToUsersRequest } from '@plant/data';
import { LoadingScreen } from '../../components/LoadingScreen';
import PushNotificationsHeader from './components/PushNotificationsHeader';
import PushNotificationsForm from './components/PushNotificationsForm';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

interface CustomState {
  pushNotification: boolean;
}

export const DashboardNotificationsPage: FC = (): JSX.Element => {
  const locations = useLocation();
  const state = locations.state as CustomState;
  const pushNotification = state?.pushNotification;
  const [currentTab, setCurrentTab] = useState<'Email' | 'Notifications'>(
    pushNotification ? 'Notifications' : 'Email',
  );
  const [expanded, setExpanded] = useState(false);

  const users = useSelector(usersSelector);
  const searchInputValue = useSelector(inputValueSelector);
  const selectedEmailTags = useSelector(selectedEmailTagsSelector);
  const isEmailsLoading = useSelector(isEmailsLoadingSelector);
  const subject = useSelector(subjectSelector);
  const emailContent = useSelector(emailContentSelector);
  const selectedAllEmail = useSelector(selectedAllEmailSelector);
  const isSendingEmails = useSelector(isSendingEmailsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchInputValue) {
      dispatch(
        getUsersEmailsAction.request({
          query: searchInputValue,
          order: 'ASC',
          orderField: 'email',
          page: 1,
          count: 10,
        }),
      );
    }
  }, [dispatch, searchInputValue]);

  const onAccordionMenuClickHandle = () => {
    setExpanded(!expanded);
  };

  const onMenuItemClick = (key: 'Email' | 'Notifications') => () => {
    if (key !== currentTab) {
      setCurrentTab(key);
    }
    setExpanded(false);
  };

  const onInputChangeHandler = (val: string) => {
    dispatch(setInputValueAction(val));
  };

  const onEmailTagClickHandler = (user: { email: string; uuid: string }) => {
    dispatch(selectEmailTagAction(user));
    if (searchInputValue) {
      dispatch(setInputValueAction(''));
    }
  };

  const onSubjectInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    dispatch(setSubjectAction(value));
  };

  const onChangeEmailContent = (content: string) => {
    dispatch(setEmailContentAction(content));
  };

  const onClearEmailState = () => {
    dispatch(resetUsersEmailsAction());
  };

  const onSelectAllCheckBox = () => {
    dispatch(selectAllEmailsAction());
  };

  const isValidEmailFields = () => {
    console.log('here')
    return (!!selectedEmailTags.length || selectedAllEmail) && !!emailContent && !!subject;
  };

  const onSendButtonClick = () => {
    const payload: ISendEmailsToUsersRequest = {
      emails: !selectedAllEmail ? selectedEmailTags.map((user) => user.email) : [],
      html: emailContent,
      subject: subject,
      selectedAll: selectedAllEmail,
    };
    const isValid = isValidEmailFields();
    if (isValid) {
      dispatch(sendEmailToUsersAction.request(payload));
    } else {
      toast('All fields are required', { type: 'error' })
    }
  };

  return (
    <DashBoardLayout rootPage currentPage="notifications">
      {!isSendingEmails ? (
        <Styled.NotificationWrapper>
          <Styled.TopContainer>
            <Styled.AccordionMenuSummary>
              <Styled.AccordionMenu expanded={expanded} onClick={onAccordionMenuClickHandle}>
                <Styled.AccordionMenuSummary current expandIcon={<Styled.StyledExpandMoreIcon />}>
                  {currentTab}
                </Styled.AccordionMenuSummary>
                <Styled.AccordionMenuSummary
                  onClick={onMenuItemClick('Email')}
                  current={currentTab === 'Email'}
                >
                  Email
                </Styled.AccordionMenuSummary>
                <Styled.AccordionMenuSummary
                  onClick={onMenuItemClick('Notifications')}
                  current={currentTab === 'Notifications'}
                >
                  Notifications
                </Styled.AccordionMenuSummary>
              </Styled.AccordionMenu>
            </Styled.AccordionMenuSummary>
            {currentTab === 'Email' && (
              <>
                <Styled.SelectAllEmailsButton
                  position="absolute"
                  right="250px"
                  onClick={onSelectAllCheckBox}
                >
                  <Styled.SelectAllEmailsTitle>Select all emails</Styled.SelectAllEmailsTitle>
                  <Styled.SelectAllEmailsCheckbox checked={selectedAllEmail} />
                </Styled.SelectAllEmailsButton>
                <Button
                  title="Send"
                  variant="primary"
                  onClick={onSendButtonClick}
                  width="100px"
                  position="absolute"
                  right="120px"
                />
                <Button
                  title="Clear"
                  variant="warning"
                  onClick={onClearEmailState}
                  width="100px"
                  position="absolute"
                  right="0"
                />
              </>
            )}
            {currentTab === 'Notifications' && <PushNotificationsHeader />}
          </Styled.TopContainer>
          {currentTab === 'Email' ? (
            <Styled.EmailNotificationContainer>
              <AutocompleteInput
                title="Emails addresses"
                onInputChangeHandler={onInputChangeHandler}
                onEmailTagClickHandler={onEmailTagClickHandler}
                inputValue={searchInputValue}
                placeholder="Email"
                loading={isEmailsLoading}
                preselected={selectedEmailTags}
                data={users}
                disabled={selectedAllEmail}
              />
              <Styled.StyledInputWrapper>
                <Styled.StyledInputLabel>Subject</Styled.StyledInputLabel>
                <Styled.StyledInput
                  placeholder="Write your subject here"
                  onChange={onSubjectInputChangeHandler}
                  value={subject}
                />
              </Styled.StyledInputWrapper>
              <TextEditor content={emailContent} setContent={onChangeEmailContent} />
            </Styled.EmailNotificationContainer>
          ) : (
            currentTab === 'Notifications' && <PushNotificationsForm />
          )}
        </Styled.NotificationWrapper>
      ) : (
        <LoadingScreen />
      )}
    </DashBoardLayout>
  );
};
