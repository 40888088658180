import { createTheme } from '@mui/material';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      light: '#e8fffb',
      main: '#b6d9c8',
      dark: '#86a797',
    },
    secondary: {
      light: '#56896f',
      main: '#2a5c44',
      dark: '#00321d',
    },
    light: {
      light: '#A7B3AE',
      main: '#fff',
      dark: '#A7B2AE',
    },
    error: {
      light: '#FFE6E6',
      main: '#EE7674',
    },
    admin: {
      light: '#ebf4ef',
      main: '#f0f5f4',
      dark: '#f0f5f4',
      contrastText: '#a7b2ae',
    },
    green: {
      light: '#deede6',
      main: '#99c6b0',
      dark: '#1F483E',
      contrastText: '#0F896C',
    },
    gray: {
      light: '#DAE3E1',
      main: '#BFC5C4',
      dark: '#54625C',
      contrastText: '#3C3B3B',
    },
    brown: {
      main: '#5C482A',
      light: '#A07300',
    },
    yellow: {
      light: '#FCF2D9',
      main: '#FFD15C',
      dark: '#E09870',
      contrastText: '#EFB492',
    },
    black: {
      main: '#FFF',
      light: '#455458',
    },
  },
});
