import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import { IGetUsersDataRequest, IUsersDataSuccessFullResponse } from '@plant/data';
import { PushNotification } from '@plant/data';

export enum PushNotificationsTypes {
  SendPushNotification = '[Push Notifications] SendPushNotification',
  SendPushNotificationSuccess = '[Push Notifications] SendPushNotificationSuccess',
  SendPushNotificationFailed = '[Push Notifications] SendPushNotificationFailed',

  RequestUsers = '[Push Notifications] RequestUsers',
  RequestUsersSuccess = '[Push Notifications] RequestUsersSuccess',
  RequestUsersFailed = '[Push Notifications] RequestUsersFailed',

  SetBody = '[Push Notifications] SetBody',
  SetSubject = '[Push Notifications] SetSubject',
  SelectAllUsers = '[Push Notifications] SelectAllUsers',
  ToggleUser = '[Push Notifications] ToggleUser',
  ClearForm = '[Push Notifications] ClearForm',
}

export const sendPushNotification = createAsyncAction(
  PushNotificationsTypes.SendPushNotification,
  PushNotificationsTypes.SendPushNotificationSuccess,
  PushNotificationsTypes.SendPushNotificationFailed,
)<PushNotification, void, string>();

export const requestUsersAction = createAsyncAction(
  PushNotificationsTypes.RequestUsers,
  PushNotificationsTypes.RequestUsersSuccess,
  PushNotificationsTypes.RequestUsersFailed,
)<IGetUsersDataRequest, IUsersDataSuccessFullResponse, string>();

export const setBodyAction = createAction(PushNotificationsTypes.SetBody)<string>();
export const setSubjectAction = createAction(PushNotificationsTypes.SetSubject)<string>();
export const selectAllUsersAction = createAction(PushNotificationsTypes.SelectAllUsers)();
export const toggleUserAction = createAction(PushNotificationsTypes.ToggleUser)<{
  email: string;
  uuid: string;
}>();
export const clearFormAction = createAction(PushNotificationsTypes.ClearForm)();

export type PushNotificationsActionUnion = ActionType<
  | typeof sendPushNotification
  | typeof setBodyAction
  | typeof setSubjectAction
  | typeof selectAllUsersAction
  | typeof toggleUserAction
  | typeof clearFormAction
  | typeof requestUsersAction
>;
