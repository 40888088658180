import  { FC } from "react";
import { StyledErrorWrapper } from './style';

interface IProps {
  text?: string,
  top?: string,
  left?: string,
  bottom?: string,
  right?: string,
  width?: string,
  position?: 'relative' | 'absolute'
  margin?: string;
}

export const ErrorAlert: FC<IProps> = ({ 
  text,
  top,
  bottom,
  right,
  width,
  left,
  position,
  margin
}) => {
  return (
    <StyledErrorWrapper 
      top={top}
      bottom={bottom}
      right={right}
      width={width}
      left={left}
      position={position}
      margin={margin}
    >
      {text}
    </StyledErrorWrapper>
  )
}