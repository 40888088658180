import { UnitAPI } from '../interfaces/api/unit';
import { Unit } from '../interfaces/unit';
import { settingsMapper } from './settingsMapper';

export const unitMapper = (unit: UnitAPI): Unit => {
  const { status, settings, images } = unit;

  return {
    uuid: unit.uuid,
    active: unit.active,
    name: unit.name,
    unitSerial: unit.unit_serial,
    podLimit: unit.pod_limit,
    createdOn: unit.created_on,
    updatedOn: unit.updated_on,
    status: {
      ledIntensity: status.led_intensity,
      fan: status.fan,
      leakSensor: status.leak_sensor,
      waterLevel: status.water_level,
      temperature: status.temperature,
      humidity: status.humidity,
      connected: status.connected,
      resetFactory: status.reset_factory,
      cameraImageLastDate: status.camera_image_last_date,
      cameraVideoLastDate: status.camera_video_last_date,
    },
    settings: settingsMapper(settings),
    alerts: unit.alerts,
    images: images,
    pods: unit.pods.map((item) => {
      const { plant } = item;
      const { plant_action } = plant;
      const { crop } = plant;
      return {
        number: item.number,
        plant: {
          uuid: plant.uuid,
          active: plant.active,
          photo: plant.photo,
          video: plant.video,
          plantActionDate: plant?.plantActionDate,
          plantAction: {
            uuid: plant_action.uuid,
            name: plant_action.name,
            description: plant_action.description,
            plantAge: plant_action.age,
          },
          crop: {
            name: crop.name,
            active: crop.active,
            alias: crop.alias,
            photo: crop.photo,
            video: crop.video,
            icon: crop.icon,
            shortDescription: crop.shortDescription,
            fullDescription: crop.fullDescription,
            uuid: crop.uuid,
            harvest: crop.harvest,
            replant: crop.replant,
            lifespan: crop.lifespan,
            nutrition: crop.nutrition,
            flavor: crop.flavor,
          },
        },
      };
    }),
  };
};
