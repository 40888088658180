import React, { useEffect, useState } from "react";
import { defaultTheme } from '@plant/ui';
import * as S from './style';
import { Input } from '../Input';
import { TextArea } from '../TextArea';
import { TECHNIQUE_BUTTONS } from '../../constants/content';
import { 
  cropToSetSelector, 
  initialCropToSetSelector,
  nutritionSelector, 
  flavorSelector,
  flavorUuidsSelector,
  nutritionUuidsSelector,
  errorSelector,
  uploadIsFinishedSelector,
  uploadingSelector,
  loadingSelector
} from '../../store/selectors/content';
import { authAccessTokenSelector } from '../../store/selectors/auth';
import { useSelector, useDispatch } from "react-redux";
import { 
  changeCropToSetFieldActionAction, 
  getNutritionAction, 
  getFlavorAction, 
  selectOptionsAction,
  clearNewCropAction,
  uploadCropArticleAction,
  updateCropArticleAction,
  resetUploadIsFinishedAction
} from '../../store/actions/content'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoPlayer from "../VideoPlayer";
import { Button } from "../Button";
import { ErrorAlert } from "../ErrorAlert";
import { toast } from "react-toastify";
import { LoadingScreen } from '../LoadingScreen';
import { getCropToChangeByIdAction } from '../../store/actions/content';

export const CropEditor = ({
  mode,
  page,
  id
}: {
  mode: string,
  page: string,
  id: string
}) => {

  const dispatch = useDispatch();

  const cropToSet = useSelector(cropToSetSelector);
  const initialCropToSet = useSelector(initialCropToSetSelector);
  const nutrition = useSelector(nutritionSelector);
  const flavor = useSelector(flavorSelector);
  const flavorUuids = useSelector(flavorUuidsSelector);
  const nutritionUuids = useSelector(nutritionUuidsSelector);
  const error = useSelector(errorSelector);
  const uploading = useSelector(uploadingSelector);
  const uploadIsFinished = useSelector(uploadIsFinishedSelector);
  const token = useSelector(authAccessTokenSelector);
  const loading = useSelector(loadingSelector);

  useEffect(() => {
    if(page === 'crop' && mode === 'edit') {
      dispatch(getCropToChangeByIdAction.request({ id }))
    }
    if(page === 'crop' && mode === 'add') {
      dispatch(clearNewCropAction());
      dispatch(getNutritionAction.request());
      dispatch(getFlavorAction.request());
    }
  }, [id, mode, page, dispatch])

  useEffect(() => {
    if (uploadIsFinished) {
      dispatch(clearNewCropAction())
      setMediaData({
        icon: {
          src: null,
          file: null
        },
        image:  {
          src: null,
          file: null
        },
        video:  {
          src: null,
          file: null
        },
        techImage:  {
          src: null,
          file: null
        }
      })
      setInvalidFields([]);
    }
  }, [uploadIsFinished, dispatch])

  const [ invalidFields, setInvalidFields ] = useState([]);
  const [ currentTab, setCurrentTab ] = useState<'harvest' | 'replant' | 'lifespan'>('harvest');
  const [ mediaData, setMediaData ] = useState({
    icon: {
      src: null,
      file: null
    },
    image:  {
      src: null,
      file: null
    },
    video:  {
      src: null,
      file: null
    },
    techImage:  {
      src: null,
      file: null
    }
  });

  const requiredFields = [
    'name', 
    'full_description', 
    'type', 
    'icon',
    'photo',
    'harvest_description',
    'harvest_image',
    'harvest_time',
    'replant_description',
    'replant_time',
    'lifespan_description',
    'lifespan_time'
  ]

  const techniqueData = {
    harvest: {
      time: {
        inputTitle: 'Harvest time',
        fieldName: 'harvest_time',
        fieldValue: cropToSet.harvest_time
      },
      image: {
        fieldName: 'harvest_image',
        fieldValue: cropToSet.harvest_image
      },
      description: {
        inputTitle: 'Harvest description',
        fieldName: 'harvest_description',
        fieldValue: cropToSet.harvest_description
      }
    },
    replant: {
      time: {
        inputTitle: 'Replant time',
        fieldName: 'replant_time',
        fieldValue: cropToSet.replant_time
      },
      description: {
        inputTitle: 'Replant description',
        fieldName: 'replant_description',
        fieldValue: cropToSet.replant_description
      },
      image: false
    },
    lifespan: {
      time: {
        inputTitle: 'lifespan time',
        fieldName: 'lifespan_time',
        fieldValue: cropToSet.lifespan_time
      },
      description: {
        inputTitle: 'lifespan description',
        fieldName: 'lifespan_description',
        fieldValue: cropToSet.lifespan_description
      },
      image: false
    }
  }

  const submitActions = {
    add: uploadCropArticleAction,
    edit: updateCropArticleAction
  }

  const checkIsValidForm = () => {
    const dataToUpload = {
      ...cropToSet,
      icon: mediaData.icon.file || cropToSet.icon,
      photo: mediaData.image.file || cropToSet.photo,
      video: mediaData.video.file || cropToSet.video,
      harvest_image: mediaData.techImage.file || cropToSet.harvest_image
    }
    const inValidFields = requiredFields.reduce((res, val) => !dataToUpload[val] ? [...res, val] : res, []);
    setInvalidFields(inValidFields)
    return !inValidFields.length;
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const { files } = e.dataTransfer;
    e.preventDefault();
    e.stopPropagation();
    updateDataState('video', files[0])
  };

  const onTabButtonsClick = (tabName: 'harvest' | 'replant' | 'lifespan') => () => {
    setCurrentTab(tabName)
  }

  const onEditTextHandler = (fieldName: string, value: string) => {
    dispatch(changeCropToSetFieldActionAction({ fieldName, value }))
  }

  const updateDataState = (dataField: string, file: File) => {
    setMediaData({
      ...mediaData,
      [dataField]: {
        ...mediaData[dataField],
        src: URL.createObjectURL(file),
        file
      }
    })
  }

  const onAddIconInputHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    updateDataState('icon', files[0])
  }

  const onAddVideoInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    updateDataState('video', files[0])

  }

  const onAddImageInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    updateDataState('image', files[0])
  }

  const onAddTechInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    updateDataState('techImage', files[0])
  }

  const onSetCheckbox = (fieldName: 'flavorUuids' | 'nutritionUuids', value: string) => () => {
    dispatch(selectOptionsAction({ fieldName, value }))
  }

  const onDeleteContent = (storeKey: string, localKey: string) => () => {
    onEditTextHandler(storeKey, '');
    setMediaData({
      ...mediaData,
      [localKey]: {
        src: null,
        file: null
      }
    })
  }

  const onClearNewCropButtonClick = () => {
    dispatch(clearNewCropAction())
    setMediaData({
      icon: {
        src: null,
        file: null
      },
      image:  {
        src: null,
        file: null
      },
      video:  {
        src: null,
        file: null
      },
      techImage:  {
        src: null,
        file: null
      }
    })
    setInvalidFields([]);
  }
  
  const markFieldAsInvalid = (key: string) => ({
    inValid: invalidFields.some(el => el === key)
  })

  const markButtonAsInvalid = (keys: string[]) => ({
    inValid: !!keys.filter(key => invalidFields.includes(key))?.length
  })

  const onSubmitButtonClick = () => {
    const isValid = checkIsValidForm();
    if (isValid) {
      const dataToUpload = {
        ...cropToSet,
        icon: mediaData.icon.file || cropToSet.icon,
        photo: mediaData.image.file || cropToSet.photo,
        video: mediaData.video.file || cropToSet.video,
        harvest_image: mediaData.techImage.file || cropToSet.harvest_image
      }
      if (page === 'crop' && mode === 'edit') {
        if (cropToSet.name === initialCropToSet.name) {
          delete dataToUpload.name
        }
        if (!dataToUpload.video) {
          delete dataToUpload.video;
        }
      }

      const formData = new FormData();
      Object.keys(dataToUpload).forEach(key => {
        if (Array.isArray(dataToUpload[key])) {
          formData.append(key, JSON.stringify(dataToUpload[key]));
        } else {
          formData.append(key, dataToUpload[key]);
        }
      })
      dispatch(submitActions[mode].request(formData));
    }
  }

  return (
    <S.StyledCropEditorWrapper>
      <S.GridContainer columns="49% 49%">
        <div>
          <S.GridContainer columns="65px 1fr" gridGap="20px">
            <S.LogoImageInputWrapper>
              <S.LogoImageInput onChange={onAddIconInputHandler} accept="image/*" id="crop_small_logo" type="file" />
              <S.LogoImageInputLabel {...markFieldAsInvalid('icon')} htmlFor="crop_small_logo">
                {!mediaData.icon.src && !cropToSet.icon ? (
                  <>
                    <S.LogoImageInputLabelSpan>Add</S.LogoImageInputLabelSpan>
                    <S.LogoImageInputLabelSpan>logo</S.LogoImageInputLabelSpan>
                  </>
                ): (
                  <S.ContentWrapper>
                    <S.LogoImageInputLabelImage src={mediaData.icon.src || `${cropToSet.icon}?token=${token}`}/>
                    <S.DeleteContentButton position='absolute' bottom="-7px" right="-7px" width="30px" onClick={onDeleteContent('icon', 'icon')}/>
                  </S.ContentWrapper>
                )}
              </S.LogoImageInputLabel>
            </S.LogoImageInputWrapper>  
            <Input
              fieldTitle="name"
              title="Crop name"
              value={cropToSet.name}
              onChange={onEditTextHandler}
              {...markFieldAsInvalid('name')}
            />
          </S.GridContainer>
          <Input
            fieldTitle="scientific_name"
            title="Scientific crop name"
            value={cropToSet.scientific_name}
            onChange={onEditTextHandler}
            {...markFieldAsInvalid('scientific_name')}
            st={{
              margin: "20px 0"
            }}
          />
          <Input
            fieldTitle="type"
            title="Type"
            value={cropToSet.type}
            onChange={onEditTextHandler}
            {...markFieldAsInvalid('type')}
            st={{
              margin: "20px 0 0 0"
            }}
          />
        </div>
        <TextArea
          fieldTitle="subspecies"
          title="Subspecies"
          value={cropToSet.subspecies}
          onChange={onEditTextHandler}
          {...markFieldAsInvalid('subspecies')}
          st={{
            height: '100%',
          }}
        />
      </S.GridContainer>
      <TextArea
        fieldTitle="short_description"
        title="Short description"
        value={cropToSet.short_description}
        onChange={onEditTextHandler}
        {...markFieldAsInvalid('short_description')}
        st={{
          height: '100px',
          margin: '20px 0 0 0'
        }}
      />
      <TextArea
        fieldTitle="full_description"
        title="Full description"
        value={cropToSet.full_description}
        onChange={onEditTextHandler}
        {...markFieldAsInvalid('full_description')}
        st={{
          height: '250px',
          margin: '20px 0 0 0'
        }}
      />
      <S.GridContainer
        columns="70% auto"
        margin="20px 0"
      >
        {!mediaData.video.src && !cropToSet.video ?
         <S.UploadVideoContainer
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <S.UploadVideoContainerTitle>Drag video file here or</S.UploadVideoContainerTitle>
          <S.UploadVideoContainerInput onChange={onAddVideoInputHandler} accept="video/*" id="upload_video" type="file" />
          <S.UploadVideoContainerLabel htmlFor="upload_video">
            Click me to open upload window
          </S.UploadVideoContainerLabel>
        </S.UploadVideoContainer> : (
          <S.ContentWrapper>
            <VideoPlayer src={mediaData.video.src || `${cropToSet.video}?token=${token}`}/>
            <S.DeleteContentButton position='absolute' width="40px" right="-15px" top="-15px" onClick={onDeleteContent('video', 'video')}/>
          </S.ContentWrapper>
        )}
        <S.CropWrapper>
          <S.CropPlateBorder {...markFieldAsInvalid('photo')}>
            {(mediaData.image.src || cropToSet.photo) && <S.DeleteContentButton position='absolute' width="40px" right="-15px" top="-15px" onClick={onDeleteContent('photo', 'image')}/>}
            <S.CropPlate>
              <S.CropPlateInput onChange={onAddImageInputHandler} accept="image/* " id="crop_logo" type="file" />
              <S.CropPlateInputLabel htmlFor="crop_logo">
                {!mediaData.image.src && !cropToSet.photo ? (
                  <>
                    <S.CropPlateInputLabelSpan>Add</S.CropPlateInputLabelSpan>
                    <S.CropPlateInputLabelSpan>image</S.CropPlateInputLabelSpan>
                  </>
                ): (
                  <S.ContentWrapper>
                    <S.CropPlateInputLabelImage src={mediaData.image.src || `${cropToSet.photo}?token=${token}`}/>
                  </S.ContentWrapper>
                )}
              </S.CropPlateInputLabel>
            </S.CropPlate>
          </S.CropPlateBorder>
        </S.CropWrapper>
      </S.GridContainer>
      <S.TechniqueContainer>
        <S.TechniqueBlock>
          <S.TechniqueButtonContainerTitle>
            {TECHNIQUE_BUTTONS.find(button => button.tabName === currentTab).headTitle}
          </S.TechniqueButtonContainerTitle>
          <S.TechniqueButtonContainer>
            {
              TECHNIQUE_BUTTONS.map(button => {
                return (
                  <S.TechniqueButton {...markButtonAsInvalid([button.fields.time, button.fields.description])} onClick={onTabButtonsClick(button.tabName)} active={button.tabName === currentTab} key={button.id}>
                    <S.TechniqueButtonIcon src={button.iconSrc} />
                    <S.TechniqueButtonTextContainer>
                      <S.TechniqueButtonText color={defaultTheme.palette.secondary.dark}>
                        {button.title}
                      </S.TechniqueButtonText>
                      <S.TechniqueButtonText fontSize="13px" active={button.tabName === currentTab} color={defaultTheme.palette.primary.dark}>
                        {cropToSet[`${button.tabName}_time`]}
                      </S.TechniqueButtonText>
                    </S.TechniqueButtonTextContainer>
                  </S.TechniqueButton>
                )
              })
            }
          </S.TechniqueButtonContainer>
        </S.TechniqueBlock>
        <S.TechniqueInfoContainer>
          <S.GridContainer
            columns={techniqueData[currentTab]?.image ? "49% 49%" : '100%'}
            margin="20px 0"
            gridGap="15px"
          >
            <div>
              <TextArea
                {...markFieldAsInvalid(techniqueData[currentTab].time.fieldName)}
                fieldTitle={techniqueData[currentTab].time.fieldName}
                title={techniqueData[currentTab].time.inputTitle}
                value={techniqueData[currentTab].time.fieldValue}
                onChange={onEditTextHandler}
                st={{
                  width: "100%",
                }}
              />
              <TextArea
                {...markFieldAsInvalid(techniqueData[currentTab].description.fieldName)}
                fieldTitle={techniqueData[currentTab].description.fieldName}
                title={techniqueData[currentTab].description.inputTitle}
                value={techniqueData[currentTab].description.fieldValue}
                onChange={onEditTextHandler}
                st={{
                  width: "100%",
                  height: "150px",
                  margin: "15px 0 0 0"
                }}
              />
            </div>
            {
              techniqueData[currentTab]?.image && (
                <S.UploadVideoContainer {...markFieldAsInvalid('harvest_image')} height="100%">
                  <S.UploadVideoContainerInput onChange={onAddTechInputHandler} accept="image/*" id="upload_Technique_image" type="file" />
                  <S.UploadVideoContainerLabel htmlFor="upload_Technique_image">
                    {!mediaData.techImage.src && !cropToSet.harvest_image ? (
                    <>
                      Click me to open upload window                  
                    </>
                    ) : (
                      <S.ContentWrapper>
                        <S.TechniqueInfoContainerImage src={mediaData.techImage.src || `${cropToSet.harvest_image}?token=${token}`}/>
                        <S.DeleteContentButton position='absolute' width="40px" right="-25px" top="-25px" onClick={onDeleteContent('harvest_image', 'techImage')}/>
                      </S.ContentWrapper>
                    )}
                  </S.UploadVideoContainerLabel>
                </S.UploadVideoContainer>
              )
            }
          </S.GridContainer>
          <S.StyledAccordion>
            <S.StyledAccordionSummary
              expandIcon={<ExpandMoreIcon/>}
            >
              Select nutrients {!!nutritionUuids.length && `(${nutritionUuids.length})`}
            </S.StyledAccordionSummary>
            <S.StyledAccordionDetails>
              <S.GridContainer columns="33% 33% 33%">
                {!!nutrition && nutrition.map(n => {
                  return (
                    <S.GridContainer key={n.uuid} margin="5px 0" columns="10% 90%">
                      <S.StyledCheckbox checked={nutritionUuids.some(uuid => uuid === n.uuid)} onChange={onSetCheckbox('nutritionUuids', n.uuid)}/>
                      <S.StyledName>{n.name}</S.StyledName>
                    </S.GridContainer>
                  )
                })}
              </S.GridContainer>
            
            </S.StyledAccordionDetails>
          </S.StyledAccordion>
          <S.StyledAccordion>
            <S.StyledAccordionSummary
              expandIcon={<ExpandMoreIcon/>}
            >
              Select flavor {!!flavorUuids.length && `(${flavorUuids.length})`}
            </S.StyledAccordionSummary>
            <S.StyledAccordionDetails>
              <S.GridContainer columns="33% 33% 33%">
                {!!flavor && flavor.map(n => {
                  return (
                    <S.GridContainer key={n.uuid} margin="5px 0" columns="10% 90%">
                      <S.StyledCheckbox checked={flavorUuids.some(uuid => uuid === n.uuid)} onChange={onSetCheckbox('flavorUuids', n.uuid)}/>
                      <S.StyledName>{n.name}</S.StyledName>
                    </S.GridContainer>
                  )
                })}
              </S.GridContainer>
            </S.StyledAccordionDetails>
          </S.StyledAccordion>
        </S.TechniqueInfoContainer>
      </S.TechniqueContainer>
      <S.GridContainer
        columns="1fr"
        height="50px"
      >
        {
          !!invalidFields.length && 
          <ErrorAlert 
            position="relative"
            text="Some fields are required"
          />
        }
        {
          error && 
          <ErrorAlert 
            position="relative"
            text={error}
          />
        }
      </S.GridContainer>
      <S.GridContainer
        columns="1fr 1fr"
        width="350px"
        margin="10px auto 0"
      >
        <Button 
          variant="primary" 
          title="Submit"
          width="150px"
          onClick={onSubmitButtonClick}
        />
        <Button 
          variant="warning" 
          title="Clear"
          width="150px"
          onClick={onClearNewCropButtonClick}
        />
      </S.GridContainer>
      {(uploading || loading) && <LoadingScreen position="fixed"/>}
    </S.StyledCropEditorWrapper>
  )
}