import { FC, ReactNode, PropsWithChildren } from 'react';
import * as S from './style';
import { ReturnBackButton } from '../ReturnBackButton';

type PropsType = {
  children: ReactNode
  rootPage?: boolean
}

export const DashboardContainer: FC<PropsType> = ({
  children,
  rootPage
}) => {
  return (
    <S.DashboardContainerWrapper>
      <>
        {!rootPage && <ReturnBackButton top='15px' left='30px'/>}
        {children}
      </>
    </S.DashboardContainerWrapper>
  )
}