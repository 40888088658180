import { Accordion, AccordionSummary } from '@mui/material';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

export const NotificationWrapper = styled.div`
    padding: 20px;
    height: 85%;
    width: 95%;
    margin: 0 auto;
    border-radius: 20px;
    max-width: 1400px;
    position: relative;
  & > .MuiAccordionSummary-root {
    padding: 0;
  }
`

export const TopContainer = styled.div`
  display: flex;
  position: relative;
`

export const AccordionMenu = styled(Accordion)`
  padding: 0 0 0 5px !important;
  border-radius: 25px !important;
  box-shadow: none !important;
  font-weight: 600;
  width: 100%;
  text-align: center !important;
    ${props => ({
    color: props.theme.palette.secondary.main,
    background: `${props.theme.palette.light.main} !important`,
  })};
  
`

export const AccordionMenuSummary = styled(AccordionSummary)<{
  current?: boolean 
}>`
  width: 150px;
  font-weight: 400;
  ${props => props.current && ({
    fontWeight: 600,
    color: `${props.theme.palette.secondary.main} !important`
  })}
  & > .MuiAccordionSummary-content {
    margin: 0;
  }
`

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin: 0 !important;
  padding: 0 !important;
`

export const SelectAllEmailsButton = styled.button<{
  position?: 'absolute' | 'relative'
  top?: string
  right?: string
  bottom?: string
  left?: string
}>`
  padding: 3px 20px 3px 35px;
  border-radius: 25px;
  transition: all 0.3s;
  border: none;
  font-size: 14px;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  ${props => ({
    position: props.position || 'relative',
    top: props.top,
    right: props.right,
    bottom: props.bottom,
    left: props.left,
  })}
`
export const SelectAllEmailsTitle = styled.span`
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-weight: 600;
`

export const SelectAllEmailsCheckbox = styled(Checkbox)`
  & > .MuiSvgIcon-root {
      ${props => ({
    color: props.theme.palette.secondary.main
  })};
  }
`

export const EmailNotificationContainer = styled.div`
  height: 100%;
  margin-top: 25px;
`

export const StyledInputWrapper = styled.div`
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding: 10px 35px 15px;
  border-radius: 50px;
`

export const StyledInputLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
`

export const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: 17px;
  font-weight: 600;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  margin-top: 5px;
  &::placeholder {
      ${props => ({
    color: props.theme.palette.secondary.light
  })};
    font-weight: 400;
  }
`