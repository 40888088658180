import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';

export enum StartAppTypes {
  StartApp = '[App] StartApp',
  StartAppSuccess = '[App] StartAppSuccess',
  StartAppFailed = '[App] StartAppFailed',

  SwitchAppTab = '[App] SwitchAppTab'
}

export const startAppAction = createAsyncAction(
  StartAppTypes.StartApp,
  StartAppTypes.StartAppSuccess,
  StartAppTypes.StartAppFailed
)<() => void, void, void>();

export type StartAppActionUnion = ActionType<
  typeof startAppAction 
>