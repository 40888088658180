import { TableCell } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 30px auto 20px;
  max-width: 1400px;
  position: relative;
  gap: 20px;
  height: 100%;
`;

export const Cell = styled(TableCell)`
  font-family: Manrope;
`;

export const Table = styled.div`
  padding: 20px;
  ${props => ({
    background: props.theme.palette.admin.main
  })}  
  height: calc(100% - 130px);
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
`

export const TableHead = styled.div`
  display: flex;
  padding: 0 16px;
  width: 100%;
`

export const TableHeadTitle = styled.p<{
  width: string;
  fontWeight?: number
}>`
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
  ${props => ({
    width: props.width,
  })}
`

export const TableBody = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  border-radius: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const AccordionTR = styled.div`
  margin: 12px 0 !important;
  padding: 10px 15px;
  border-radius: 10px !important;
  box-shadow: none !important;
  overflow: hidden !important;
  display: flex;
  flex-direction: row;
  ${props => ({
    background: props.theme.palette.black.main,
  })}
`

export const TableRowColumn = styled.div<{
  width?: string;
  padding?: string;
  fontWeight?: number;
  flexDirection?: 'column' | 'row'
}>`
  position: relative;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
  font-weight: 700;
  ${props => ({
    color: props.theme.palette.secondary.main,
    width: props.width,
  })};
  overflow: hidden;
  margin-right: 5px;
  display: flex;
  ${props => ({
    padding: props.padding,
    fontWeight: props.fontWeight,
    flexDirection: props.flexDirection
  })}
`

export const StyledButton = styled.button`
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  position: relative;
  ${props => ({
    background: props.theme.palette.secondary.main,
    color: props.theme.palette.light.main
  })}
`

export const AddIcon = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  width: 20px;
  height: 20px;
  ${props => ({
    color: props.theme.palette.secondary.main,
    background: props.theme.palette.light.main,
  })};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  ${props => ({
    color: props.theme.palette.black.main
  })}
`