import { createReducer } from 'typesafe-actions';
import {
  IUsersDataSuccessFullResponse,
  IUserInfo,
  ICurrentUnitDataSuccessFullResponse,
} from '@plant/data';
import * as UsersActions from '../actions/users';
import { INITIAL_TABLE_HEAD_BUTTONS } from '../../constants/users';
import { CurrentSearchFieldType, StatusButtonsType, TableHeadButtonsType } from '../types/users';

export interface State {
  data: IUsersDataSuccessFullResponse;
  loading: boolean;
  updatingUsers: boolean;
  loadingMoreUsers: boolean;
  error: string | null;
  completeScroll: boolean;
  isSearching: boolean;
  isLoadingCurrentUser: boolean;
  currentUser: IUserInfo | null;
  isLoadingCurrentUnit: boolean;
  isDeletingUnit: boolean;
  currentUnit: ICurrentUnitDataSuccessFullResponse | null;
  statusButtons: StatusButtonsType;
  currentField: CurrentSearchFieldType;
  sortDirection: 'ASC' | 'DESC';
  tableHeadButtons: TableHeadButtonsType;
}

export const initialState: State = {
  data: [],
  loading: false,
  updatingUsers: false,
  loadingMoreUsers: false,
  isSearching: false,
  error: null,
  completeScroll: false,
  isLoadingCurrentUser: false,
  currentUser: null,
  isLoadingCurrentUnit: false,
  currentUnit: null,
  isDeletingUnit: false,
  statusButtons: {
    userStatus: {
      active: false,
      status: null,
      value: null,
    },
    unitStatus: {
      active: false,
      status: null,
      value: null,
    },
  },
  currentField: null,
  sortDirection: 'ASC',
  tableHeadButtons: INITIAL_TABLE_HEAD_BUTTONS,
};

export const reducer = createReducer<State, UsersActions.UsersActionUnion>(initialState)
  .handleAction(UsersActions.getAllUsersAction.request, (state) => ({
    ...state,
    loading: true,
    completeScroll: false,
  }))
  .handleAction(UsersActions.getAllUsersAction.success, (state, action) => {
    return {
      ...state,
      data: action.payload,
      error: null,
      loading: false,
    };
  })
  .handleAction(UsersActions.getAllUsersAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))

  .handleAction(UsersActions.updateAllUsersAction.request, (state) => ({
    ...state,
    updatingUsers: true,
    completeScroll: false,
  }))
  .handleAction(UsersActions.updateAllUsersAction.success, (state, action) => ({
    ...state,
    data: action.payload,
    error: null,
    updatingUsers: false,
  }))
  .handleAction(UsersActions.updateAllUsersAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
    updatingUsers: false,
  }))

  .handleAction(UsersActions.loadMoreUsersAction.request, (state) => ({
    ...state,
    loadingMoreUsers: true,
    completeScroll: false,
  }))
  .handleAction(UsersActions.loadMoreUsersAction.success, (state, action) => ({
    ...state,
    data: [...state.data, ...action.payload],
    completeScroll: action.payload.length === 0,
    error: null,
    loadingMoreUsers: false,
  }))
  .handleAction(UsersActions.loadMoreUsersAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loadingMoreUsers: false,
  }))

  .handleAction(UsersActions.findUsersAction.request, (state) => ({
    ...state,
    isSearching: true,
  }))
  .handleAction(UsersActions.findUsersAction.success, (state, action) => ({
    ...state,
    data: action.payload,
    error: null,
    isSearching: false,
    completeScroll: true,
  }))

  .handleAction(UsersActions.getCurrentUserAction.request, (state) => ({
    ...state,
    isLoadingCurrentUser: true,
  }))
  .handleAction(UsersActions.getCurrentUserAction.success, (state, action) => ({
    ...state,
    isLoadingCurrentUser: false,
    currentUser: action.payload.data,
  }))
  .handleAction(UsersActions.getCurrentUserAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loadingMoreUsers: false,
  }))

  .handleAction(UsersActions.getCurrentUnitAction.request, (state) => ({
    ...state,
    isLoadingCurrentUnit: true,
  }))
  .handleAction(UsersActions.getCurrentUnitAction.success, (state, action) => ({
    ...state,
    isLoadingCurrentUnit: false,
    currentUnit: action.payload.data,
  }))
  .handleAction(UsersActions.getCurrentUnitAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isLoadingCurrentUnit: false,
  }))

  .handleAction(UsersActions.deleteCurrentUnitAction.request, (state) => ({
    ...state,
    isDeletingUnit: true,
  }))
  .handleAction(UsersActions.deleteCurrentUnitAction.success, (state, action) => ({
    ...state,
    // isDeletingUnit: false,
    // currentUser: {
    //   ...state.currentUser,
    //   units: state.currentUser.units.filter(unit => unit.uuid !== action.payload)
    // },
    // currentUnit: state.currentUnit.uuid !== action.payload ?
    //   state.currentUnit.uuid : state.currentUser.units.filter(unit => unit.uuid !== action.payload)[0]
  }))
  .handleAction(UsersActions.deleteCurrentUnitAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isDeletingUnit: false,
  }))

  .handleAction(UsersActions.setTableHeadButtonsAction, (state, action) => ({
    ...state,
    tableHeadButtons: action.payload,
  }))

  .handleAction(UsersActions.setStatusButtonsAction, (state, action) => ({
    ...state,
    statusButtons: action.payload,
  }))

  .handleAction(UsersActions.setCurrentFieldAction, (state, action) => ({
    ...state,
    currentField: action.payload,
  }))

  .handleAction(UsersActions.setSortDirectionAction, (state, action) => ({
    ...state,
    sortDirection: action.payload,
  }))

  .handleAction(UsersActions.resetAllUsersDataAction, (state) => ({
    ...state,
    data: [],
  }))

  .handleAction(UsersActions.resetAllUsersAction, () => initialState);
