import { FC } from "react";
import { PopUpLayout } from "../../../../layouts/PopUp";
import { Button } from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";
import * as Styled from './style';

type PropsType = {
  open: boolean;
  onSubmit: () => void;
  onClosePopup: () => void;
  uploading: boolean;
}

export const DeletePopup: FC<PropsType> = ({
  open,
  onSubmit,
  onClosePopup,
  uploading,
}) => {
  const onSubmitJHandle = () => {
    onSubmit();
  };

  return (
    <PopUpLayout open={open} onClose={onClosePopup}>
      <Styled.PopUpContainer>
        <Styled.PopupTitle>
          Are you sure that you want to delete this Unit?
        </Styled.PopupTitle>
        <Styled.PopupButtonsContainer>
          <Button
            width="80px"
            title="Yes"
            variant="primary"
            onClick={onSubmitJHandle}
          />
          <Button
            width="80px"
            title="No"
            variant="warning"
            onClick={onClosePopup}
          />
        </Styled.PopupButtonsContainer>

        <Styled.PopupWarning>
          Warning: Unit will be deleted permanently!
        </Styled.PopupWarning>
        {uploading && (
          <Loader
            position="absolute"
            bottom="0"
            bubbleSize="20px"
            width="100px"
          />
        )}
      </Styled.PopUpContainer>
    </PopUpLayout>
  );
};