import { FC } from 'react';
import * as Styled from './style';

type PropsType = {
  title: string
  value: string
  fieldTitle: string
  onChange: (fieldTitle: string, value: string) => void
  inValid?: boolean
  st?: {
    width?: string
    margin?: string
  }
  disabled?: boolean
}

export const Input: FC<PropsType> = ({
  title,
  value,
  fieldTitle,
  onChange,
  st,
  inValid,
  disabled,
}) => {

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(fieldTitle, e.currentTarget.value)
  }

  return (
    <Styled.StyledInputFieldWrapper disabled={disabled} inValid={inValid} margin={st?.margin} width={st?.width}>
      <Styled.StyledInputLabel>{title}</Styled.StyledInputLabel>
      <Styled.StyledInputField disabled={disabled} onChange={onChangeHandler} value={value}/>
    </Styled.StyledInputFieldWrapper>
  )
}