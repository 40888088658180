import { Product } from '../interfaces';
import { ProductAPI } from '../interfaces/api/product';
import { ApiService } from './apiService';

export class ProductService {
  static async getProducts(limit: number, page: number): Promise<Product[]> {
    return ApiService.api
      .get<{ products: ProductAPI[] }>(`/shopify/products`)
      .then((response) => response.data.products);
  }
}
