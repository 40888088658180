import React from 'react';
import { Icon } from '@plant/data';

const OffSunIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path d="M21.0765 15.9751C18.2409 15.9751 15.9727 18.2434 15.9727 21.0789C15.9727 23.9145 18.2409 26.2183 21.0765 26.2183C23.9121 26.2183 26.2159 23.9147 26.2159 21.0789C26.2159 18.2432 23.9123 15.9751 21.0765 15.9751Z" />
        <path d="M16.4222 16.9692C16.8115 16.5019 17.2788 16.0736 17.8241 15.7624C17.8241 13.7763 15.7998 13.9713 15.3324 11.1677C14.7481 13.5431 14.7091 15.1392 16.423 16.9693L16.4222 16.9692Z" />
        <path d="M14.8651 21.525C14.7871 20.9016 14.8651 20.2792 15.0211 19.6558C13.5023 18.0988 12.5685 20.0061 9.99805 18.176C11.0106 19.85 12.6846 21.7192 14.8651 21.5241L14.8651 21.525Z" />
        <path d="M16.9676 25.808C16.5003 25.4188 16.111 24.9125 15.7608 24.4062C13.6194 24.3282 14.3199 26.3533 11.166 26.8979C13.1132 27.3261 15.6048 27.5212 16.9677 25.8073L16.9676 25.808Z" />
        <path d="M25.8044 25.2243C25.4152 25.6916 24.9089 26.1199 24.4026 26.4311C24.3246 28.6114 26.3497 27.9109 26.8943 31.0258C27.3225 29.1177 27.5176 26.6262 25.8037 25.2242L25.8044 25.2243Z" />
        <path d="M27.365 20.7075C27.404 21.3308 27.326 21.9533 27.209 22.5376C28.6889 24.0946 29.6234 22.1874 32.193 24.0175C31.1804 22.3434 29.5453 20.4742 27.365 20.7075L27.365 20.7075Z" />
        <path d="M25.2228 16.3859C25.6901 16.8141 26.1183 17.2814 26.4295 17.8267C28.6099 17.8657 27.9094 15.8406 31.0243 15.335C29.1162 14.8677 26.6246 14.6727 25.2227 16.3866L25.2228 16.3859Z" />
        <path d="M22.5371 15.0231C23.978 13.5822 22.3811 12.2975 24.017 10C21.9537 11.2848 20.7852 12.3363 20.707 14.8671C21.3304 14.8281 21.9528 14.8671 22.5371 15.0231L22.5371 15.0231Z" />
        <path d="M19.6537 27.2097C18.0967 28.6896 20.0039 29.6242 18.1738 32.1938C19.8479 31.1812 21.7171 29.5461 21.522 27.3658C20.8986 27.4048 20.2762 27.3268 19.6528 27.2097L19.6537 27.2097Z" />
      </g>
      <line
        x1="32"
        y1="10.7071"
        x2="10.7071"
        y2="32"
        stroke="#7D807F"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0214 17.6865C25.2167 17.8818 25.3225 18.0926 25.2577 18.1574L18.1918 25.2233C18.127 25.2881 17.9161 25.1824 17.7209 24.9871C17.5256 24.7919 17.4198 24.581 17.4846 24.5162L24.5506 17.4503C24.6154 17.3855 24.8262 17.4913 25.0214 17.6865Z"
        fill="#BFC5C4"
      />
    </svg>
  );
};

export default React.memo(OffSunIcon);
