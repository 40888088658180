import { Navigate, Route, Routes } from 'react-router-dom';
import { privateRoutes, publicRoutes, RouteNames } from '../../router';
import { IUserData, LOCAL_STORAGE_KEYS } from '@plant/data';
import { isAuthenticatedSelector } from '../../store/selectors/auth';
import { useSelector } from 'react-redux';

const AppRouter = () => {
  const authData: IUserData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.authData));
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return isAuthenticated || authData?.token ? (
    <Routes>
      {privateRoutes.map((route) => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      <Route path={RouteNames.ANY} element={<Navigate to={RouteNames.DashboardUsers} replace />} />
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map((route) => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      <Route path={RouteNames.ANY} element={<Navigate to={RouteNames.Login} replace />} />
    </Routes>
  );
};

export default AppRouter;
