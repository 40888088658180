import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import createStore from './store';
import AppNavigator from './components/AppNavigator';
import { Navigator } from '@plant/data';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@plant/ui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const { store } = createStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppNavigator navigator={Navigator.history}>
        <ThemeProvider theme={defaultTheme}>
          <App />
          <ToastContainer />
        </ThemeProvider>
      </AppNavigator>
    </Provider>
  </React.StrictMode>,
);
