import { Switch, styled } from '@mui/material';
import { Input } from '../Input';

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.secondary.light,
  },
}));

export const CustomInput = styled(Input)({
  width: '100%',
});
