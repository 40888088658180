import { createReducer } from 'typesafe-actions';
import { IUsersDataSuccessFullResponse } from '@plant/data';
import {
  clearFormAction,
  PushNotificationsActionUnion,
  toggleUserAction,
  selectAllUsersAction,
  setBodyAction,
  setSubjectAction,
  requestUsersAction,
  sendPushNotification,
} from '../actions/pushNotifications';

export interface State {
  data: IUsersDataSuccessFullResponse;
  loading: boolean;
  selectedAll: boolean;
  subject: string;
  body: string;
  users: { email: string; uuid: string }[];
  error: string;
}

export const initialState: State = {
  loading: false,
  selectedAll: false,
  subject: '',
  body: '',
  error: null,
  users: [],
  data: [],
};

export const reducer = createReducer<State, PushNotificationsActionUnion>(initialState)
  .handleAction(requestUsersAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(requestUsersAction.success, (state, action) => ({
    ...state,
    loading: false,
    data: action.payload,
  }))
  .handleAction(requestUsersAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(setBodyAction, (state, action) => ({
    ...state,
    body: action.payload,
  }))
  .handleAction(setSubjectAction, (state, action) => ({
    ...state,
    subject: action.payload,
  }))
  .handleAction(selectAllUsersAction, (state) => ({
    ...state,
    selectedAll: !state.selectedAll,
    users: [],
  }))
  .handleAction(toggleUserAction, (state, action) => ({
    ...state,
    users: state.users.find((user) => user === action.payload)
      ? state.users.filter((user) => user !== action.payload)
      : [...state.users, action.payload],
  }))
  .handleAction(clearFormAction, (state) => ({
    ...state,
    users: [],
    selectedAll: false,
    subject: '',
    body: '',
  }))
  .handleAction(sendPushNotification.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(sendPushNotification.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(sendPushNotification.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));
