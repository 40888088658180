import { PaletteColor, Theme } from '@mui/material';
import { ThemeOptions, PaletteColorOptions } from '@mui/material/styles';

interface AppTheme extends Theme {
  palette: Theme['palette'] & {
    light: PaletteColor;
    admin: PaletteColor;
    green: PaletteColor;
    gray: PaletteColor;
    brown: PaletteColor;
    yellow: PaletteColor;
    black: PaletteColor;
  };
}

interface CustomThemeOptions extends ThemeOptions {
  palette: ThemeOptions['palette'] & {
    light: PaletteColorOptions;
    admin: PaletteColorOptions;
    green: PaletteColorOptions;
    gray: PaletteColorOptions;
    brown: PaletteColorOptions;
    yellow: PaletteColorOptions;
    black: PaletteColorOptions;
  };
}

export { AppTheme, CustomThemeOptions };
export { defaultTheme } from './default';
export { mountingAnimation } from './animations/popupAnimation';
export { opacityAnimation } from './animations/opacityAnimation';
export { default as ScrollBarStyles } from './scrollbar';
