import styled from "styled-components";
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const StyledFaqListContainer = styled.div`
  margin-top: 20px;
  border-radius: 15px;
  height: 90%;
  max-height: 730px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const StyledAccordion = styled(Accordion)`
  margin: 12px 0 !important;
  padding: 0 !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none !important;
  overflow: hidden !important;
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  & > .MuiAccordionSummary-content {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto 70px 70px 20px;
    padding-right: 20px;
  }
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  font-weight: 600;
${props => ({
  color: props.theme.palette.secondary.main,
})};
  white-space: pre-wrap;
`

export const SummaryTitle = styled.h3`
  font-size: 15px;
    ${props => ({
    color: props.theme.palette.secondary.mainб
  })};
`


export const OpenCloseButton = styled.button<{
  disabled?: boolean
}>`
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => ({
    background: props.theme.palette.primary.main
  })}
`

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin: 0 !important;
  padding: 0 !important;
`

export const DeployButton = styled.button<{
  active?: boolean
}>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  ${props => ({
    background: props.active ? props.theme.palette.secondary.main : props.theme.palette.primary.light,
    color: props.active ? props.theme.palette.light.main : props.theme.palette.error.main,
  })}
`


export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`

export const PopupTitle = styled.h3`
  width: 350px;
  text-align: center;
  font-weight: 500;
${props => ({
  color: props.theme.palette.secondary.main,
})}
`

export const PopupWarning = styled.h3`
  width: 350px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  ${props => ({
    color: props.theme.palette.error.main
  })}
`

export const PopupButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  width: 230px;
`

export const EditPoPupWrapper = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
`

export const StyledInputFieldWrapper = styled.div<{
  width?: string
}>`
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  border-radius: 35px;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  ${props => ({
    width: props.width,
  })}
`

export const StyledInputLabel = styled.span`
  font-weight: 500;
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
`

export const StyledInputField = styled.input`
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  border: none;
  outline: none;
  font-weight: 600;
  margin: 10px 0 5px 0;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-size: 16px;
`


export const StyledTextAreaWrapper = styled.div<{
  width?: string
  height?: string
  margin?: string
  inValid?: boolean
}>`
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  border-radius: 35px;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
  transition: all 0.3s;
  ${props => ({
    width: props.width,
    height: props.height,
    margin: props.margin,
  })}
    ${props => props.inValid && ({
    boxShadow: `inset 0 0 1px 2px ${props.theme.palette.error.main}`,
  })}
`

export const StyledTextAreaLabel = styled.span`
  font-weight: 500;
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
`

export const StyledTextAreaField = styled.textarea`
  resize: none;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  border: none;
  outline: none;
  font-weight: 600;
  margin: 10px 0 5px 0;
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-size: 16px;
  height: 100%;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
      ${props => ({
    background: props.theme.palette.primary.light
  })};
    border-radius: 20px;
  }

   &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    ${props => ({
      backgroundColor: props.theme.palette.secondary.main,
      border: `3px solid ${props.theme.palette.secondary.light}`
    })}
  }

`
