import React, { ComponentType } from 'react';
import {
  ButtonTitle,
  ContainedButtonContainer,
  LinkButtonContainer,
  OutlinedButtonContainer,
} from './styles';
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  variant: ButtonVariants;
  disabled?: boolean;
  maxWidth?: number;
  leftIcon?: React.ReactNode;
}

type ButtonVariants = 'contained' | 'link' | 'outlined';

const buttonComponent: {
  [key in ButtonVariants]: ComponentType<any>;
} = {
  contained: ContainedButtonContainer,
  link: LinkButtonContainer,
  outlined: OutlinedButtonContainer,
};

const KitButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      title,
      maxWidth,
      disabled = false,
      variant = 'contained',
      leftIcon,
      ...elementProps
    },
    ref
  ) => {
    const Container = buttonComponent[variant];

    return (
      <Container
        ref={ref}
        maxwidth={maxWidth}
        disabled={disabled}
        {...elementProps}
      >
        {leftIcon}
        <ButtonTitle>{title}</ButtonTitle>
      </Container>
    );
  }
);

export default React.memo(KitButton);
