import React from 'react';
import { Icon } from '@plant/data';

const LifespanIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.06836 0H8.73909V1.29555H5.06836V0Z" />
    <path d="M11.5022 2.05128L12.3439 2.89304L11.1783 4.05869V4.05954C9.32943 2.56745 6.84035 2.1373 4.5984 2.92256C2.35558 3.70697 0.678056 5.5955 0.163506 7.91497C-0.351843 10.2344 0.369309 12.6552 2.06887 14.3152C3.76845 15.9751 6.20514 16.639 8.51209 16.0696C10.819 15.5003 12.6669 13.7789 13.3991 11.5183C14.1313 9.25781 13.642 6.77985 12.1069 4.96629L13.2515 3.82172L14.0933 4.66432L15 3.75761L12.4089 1.1665L11.5022 2.05128ZM8.28532 10.7751C7.57007 11.5798 6.33694 11.6523 5.53228 10.937C4.72761 10.2209 4.65508 8.98866 5.37033 8.18399L4.03177 5.95974L5.15441 5.29003L6.4719 7.49225V7.4931C7.32295 7.28561 8.20858 7.6736 8.63201 8.44114C9.05626 9.20785 8.91373 10.1643 8.28535 10.7751L8.28532 10.7751Z" />
    <path d="M6.90367 8.74524C6.59581 8.75114 6.33519 8.97214 6.27868 9.27409C6.22217 9.57604 6.3858 9.87632 6.67004 9.99271C6.95513 10.1091 7.28154 10.0096 7.45361 9.75484C7.62484 9.50012 7.59363 9.15936 7.37855 8.94007C7.25372 8.81271 7.08165 8.74186 6.90368 8.74523L6.90367 8.74524Z" />
  </svg>
);

export default React.memo(LifespanIcon);
