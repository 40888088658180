import styled from "styled-components";

export const InfoLineContainer = styled.span`
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  margin: 5px 0;
`
export const InfoLineTitle = styled.span`
  font-weight: 600;
`
export const InfoLineValue = styled.span`
  margin-left: 5px;
    ${props => ({
    color: props.theme.palette.secondary.light
  })};
`