import { FC, useEffect, useState } from 'react';
import { DashBoardLayout } from '../../layouts/Dashboard';
import { ORDER_VARIANTS } from './data';
import * as S from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAllUsersAction,
  loadMoreUsersAction,
  resetAllUsersDataAction,
  findUsersAction,
  setStatusButtonsAction,
  setCurrentFieldAction,
  setSortDirectionAction,
  setTableHeadButtonsAction,
} from '../../store/actions/users';
import { selectEmailTagAction, resetUsersEmailsAction } from '../../store/actions/notifications';
import { selectedEmailTagsSelector } from '../../store/selectors/notifications';
import {
  usersLoadingSelector,
  usersSelector,
  updatingUsersSelector,
  loadingMoreUsersSelector,
  completeScrollSelector,
  searchingUsersSelector,
  statusButtonsSelector,
  currentFieldSelector,
  sortDirectionSelector,
  tableHeadButtonsSelector,
} from '../../store/selectors/users';
import { LoadingScreen } from '../../components/LoadingScreen';
import { Loader } from '../../components/Loader';
import { SearchInput } from '../../components/SearchInput';
import { StatusButtonComponent } from './components/StatusButton';
import { NotificationButton } from './components/NotificationButton';
import { RouteNames } from '../../router';
import { useNavigate } from 'react-router-dom';
import { toggleUserAction } from '../../store/actions/pushNotifications';

export const DashboardUsersPage: FC = (): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [expandedRow, setExpandedRow] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUsersLoading = useSelector(usersLoadingSelector);
  const users = useSelector(usersSelector);
  const isUpdatingUsers = useSelector(updatingUsersSelector);
  const isLoadingMoreUsers = useSelector(loadingMoreUsersSelector);
  const isCompletedScroll = useSelector(completeScrollSelector);
  const isSearchingUsers = useSelector(searchingUsersSelector);
  const statusButtons = useSelector(statusButtonsSelector);
  const currentField = useSelector(currentFieldSelector);
  const sortDirection = useSelector(sortDirectionSelector);
  const tableHeadButtons = useSelector(tableHeadButtonsSelector);

  const selectedEmailTags = useSelector(selectedEmailTagsSelector);

  const count = 25;

  useEffect(() => {
    if (searchText) {
      dispatch(
        findUsersAction.request({
          query: searchText,
          order: sortDirection,
          orderField: currentField,
          page: 0,
          count,
        }),
      );
    } else {
      dispatch(
        updateAllUsersAction.request({
          query: searchText,
          order: sortDirection,
          orderField: currentField,
          page: 0,
          unitStatus: statusButtons.unitStatus.active,
          userStatus: statusButtons.userStatus.active,
          unitStatusValue: statusButtons.unitStatus.value,
          userStatusValue: statusButtons.userStatus.value,
          count,
        }),
      );
    }
  }, [dispatch, currentField, sortDirection, statusButtons, searchText]);

  useEffect(() => {
    dispatch(resetUsersEmailsAction());
    return () => {
      dispatch(resetAllUsersDataAction());
    };
  }, []);

  const onSortButtonClick = (id: number) => () => {
    dispatch(resetAllUsersDataAction());
    const currentSearchField = tableHeadButtons[id].searchField;
    const orderIndex =
      tableHeadButtons[id].currentOrderVariant < tableHeadButtons[id].orderVariants.length - 1
        ? tableHeadButtons[id].currentOrderVariant + 1
        : 0;
    const sort = tableHeadButtons[id].orderVariants[orderIndex];

    const updatedTableNavButtons = tableHeadButtons.map((button) => {
      return id === button.id
        ? {
            ...button,
            currentOrderVariant:
              button.currentOrderVariant < button.orderVariants.length - 1
                ? button.currentOrderVariant + 1
                : 0,
          }
        : {
            ...button,
            currentOrderVariant: 0,
          };
    });

    if (sort !== null) {
      dispatch(setCurrentFieldAction(currentSearchField));
      dispatch(setSortDirectionAction(sort));
    } else {
      dispatch(setCurrentFieldAction(null));
      dispatch(setSortDirectionAction(null));
    }
    dispatch(setTableHeadButtonsAction(updatedTableNavButtons));
    setPage(1);
  };

  const loadNextUsers = () => {
    dispatch(
      loadMoreUsersAction.request({
        query: searchText,
        order: sortDirection,
        orderField: currentField,
        page: page + 1,
        unitStatus: statusButtons.unitStatus.active,
        userStatus: statusButtons.userStatus.active,
        unitStatusValue: statusButtons.unitStatus.value,
        userStatusValue: statusButtons.userStatus.value,
        count,
      }),
    );
    setPage(page + 1);
  };

  const onContainerScroll = (e: any) => {
    const nearTheListBottom =
      e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop - 10 < 0;
    if (nearTheListBottom && !isUpdatingUsers && !isLoadingMoreUsers && !isUsersLoading) {
      loadNextUsers();
    }
  };

  const onInputChangeHandle = (text: string) => {
    setSearchText(text);
    if (page !== 1) {
      setPage(1);
    }
  };

  const onShowHideButtonClick = (id: string) => () => {
    if (expandedRow === id) {
      setExpandedRow('');
    } else {
      setExpandedRow(id);
    }
  };

  const onUserClickHandler =
    ({ uuid }: { uuid: string }) =>
    () => {
      navigate(RouteNames.DashboardUsersCurrentUser.replace(':id', uuid));
    };

  const onUnitClickHandler =
    ({ userId, unitId }: { userId: string; unitId: string }) =>
    () => {
      navigate(RouteNames.DashboardUnit.replace(':userId', userId).replace(':unitId', unitId));
    };

  const onStatusButtonClick = (field: 'userStatus' | 'unitStatus') => () => {
    const statusButtonsState = Object.keys(statusButtons).reduce((res, key) => {
      return key === field
        ? {
            ...res,
            [key]: {
              ...res[key],
              active:
                res[key].status === null
                  ? true
                  : res[key].status === 'online'
                  ? true
                  : res[key].status === 'offline' && false,
              status:
                res[key].status === null
                  ? 'online'
                  : res[key].status === 'online'
                  ? 'offline'
                  : res[key].status === 'offline' && null,
              value:
                key === 'userStatus' && res[key].value === null
                  ? 'online'
                  : key === 'userStatus' && res[key].value === 'online'
                  ? 'offline'
                  : key === 'userStatus' && res[key].value === 'offline'
                  ? null
                  : key === 'unitStatus' && res[key].value === null
                  ? true
                  : key === 'unitStatus' && res[key].value === true
                  ? false
                  : key === 'unitStatus' && res[key].value === false && null,
            },
          }
        : res;
    }, statusButtons);
    dispatch(setStatusButtonsAction(statusButtonsState));
  };

  const onSetCheckbox = (user: { email: string; uuid: string }) => () => {
    dispatch(selectEmailTagAction(user));
    dispatch(toggleUserAction(user));
  };

  const onSendEmailsButtonClick = () => {
    navigate(RouteNames.DashboardNotifications);
  };

  const onSendNotificationsButtonClick = () => {
    navigate(RouteNames.DashboardNotifications, { state: { pushNotification: true } });
  };

  return (
    <DashBoardLayout rootPage currentPage="users">
      <>
        <S.TopContainer>
          <S.StyledHeader>User management</S.StyledHeader>
          <SearchInput value={searchText} setSearchText={onInputChangeHandle} />
          {!!selectedEmailTags.length && (
            <>
              <NotificationButton
                title={`Send emails (${selectedEmailTags.length})`}
                position="absolute"
                right="400px"
                bottom="0"
                callback={onSendEmailsButtonClick}
              />
              <NotificationButton
                title={`Send notifications (${selectedEmailTags.length})`}
                position="absolute"
                right="600px"
                bottom="0"
                callback={onSendNotificationsButtonClick}
              />
            </>
          )}

          <StatusButtonComponent
            title="Users status:"
            callback={onStatusButtonClick('userStatus')}
            active={statusButtons.userStatus.active}
            status={statusButtons.userStatus.status}
            position="absolute"
            right="200px"
            bottom="0px"
          />
          <StatusButtonComponent
            title="Units status:"
            callback={onStatusButtonClick('unitStatus')}
            active={statusButtons.unitStatus.active}
            status={statusButtons.unitStatus.status}
            position="absolute"
            right="0"
            bottom="0px"
          />
        </S.TopContainer>
        {!isUsersLoading && users ? (
          <S.Table>
            <S.TableHead>
              {tableHeadButtons.map((column) => (
                <S.TableHeadTitle key={column.id} width={column.width} fontWeight={100}>
                  {column.title}
                  {column.searchField && (
                    <S.SortFieldButton onClick={onSortButtonClick(column.id)}>
                      {column.orderVariants[column.currentOrderVariant] === ORDER_VARIANTS.none ? (
                        <>
                          <FontAwesomeIcon icon={faSortUp} />
                          <FontAwesomeIcon icon={faSortDown} />
                        </>
                      ) : column.orderVariants[column.currentOrderVariant] ===
                        ORDER_VARIANTS.asc ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        column.orderVariants[column.currentOrderVariant] ===
                          ORDER_VARIANTS.desc && <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </S.SortFieldButton>
                  )}
                </S.TableHeadTitle>
              ))}
            </S.TableHead>
            <S.TableBody onScroll={!isCompletedScroll ? onContainerScroll : null}>
              {(isUpdatingUsers || isSearchingUsers) && <LoadingScreen />}
              {users &&
                Array.isArray(users) &&
                users.map((user) => {
                  return (
                    <S.AccordionTR key={user.uuid} expanded={expandedRow === user.uuid}>
                      <S.AccordionSummaryTR
                        grid="true"
                        col="5% 35% 30% 30%"
                        expandIcon={
                          <S.OpenCloseButton disabled={!user.usersPlaants.length}>
                            <S.StyledExpandMoreIcon
                              onClick={onShowHideButtonClick(user.uuid)}
                              sx={{
                                pointerEvents: user.usersPlaants.length ? 'auto' : 'none',
                              }}
                            />
                          </S.OpenCloseButton>
                        }
                        aria-controls={`${user.uuid}-panel2a-content`}
                        id={user.uuid}
                      >
                        <S.TableRowColumn>
                          <S.StyledCheckbox
                            onChange={onSetCheckbox(user)}
                            checked={selectedEmailTags.some((tag) => tag.email === user.email)}
                          />
                        </S.TableRowColumn>
                        <S.TableRowColumn onClick={onUserClickHandler(user)} fontWeight={500}>
                          {user.firstName} {user.lastName}
                          <S.Indicator
                            position="relative"
                            online={user?.isOnline}
                            margin="0 5px 0 5px"
                          />
                        </S.TableRowColumn>
                        <S.TableRowColumn onClick={onUserClickHandler(user)} fontWeight={400}>
                          {user.email}
                        </S.TableRowColumn>
                        <S.TableRowColumn fontWeight={400}>
                          {user.usersPlaants.length
                            ? `${user.usersPlaants.length} units`
                            : 'no units'}
                        </S.TableRowColumn>
                      </S.AccordionSummaryTR>
                      <S.AccordionDetailsTR grid="true" col="30% 20% 20% 30%">
                        <S.TableRowColumn />
                        <S.TableRowColumn />
                        <S.TableRowColumn />
                        <S.TableRowColumn flexDirection="column">
                          {!!user.usersPlaants.length &&
                            user.usersPlaants.map((unit) => {
                              return (
                                <S.UnitContainer
                                  onClick={onUnitClickHandler({
                                    userId: user.uuid,
                                    unitId: unit.uuid,
                                  })}
                                  key={unit.uuid}
                                >
                                  <S.UnitInfo>
                                    <S.UnitInfoText>
                                      <S.UnitInfoTextName>
                                        {unit.name}
                                        <S.Indicator left="103%" online={unit?.status} />
                                      </S.UnitInfoTextName>
                                    </S.UnitInfoText>

                                    <S.UnitInfoText fontWeight={400}>{unit.uuid}</S.UnitInfoText>
                                  </S.UnitInfo>
                                </S.UnitContainer>
                              );
                            })}
                        </S.TableRowColumn>
                      </S.AccordionDetailsTR>
                    </S.AccordionTR>
                  );
                })}
              {!isUpdatingUsers && !isUsersLoading && isLoadingMoreUsers && (
                <Loader
                  position="relative"
                  bottom="0"
                  margin="0 auto"
                  bubbleSize="11px"
                  height="30px"
                  width="70px"
                />
              )}
            </S.TableBody>
          </S.Table>
        ) : (
          isUsersLoading && !users.length && <LoadingScreen />
        )}
      </>
    </DashBoardLayout>
  );
};
