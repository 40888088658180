import styled from "styled-components";
import CircularProgress from '@mui/material/CircularProgress';

export const StyledSpinner = styled(CircularProgress)<{
  top?: string
  left?: string
  right?: string
  bottom?: string
  width?: string
  height?: string
  owncolor?: string
}>`
  width: 30px;
  height: 30px;
  position: absolute;
  ${props => ({
    top: props.top,
    left: props.left,
    right: props.right,
    bottom: props.bottom,
    width: `${props.width} !important`,
    height: `${props.height} !important`,
    color: `${props.owncolor} !important`,
  })}
`
