import React from 'react';
import { Icon } from '@plant/data';

const StoreIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.9992 10.0296L22.1649 4.10215C22.0084 3.86032 21.74 3.71429 21.452 3.71429H4.54715C4.25916 3.71429 3.9908 3.86029 3.83436 4.10208L0 10.0281C0 11.4391 1.1881 12.6286 2.60049 12.6286C4.01146 12.6286 5.20099 11.5143 5.20099 10.0281C5.20099 11.4391 6.38909 12.6286 7.80148 12.6286C9.21244 12.6286 10.402 11.5143 10.402 10.0281C10.402 11.4391 11.5901 12.6286 13.0025 12.6286C14.4134 12.6286 15.603 11.5143 15.603 10.0281C15.603 11.4391 16.7911 12.6286 18.2035 12.6286C19.6144 12.6286 20.8039 11.5143 20.8039 10.0281C20.8039 11.4391 21.9921 12.6286 23.4044 12.6286C24.8112 12.6286 25.9992 11.5144 25.9992 10.0296Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.85645 14.1187C2.09294 14.1871 2.34269 14.2241 2.59987 14.2241C4.01088 14.2241 5.20044 13.6313 5.20044 12.238C5.20044 13.5608 6.38858 14.2241 7.80101 14.2241C9.21202 14.2241 10.4016 13.6313 10.4016 12.238C10.4016 13.5608 11.5897 14.2241 13.0022 14.2241C14.4132 14.2241 15.6027 13.6313 15.6027 12.238C15.6027 13.5608 16.7909 14.2241 18.2033 14.2241C19.6143 14.2241 20.8039 13.6313 20.8039 12.238C20.8039 13.5608 21.992 14.2241 23.4044 14.2241C23.6595 14.2241 23.9073 14.1898 24.1422 14.1253V20.7243C24.1422 21.7499 23.3107 22.5814 22.285 22.5814H3.71359C2.68792 22.5814 1.85645 21.7537 1.85645 20.728V14.1187Z"
      />
    </svg>
  );
};

export default React.memo(StoreIcon);
