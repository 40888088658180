import React from 'react';
import { Icon } from '@plant/data';

const RemoveIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.3942 3.49464V4.34964C16.3942 4.58566 16.2028 4.77714 15.9667 4.77714H3.14173C2.90572 4.77714 2.71423 4.58566 2.71423 4.34964V3.49464C2.71423 3.25863 2.90572 3.06714 3.14173 3.06714H6.98923V2.21214C6.98923 1.74011 7.3722 1.35714 7.84423 1.35714H11.2642C11.7363 1.35714 12.1192 1.74011 12.1192 2.21214V3.06714H15.9667C16.2028 3.06714 16.3942 3.25863 16.3942 3.49464ZM4.31289 16.8667C4.37598 17.764 5.12336 18.4594 6.02289 18.4572H13.1027C14.0022 18.4594 14.7496 17.764 14.8127 16.8667L15.5393 6.48714H3.56927L4.31289 16.8667Z" />
  </svg>
);

export default React.memo(RemoveIcon);
