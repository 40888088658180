import { FC } from 'react';
import * as Styled from './style';

type PropsType = {
  title: string
  value: string
  fieldTitle: string
  onChange: (fieldTitle: string, value: string) => void
  inValid?: boolean
  st?: {
    width?: string
    height?: string
    margin?: string
  }
}

export const TextArea: FC<PropsType> = ({
  title,
  value,
  fieldTitle,
  onChange,
  st,
  inValid
}) => {

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(fieldTitle, e.currentTarget.value)
  }

  return (
    <Styled.StyledTextAreaWrapper inValid={inValid} margin={st?.margin} height={st?.height} width={st?.width}>
      <Styled.StyledTextAreaLabel>{title}</Styled.StyledTextAreaLabel>
      <Styled.StyledTextAreaField onChange={onChangeHandler} value={value}/>
    </Styled.StyledTextAreaWrapper>
  )
}