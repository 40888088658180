import { Button as MaterialButton, styled } from '@mui/material';

export const Button = styled(MaterialButton)(({ theme }) => ({
  color: theme.palette.light.main,
  borderRadius: 76,
  padding: 15,
  width: '100%',
  maxWidth: 250,
  maxHeight: 46,
}));

export const ContainedButtonContainer = styled('button')<{
  maxwidth: number | undefined;
  disabled: boolean;
}>(({ theme, maxwidth, disabled }) => ({
  backgroundColor: disabled ? theme.palette.light.light : theme.palette.secondary.main,
  color: theme.palette.light.main,
  border: 'none',
  borderRadius: 76,
  padding: 15,
  width: '100%',
  cursor: disabled ? 'default' : 'pointer',
  maxWidth: maxwidth ? maxwidth : 'auto',
  maxHeight: 46,

  fontWeight: 600,
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
}));

export const LinkButtonContainer = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.secondary.main,
  border: 'none',
  borderRadius: 76,
  padding: '5px 0',
  cursor: 'pointer',

  fontWeight: 600,
  fontSize: 14,

  display: 'flex',
  justifyContent: 'start',
  '& span': {
    margin: 0,
  },
}));

export const OutlinedButtonContainer = styled('button')<{
  maxwidth: number | undefined;
  disabled: boolean;
}>(({ theme, maxwidth, disabled }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.secondary.main,
  border: `2px solid ${theme.palette.secondary.main}`,
  borderRadius: 76,
  padding: 15,
  width: '100%',
  cursor: disabled ? 'default' : 'pointer',
  maxWidth: maxwidth ? maxwidth : 'auto',

  fontWeight: 600,
  fontSize: 16,
}));

export const ButtonTitle = styled('span')({
  margin: '0 auto',
});
