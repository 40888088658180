import HARVEST_ICON from '../assets/icons/Harvest.png';
import LIFESPAN_ICON from '../assets/icons/Lifespan.png';
import REPLANT_ICON from '../assets/icons/Replant.png';

export const TECHNIQUE_BUTTONS: {
  id: string | number
  title: string
  iconSrc: string
  headTitle: string
  tabName: 'harvest' | 'replant' | 'lifespan'
  fields: {
    time: string,
    description: string,
    image?: string
  }
}[] = [
  {
    id: 1,
    title: "Harvest",
    iconSrc: HARVEST_ICON,
    headTitle: 'Harvesting Technique',
    tabName: 'harvest',
    fields: {
      time: "harvest_time",
      description: "harvest_description",
      image: "harvest_image"
    }
  },
  {
    id: 2,
    title: "Replant",
    iconSrc: REPLANT_ICON,
    headTitle: 'Replant',
    tabName: 'replant',
    fields: {
      time: "replant_time",
      description: "replant_description"
    }
  },
  {
    id: 3,
    title: "Lifespan",
    iconSrc: LIFESPAN_ICON,
    headTitle: 'Lifespan',
    tabName: 'lifespan',
    fields: {
      time: "lifespan_time",
      description: "lifespan_description"
    }
  },
]