import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const AuthLayoutWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  ${props => ({
    background: props.theme.palette.admin.light
  })}`

export const FrontSide = styled.div<{
  current: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.6s linear;
  ${props => props.current && ({
    transform: 'perspective(600px) rotateY(-180deg)',
  })}
`
export const BackSide = styled.div<{
  current: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transform: perspective(600px) rotateY(180deg);
  transition: transform 0.6s linear;
  ${props => props.current && ({
    transform: 'perspective(600px) rotateY(0deg)',
  })}
`

export const HelperText = styled.p`
  margin: 30px auto 0;
  text-align: center;
  min-width: 350px;
  max-width: 400px;
  font-weight: 400;
  ${props => ({
    color: props.theme.palette.primary.dark
  })}
`

export const EmailText = styled.span`
  font-weight: 600;
    ${props => ({
    color: props.theme.palette.secondary.main
  })}
`

export const LogoWrapper = styled.div`
  position: absolute;
  top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const LogoText = styled.span`
  ${props => ({
    color: props.theme.palette.light.main,
  })}
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-left: 5px;
`

export const LogoImg = styled.img`
  height: 50px;
`