import styled from "styled-components";

export const StyledButton = styled.button<{
  variant: 'primary' | 'secondary' | 'warning';
  width?: string;
  height?: string;
  marginTop?: string | 0;
  marginBottom?: string | 0;
  fullWidth?: boolean;
  align?: 'left' | 'right';
  disabled?: boolean;
  position?: 'relative' | 'absolute';
  top?: string
  right?: string
  bottom?: string
  left?: string 
}>`
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-weight: 500;
  line-height: 16px;
  font-size: 16px;
  margin: 0 auto;
  position: relative;
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props => ({
    position: props.position || 'relative',
    top: props.top,
    left: props.left,
    right: props.right,
    bottom: props.bottom,
    width: !props.fullWidth ? props.width : '100%',
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    textAlign: props.align ? props.align : 'center',
    minHeight: props.height,
    height: props.height,
  }))}
  ${(props => props.variant === 'warning' && !props.disabled && ({
    color: props.theme.palette.error.main,
    background: props.theme.palette.error.light,
    transition: 'all 0.5s'
  }))}
  ${(props => props.variant === 'primary' && !props.disabled && ({
    color: props.theme.palette.light.main,
    background: props.theme.palette.secondary.main,
    transition: 'all 0.5s'
  }))}

  ${(props => props.variant === 'secondary' && !props.disabled && ({
    color: props.theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: '14px',
    background: 'none',
  }))}
`