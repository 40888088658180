import { FC, memo } from "react";
import * as Styled from './style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Navigator } from '@plant/data';

type PropsType = {
  position?: 'relative' | 'absolute' | 'fixed'
  top?: string
  right?: string
  bottom?: string
  left?: string
}

export const ReturnBackButton: FC<PropsType> = memo(({
  position,
  top,
  right,
  bottom,
  left,
}) => {

  const returnToPrevPage = () => Navigator.history.back();

  return (
    <Styled.ButtonWrapper 
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      onClick={returnToPrevPage}
    >
      <ArrowBackIcon/>
    </Styled.ButtonWrapper>
  )
})