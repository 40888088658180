import React from 'react';
import { Icon } from '@plant/data';

const AddIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 10 9'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.76786 0.434953C4.22891 0.434953 3.79209 0.871774 3.79209 1.41072V3.64924H1.55357C1.01463 3.64924 0.577806 4.08606 0.577806 4.625C0.577806 5.16395 1.01463 5.60077 1.55357 5.60077H3.79209V7.83929C3.79209 8.37824 4.22891 8.81506 4.76786 8.81506C5.3068 8.81506 5.74362 8.37824 5.74362 7.83929V5.60077H7.98214C8.52109 5.60077 8.95791 5.16395 8.95791 4.625C8.95791 4.08606 8.52109 3.64924 7.98214 3.64924H5.74362V1.41072C5.74362 0.871774 5.3068 0.434953 4.76786 0.434953Z"
      stroke-width="0.344388"
    />
  </svg>
);

export default React.memo(AddIcon);
