import React from 'react';
import { Icon } from '@plant/data';

const SettingsIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.909 14.7761C23.311 14.9896 23.6212 15.3269 23.8394 15.6641C24.2645 16.361 24.23 17.2153 23.8165 17.9684L23.0123 19.3173C22.5873 20.0367 21.7947 20.4863 20.9791 20.4863C20.577 20.4863 20.129 20.3739 19.7614 20.1491C19.4627 19.958 19.1181 19.8906 18.7505 19.8906C17.6133 19.8906 16.6598 20.8235 16.6253 21.9363C16.6253 23.229 15.5685 24.2407 14.2474 24.2407H12.6852C11.3526 24.2407 10.2958 23.229 10.2958 21.9363C10.2728 20.8235 9.31935 19.8906 8.18209 19.8906C7.80301 19.8906 7.45838 19.958 7.1712 20.1491C6.8036 20.3739 6.3441 20.4863 5.95353 20.4863C5.12644 20.4863 4.33381 20.0367 3.90877 19.3173L3.11614 17.9684C2.69111 17.2378 2.66813 16.361 3.09317 15.6641C3.27696 15.3269 3.62159 14.9896 4.01216 14.7761C4.33381 14.6187 4.54058 14.3602 4.73587 14.0567C5.31024 13.09 4.96561 11.8198 3.98918 11.2465C2.85193 10.6058 2.48433 9.17826 3.13912 8.06544L3.90877 6.73905C4.57504 5.62623 5.99948 5.23281 7.14822 5.88476C8.14763 6.42431 9.44571 6.06461 10.0316 5.10916C10.2154 4.79442 10.3188 4.45721 10.2958 4.11999C10.2728 3.6816 10.3992 3.2657 10.6174 2.92848C11.0425 2.23157 11.8121 1.78194 12.6507 1.75946H14.2704C15.1205 1.75946 15.8901 2.23157 16.3152 2.92848C16.522 3.2657 16.6598 3.6816 16.6253 4.11999C16.6024 4.45721 16.7058 4.79442 16.8896 5.10916C17.4754 6.06461 18.7735 6.42431 19.7844 5.88476C20.9216 5.23281 22.3576 5.62623 23.0123 6.73905L23.782 8.06544C24.4483 9.17826 24.0807 10.6058 22.9319 11.2465C21.9555 11.8198 21.6109 13.09 22.1967 14.0567C22.3805 14.3602 22.5873 14.6187 22.909 14.7761ZM10.2147 13.0111C10.2147 14.7758 11.6736 16.1809 13.4771 16.1809C15.2807 16.1809 16.7051 14.7758 16.7051 13.0111C16.7051 11.2463 15.2807 9.82997 13.4771 9.82997C11.6736 9.82997 10.2147 11.2463 10.2147 13.0111Z"
    />
  </svg>
);

export default React.memo(SettingsIcon);
