import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigator } from '@plant/data';
import { Button } from '../../components/Button';
import { SelectedVersion, UserGroups, Esp } from '@plant/data';
import * as Styled from './styles';
import { DashBoardLayout } from '../../layouts/Dashboard';
import { Input } from '../../components/Input';
import { TextArea } from '../../components/TextArea';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { AutocompleteInput } from '../../components/AutocompleteInput';
import { isEmailsLoadingSelector, usersSelector } from '../../store/selectors/notifications';
import { getUsersEmailsAction } from '../../store/actions/notifications';
import { useParams } from 'react-router-dom';
import { getVersionDetails, updateVersionDetails, deleteVersion } from '../../store/actions/software';
import {
  softwareLoadingSelector,
  softwareSelectedVersionSelector,
} from '../../store/selectors/software';
import { LoadingScreen } from '../../components/LoadingScreen';
import { DeletePopup } from './components/DeletePopup';
import { toast } from 'react-toastify';

const VersionPage = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [version, setVersion] = useState('');
  const [search, setSearch] = useState('');
  const [group, setGroup] = useState<UserGroups>(UserGroups.Close);
  const [espType, setEspType] = useState<Esp>(Esp.Main);
  const [selectedUsers, setSelectedUsers] = useState<{ uuid: string; email: string }[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const isEmailsLoading = useSelector(isEmailsLoadingSelector);
  const loading = useSelector(softwareLoadingSelector);
  const selectedVersion = useSelector(softwareSelectedVersionSelector);
  const users = useSelector(usersSelector);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getVersionDetails.request(id));
  }, [id]);

  useEffect(() => {
    if (selectedVersion) {
      setVersion(String(selectedVersion.version));
      setName(selectedVersion.versionName);
      setGroup(selectedVersion.canDownload);
      setDescription(selectedVersion.description);
      setSelectedUsers(selectedVersion.userInfo);
      setEspType(selectedVersion.type)
    }
  }, [selectedVersion]);

  useEffect(() => {
    if (search) {
      dispatch(
        getUsersEmailsAction.request({
          query: search,
          order: 'ASC',
          orderField: 'email',
          page: 1,
          count: 10,
        }),
      );
    }
  }, [dispatch, search]);

  const onUserClick = (selectedUser: { uuid: string; email: string }) => {
    setSearch('');
    setSelectedUsers((value) => {
      if (value.includes(selectedUser)) {
        return value.filter((user) => user.uuid !== selectedUser.uuid);
      } else {
        return value.concat(selectedUser);
      }
    });
  };

  const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (field, value) => {
    setter(value);
  };

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup(e.currentTarget.value as UserGroups);
  };

  const handleEspTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEspType(e.currentTarget.value as Esp);
  };

  const successCallback = () => {
    setOpenDeletePopup(false);
    Navigator.history.back();
  }

  const handleSubmit = () => {
    try {
      if (version && name && description) {
        const updatedVersion: SelectedVersion = {
          version: +version,
          versionName: name,
          description,
          type: espType,
          canDownload: group,
          userInfo: selectedUsers,
        };

        dispatch(updateVersionDetails.request({ updatedVersion, callback: successCallback }));
      } else {
        toast('Fill all fields', { type: 'error' });
      }
    } catch (error) {
      toast('Error, check all fields', { type: 'error' });
    }
  };

  const onDeleteFirmware = () => {
    dispatch(deleteVersion.request({
      id,
      callback: successCallback
    }))
  }

  const onOpenDeletePopup = () => setOpenDeletePopup(true);
  const onCloseDeletePopup = () => setOpenDeletePopup(false);

  return (
    <DashBoardLayout currentPage="firmware">
      {loading ? (
        <LoadingScreen/>
      ) : (
        <Styled.Container>
          <DeletePopup
            onDeletePopupSubmit={onDeleteFirmware}
            onCloseDeletePopup={onCloseDeletePopup}
            openDeletePopup={openDeletePopup}
            uploading={loading}
          />
          <Styled.StyledCropEditorWrapper>
            <Input title="Name" value={name} fieldTitle="name" onChange={handleChange(setName)} />
            <Input
              title="Version (Number)"
              value={version}
              fieldTitle="version"
              onChange={handleChange(setVersion)}
              disabled
            />
            <TextArea
              title="Description"
              value={description}
              fieldTitle="Description"
              onChange={handleChange(setDescription)}
            />
            <Styled.FileLabel>User group</Styled.FileLabel>
            {group === UserGroups.Testing && (
              <AutocompleteInput
                title="Emails addresses"
                onInputChangeHandler={handleChangeSearch}
                inputValue={search}
                onEmailTagClickHandler={onUserClick}
                placeholder="Email"
                loading={isEmailsLoading}
                preselected={selectedUsers}
                data={users}
                disabled={false}
              />
            )}
            <Styled.BottomControls>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                value={group}
                onChange={handleGroupChange}
              >
                <FormControlLabel value={UserGroups.All} control={<Radio />} label="All" />
                <FormControlLabel
                  value={UserGroups.Testing}
                  control={<Radio />}
                  label="Testing"
                />
                <FormControlLabel value={UserGroups.Close} control={<Radio />} label="Close" />
              </RadioGroup>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                value={espType}
                onChange={handleEspTypeChange}
              >
                <FormControlLabel value={Esp.Main} control={<Radio />} label="ESP-32" />
                <FormControlLabel value={Esp.Cam} control={<Radio />} label="ESP-32 CAM" />
              </RadioGroup>
              <Button width='100%' title="Save" variant="primary" onClick={handleSubmit} />
              <Button width='100%' title="Delete" variant="warning" onClick={onOpenDeletePopup} />
            </Styled.BottomControls>
          </Styled.StyledCropEditorWrapper>
        </Styled.Container>
      )}
    </DashBoardLayout>
  );
};

export default React.memo(VersionPage);
