import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './styles';
import {
  clearFormAction,
  selectAllUsersAction,
  sendPushNotification,
} from '../../../../store/actions/pushNotifications';
import {
  bodySelector,
  searchUsersSelector,
  SelectedAllSelector,
  subjectSelector,
  usersSelector,
} from '../../../../store/selectors/pushNotifications';
import { Button } from '../../../../components/Button';

const PushNotificationsHeader = () => {
  const dispatch = useDispatch();
  const selectedAll = useSelector(SelectedAllSelector);
  const subject = useSelector(subjectSelector);
  const payload = useSelector(bodySelector);
  const selectedUsers = useSelector(usersSelector);
  const data = useSelector(searchUsersSelector);

  const toggleSelectedAll = () => {
    dispatch(selectAllUsersAction());
  };

  const send = () => {
    dispatch(
      sendPushNotification.request({
        selectedAll,
        subject,
        payload,
        uuids: selectedUsers.map((user) => user.uuid),
      }),
    );
  };

  const clear = () => {
    dispatch(clearFormAction());
  };

  return (
    <>
      <Styled.SelectAllEmailsButton position="absolute" right="250px" onClick={toggleSelectedAll}>
        <Styled.SelectAllEmailsTitle>Select all users</Styled.SelectAllEmailsTitle>
        <Styled.SelectAllEmailsCheckbox checked={selectedAll} />
      </Styled.SelectAllEmailsButton>
      <Button
        title="Send"
        variant="primary"
        onClick={send}
        width="100px"
        position="absolute"
        right="120px"
      />
      <Button
        title="Clear"
        variant="warning"
        onClick={clear}
        width="100px"
        position="absolute"
        right="0"
      />
    </>
  );
};

export default React.memo(PushNotificationsHeader);
