import { UnitsResponseAPI } from '../interfaces/api/unitsResponse';
import { UnitResponseAPI } from '../interfaces/api/unitResponse';
import { Unit } from '../interfaces/unit';
import { unitMapper } from '../mappers/unitMapper';
import { ApiService } from './apiService';
import { SuccessResponse } from '../interfaces';
import { Settings } from '../interfaces/settings';
import { settingsMapper } from '../mappers/settingsMapper';
import { SettingsAPI } from '../interfaces/api/settings';
import { EnvironmentAPI } from '../interfaces/api/environment';
import { RemoveNutritionAlertResponse } from '../interfaces/api/removeNutritionAlertResponse';
import { AxiosResponse } from 'axios';

export class UnitService {
  static async getUnits(limit: number): Promise<Unit[]> {
    return ApiService.api.get<UnitsResponseAPI>(`/unit/search?limit=${limit}`).then((response) => {
      if (response.data.count === 0) {
        return [];
      }
      return response.data.data.map(unitMapper);
    });
  }

  static async getUnit(uuid: string): Promise<Unit> {
    return ApiService.api
      .get<UnitResponseAPI>(`/unit/${uuid}`)
      .then((response) => unitMapper(response.data.data));
  }

  static async deleteUnit(uuid: string): Promise<string> {
    return ApiService.api
      .delete<{ status: string }>(`/unit/${uuid}`)
      .then((response) => response.data.status);
  }

  static async saveTimelapse(
    unitUuid: string,
    speed: number,
    startDate: string,
    endDate: string,
  ): Promise<string> {
    const params = new URLSearchParams([
      ['start_date', startDate],
      ['end_date', endDate],
      ['speed', String(speed)],
      ['save', 'true'],
    ]);
    return ApiService.api
      .get<SuccessResponse<{ video: string }>>(`/unit/${unitUuid}/timelapse?${params}`)
      .then((response) => {
        return response.data.data.video;
      });
  }

  static async stopLighting(unitUuid: string): Promise<Settings> {
    return ApiService.api
      .put<SuccessResponse<{ settings: SettingsAPI; environment: EnvironmentAPI }>>(
        `/unit/settings/${unitUuid}`,
        {
          led_light: false,
        },
      )
      .then((response) => {
        return settingsMapper(response.data.data.settings);
      });
  }

  static async changeStartDate(unitUuid: string, startDate: string): Promise<Settings> {
    return ApiService.api
      .put<SuccessResponse<{ settings: SettingsAPI; environment: EnvironmentAPI }>>(
        `/unit/settings/${unitUuid}`,
        {
          led_light_start: startDate,
        },
      )
      .then((response) => {
        return settingsMapper(response.data.data.settings);
      });
  }

  static async rebootUnit(unitUuid: string): Promise<SuccessResponse<{ uuid: string }>> {
    return ApiService.api
      .put<SuccessResponse<{ uuid: string }>>(`/unit/reboot/${unitUuid}`)
      .then((response) => response.data);
  }

  static async resetUnit(unitUuid: string): Promise<SuccessResponse<{ uuid: string }>> {
    return ApiService.api
      .put<SuccessResponse<{ uuid: string }>>(`/unit/reset/${unitUuid}`)
      .then((response) => response.data);
  }

  static async shareFile(url: string): Promise<string> {
    return ApiService.api
      .get<{ url: string }>(`/unit/file/share?fileUrl=${url}`)
      .then((response) => response.data.url);
  }

  static async removeNutritionAlert(unitUuid: string): Promise<AxiosResponse<{ data: RemoveNutritionAlertResponse }>> {
    return ApiService.api.put<{ data: RemoveNutritionAlertResponse }> (`/unit/${unitUuid}`, { nutritions: true });
  }
}
