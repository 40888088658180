import React from 'react';
import { Icon } from '@plant/data';

const QuestionIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.42629 3.31639C4.27815 3.87862 4.75916 4.36679 5.34058 4.36679C5.88527 4.36679 6.30141 3.91894 6.54934 3.43395C6.8567 2.8327 7.43914 2.47934 8.21297 2.47934C9.3027 2.47934 10.0292 3.19527 10.0292 4.18084C10.0292 5.10132 9.66594 5.6034 8.59351 6.30074C7.39999 7.05386 6.89837 7.89067 6.97621 9.26674L6.97774 9.48087C6.98168 10.0341 7.43128 10.4805 7.98453 10.4805C8.54058 10.4805 8.99134 10.0297 8.99134 9.47369V9.38762C8.99134 8.45783 9.31134 7.97435 10.4443 7.26771C11.6205 6.51459 12.2778 5.51972 12.2778 4.09716C12.2778 2.05164 10.6951 0.582581 8.3254 0.582581C6.18984 0.582581 4.85535 1.68799 4.42629 3.31639ZM8.01405 14.2969C8.71459 14.2969 9.2681 13.7111 9.2681 12.9766C9.2681 12.242 8.71459 11.6656 8.01405 11.6656C7.31351 11.6656 6.75135 12.242 6.75135 12.9766C6.75135 13.7111 7.31351 14.2969 8.01405 14.2969Z" />
  </svg>
);

export default React.memo(QuestionIcon);
