import { FC } from "react";
import { PopUpLayout } from "../../../../layouts/PopUp";
import { IGetFaqType } from "@plant/data";
import { Button } from "../../../Button";
import { Loader } from "../../../Loader";
import * as Styled from './style';

type PropsType = {
  currentModel: 'add' | 'delete' | 'edit' | 'deploy' | null;
  currentFaq: IGetFaqType;
  formError: string;
  setCurrentFaq: (faq: IGetFaqType) => void;
  onSubmit: (payload: { id: string; faq: IGetFaqType }) => void;
  onClosePopup: () => void;
  uploading: boolean;
}

export const EditPopup: FC<PropsType> = ({
  currentModel,
  currentFaq,
  formError,
  setCurrentFaq,
  onSubmit,
  onClosePopup,
  uploading,
}) => {
  const onEditTabHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCurrentFaq({
      ...currentFaq,
      [e.currentTarget.id]: e.currentTarget.value,
    });
  };

  const onSubmitButtonClickHandle = () => {
    onSubmit({ id: currentFaq.uuid, faq: currentFaq });
  };

  return (
    <PopUpLayout open={currentModel === 'edit'} onClose={onClosePopup}>
      {currentFaq && (
        <>
          <Styled.PopUpContainer>
            <Styled.ShadowWrapper>
              <Styled.StyledInputFieldWrapper width="100%">
                <Styled.StyledInputLabel>Faq title</Styled.StyledInputLabel>
                <Styled.StyledInputField
                  id="subject"
                  onChange={onEditTabHandler}
                  value={currentFaq.subject}
                />
              </Styled.StyledInputFieldWrapper>
            </Styled.ShadowWrapper>
            <Styled.ShadowWrapper>
              <Styled.StyledTextAreaWrapper
                margin="25px 0"
                width="650px"
                height="200px"
              >
                <Styled.StyledTextAreaLabel>Faq description</Styled.StyledTextAreaLabel>
                <Styled.StyledTextAreaField
                  id="content"
                  onChange={onEditTabHandler}
                  value={currentFaq.content}
                />
              </Styled.StyledTextAreaWrapper>
            </Styled.ShadowWrapper>
            <Styled.PopupButtonsContainer>
              <Button
                title="Send"
                width="80px"
                variant="primary"
                onClick={onSubmitButtonClickHandle}
              />
              <Button
                title="Cancel"
                width="80px"
                variant="warning"
                onClick={onClosePopup}
              />
            </Styled.PopupButtonsContainer>
            {uploading && (
              <Loader
                position="absolute"
                bottom="70px"
                bubbleSize="20px"
                width="100px"
              />
            )}
            {formError && <Styled.ErrorMessage>{formError}</Styled.ErrorMessage>}
          </Styled.PopUpContainer>
        </>
      )}
    </PopUpLayout>
  );
};