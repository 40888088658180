import { styled, css } from '@mui/material';
import { mountingAnimation } from '../themes';

export const ArrowWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  '& svg': {
    transform: `rotate(${isOpen ? 0 : -90}deg)`,
    fill: theme.palette.green.dark,
    transition: 'all .1s linear',
  },
}));

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>(({ disabled }) => ({
  position: 'relative',
  opacity: disabled ? 0.6 : 1,
  cursor: disabled ? 'default' : 'pointer',

  '& *': {
    cursor: disabled ? 'default' : 'pointer',
  },
}));

export const Backdrop = styled('div')({
  position: 'absolute',
  zIndex: 2,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

export const ListContainer = styled('div')(
  ({ theme }) => ({
    width: '100%',
    maxHeight: 200,
    borderRadius: 15,
    backgroundColor: theme.palette.light.main,
    marginTop: 5,
    boxShadow: '-32px 9px 31px #E1EBE9',
    overflowY: 'scroll',

    display: 'flex',
    flexDirection: 'column',

    position: 'absolute',
    left: 0,
    top: '100%',
    right: 0,
    zIndex: 3,

    '&::-webkit-scrollbar': {
      width: 0,
    },

    scrollbarWidth: 'none',
  }),
  css`
    animation: ${mountingAnimation} 0.2s linear;
  `,
);

export const ListItem = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 16,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,

  color: theme.palette.green.dark,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '16px',

  '&:last-child': {
    border: 'none',
  },
}));

export const HiddenInput = styled('input')({
  opacity: 0,
  right: 9999,
  position: 'absolute',
});
