import { EnvironmentAPI } from '../interfaces/api/environment';
import { Environment } from '../interfaces/environment';

export const environmentMapper = (environment: EnvironmentAPI): Environment => {
  const {
    uuid,
    active,
    standard,
    name,
    description,
    led_level,
    led_start_time,
    led_stop_time,
    led_time,
    vpa_level,
    nutrition_level,
    nutrition_ml,
  } = environment;
  return {
    uuid,
    active,
    standard,
    name,
    description,
    ledLevel: led_level,
    ledStartTime: led_start_time,
    ledStopTime: led_stop_time,
    ledTime: led_time,
    vpaLevel: vpa_level,
    nutritionMl: nutrition_ml,
    nutritionLevel: nutrition_level,
  };
};
