import { FC, useState } from 'react';
import { defaultTheme } from '@plant/ui';
import * as Styled from './style';
import LOGO from '../../assets/images/dashboard/Logo.png';
import { RouteNames } from '../../router';
import {
  faUsers,
  faComments,
  faFolderTree,
  faArrowRightFromBracket,
  faMicrochip,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as ASelector from '../../store/selectors/auth';
import { Popover } from '@mui/material';
import { Button } from '../Button';
import { resetAllUsersAction } from '../../store/actions/users';
import { resetContentActionAction } from '../../store/actions/content';

type PropsType = {
  currentPage: string;
  onLogOutButtonHandler: () => void;
  onChangeButtonPageHandler: (url: string) => void;
};

export const AsideMenu: FC<PropsType> = ({
  currentPage,
  onLogOutButtonHandler,
  onChangeButtonPageHandler,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const userEmail = useSelector(ASelector.emailSelector);
  const firstName = useSelector(ASelector.firstNameSelector);
  const lastName = useSelector(ASelector.lastNameSelector);

  const dispatch = useDispatch();

  const id = anchorEl ? 'simple-popover' : null;

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const opSetCurrentPageButtonClick = (url: string) => () => {
    dispatch(resetAllUsersAction());
    dispatch(resetContentActionAction());
    onChangeButtonPageHandler(url);
  };

  return (
    <Styled.AsideMenuWrapper>
      <Styled.Logo src={LOGO} />
      <Styled.ButtonsContainer>
        {[
          {
            order: 1,
            title: 'Users',
            redirectUrl: RouteNames.DashboardUsers,
            activeIcon: <Styled.StyledIcon icon={faUsers} />,
            passiveIcon: <Styled.StyledIcon icon={faUsers} />,
          },
          {
            order: 2,
            title: 'Notifications',
            redirectUrl: RouteNames.DashboardNotifications,
            activeIcon: <Styled.StyledIcon icon={faComments} />,
            passiveIcon: <Styled.StyledIcon icon={faComments} />,
          },
          {
            order: 3,
            title: 'Content',
            redirectUrl: RouteNames.DashboardContent,
            activeIcon: <Styled.StyledIcon icon={faFolderTree} />,
            passiveIcon: <Styled.StyledIcon icon={faFolderTree} />,
          },
          {
            order: 4,
            title: 'Firmware',
            redirectUrl: RouteNames.DashboardFirmware,
            activeIcon: <Styled.StyledIcon icon={faMicrochip} />,
            passiveIcon: <Styled.StyledIcon icon={faMicrochip} />,
          },
        ].map((button) => {
          return (
            <Styled.StyledButton
              key={button.title}
              active={button.title.toLowerCase() === currentPage}
              onClick={
                button.title.toLowerCase() === currentPage
                  ? null
                  : opSetCurrentPageButtonClick(button.redirectUrl)
              }
            >
              {button.title.toLowerCase() === currentPage ? button.activeIcon : button.passiveIcon}
              {button.title}
            </Styled.StyledButton>
          );
        })}
      </Styled.ButtonsContainer>
      <Styled.StyledMenuButton onClick={handleMenuButtonClick}>
        <Styled.StyledIcon icon={faArrowRightFromBracket} />
      </Styled.StyledMenuButton>
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Styled.StyledPopoverInternalContainer>
          <Styled.StyledSpan
            color={defaultTheme.palette.secondary.dark}
            fontWeight={600}
            fontSize="17px"
          >
            <>
              {firstName} {lastName}
            </>
          </Styled.StyledSpan>
          <Styled.StyledSpan
            color={defaultTheme.palette.primary.dark}
            fontWeight={500}
            fontSize="14px"
          >
            <>{userEmail}</>
          </Styled.StyledSpan>
        </Styled.StyledPopoverInternalContainer>
        <Button
          marginBottom="20px"
          onClick={onLogOutButtonHandler}
          width="100px"
          variant="warning"
          title="Log out"
        />
      </Popover>
    </Styled.AsideMenuWrapper>
  );
};
