import { FC } from "react";
import * as Styled from './style';

type PropsType = {
  title: string
  value: string | number
}

export const InfoLine: FC<PropsType> = ({
  title,
  value
}) => {
  return (
    <Styled.InfoLineContainer>
      <Styled.InfoLineTitle>{title}</Styled.InfoLineTitle>
      <Styled.InfoLineValue>{value}</Styled.InfoLineValue>
    </Styled.InfoLineContainer>
  )
}