import * as S from './style';
import { Loader } from  '../Loader';
import { FC } from 'react';

type PropsType = {
  position?: 'absolute' | 'relative' | 'fixed';
}

export const LoadingScreen: FC<PropsType> = ({
  position
}) => {
  return (
    <S.LoaderWrapper position={position}>
      <Loader />
    </S.LoaderWrapper>
  )
}