import React from 'react';
import { Icon } from '@plant/data';

const NutritionIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.98361 5.38791V2.03102H9.03907C9.31243 2.03102 9.53429 1.80342 9.53429 1.52301V0.508007C9.53429 0.22759 9.31243 0 9.03907 0L5.19009 0.00101652C4.91673 0.00101652 4.69487 0.228607 4.69487 0.509024V1.52402C4.69487 1.80444 4.91673 2.03203 5.19009 2.03203H5.24556V5.38996C2.25943 6.23936 0.131836 9.07822 0.131836 12.2897C0.131836 16.2391 3.26452 19.4527 7.11452 19.4527C10.9645 19.4527 14.0972 16.2391 14.0972 12.2897C14.0972 9.07796 11.9697 6.2392 8.98348 5.38788L8.98361 5.38791ZM7.11462 18.4378C3.81053 18.4378 1.12131 15.6802 1.12131 12.2897C1.12131 9.4143 3.10716 6.8874 5.8438 6.28188L6.23404 6.19552L6.23503 2.03075H7.99408V6.19552L8.38433 6.28188C11.121 6.88743 13.1068 9.4143 13.1068 12.2897C13.1078 15.6802 10.4186 18.4378 7.11452 18.4378H7.11462Z" />
    <path d="M12.0532 10.4954C10.5536 10.346 8.42431 10.4375 6.17374 11.5053C3.94422 12.564 2.6597 12.0438 1.94341 11.3153C1.87309 11.6689 1.83545 12.0337 1.83545 12.4086C1.83545 15.3987 4.19867 17.8239 7.11449 17.8239C10.0294 17.8239 12.3935 15.3997 12.3935 12.4086C12.3935 11.735 12.2727 11.0908 12.0528 10.4954L12.0532 10.4954Z" />
  </svg>
);

export default React.memo(NutritionIcon);
