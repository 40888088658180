import styled from 'styled-components';
import Modal from '@mui/material/Modal';

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DeletePopupContentWrapper = styled.div`
  ${props => ({
    background: props.theme.palette.admin.main
  })}  padding: 25px;
  border-radius: 15px;
  outline: none;
`