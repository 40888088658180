import * as Styled from './style';
import { Loader } from '../../../Loader';
import { Button } from '../../../Button';
import { PopUpLayout } from '../../../../layouts/PopUp';
import { IGetFaqType } from '@plant/data';
import { FC } from 'react';

type PropsType = {
  currentModel: 'add' | 'delete' | 'edit' | 'deploy' | null
  currentFaq: IGetFaqType
  onSubmit: ({ id: string }) => void
  onClosePopup: () => void,
  uploading: boolean
}

export const DeletePopup: FC<PropsType> = ({
  currentModel,
  currentFaq,
  onSubmit,
  onClosePopup,
  uploading
}) => {
  const onSubmitJHandle = () => {
    onSubmit({ id: currentFaq.uuid })
  } 
  return(
    <PopUpLayout
      open={currentModel === 'delete'}
      onClose={onClosePopup}
    >
      <Styled.PopUpContainer>
        <Styled.PopupTitle>
          Are you sure that you want to delete this "about" article?
        </Styled.PopupTitle>
        <Styled.PopupButtonsContainer>
          <Button width='80px' title='Yes' variant='primary' onClick={onSubmitJHandle}/>
          <Button width='80px' title='No' variant='warning' onClick={onClosePopup}/>
        </Styled.PopupButtonsContainer>

        <Styled.PopupWarning>
          Warning: article will be deleted permanently!
        </Styled.PopupWarning>
        {uploading && <Loader position='absolute' bottom='0' bubbleSize='20px' width='100px'/>}
      </Styled.PopUpContainer>
    </PopUpLayout>
  )
}