import React, { useEffect, useState } from 'react';
import * as S from './style';
import { useDispatch } from 'react-redux';
import { getListOfCropsAction, loadMoreCropsCropsAction } from '../../store/actions/content';
import { useSelector } from 'react-redux';
import { cropSelector, loadingSelector, uploadingSelector, uploadIsFinishedSelector, loadingMoreCropsSelector, loadingMoreCropsCompleteSelector } from '../../store/selectors/content';
import { authAccessTokenSelector } from '../../store/selectors/auth';
import { LoadingScreen } from '../LoadingScreen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Loader } from '../Loader';
import { IGetCropInterface } from '@plant/data';
import { changeCropArticleStatusAction } from '../../store/actions/content';
import { ShowHideCropPopup } from './components/ShowHideCropPopup';
import { RouteNames } from '../../router';
import { useNavigate } from "react-router-dom";

export const CropList = () => {

  const navigate = useNavigate();

  const count = 9;

  const dispatch = useDispatch();

  const crops = useSelector(cropSelector);
  const loading = useSelector(loadingSelector);
  const uploading = useSelector(uploadingSelector);
  const loadingMoreCrops = useSelector(loadingMoreCropsSelector);
  const loadingMoreCropsComplete = useSelector(loadingMoreCropsCompleteSelector);
  const uploadIsFinished = useSelector(uploadIsFinishedSelector);
  
  const token = useSelector(authAccessTokenSelector);

  const [ page, setPage ] = useState(1);
  const [ openPopup, setOpenPopup ] = useState(false);
  const [ currentCropId, setCurrentCropId ] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getListOfCropsAction.request({
      count,
      page: 1
    }))
  }, [dispatch])

  useEffect(() => {
    if(uploadIsFinished) {
      setOpenPopup(false);
    }
  }, [uploadIsFinished])

  const loadMore = () => {
    dispatch(loadMoreCropsCropsAction.request({
      count,
      page: page + 1
    }))
    setPage(page + 1)
  }

  const onContainerScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = (e.target as HTMLElement);
    const nearTheListBottom = target.scrollHeight - target.offsetHeight - target.scrollTop - 10 < 0;
    if (nearTheListBottom && !loadingMoreCrops) {
      loadMore()
    }
  }

  const onCropClick = (cropUuid: string) => () => {
    navigate(RouteNames.DashboardContentCrop.replace(':id', cropUuid));
  }

  const onClosePopup = () => {
    setOpenPopup(false);
    setCurrentCropId(null);
  }

  const onDeployButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenPopup(true);
    setCurrentCropId(e.currentTarget.id)
  }

  const onSubmitButtonClick = (crop: IGetCropInterface) => {
    const dataToUpload = {
      active: !crop.active,
      uuid: crop.uuid,
      full_description: crop.fullDescription || '',
      type: crop.type || '',
      harvest_description: crop.harvest.description || '',
      harvest_time: crop.harvest.time || '',
      replant_description: crop.replant.description || '',
      replant_time: crop.replant.time || '',
      lifespan_description: crop.lifespan.description || '',
      lifespan_time: crop.lifespan.time || '',
      flavorUuids: crop.flavor.map(n => n.uuid),
      nutritionUuids: crop.nutrition.map(n => n.uuid),
      video: crop.video,
    };
    const formData = new FormData();
    Object.keys(dataToUpload).forEach(key => {
      if (Array.isArray(dataToUpload[key])) {
        formData.append(key, JSON.stringify(dataToUpload[key]));
      } else {
        formData.append(key, dataToUpload[key]);
      }
    })
    dispatch(changeCropArticleStatusAction.request(formData))
  }

  return (
    <S.StyledCropListContainer onScroll={!loadingMoreCropsComplete ? onContainerScroll : null}>
      {crops && !loading && crops.map(crop => {
        return (
          <S.CropWrapper onClick={onCropClick(crop.uuid)} key={crop.uuid}>
            <S.CropImage src={`${crop.photo}?token=${token}`}/>
            <S.TextContainer>
              <S.StyledCropName>
                {crop.name}
              </S.StyledCropName>
              <S.StyledShortDescription>
                {crop.shortDescription}
              </S.StyledShortDescription>
            </S.TextContainer>
            <S.DeployButton id={crop.uuid} onClick={onDeployButtonClick} active={crop.active}>
              {crop.active ? <VisibilityIcon/> : <VisibilityOffIcon/>}
            </S.DeployButton>
          </S.CropWrapper>
        )
      })}
      {!crops.length && loading && <LoadingScreen/>}
      {
        loadingMoreCrops && 
        <Loader
          position='relative'
          bottom='0'
          left='100%'
          margin='0 auto'
          bubbleSize='11px'
          height='30px'
          width='70px'
        />
      }
      <ShowHideCropPopup onSubmit={onSubmitButtonClick} currentCropId={currentCropId} crops={crops} loading={uploading} open={openPopup} onClose={onClosePopup}/>
    </S.StyledCropListContainer>
  )
}