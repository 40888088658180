import { FC } from "react";
import { StyledText } from "../../style";
import * as Styled from './style';

type PropType = {
  title: string,
  callback: () => void
  active?: boolean
  position?: 'absolute' | 'relative'
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  status: "offline" | "online"
}

export const StatusButtonComponent: FC<PropType> = ({
  title,
  callback,
  position,
  top,
  right,
  bottom,
  left,
  active,
  status
}) => {
  return (
    <Styled.StatusButton
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      onClick={callback}
      active={active}
    >
      <Styled.StatusButtonText>
        {title}
      </Styled.StatusButtonText>
      <Styled.StatusButtonText
        margin='0 0 0 10px'
        active={status === 'offline' || status === 'online'}
        isOnline={status === 'online'}
        isOffline={status === 'offline'}
      >
        {
          status === 'offline' ? 'Off' :
          status === 'online' ? 'On' :
          status === null && 'Off/On'
        }
      </Styled.StatusButtonText>
    </Styled.StatusButton>
  )
}