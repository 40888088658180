import { ContentAdminService, Navigator, IGetCropApiResponseInterface, IGetCropInterface, IGetFaqType } from '@plant/data';
import { takeLatest, Effect, call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as ContentActions from '../actions/content';
import { RouteNames } from '../../router/index'
import { snackToCamel } from '../../helpers/snakeToCamel';

export class ContentSagaWorker {
  static *getNutrition() {
    try {
      const response: SagaReturnType<typeof ContentAdminService.getNutrition> = yield call(
        ContentAdminService.getNutrition,
      );
      yield put(ContentActions.getNutritionAction.success(response.data))
    } catch (error) {
      yield put(ContentActions.getNutritionAction.failure(error?.response?.data))
    }
  }

  static *getFlavor() {
    try {
      const response: SagaReturnType<typeof ContentAdminService.getFlavor> = yield call(
        ContentAdminService.getFlavor,
      );
      yield put(ContentActions.getFlavorAction.success(response.data))
    } catch (error) {
      yield put(ContentActions.getFlavorAction.failure(error?.response?.data))
    }
  }

  static *uploadCropArticle({ payload }: ActionType<typeof ContentActions.uploadCropArticleAction.request>) {
    try {
      yield call(ContentAdminService.uploadCropArticle, payload);
      yield put(ContentActions.uploadCropArticleAction.success())
      Navigator.push(RouteNames.DashboardContent)
    } catch (error) {
      yield put(ContentActions.uploadCropArticleAction.failure(error?.response?.data))
    }
  }

  static *updateCropArticle({ payload }: ActionType<typeof ContentActions.updateCropArticleAction.request>) {
    try {
      yield call(ContentAdminService.updateCropArticle, payload);
      yield put(ContentActions.updateCropArticleAction.success());
      Navigator.push(RouteNames.DashboardContent)
    } catch (error) {
      yield put(ContentActions.updateCropArticleAction.failure(error?.response?.data))
    }
  }

  static *changeCropArticleStatus({ payload }: ActionType<typeof ContentActions.changeCropArticleStatusAction.request>) {
    try {
      const id: string = payload.get('uuid').toString();
      yield call(ContentAdminService.updateCropArticle, payload);
      yield put(ContentActions.changeCropArticleStatusAction.success({ id }))
    } catch (error) {
      yield put(ContentActions.changeCropArticleStatusAction.failure(error?.response?.data))
    }
  }

  static *deleteCropArticle({ payload }: ActionType<typeof ContentActions.deleteCropByIdAction.request>) {
    try {
      yield call(ContentAdminService.deleteCropArticle, payload.id);
      Navigator.push(RouteNames.DashboardContent)
      yield put(ContentActions.deleteCropByIdAction.success())
    } catch (error) {
      yield put(ContentActions.deleteCropByIdAction.failure(error?.response?.data))
    }
  }

  static *getCrop({ payload }: ActionType<typeof ContentActions.getListOfCropsAction.request>) {
    try {
      const response: SagaReturnType<typeof ContentAdminService.getCrop> = 
      yield call(
        ContentAdminService.getCrop,
        { page: payload.page, count: payload.count }
      );
      const mappedResponse: {data: IGetCropInterface[] | []} = snackToCamel(response.data)
      yield put(ContentActions.getListOfCropsAction.success(mappedResponse))
    } catch (error) {
      yield put(ContentActions.getListOfCropsAction.failure(error?.response?.data))
    }
  }

  static *getCropById({ payload }: ActionType<typeof ContentActions.getCropByIdAction.request>) {    
    try {
      const response: SagaReturnType<typeof ContentAdminService.getCropById> = 
      yield call(
        ContentAdminService.getCropById,
        { id: payload.id }
      );
      const mappedResponse: IGetCropInterface = snackToCamel(response.data.data)
      yield put(ContentActions.getCropByIdAction.success(mappedResponse))
    } catch (error) {
      yield put(ContentActions.getCropByIdAction.failure(error?.response?.data))
    }
  }

  static *getCropToSetById({ payload }: ActionType<typeof ContentActions.getCropToChangeByIdAction.request>) {    
    try {
      const response: SagaReturnType<typeof ContentAdminService.getCropById> = 
      yield call(
        ContentAdminService.getCropById,
        { id: payload.id }
      );
      const mappedResponse: IGetCropInterface = snackToCamel(response.data.data)
      yield put(ContentActions.getCropToChangeByIdAction.success(mappedResponse))
      yield put(ContentActions.getNutritionAction.request())
      yield put(ContentActions.getFlavorAction.request())
    } catch (error) {
      yield put(ContentActions.getCropToChangeByIdAction.failure(error?.response?.data))
    }
  }

  static *loadingMoreCrops({ payload }: ActionType<typeof ContentActions.loadMoreCropsCropsAction.request>) {
    try {
      const response: SagaReturnType<typeof ContentAdminService.getCrop> = 
      yield call(
        ContentAdminService.getCrop,
        { page: payload.page, count: payload.count }
      );
      const mappedResponse: {data: IGetCropInterface[] | []} = snackToCamel(response.data)
      yield put(ContentActions.loadMoreCropsCropsAction.success(mappedResponse))
    } catch (error) {
      yield put(ContentActions.loadMoreCropsCropsAction.failure(error?.response?.data))
    }
  }


  static *getListFaqs({ payload }: ActionType<typeof ContentActions.getListOfFaqsAction.request>) {
    try {
      const response: SagaReturnType<typeof ContentAdminService.getFaq> = 
      yield call(
        ContentAdminService.getFaq,
        { page: payload.page, count: payload.count }
      );
      const mappedResponse: {data: IGetFaqType[] | []} = snackToCamel(response.data)
      yield put(ContentActions.getListOfFaqsAction.success(mappedResponse))
    } catch (error) {
      yield put(ContentActions.getListOfFaqsAction.failure(error?.response?.data))
    }
  }

  static *loadingMoreFaqs({ payload }: ActionType<typeof ContentActions.loadMoreFaqsAction.request>) {
    try {
      const response: SagaReturnType<typeof ContentAdminService.getFaq> = 
      yield call(
        ContentAdminService.getFaq,
        { page: payload.page, count: payload.count }
      );
      const mappedResponse: {data: IGetFaqType[] | []} = snackToCamel(response.data)
      yield put(ContentActions.loadMoreFaqsAction.success(mappedResponse))
    } catch (error) {
      yield put(ContentActions.loadMoreFaqsAction.failure(error?.response?.data))
    }
  }

  static *deleteFaqById({ payload }: ActionType<typeof ContentActions.deleteFaqByIdAction.request>) {
    try {
      yield call(ContentAdminService.deleteFaq, payload.id);
      yield put(ContentActions.deleteFaqByIdAction.success(payload.id))
    } catch (error) {
      yield put(ContentActions.deleteFaqByIdAction.failure(error?.response?.data))
    }
  }

  static *setFaqById({ payload }: ActionType<typeof ContentActions.setFaqByIdAction.request>) {
    try {
      yield call(ContentAdminService.setFaq, {
        id: payload.id,
        faq: payload.faq
      });
      yield put(ContentActions.setFaqByIdAction.success(payload.id))
    } catch (error) {
      yield put(ContentActions.setFaqByIdAction.failure(error?.response?.data))
    }
  }

  static *addFaqById({ payload }: ActionType<typeof ContentActions.addFaqByIdAction.request>) {
    try {
      yield call(ContentAdminService.addFaq, {
        faq: payload.faq
      });
      yield put(ContentActions.addFaqByIdAction.success())
    } catch (error) {
      yield put(ContentActions.addFaqByIdAction.failure(error?.response?.data))
    }
  }




  static *getListAbout({ payload }: ActionType<typeof ContentActions.getListOfAboutAction.request>) {
    try {
      const response: SagaReturnType<typeof ContentAdminService.getAbout> = 
      yield call(
        ContentAdminService.getAbout,
        { page: payload.page, count: payload.count }
      );
      yield put(ContentActions.getListOfAboutAction.success(response.data))
    } catch (error) {
      yield put(ContentActions.getListOfAboutAction.failure(error?.response?.data))
    }
  }

  static *loadingMoreAbout({ payload }: ActionType<typeof ContentActions.loadMoreAboutAction.request>) {
    try {
      const response: SagaReturnType<typeof ContentAdminService.getAbout> = 
      yield call(
        ContentAdminService.getAbout,
        { page: payload.page, count: payload.count }
      );
      yield put(ContentActions.loadMoreAboutAction.success(response.data))
    } catch (error) {
      yield put(ContentActions.loadMoreAboutAction.failure(error?.response?.data))
    }
  }

  static *deleteAboutById({ payload }: ActionType<typeof ContentActions.deleteAboutByIdAction.request>) {
    try {
      yield call(ContentAdminService.deleteAbout, payload.id);
      yield put(ContentActions.deleteAboutByIdAction.success(payload.id))
    } catch (error) {
      yield put(ContentActions.deleteAboutByIdAction.failure(error?.response?.data))
    }
  }

  static *setAboutById({ payload: { faq, successCallback } }: ActionType<typeof ContentActions.setAboutByIdAction.request>) {
    try {
      yield call(ContentAdminService.setAbout, faq);
      yield put(ContentActions.setAboutByIdAction.success(faq.uuid))
    } catch (error) {
      yield put(ContentActions.setAboutByIdAction.failure(error?.response?.data))
    }
  }

  static *addAboutById({ payload: { faq, successCallback } }: ActionType<typeof ContentActions.addAboutByIdAction.request>) {
    try {
      yield call(ContentAdminService.addAbout, { faq });
      yield put(ContentActions.addAboutByIdAction.success());
      yield call(successCallback);
    } catch (error) {
      yield put(ContentActions.addAboutByIdAction.failure(error?.response?.data))
    }
  }

}

export function* contentSaga(): Generator<Effect, void> {
  yield takeLatest(ContentActions.getNutritionAction.request, ContentSagaWorker.getNutrition);
  yield takeLatest(ContentActions.getFlavorAction.request, ContentSagaWorker.getFlavor);
  yield takeLatest(ContentActions.uploadCropArticleAction.request, ContentSagaWorker.uploadCropArticle);
  yield takeLatest(ContentActions.updateCropArticleAction.request, ContentSagaWorker.updateCropArticle);
  yield takeLatest(ContentActions.changeCropArticleStatusAction.request, ContentSagaWorker.changeCropArticleStatus);
  yield takeLatest(ContentActions.deleteCropByIdAction.request, ContentSagaWorker.deleteCropArticle);
  yield takeLatest(ContentActions.getListOfCropsAction.request, ContentSagaWorker.getCrop);
  yield takeLatest(ContentActions.loadMoreCropsCropsAction.request, ContentSagaWorker.loadingMoreCrops);
  yield takeLatest(ContentActions.getCropByIdAction.request, ContentSagaWorker.getCropById);
  yield takeLatest(ContentActions.getCropToChangeByIdAction.request, ContentSagaWorker.getCropToSetById);
  yield takeLatest(ContentActions.getListOfFaqsAction.request, ContentSagaWorker.getListFaqs);
  yield takeLatest(ContentActions.loadMoreFaqsAction.request, ContentSagaWorker.loadingMoreFaqs);
  yield takeLatest(ContentActions.deleteFaqByIdAction.request, ContentSagaWorker.deleteFaqById);
  yield takeLatest(ContentActions.setFaqByIdAction.request, ContentSagaWorker.setFaqById);
  yield takeLatest(ContentActions.addFaqByIdAction.request, ContentSagaWorker.addFaqById);

  yield takeLatest(ContentActions.getListOfAboutAction.request, ContentSagaWorker.getListAbout);
  yield takeLatest(ContentActions.loadMoreAboutAction.request, ContentSagaWorker.loadingMoreAbout);
  yield takeLatest(ContentActions.deleteAboutByIdAction.request, ContentSagaWorker.deleteAboutById);
  yield takeLatest(ContentActions.setAboutByIdAction.request, ContentSagaWorker.setAboutById);
  yield takeLatest(ContentActions.addAboutByIdAction.request, ContentSagaWorker.addAboutById);
}