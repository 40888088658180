import styled, { keyframes } from "styled-components";

const expand = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.7);
    opacity: 1;
  }
  100% {
    transform: scale(0.6);
    opacity: 0.5;
  }
`

export const Loader = styled.div<{
  height?: string;
  width?: string
  position?: 'absolute' | 'relative' ;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  margin?: string;
}>`
  width: 50%;
  max-width: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${props => ({
    height: props.height,
    width: props.width,
    position: props.position,
    top: props.top,
    left: props.left,
    right: props.right,
    bottom: props.bottom,
    margin: props.margin,
  })}
`

export const Bubble = styled.div<{
  bubbleSize?: string
}>`
  border-radius: 50%;
  display: inline-block;
  transform-origin: center center;
  margin: 0 3px;
  width: 30px;
  height: 30px;
  animation: ${expand} 0.6s ease-in-out infinite;
  ${props => ({
    width: props.bubbleSize,
    height: props.bubbleSize,
    background: props.theme.palette.secondary.main,
  })}
  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0s;
  }
`

