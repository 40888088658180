import React from 'react';
import { Icon } from '@plant/data';

const ManagmentIcon: React.FC<Icon> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6221 11.3662C17.9313 11.5305 18.1699 11.7899 18.3378 12.0493C18.6648 12.5853 18.6382 13.2425 18.3201 13.8218L17.7016 14.8594C17.3746 15.4128 16.7649 15.7587 16.1375 15.7587C15.8282 15.7587 15.4836 15.6722 15.2009 15.4993C14.9711 15.3523 14.706 15.3004 14.4232 15.3004C13.5484 15.3004 12.815 16.0181 12.7885 16.8741C12.7885 17.8684 11.9755 18.6466 10.9593 18.6466H9.75759C8.73256 18.6466 7.9196 17.8684 7.9196 16.8741C7.90193 16.0181 7.1685 15.3004 6.29369 15.3004C6.00209 15.3004 5.73699 15.3523 5.51608 15.4993C5.23331 15.6722 4.87985 15.7587 4.57941 15.7587C3.94319 15.7587 3.33347 15.4128 3.00652 14.8594L2.39681 13.8218C2.06986 13.2598 2.05218 12.5853 2.37913 12.0493C2.52052 11.7899 2.78561 11.5305 3.08605 11.3662C3.33347 11.2451 3.49253 11.0462 3.64275 10.8128C4.08457 10.0692 3.81948 9.09211 3.06838 8.65114C2.19357 8.15828 1.9108 7.06016 2.41448 6.20414L3.00652 5.18384C3.51904 4.32783 4.61476 4.0252 5.49841 4.5267C6.26718 4.94174 7.2657 4.66505 7.71636 3.93008C7.85775 3.68798 7.93727 3.42858 7.9196 3.16918C7.90193 2.83196 7.99913 2.51204 8.16702 2.25264C8.49397 1.71655 9.08602 1.37069 9.73108 1.35339H10.977C11.6309 1.35339 12.223 1.71655 12.5499 2.25264C12.709 2.51204 12.815 2.83196 12.7885 3.16918C12.7708 3.42858 12.8504 3.68798 12.9917 3.93008C13.4424 4.66505 14.4409 4.94174 15.2185 4.5267C16.0933 4.0252 17.1979 4.32783 17.7016 5.18384L18.2936 6.20414C18.8061 7.06016 18.5234 8.15828 17.6397 8.65114C16.8886 9.09211 16.6235 10.0692 17.0742 10.8128C17.2156 11.0462 17.3746 11.2451 17.6221 11.3662ZM7.85777 10.0086C7.85777 11.3661 8.98 12.4469 10.3673 12.4469C11.7547 12.4469 12.8504 11.3661 12.8504 10.0086C12.8504 8.65105 11.7547 7.56158 10.3673 7.56158C8.98 7.56158 7.85777 8.65105 7.85777 10.0086Z"
      />
    </svg>
  );
};

export default React.memo(ManagmentIcon);
