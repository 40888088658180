import { SuccessResponse, NotificationsData } from '../interfaces';
import { ApiService } from './apiService';

export class NotificationsService {
  static async updateNotifications(data: NotificationsData): Promise<NotificationsData> {
    console.log(data)
    return ApiService.api
      .put<SuccessResponse<NotificationsData>>(`/users/notifications`, data)
      .then((response) => {
        const { pushNotifications, emailNotifications } = response.data.data;
        return {
          pushNotifications,
          emailNotifications,
        };
      });
  }
}
