import React from 'react';
import { Icon } from '@plant/data';

const SproutedSeedReplantIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox={"0 0 14 16"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.8615 8.80922C11.9639 6.29251 7.8282 5.46782 5.31149 6.36478C3.67447 6.94843 2.54774 8.32278 2.20643 9.90555C1.39063 9.9521 1.89968 6.17808 3.62917 4.77146C5.84858 5.80092 7.10426 4.0034 7.10426 4.0034C6.33679 3.02586 5.25718 3.04734 4.35487 3.32366C5.36105 1.58229 4.13406 0.486572 4.13406 0.486572C2.48635 1.05352 2.61523 2.92323 2.845 4.00581C1.03259 5.52942 -0.107283 7.79241 0.188036 10.497C0.310378 11.6208 0.845699 12.6072 2.42776 12.6723C3.37128 15.1066 6.08725 16.3611 8.56149 15.479C11.0782 14.5814 13.759 11.3259 12.8614 8.8093L12.8615 8.80922Z" />
  </svg>
);

export default React.memo(SproutedSeedReplantIcon);
