export interface ISignInRequest {
  email: string;
  password: string;
}

export interface IEnterCodeRequest {
  email: string;
  code: string;
}

export interface IUserData {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  alias: string;
  code: number;
  is_owner: boolean;
  updated_on: string;
  social_auth_token: null | string;
  photo: string;
  type: number;
  confirm: boolean;
  status: string;
  token: string;
}

export interface ISignInSuccessFullResponse {
  data: {
    data: IUserData;
    token: {
      refreshToken: string;
      token: string;
    };
  };
}

export interface ISignInFailedResponse {
  status: string;
  error: string;
  message?: string;
}
