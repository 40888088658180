import styled, { css } from 'styled-components';

export const StyledCropListContainer = styled.div`
  height: 88%;
  position: relative;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  align-self: end;
  overflow-y: auto;
  border-radius: 15px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CropWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${props => ({
    background: props.theme.palette.light.main,
  })}
`

export const CropImage = styled.img`
  width: 150px;
  height: 150px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledCropName = styled.span`
  font-weight: 600;
  text-align: center;
  margin: 15px 0;
  font-size: 17px;
  ${props => ({
    color: props.theme.palette.secondary.main,
  })}
`

export const StyledShortDescription = styled.div`
  font-weight: 500;
  height: 55px;
  width: 100%;
  max-width: 150px;
  font-size: 14px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  ${props => ({
    color: props.theme.palette.secondary.main,
  })}
`

export const DeployButton = styled.button<{
  active?: boolean;
}>`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  ${props => ({
    background: props.active ? props.theme.palette.secondary.main : props.theme.palette.secondary.light,
    color: props.active ? props.theme.palette.light.main : props.theme.palette.error.main,
  })}
`