import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
`;

export const StyledInputWrapper = styled.div`
  ${(props) => ({
    background: props.theme.palette.light.main,
  })}
  display: flex;
  flex-direction: column;
  padding: 10px 35px 15px;
  border-radius: 50px;
`;

export const StyledInputLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  ${(props) => ({
    color: props.theme.palette.secondary.main,
  })};
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: 17px;
  font-weight: 600;
  ${(props) => ({
    color: props.theme.palette.secondary.main,
  })};
  margin-top: 5px;
  &::placeholder {
    ${(props) => ({
      color: props.theme.palette.secondary.light,
    })};
    font-weight: 400;
  }
`;

export const StyledTextArea = styled.textarea`
  border: none;
  outline: none;
  font-size: 17px;
  font-weight: 600;
  resize: vertical;
  min-height: 100px;
  max-height: 500px;
  ${(props) => ({
    color: props.theme.palette.secondary.main,
  })};
  margin-top: 5px;
  &::placeholder {
    ${(props) => ({
      color: props.theme.palette.secondary.light,
    })};
    font-weight: 400;
  }
`;
