export interface Environment {
  uuid: string;
  active: boolean;
  standard: boolean;
  name: string;
  description: string;
  ledLevel: number;
  ledTime: number;
  ledStartTime: number;
  ledStopTime: number;
  nutritionLevel: number;
  nutritionMl: number;
  vpaLevel: number;
}
