import { FC } from "react";
import { PopUpLayout } from "../../../../layouts/PopUp";
import { IGetFaqType } from "@plant/data";
import { Button } from "../../../Button";
import { Loader } from "../../../Loader";
import * as Styled from './style';

type PropsType = {
  currentModel: 'add' | 'delete' | 'edit' | 'deploy' | null;
  currentFaq: IGetFaqType;
  onSubmit: (payload: { id: string; faq: IGetFaqType }) => void;
  onClosePopup: () => void;
  uploading: boolean;
}

export const DeployPopup: FC<PropsType> = ({
  currentModel,
  currentFaq,
  onSubmit,
  onClosePopup,
  uploading,
}) => {
  const onSubmitButtonClickHandle = () => {
    onSubmit({ id: currentFaq.uuid, faq: currentFaq });
  };

  return (
    <PopUpLayout open={currentModel === 'deploy'} onClose={onClosePopup}>
      {currentFaq && (
        <>
          <Styled.PopUpContainer>
            <Styled.PopupTitle>
              {currentFaq.active
                ? 'Are you sure to show this FAQ'
                : 'Are you sure to hide this FAQ'}
            </Styled.PopupTitle>
            <Styled.PopupButtonsContainer>
              <Button
                title="Send"
                width="80px"
                variant="primary"
                onClick={onSubmitButtonClickHandle}
              />
              <Button
                title="Cancel"
                width="80px"
                variant="warning"
                onClick={onClosePopup}
              />
            </Styled.PopupButtonsContainer>
            {uploading && (
              <Loader
                position="absolute"
                bottom="70px"
                bubbleSize="20px"
                width="100px"
              />
            )}
          </Styled.PopUpContainer>
        </>
      )}
    </PopUpLayout>
  );
};