import { createReducer } from 'typesafe-actions';
import { IUsersDataSuccessFullResponse } from '@plant/data';
import * as NotificationsActions from '../actions/notifications';

export interface State {
  data: IUsersDataSuccessFullResponse | [];
  emailLoading: boolean;
  selectedEmailTags: { email: string; uuid: string }[];
  subject: string;
  emailContent: string;
  inputValue: string;
  selectedAllEmail: boolean;
  isSendingEmails: boolean;
  sendIsFinished: boolean;
}

export const initialState: State = {
  data: [],
  emailLoading: false,
  selectedEmailTags: [],
  subject: '',
  emailContent: '',
  inputValue: '',
  selectedAllEmail: false,
  isSendingEmails: false,
  sendIsFinished: false,
};

export const reducer = createReducer<State, NotificationsActions.UsersActionUnion>(initialState)
  .handleAction(NotificationsActions.getUsersEmailsAction.request, (state) => ({
    ...state,
    emailLoading: true,
  }))
  .handleAction(NotificationsActions.getUsersEmailsAction.success, (state, action) => {
    return {
      ...state,
      data: action.payload,
      error: null,
      emailLoading: false,
    };
  })

  .handleAction(NotificationsActions.getUsersEmailsAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    emailLoading: false,
  }))

  .handleAction(NotificationsActions.sendEmailToUsersAction.request, (state) => ({
    ...state,
    isSendingEmails: true,
  }))
  .handleAction(NotificationsActions.sendEmailToUsersAction.success, (state) => {
    return {
      ...state,
      error: null,
      isSendingEmails: false,
      data: [],
      selectedEmailTags: [],
      subject: '',
      emailContent: '',
      inputValue: '',
      selectedAllEmail: false,
      sendIsFinished: true,
    };
  })

  .handleAction(NotificationsActions.sendEmailToUsersAction.failure, (state, action) => ({
    ...state,
    error: action.payload?.error,
    isSendingEmails: false,
  }))

  .handleAction(NotificationsActions.setInputValueAction, (state, action) => ({
    ...state,
    inputValue: action.payload,
  }))

  .handleAction(NotificationsActions.selectEmailTagAction, (state, action) => ({
    ...state,
    data: [],
    selectedEmailTags: state.selectedEmailTags.some((email) => email === action.payload)
      ? state.selectedEmailTags.filter((email) => email !== action.payload)
      : [...state.selectedEmailTags, action.payload],
  }))

  .handleAction(NotificationsActions.setEmailContentAction, (state, action) => ({
    ...state,
    emailContent: action.payload,
  }))

  .handleAction(NotificationsActions.setSubjectAction, (state, action) => ({
    ...state,
    subject: action.payload,
  }))

  .handleAction(NotificationsActions.selectAllEmailsAction, (state) => ({
    ...state,
    selectedAllEmail: !state.selectedAllEmail,
  }))

  .handleAction(NotificationsActions.resetUsersEmailsAction, () => initialState);
