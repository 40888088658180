import React from 'react';
import { Icon } from '@plant/data';

const FacebookLogoIcon: React.FC<Icon> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.28185 17.0235V8.51075H7.63174L7.94315 5.57721H5.28185L5.28584 4.10895C5.28584 3.34384 5.35853 2.93387 6.45746 2.93387H7.92651V0H5.57629C2.75329 0 1.75967 1.42308 1.75967 3.81626V5.57755H0V8.51108H1.75967V17.0235H5.28185Z" />
  </svg>
);

export default FacebookLogoIcon;
