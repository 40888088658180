import { AdminAuthService, ApiService, LOCAL_STORAGE_KEYS } from '@plant/data';
import { takeLatest, Effect, call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as AuthActions from '../actions/auth';

export class AuthSagaWorker {
  static *login({ payload }: ActionType<typeof AuthActions.loginAction.request>) {
    try {
      const response: SagaReturnType<typeof AdminAuthService.authCode> = yield call(
        AdminAuthService.authCode,
        payload.email,
        payload.password,
      );
      yield put(AuthActions.loginAction.success({
        email: payload.email,
        status: response.data
      }));
    } catch (error: any) {
      yield put(AuthActions.loginAction.failure(error?.response?.data));
    }
  }

  static *enterCode({ payload }: ActionType<typeof AuthActions.enterCodeAction.request>) {
    try {
      const response: SagaReturnType<typeof AdminAuthService.login> = yield call(
        AdminAuthService.login,
        payload.email,
        payload.code,
      );
      localStorage.setItem(LOCAL_STORAGE_KEYS.refreshToken, response.data.data.token.refreshToken);
      localStorage.setItem(LOCAL_STORAGE_KEYS.token, response.data.data.token.token);
      localStorage.setItem(LOCAL_STORAGE_KEYS.authData, JSON.stringify(response.data.data));
      yield put(AuthActions.setAuthDataAction({ data: response.data.data.data, token: response.data.data.token.token }));
      yield put(AuthActions.enterCodeAction.success(response.data));
      yield ApiService.getToken(response.data.data.token.token);
    } catch (error: any) {
      yield put(AuthActions.enterCodeAction.failure(error?.response?.data?.message));
    }
  }
}

export function* authSaga(): Generator<Effect, void> {
  yield takeLatest(AuthActions.loginAction.request, AuthSagaWorker.login);
  yield takeLatest(AuthActions.enterCodeAction.request, AuthSagaWorker.enterCode);
}
