import { FC } from 'react';
import * as S from './style';

type PropsType = {
  bubbleSize?: string
  height?: string;
  width?: string
  position?: 'absolute' | 'relative' ;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  margin?: string
}

export const Loader: FC<PropsType> = ({
  bubbleSize,
  height,
  width,
  position,
  top,
  left,
  right,
  bottom,
  margin
}) => {
  return (
    <S.Loader
      height={height}
      width={width}
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      margin={margin}
    >
      <S.Bubble bubbleSize={bubbleSize} />
      <S.Bubble bubbleSize={bubbleSize} />
      <S.Bubble bubbleSize={bubbleSize} />
    </S.Loader>
  )
}