import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { State } from '../reducers/auth';

const authStoreSelector = (state: AppState) => state.auth;

export const authLoadingSelector = createSelector(authStoreSelector, (state) => state.loading);
export const firstNameSelector = createSelector(
  authStoreSelector,
  (state: State) => state.data?.firstName,
);
export const lastNameSelector = createSelector(
  authStoreSelector,
  (state: State) => state.data?.lastName,
);
export const authAccessTokenSelector = createSelector(
  authStoreSelector,
  (state) => state.tokens?.token,
);
export const isAuthenticatedSelector = createSelector(
  authStoreSelector,
  (state) => state.isAuthenticated,
);

export const authEmailSelector = createSelector(authStoreSelector, state => state.authEmail);
export const authStatusSelector = createSelector(authStoreSelector, state => state.authStatus);
export const emailSelector = createSelector(authStoreSelector, (state) => state.data?.email);
export const errorSelector = createSelector(authStoreSelector, (state) => state.error);
export const authDataSelector = createSelector(authStoreSelector, (state) => state.data);
