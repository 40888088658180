export const COLUMNS = [
  { 
    field: 'name', 
    headerName: 'Updates Name',
    width: '30%',
  },
  { 
    field: 'description', 
    headerName: 'Description',
    width: '30%',
  },
  {
    field: 'version',
    headerName: 'Version',
    width: '30%',
  },
  {
    field: 'date',
    headerName: 'Downloaded Date',
    width: '40%',
  },
];
