import styled from "styled-components";

export const EnvInfoContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 10px;
`

export const EnvInfoContainerWrapperImg = styled.img`
  height: 15px;
  object-fit: contain;
  object-position: center;
`
export const EnvInfoContainerWrapperValue = styled.span`
${props => ({
  color: props.theme.palette.secondary.main,
})};
  font-size: 32px;
  text-align: center;
`
export const EnvInfoContainerWrapperTitle = styled.span`
    ${props => ({
    color: props.theme.palette.secondary.main
  })};
  font-size: 14px;
  text-align: center;
`