import { FC, ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

type WrapperGuard = (element: ReactElement) => ReactElement;

interface PrivateRouteProps {
  guards: WrapperGuard[];
}

const PrivateRoute: FC<PrivateRouteProps> = ({ guards }) => {
  const guardedRoute = guards.reduce(
    (acc, withGuard) => withGuard(acc),
    <Outlet />
  );

  return guardedRoute;
};

export { PrivateRoute, WrapperGuard, PrivateRouteProps };
